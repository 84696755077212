import { Coordinate } from 'ol/coordinate';
import { transform } from 'ol/proj';

export const EPSG_3867 = 'EPSG:3857'; // default open layers projection
export const SPATIAL_REFERENCE_3996_PROJECTION = 'EPSG:3996'; // geus projection
export const WSG84_PROJECTION = 'EPSG:4326'; // Longitude latitude projection

export function toSpatialReference3996(coordinate: Coordinate): Coordinate {
    return transform(coordinate, WSG84_PROJECTION, SPATIAL_REFERENCE_3996_PROJECTION);
}

export function toEPSG3857(coordinate: Coordinate): Coordinate {
    return transform(coordinate, WSG84_PROJECTION, EPSG_3867);
}

export function fromSpatialReference3996(coordinate: Coordinate): Coordinate {
    return transform(coordinate, SPATIAL_REFERENCE_3996_PROJECTION, WSG84_PROJECTION);
}

export function fromEPSG3857(coordinate: Coordinate): Coordinate {
    return transform(coordinate, EPSG_3867, WSG84_PROJECTION);
}
