<isav-card class="is-full">
    <img
        *isavCardImage
        [isavApiImg]="image"
        [useFallbackImg]="true"
        alt=""
        [class.is-covering]="covering"
        [class.is-containing]="!covering"
    />
    <isav-caption *isavCardImageCaption class="is-size-7">
        <ng-content select="[isavLandingCardCaption]"></ng-content>
    </isav-caption>
    <h2 isavCardHeader class="title is-5 has-text-darkblue mb-0">
        <ng-content select="[isavLandingCardHeading]"></ng-content>
    </h2>
    <ng-content></ng-content>
    <div isavCardFooter>
        <ng-content select="[isavLandingCardFooter]"></ng-content>
    </div>
</isav-card>
