import {
    Component,
    ChangeDetectionStrategy,
    Input,
    NgZone,
    ElementRef,
    OnDestroy,
    ViewChild,
    SimpleChanges,
    OnChanges,
    OnInit,
} from '@angular/core';
import { createPopper, Instance as PopperInstance, Placement } from '@popperjs/core';

import { Observable } from 'rxjs';
import { ViewportObserver } from '../../shared/viewport/viewport';
import { TooltipsService } from '../../core/api/tooltips-service';

@Component({
    selector: 'isav-tooltip-display',
    templateUrl: './tooltip-display.html',
    styleUrls: ['./tooltip-display.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavTooltipDisplay implements OnDestroy, OnChanges, OnInit {
    @Input() tooltipKey;
    @Input() placementTablet: Placement = 'right';
    @Input() map: boolean = false;

    placement: Placement = 'right';

    @ViewChild('icon') icon: ElementRef<HTMLDivElement>;
    @ViewChild('tooltip') tooltip: ElementRef<HTMLDivElement>;

    popperInstance: PopperInstance | null = null;

    value$: Observable<string>;

    isOpened: boolean = false;

    constructor(
        private ngZone: NgZone,
        private tooltipsService: TooltipsService,
        private viewportObserver: ViewportObserver
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tooltipKey) {
            this.value$ = this.tooltipsService.getValue(this.tooltipKey);
        }
    }

    ngOnInit() {
        this.viewportObserver.isMobile$().subscribe((isMobile) => {
            this.placement = isMobile ? 'bottom' : this.placementTablet;
        });
    }

    create() {
        this.isOpened = true;
        this.ngZone.runOutsideAngular(() => {
            this.popperInstance?.destroy();
            this.popperInstance = createPopper(
                this.icon.nativeElement,
                this.tooltip.nativeElement,
                {
                    placement: this.placement,
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 5],
                            },
                        },
                    ],
                }
            );
        });
    }

    destroy() {
        this.isOpened = false;
        this.popperInstance?.destroy();
        this.popperInstance = null;
    }

    ngOnDestroy() {
        this.destroy();
    }
}
