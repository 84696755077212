import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose, pluck, sortBy, prop } from 'ramda';
import { TaxonomiesService } from '../../core/taxonomies/taxonomies.service';
import { Iri } from '../../core/dto/iri';
import { onlyStrings } from '../../utils/strings';

@Component({
    selector: 'isav-taxonomies-names',
    templateUrl: './taxonomies-names.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxonomiesNames implements OnChanges {
    @Input() iris?: Iri[];
    @Input() property = 'name';
    @Input() limit: boolean = false;

    taxonomies$?: Observable<string>;

    constructor(private taxonomiesService: TaxonomiesService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.iris || changes.property || changes.limit) {
            this.reload();
        }
    }

    reload(): void {
        if (!Array.isArray(this.iris)) return;

        // we want to warn user when we get something that is not a string inside the iris array
        const iris = this.iris.filter(Boolean).filter(onlyStrings);
        const sortNames = sortBy(prop(this.property));
        const limitAndJoinNames = (limit) => (arr) => {
            if (this.limit && arr.length > limit) {
                return arr.slice(0, limit).join(', ') + '...';
            }
            return arr.join(', ');
        };
        const combineNames = compose(limitAndJoinNames(5), pluck(this.property));
        this.taxonomies$ = this.taxonomiesService
            .resolveMany(iris)
            .pipe(map(sortNames), map(combineNames) as any);
    }
}
