import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickedOutsideDirective } from './clicked-outside';
import { EscapeClickedDirective } from './escape-clicked';
import { MatchUrlDirective } from './match-url';
import { BodyChildDirective } from './body-child';
import { IsavApiImg } from './api-img';
import { PrintPageDirective } from './print-page';
import { IsavDatePipe } from './date.pipe';
import { IsavMonthNamePipe } from './month-name.pipe';
import { IsavActiveUrlSegment } from './active-url-segment';
import { IsavFileDropped } from './file-drag-and-drop';
import { IsavTeleportToDirective } from './teleport-to';
import { IsavSpacePipe } from './space.pipe';
import { IsavCloneContentDirective } from './clone-content';
import { IsavHasCreateAccessDirective } from './has-create-access';
import { IsavAltImageCaption } from './alt-image-caption';
import { CapitalizePipe } from './capitalize.pipe';

export const EXPORTED_DECLARATIONS = [
    ClickedOutsideDirective,
    EscapeClickedDirective,
    MatchUrlDirective,
    BodyChildDirective,
    IsavApiImg,
    PrintPageDirective,
    IsavDatePipe,
    IsavMonthNamePipe,
    IsavActiveUrlSegment,
    IsavFileDropped,
    IsavTeleportToDirective,
    IsavSpacePipe,
    IsavCloneContentDirective,
    IsavHasCreateAccessDirective,
    IsavAltImageCaption,
    CapitalizePipe,
];

@NgModule({
    declarations: [EXPORTED_DECLARATIONS],
    exports: [EXPORTED_DECLARATIONS],
    imports: [CommonModule],
})
export class UtilitiesModule {}
