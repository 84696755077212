import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { INotifType } from '../../shared/notification/notification';

export interface INotif {
    title?: string;
    template?: TemplateRef<any>;
    message?: string;
    type: INotifType;
    delay?: number;
}

@Injectable()
export class NotificationService {
    private _notifications = new BehaviorSubject<INotif[]>([]);

    get(): Observable<INotif[]> {
        return this._notifications.asObservable();
    }

    add(notification: INotif) {
        if (notification.delay) this.removeLater(notification);
        this._notifications.next(this._notifications.getValue().concat([notification]));
    }

    remove(notification: INotif) {
        this._notifications.next(this._notifications.getValue().filter((n) => n !== notification));
    }

    /**
     * Creates error notification with message passed in, if you omit 2nd parameter title becomes
     * `Error occurred` and first parameter is used as message
     * @param titleOrMessage - if message passed in used as title otherwise as message
     * @param message
     */
    error(titleOrMessage: string, message?: string): void {
        const title = message ? titleOrMessage : 'Error occurred';
        message = message ?? titleOrMessage;

        this.add({ type: 'danger', delay: 5000, title, message });
    }

    /**
     * Creates success notification with message passed in, if you omit 2nd parameter title becomes
     * `Success` and first parameter is used as message
     * @param titleOrMessage - if message passed in used as title otherwise as message
     * @param message
     */
    success(titleOrMessage: string, message?: string): void {
        const title = message ? titleOrMessage : 'Success';
        message = message ?? titleOrMessage;

        this.add({ type: 'success', delay: 5000, title, message });
    }

    private removeLater(notification: INotif) {
        setTimeout(() => {
            const notifications = this._notifications.getValue();
            if (notifications.includes(notification)) {
                this.remove(notification);
            }
        }, notification.delay);
    }
}
