import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { IsavMap } from '../map';
import { IsavMapOverlayContent } from '../overlay/popup';
import { fromMapEvent } from '../util/from-map-event';
import { IsavFeatureStyle, IsavMapMarker, STYLES } from './marker';
import { IMapMarker } from './marker.interface';
import { IsavMapVectorLayer } from './vector-layer';

@UntilDestroy()
@Component({
    selector: 'isav-map-reactive-marker',
    template: `
        <ng-container *ngIf="allowDelete">
            <ng-container *isavMapControl="'top right'">
                <button
                    *ngIf="coordinate !== null"
                    type="button"
                    aria-label="Remove point"
                    (click)="remove()"
                >
                    <i class="fas fa-trash" style="font-size: 14px" aria-hidden="true"></i>
                </button>
                <button
                    *ngIf="initialCoordinate !== null && !coordinateEqualInitial"
                    type="button"
                    aria-label="Revert coordinates to initial"
                    (click)="revert()"
                >
                    <i class="fas fa-undo-alt" style="font-size: 14px" aria-hidden="true"></i>
                </button>
            </ng-container>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: IsavMapMarker, useExisting: forwardRef(() => IsavMapReactiveMarker) }],
})
export class IsavMapReactiveMarker implements IMapMarker, OnInit, OnChanges, OnDestroy {
    @Input() display: IsavFeatureStyle;
    @Input() center: boolean = false;
    @Input() coordinate: Coordinate | null = null;
    @Input() allowDelete: boolean = false;
    @Output() coordinateChange = new EventEmitter<Coordinate | null>();

    initialCoordinate: Coordinate | null = null;

    private _feature: Feature<Point> | null;
    private _popup?: IsavMapOverlayContent;

    constructor(
        private isavMap: IsavMap,
        private layer: IsavMapVectorLayer,
        private cdRef: ChangeDetectorRef
    ) {}

    get feature(): Feature<Point> | null {
        return this._feature;
    }

    set feature(feature: Feature<Point> | null) {
        if (this._feature) {
            this.layer.getVectorSource().removeFeature(this._feature);
        }

        this._feature = feature;
        this._feature?.set('isavMapOverlayContent', this._popup);
        this._feature?.setStyle(STYLES[this.display || 'marker']());

        if (this._feature) {
            this.layer.getVectorSource().addFeature(this._feature);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.coordinate) {
            this.feature = this.coordinate
                ? new Feature(new Point(this.isavMap.toMapCoordinate(this.coordinate)))
                : null;
        }

        if (changes.coordinate?.isFirstChange()) {
            this.initialCoordinate = changes.coordinate.currentValue;
        }

        if (changes.display) {
            this.feature?.setStyle(STYLES[this.display || 'marker']());
        }
    }

    ngOnInit(): void {
        fromMapEvent(this.isavMap.map, 'click')
            .pipe(untilDestroyed(this))
            .subscribe((e: any) => {
                const coordinates = this.isavMap.map.getCoordinateFromPixel(e.pixel);
                this.feature = new Feature(new Point(coordinates));
                this.emitValue();
                this.cdRef.markForCheck();
            });

        if (this.center && this.coordinate) {
            this.isavMap.view.setCenter(this.isavMap.toMapCoordinate(this.coordinate));
        }

        this.isavMap.viewChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.feature = this.coordinate
                ? new Feature(new Point(this.isavMap.toMapCoordinate(this.coordinate)))
                : null;
        });
    }

    ngOnDestroy(): void {
        if (this.feature) {
            this.layer.getVectorSource().removeFeature(this.feature);
            this.feature.dispose();
        }
    }

    remove() {
        this.feature = null;
        this.emitValue();
        this.cdRef.markForCheck();
    }

    revert() {
        if (!this.initialCoordinate) return;

        this.feature = new Feature(new Point(this.isavMap.toMapCoordinate(this.initialCoordinate)));
        this.coordinate = this.initialCoordinate;
        this.coordinateChange.emit(this.initialCoordinate);
        this.isavMap.view.setCenter(this.isavMap.toMapCoordinate(this.initialCoordinate));
        this.cdRef.markForCheck();
    }

    addPopup(popup: IsavMapOverlayContent): void {
        this._popup = popup;
        this._feature?.set('isavMapOverlayContent', this._popup);
    }

    private emitValue() {
        const value = this.feature
            ? this.isavMap.fromMapCoordinate(this.feature.getGeometry().getCoordinates())
            : null;
        this.coordinate = value;
        this.coordinateChange.emit(value);
    }

    get coordinateEqualInitial(): boolean {
        if (!this.initialCoordinate) return false;
        if (!this.coordinate) return false;

        const c = this.coordinate;
        const ic = this.initialCoordinate;
        return c === ic || (c[0] === ic[0] && c[1] === ic[1]);
    }
}
