<div class="field is-horizontal">
    <div class="field-label field-label-tooltip">
        <button
            isavBtn
            (click)="expandMap()"
            color="primary"
            size="small"
            class="mr-1"
            aria-label="Enlarge map view"
        >
            <isav-icon icon="arrows-alt-h"></isav-icon>
        </button>
        <label [for]="id" class="has-text-weight-bold has-text-primary">
            {{ label }}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="field">
            <isav-map [initialZoom]="1" [id]="id" type="Greenland">
                <ng-container *ngIf="mode === 'edit'">
                    <button *isavMapControl="'top right'" type="button" (click)="clear()">
                        <isav-icon icon="trash" style="font-size: 14px"></isav-icon>
                    </button>
                </ng-container>
            </isav-map>
            <p class="help" *ngIf="mode === 'draw'">
                Press enter to save drawn route (or double click).<br />
                Press escape to cancel drawing and start again.<br />
                Press backspace to remove last added point.
            </p>
            <p class="help" *ngIf="mode === 'edit'">
                You can move points to change their location.<br />
                To start drawing again click trash icon in top right corner.
            </p>
        </div>
    </div>
</div>
<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip"></div>
    <div class="field-body">
        <div class="field">
            <div
                *ngFor="let value of _value; trackBy: trackByIndex; let index = index"
                class="control mb-2"
            >
                <label>
                    <span class="is-block is-inline-block-desktop has-text-primary">
                        <strong>Date {{ index + 1 }}.</strong>
                        <span class="has-text-weight-bold has-text-danger">
                            *
                            <span class="sr-only">Date field is mandatory</span>
                        </span>
                        <span class="help">({{ displayCoordinates(value.feature) }})</span>
                    </span>
                    <input
                        class="input"
                        type="date"
                        [value]="value.date"
                        (input)="value.date = $event.target.value"
                        (focus)="highlightFeature(index)"
                        (blur)="emitValue(); highlightFeature(-1)"
                    />
                </label>
            </div>
        </div>
    </div>
</div>
