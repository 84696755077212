import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { MarkerDto } from '../dto/marker';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MarkersResourceService extends BaseSearchService<MarkerDto> {
    private addedMarker = new Subject<void>();

    constructor(http: HttpClient) {
        super('markers', http);
    }

    create(marker: Partial<MarkerDto>): Observable<MarkerDto> {
        return this.http.post<MarkerDto>(`/api/markers`, marker);
    }

    next() {
        this.addedMarker.next();
    }

    notify() {
        return this.addedMarker.asObservable();
    }
}
