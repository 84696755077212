import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    forwardRef,
    OnInit,
    Input,
    Injector,
    ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Iri } from '../../../core/dto/iri';
import { IsavBaseControl } from '../base-control';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'isav-department-input',
    templateUrl: './department-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavDepartmentInput),
            multi: true,
        },
    ],
})
export class IsavDepartmentInput extends IsavBaseControl implements OnInit {
    @Input() placeholder = '';
    @Input() multiple = false;
    @Input() short: boolean;
    @Input() organisationIri: Iri;
    @Input() dropdownInBody: boolean;

    formControl = new UntypedFormControl(null);

    constructor(_injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this._onTouched();
            this._onChange(value);
        });
    }

    writeValue(obj: any): void {
        this.formControl.setValue(obj, { emitEvent: false });
        this._cdRef.markForCheck();
    }
}
