import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DialogManager } from '../../shared/dialog/dialog.service';

@Component({
    selector: 'isav-ie-warning',
    templateUrl: './ie-warning.html',
    styleUrls: ['./ie-warning.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IEWarning {
    constructor(private dialogManager: DialogManager) {}

    close() {
        this.dialogManager.close();
    }
}
