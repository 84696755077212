import {
    Component,
    ChangeDetectionStrategy,
    Injector,
    Input,
    forwardRef,
    ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Iri } from '../../../core/dto/iri';
import { TaxonomyTypesMap } from '../../../core/taxonomies/taxonomy-types';
import { IsavBaseControl } from '../base-control';

@Component({
    selector: 'isav-taxonomy-checkbox-list-input',
    templateUrl: './taxonomy-checkbox-list-input.html',
    styleUrls: ['./taxonomy-checkbox-list-input.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavTaxonomyCheckboxListInput),
            multi: true,
        },
    ],
})
export class IsavTaxonomyCheckboxListInput extends IsavBaseControl {
    @Input() taxonomy: keyof TaxonomyTypesMap;
    @Input() readonly: boolean = false;
    value: Iri[] = [];

    constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
        super(injector);
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.cdRef.markForCheck();
    }

    onChange(value: Iri[]): void {
        this.value = value;
        this._onChange(this.value);
    }
}
