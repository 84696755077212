import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectReadCollectionDto } from '../../../core/dto/project-read-collection';
import { FieldworkReadCollectionDto } from '../../../core/dto/fieldwork-read-collection';
import { ProjectsResourceService } from '../../../core/api/projects-resource.service';
import { getToday } from '../../../utils/date';
import { pluck } from 'rxjs/operators';

@Component({
    selector: 'isav-projects-shared-landing-page',
    templateUrl: './projects-shared-landing-page.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsSharedLandingPage implements OnInit {
    newestFieldworks$: Observable<(ProjectReadCollectionDto | FieldworkReadCollectionDto)[]>;
    upcomingFieldworks$: Observable<(ProjectReadCollectionDto | FieldworkReadCollectionDto)[]>;
    newestProjects$: Observable<(ProjectReadCollectionDto | FieldworkReadCollectionDto)[]>;
    upcomingProjects$: Observable<(ProjectReadCollectionDto | FieldworkReadCollectionDto)[]>;

    form = new UntypedFormGroup({
        extractedText: new UntypedFormControl(''),
        searchIn: new UntypedFormControl('projects'),
    });

    constructor(private router: Router, private projectService: ProjectsResourceService) {}

    ngOnInit() {
        this.newestFieldworks$ = this.projectService
            .getAll({
                searchIn: 'fieldworks',
                'order[createdAt]': 'desc',
                itemsPerPage: '4',
            })
            .pipe(pluck('member'));

        this.upcomingFieldworks$ = this.projectService
            .getAll({
                searchIn: 'fieldworks',
                'order[start]': 'asc',
                'advancedPeriod[start]': getToday(),
                'advancedPeriod[strictness]': 'strict',
                itemsPerPage: '4',
            })
            .pipe(pluck('member'));

        this.newestProjects$ = this.projectService
            .getAll({
                'order[createdAt]': 'desc',
                itemsPerPage: '4',
            })
            .pipe(pluck('member'));

        this.upcomingProjects$ = this.projectService
            .getAll({
                'order[projectStart]': 'asc',
                'advancedPeriod[start]': getToday(),
                'advancedPeriod[strictness]': 'strict',
                itemsPerPage: '4',
            })
            .pipe(pluck('member'));
    }

    onSubmit() {
        this.router.navigate(['/projects/search'], {
            queryParams: this.form.value,
        });
    }
}
