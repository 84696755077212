<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip">
        <label [for]="id" class="has-text-weight-bold has-text-primary">
            {{ label }}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <isav-taxonomy-checkbox-list
            [label]="label"
            [value]="value"
            (valueChange)="onChange($event)"
            [taxonomy]="taxonomy"
            [disabled]="disabled"
            [readonly]="readonly"
        ></isav-taxonomy-checkbox-list>
    </div>
</div>
