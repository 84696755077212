import { Injectable } from '@angular/core';

@Injectable()
export class BrowserDetectionService {
    /**
     * Checks whether client is using IE
     */
    isIE() {
        // Simple method duck-typing which browser client is using
        // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
        // NOTE: do not simplify using phpstorm
        return /*@cc_on!@*/ false || !!(document as any).documentMode;
    }
}
