import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GoogleMapLoaderService {
    private readonly GOOGLE_MAP_API_KEY = 'AIzaSyAACYtvmxVz80hLlNhlbhgYwL0YuZtzMy8';
    private map: google.maps.Map;
    private element = document.createElement('div');
    private loaded: Observable<google.maps.Map>;

    constructor(private readonly ngZone: NgZone) {
        this.element.style.display = 'block';
        this.element.style.height = '100%';
        this.element.style.width = '100%';
    }

    createMap(): Observable<google.maps.Map> {
        if (!this.loaded) {
            this.loaded = this.internalLoad().pipe(
                map(() => this.internalCreateMap()),
                shareReplay(1)
            );
        }

        return this.loaded;
    }

    private internalCreateMap(): google.maps.Map {
        this.map = new google.maps.Map(this.element, {
            zoom: 4,
            center: { lat: -43.44, lng: 72.66 },
            mapTypeId: 'satellite',
            fullscreenControl: false,
            streetViewControl: true,
        });
        return this.map;
    }

    private internalLoad(): Observable<void> {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&callback=googleMapInitialised`;
        script.defer = true;

        const subject = new Subject<void>();

        (window as any).googleMapInitialised = () => {
            this.ngZone.run(() => {
                subject.next();
                subject.complete();
                (window as any).googleMapInitialised = undefined;
            });
        };

        document.head.appendChild(script);

        return subject.asObservable();
    }
}
