<isav-centered-form-container>
    <h1 class="subtitle is-5 has-text-centered">Sign into Isaaffik</h1>

    <div *ngIf="backendError" class="notification is-danger" role="alert" aria-atomic="true">
        <button class="delete" type="button" (click)="setBackendError('')">
            <span class="is-sr-only">Hide notification</span>
        </button>
        {{ backendError }}
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="mb-4">
        <div class="field">
            <label for="emailInput" class="label">E-mail:</label>
            <div class="control">
                <input
                    type="text"
                    class="input"
                    formControlName="email"
                    id="emailInput"
                    autocomplete="username"
                    [class.is-invalid]="backendError"
                />
            </div>
        </div>
        <div class="field">
            <label for="passwordInput" class="label">Password:</label>
            <div class="control">
                <input
                    type="password"
                    class="input"
                    formControlName="password"
                    id="passwordInput"
                    autocomplete="current-password"
                />
            </div>
            <p class="help"><a routerLink="/reset-password-request">Forgot password?</a></p>
        </div>
        <div class="field is-grouped is-grouped-between">
            <div class="control">
                <button isavBtn color="primary" type="submit">Sign in</button>
            </div>
            <div class="control">
                <a isavBtn color="primary" routerLink="/" class="is-light">Go to Homepage</a>
            </div>
        </div>
    </form>

    <div class="content">
        <p>You do not have an account? <a routerLink="/people/register">Create new one</a>.</p>
    </div>
</isav-centered-form-container>
