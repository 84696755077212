import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    ViewEncapsulation,
    Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IsavBaseControl } from '../base-control';

@Component({
    selector: 'isav-text-input',
    templateUrl: './text-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IsavTextInput), multi: true },
    ],
})
export class IsavTextInput extends IsavBaseControl {
    @Input() type = 'text';

    value = '';

    constructor(injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(injector);
    }

    onChange(value: string) {
        this.value = value;
        this._onChange(value);
        this._cdRef.markForCheck();
    }

    writeValue(obj: any): void {
        this.value = obj;
        this._cdRef.markForCheck();
    }

    onFocus() {
        this._onTouched();
    }
}
