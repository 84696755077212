<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip">
        <label [for]="id" class="has-text-weight-bold has-text-primary">
            {{label}}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="field">
            <div class="control">
                <isav-taxonomy-select
                    [formControl]="formControl"
                    [id]="id"
                    [taxonomy]="taxonomy"
                    [placeholder]="placeholder"
                    [multiple]="multiple"
                    [disableParents]="true"
                    [short]="short"
                    [dropdownInBody]="dropdownInBody"
                ></isav-taxonomy-select>
            </div>
        </div>
    </div>
</div>
