import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import WMTS from 'ol/source/WMTS';
import { IsavMap } from '../../map';
import { IceLayerService } from './ice-layer.service';
import OSM from 'ol/source/OSM';

@UntilDestroy()
@Component({
    selector: 'isav-map-ice-layer',
    templateUrl: './ice-layer.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavMapSentinelIceLayers implements OnInit, OnDestroy {
    private readonly osmLayer = new TileLayer({
        source: new OSM(),
        zIndex: 0,
    });
    private layer?: Layer;

    constructor(
        private readonly iceLayerService: IceLayerService,
        private readonly isavMap: IsavMap
    ) {}

    ngOnInit(): void {
        this.isavMap.map.addLayer(this.osmLayer);
        this.iceLayerService.options.pipe(untilDestroyed(this)).subscribe((options) => {
            if (!this.layer) {
                this.layer = new TileLayer({
                    opacity: 1,
                    zIndex: 1,
                });
                this.isavMap.map.addLayer(this.layer);
            }

            this.layer.setSource(new WMTS(options));
        });
    }

    ngOnDestroy(): void {
        [this.osmLayer, this.layer].filter(Boolean).map((l) => this.isavMap.map.removeLayer(l!));

        this.layer?.dispose();
        this.osmLayer.dispose();
    }
}
