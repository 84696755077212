import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { HasElementRef } from '../common-behaviours/has-element-ref';
import { mixinColor } from '../common-behaviours/color';
import { safeAddClass } from '../common-behaviours/safe-classlist';

/**
 * Base class for applying mixins
 *
 * @ignore
 */
class CaptionBase implements HasElementRef {
    constructor(public _elementRef: ElementRef) {}
}

/**
 * @ignore
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
const CaptionMixinBase = mixinColor(CaptionBase);

/**
 * Caption component to display dates on images or inside teaser or card element
 *
 * @example
 * <isav-caption>12.04.2020</isav-caption>
 */
@Component({
    selector: 'isav-caption',
    templateUrl: './caption.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavCaption extends CaptionMixinBase {
    constructor(elementRef: ElementRef) {
        super(elementRef);
        this.color = 'primary';
        safeAddClass(elementRef, 'caption');
    }
}
