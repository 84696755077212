import { HttpEventType } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UploadService } from '../../../core/api/upload.service';
import { Iri } from '../../../core/dto/iri';
import { NotificationService } from '../../../core/services/notification.service';
import { environment } from '../../../../environments/environment';

enum ImageUploaderStatus {
    NoImage = 'NO_IMAGE',
    UploadingImage = 'UPLOADING_IMAGE',
    Image = 'IMAGE',
}

@Component({
    selector: 'isav-image-uploader',
    templateUrl: './image-uploader.html',
    styleUrls: ['./image-uploader.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavImageUploader implements OnInit {
    @Input() imageIri: Iri | null = null;
    @Output() imageIriChange = new EventEmitter<Iri | null>();
    @ViewChild('uploaderZone', { static: false }) uploaderZone: ElementRef<HTMLDivElement>;

    status: ImageUploaderStatus;
    percentDone = 0;

    constructor(
        private _cdRef: ChangeDetectorRef,
        private uploadService: UploadService,
        private ngZone: NgZone,
        private notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.status =
            this.imageIri === null ? ImageUploaderStatus.NoImage : ImageUploaderStatus.Image;
    }

    onImageDropped(files: FileList): void {
        this.handleProvidedImages(files);
    }

    onImageSelected(fileInput) {
        this.handleProvidedImages(fileInput.target.files);
    }

    private handleProvidedImages(files: FileList): void {
        if (files.length !== 1) {
            throw new Error(
                'Image uploader expects only one image to be uploaded. Given: ' + files.length
            );
        }

        const file = files.item(0);

        if (file === null) {
            throw new Error('Image uploader expected an image file but got none');
        }

        this.status = ImageUploaderStatus.UploadingImage;
        this._cdRef.markForCheck();

        this.uploadService.uploadImage(file).subscribe(
            (event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        this.percentDone = Math.round((100 * event.loaded) / event.total!);
                        break;
                    case HttpEventType.Response:
                        this.imageIri = event.body!['@id'];
                        this.status = ImageUploaderStatus.Image;
                        this.imageIriChange.emit(this.imageIri);
                        this.focusUploaderZone();
                        break;
                }

                this._cdRef.markForCheck();
            },
            (error) => {
                this.notificationService.add({
                    title: 'Failed to upload image!',
                    message: this.errorNotificationMessage(error),
                    type: 'danger',
                    delay: 10000,
                });
                this.status = ImageUploaderStatus.NoImage;
                this._cdRef.markForCheck();
            }
        );
    }

    /**
     * This function is used to set focus on uploader zone after file is uploaded (accessibility
     * reasons).
     *
     * SetTimeout is used to wait for change detection. Otherwise we nativeElement would be null
     * (as this div is in ngSwitch so will be rendered later).
     *
     * NgZone.runOutsideAngular is used to not trigger change detection twice (optimization reasons
     * - but we could potentially live without it in this specific case).
     */
    private focusUploaderZone() {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => this.uploaderZone.nativeElement.focus());
        });
    }

    private errorNotificationMessage(error): string {
        switch (error.status) {
            case 413:
                return `The image is exceeding the maximum size of ${environment.maxImageSize}.`;
            default:
                return this.backendMessage(error);
        }
    }

    private backendMessage(error) {
        if (error.error['message']) return error.error['message'];
        if (error.error['hydra:description']) return error.error['hydra:description'];
        return 'Unknown error occurred when uploading image.';
    }
}
