import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DOMPurifyInjectionToken } from './DOMPurifyInjectionToken';
import { DOMPurifyI, SanitizeElementHookEvent } from 'dompurify';

const ALLOWED_IFRAME_SRC: string[] = ['https://www.youtube.com/embed/'];

@Injectable()
export class SanitizerService {
    constructor(
        private readonly sanitizer: DomSanitizer,
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
        @Inject(DOMPurifyInjectionToken) private readonly DOMPurify: DOMPurifyI
    ) {
        this.DOMPurify.addHook('uponSanitizeElement', this.sanitizeIframes.bind(this));
    }

    sanitize(content: string): SafeHtml {
        const sanitizedContent = this.DOMPurify.sanitize(content, {
            ADD_ATTR: ['allowfullscreen', 'allow', 'frameborder'],
            ADD_TAGS: ['iframe'],
        });
        return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    }

    /**
     * Sanitizes iframe and removes ones that are not coming from a trusted source
     * https://dev.to/patarapolw/securely-embed-youtube-and-other-iframe-elements-in-markdown-2hoc
     */
    private sanitizeIframes(node: HTMLElement, data: SanitizeElementHookEvent): HTMLElement {
        if (data.tagName === 'iframe') {
            const src = node.getAttribute('src') || '';
            if (!ALLOWED_IFRAME_SRC.some((url) => src.startsWith(url))) {
                return node.parentNode?.removeChild(node) ?? node;
            }
        }

        return node;
    }
}
