<div *ngFor="let iri of files; trackBy: filesTrackBy" class="columns is-mobile">
    <div class="column is-11 document-display">
        <isav-file-display [iri]="iri"></isav-file-display>
    </div>
    <div class="column is-1">
        <button isavBtn size="small" color="danger" [outlined]="true" (click)="removeFile(iri)">
            <isav-icon icon="trash"></isav-icon>
        </button>
    </div>
</div>

<isav-file-dropzone
    *ngIf="canUploadFile"
    [multiple]="multiple"
    (files)="handleFilesToUpload($event)"
>
</isav-file-dropzone>
