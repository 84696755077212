<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip">
        <label [for]="id" class="has-text-weight-bold has-text-primary">
            {{label}}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="field">
            <isav-image-display
                *ngFor="let iri of value"
                [imageIri]="iri"
                [editable]="editable"
                (removeIri)="remove($event)"
                class="mb-2 is-block"
            ></isav-image-display>
            <button
                *ngIf="multiple || (!multiple && value.length === 0)"
                isavBtn
                color="primary"
                (click)="upload()"
            >
                Upload
            </button>
            <button *ngIf="multiple" isavBtn color="primary" class="ml-2" (click)="batchUpload()">
                Batch upload
            </button>
        </div>
    </div>
</div>
