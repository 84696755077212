<div
    class="tabs mb-5"
    [ngClass]="panelClass"
    [class.is-left]="aligment === 'left'"
    [class.is-centered]="aligment === 'centered'"
    [class.is-right]="aligment === 'right'"
    [class.is-small]="size === 'small'"
    [class.is-normal]="size === 'normal'"
    [class.is-medium]="size === 'medium'"
    [class.is-large]="size === 'large'"
    [class.is-boxed]="borders"
    [class.is-toggle]="toggle"
    [class.is-toggle-rounded]="toggle && toggleRounded"
>
    <ul>
        <ng-container *ngFor="let tab of tabs.toArray()">
            <li [class.is-active]="tab.title === activeTab">
                <a (click)="update(tab)">
                    <ng-container *ngIf="tab.titlePortal; else title">
                        <ng-template [cdkPortalOutlet]="tab.titlePortal"></ng-template>
                    </ng-container>
                    <ng-template #title>{{tab.title}}</ng-template>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
<ng-template [cdkPortalOutlet]="contentPortal"></ng-template>
