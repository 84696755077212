import { Component, ChangeDetectionStrategy, SimpleChanges, Input, OnChanges } from '@angular/core';
import { TaxonomiesService } from '../../core/taxonomies/taxonomies.service';
import { Observable } from 'rxjs';
import { Iri } from '../../core/dto/iri';
import { map } from 'rxjs/operators';
import { LinkDto } from '../../core/dto/link';
import { ApiResourceDto } from '../../core/dto/api-resource';
import { onlyStrings } from '../../utils/strings';

interface ApiResourceWithLink extends ApiResourceDto {
    url?: LinkDto;
    title: string;
}

interface LinkAndTitleDto {
    link?: LinkDto;
    title: string;
}

@Component({
    selector: 'isav-taxonomies-external-link',
    templateUrl: './taxonomies-external-link.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavTaxonomiesExternalLink implements OnChanges {
    @Input() iris?: Iri[];

    taxonomies$: Observable<LinkAndTitleDto[]>;

    constructor(private taxonomiesService: TaxonomiesService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.iris) {
            if (!Array.isArray(this.iris)) return;

            // we want to warn user when we get something that is not a string inside the iris array
            const iris = this.iris.filter(Boolean).filter(onlyStrings);

            this.taxonomies$ = this.taxonomiesService
                .resolveMany(iris)
                .pipe(map(this.mapToLinkAndTitle));
        }
    }

    mapToLinkAndTitle(results: ApiResourceWithLink[]): LinkAndTitleDto[] {
        const taxonomies = results.filter((value) => {
            if (value && value.url) return true;
            return false;
        });
        return taxonomies.map((taxonomy) => {
            return {
                link: taxonomy.url!,
                title: taxonomy.url!.title || taxonomy.title,
            };
        });
    }
}
