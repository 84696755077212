import { Injectable } from '@angular/core';
import { DialogManager, DialogService } from '../../shared/dialog/dialog.service';
import { SearchPopup } from './search-popup';

@Injectable({ providedIn: 'root' })
export class SearchPopupService {
    private searchPopupDialogManager?: DialogManager<void, void>;

    constructor(private dialogService: DialogService) {}

    open() {
        if (this.searchPopupDialogManager) return;

        this.searchPopupDialogManager = this.dialogService.spawnComponent(SearchPopup);
        this.searchPopupDialogManager.closed$.subscribe(
            () => (this.searchPopupDialogManager = undefined)
        );
    }

    close() {
        if (!this.searchPopupDialogManager) return;

        this.searchPopupDialogManager.close();
    }
}
