import { Action, createReducer, on } from '@ngrx/store';
import { patchTaxonomy, storeTaxonomies, storeTaxonomy } from './taxonomies.actions';
import { produceState } from '../../utils/store';
import { ApiResourceDto } from '../dto/api-resource';

export const featureKey = 'taxonomies';

export interface TaxonomiesState<T extends ApiResourceDto> {
    [iri: string]: T;
}

export const initialState: TaxonomiesState<any> = {};

const reducer = createReducer(
    initialState,
    on(
        storeTaxonomy,
        produceState((state, action) => {
            state[action.payload['@id']] = action.payload;
        })
    ),
    on(
        storeTaxonomies,
        produceState((state, action) => {
            action.taxonomies.forEach((taxonomy) => {
                state[taxonomy['@id']] = taxonomy;
            });
        })
    ),
    on(
        patchTaxonomy,
        produceState((state, action) => {
            const prev = state[action.iri];
            state[action.iri] = {
                ...prev,
                ...action.data,
            };
        })
    )
);

export function taxonomiesReducer(state: TaxonomiesState<any>, action: Action) {
    return reducer(state, action);
}
