import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { IsavAutocompleteOption } from './autocomplete-option';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { createPopper, Instance as PopperInstance, Placement } from '@popperjs/core';

let UNIQUE_ID = 0;

export type SearchFn<T> = (searchString: string) => Observable<T[]>;

@Component({
    selector: 'isav-autocomplete',
    templateUrl: './autocomplete.html',
    styleUrls: ['./autocomplete.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavAutocomplete<T = any> implements OnInit, AfterContentInit, OnDestroy {
    @Input() id = 'isav-autocomplete-' + UNIQUE_ID++;
    @Input() searchFn: SearchFn<T>;
    @Input() label = '';
    @Input() placeholder = '';
    @Input() labelledby: string | null = null;
    @Input() placement: Placement = 'bottom-start';
    @Output() search = new EventEmitter<string>();
    @Output() choose = new EventEmitter<T>();
    @ContentChild(IsavAutocompleteOption) autocompleteOption!: IsavAutocompleteOption<T>;
    @ViewChild('dropdown', { static: true }) dropdownElement!: ElementRef<HTMLDivElement>;
    @ViewChild('listbox', { static: true }) listboxElement!: ElementRef<HTMLDivElement>;

    isOpened = false;
    activeOption = 0;
    searchString = '';

    private searchStringNext = new Subject();
    private popperInstance: PopperInstance | null = null;

    constructor(private cdRef: ChangeDetectorRef, private ngZone: NgZone) {}

    get activeDescendant(): string | null {
        return (this.isOpened && this.optionId(this.activeOption)) || null;
    }

    private _options: T[] = [];
    @Input() set options(value: T[]) {
        if (Array.isArray(value)) {
            this._options = value;
            this.activeOption = 0;
            this.cdRef.markForCheck();
        }
    }

    get options(): T[] {
        return this._options;
    }

    ngOnInit() {
        this.update('');
    }

    ngAfterContentInit() {
        if (!this.autocompleteOption) {
            throw new Error('Missing *isavAutocompleteOption in autocomplete element!');
        }
    }

    ngOnDestroy() {
        this.searchStringNext.complete();
        if (this.popperInstance) this.popperInstance.destroy();
    }

    update(value: string) {
        this.searchString = value;
        this.searchStringNext.next();

        if (typeof this.searchFn === 'function') {
            this.searchFn
                .call(null, this.searchString)
                .pipe(take(1), takeUntil(this.searchStringNext))
                .subscribe((options) => {
                    this.options = options;
                    this.cdRef.markForCheck();
                });
        } else {
            this.search.emit(this.searchString);
        }
    }

    optionId(index: number): string {
        return `${this.id}-option-${index}`;
    }

    open() {
        this.isOpened = true;
        this.activeOption = 0;
        this.cdRef.markForCheck();

        // since we do not rely on angular and we need angular to perform update to initialize
        // popper we run it outside angular to not trigger another change detection
        this.ngZone.runOutsideAngular(() => {
            Promise.resolve().then(() => {
                if (this.popperInstance) this.popperInstance.destroy();
                this.popperInstance = createPopper(
                    this.dropdownElement.nativeElement,
                    this.listboxElement.nativeElement,
                    { placement: this.placement }
                );
            });
        });
    }

    close() {
        this.isOpened = false;
        this.cdRef.markForCheck();

        if (this.popperInstance) this.popperInstance.destroy();
        this.popperInstance = null;
    }

    select(activeOption: number) {
        this.choose.emit(this.options[activeOption]);
    }

    previous() {
        const len = this.options.length;
        this.activeOption = (len + this.activeOption - 1) % len;
        this.cdRef.markForCheck();
    }

    next() {
        const len = this.options.length;
        this.activeOption = (len + this.activeOption + 1) % len;
        this.cdRef.markForCheck();
    }
}
