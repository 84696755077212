<div class="mb-3">
    <ckeditor
        [editor]="Editor"
        [config]="simple ? SimpleConfig : ''"
        [data]="initialValue"
        [disabled]="disabled"
        (change)="onChange($event)"
        (focus)="onFocus($event)"
        (ready)="onReady($event)"
    ></ckeditor>
</div>
