import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Coordinate } from 'ol/coordinate';
import Polygon from 'ol/geom/Polygon';
import { GeoLocationDto } from '../../../core/dto/geo-location';
import { GeoLocationReadDto } from '../../../core/dto/geo-location-read';
import { IsavMap } from '../../../map/map';
import { IsavBaseControl } from '../base-control';

export function validateInput(obj: any): Coordinate[] {
    if (!Array.isArray(obj)) return [];
    return obj
        .filter(
            (coordinate): coordinate is GeoLocationDto =>
                typeof coordinate.longitude === 'number' && typeof coordinate.latitude === 'number'
        )
        .map(({ longitude, latitude }) => [longitude, latitude]);
}

export function mapEmitValue(coordinate: Coordinate[]): GeoLocationReadDto[] {
    return coordinate.map(([longitude, latitude]) => ({ longitude, latitude }));
}

@Component({
    selector: 'isav-coordinate-area-input',
    templateUrl: './coordinate-area-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavCoordinateAreaInput),
            multi: true,
        },
    ],
})
export class IsavCoordinateAreaInput extends IsavBaseControl implements AfterViewInit {
    @Input() pointCoordinate: Coordinate | null;
    @ViewChild(IsavMap, { static: true, read: IsavMap }) isavMap: IsavMap;

    value: Coordinate[] = [];

    constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
        super(injector);
    }

    ngAfterViewInit(): void {
        if (this.value.length > 0) {
            this.isavMap.view.fit(
                new Polygon([this.value.map((c) => this.isavMap.toMapCoordinate(c))]),
                {
                    padding: [30, 30, 30, 30],
                }
            );
        } else if (this.pointCoordinate) {
            this.isavMap.view.setCenter(this.isavMap.toMapCoordinate(this.pointCoordinate));
        }
    }

    writeValue(obj: any): void {
        this.value = validateInput(obj);
        this.cdRef.markForCheck();
    }

    update(coordinate: Coordinate[]) {
        this.value = coordinate;
        this._onChange(mapEmitValue(this.value));
    }

    expandMap() {
        this.isavMap.toggleFullScreenMode(true);
    }
}
