import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'isav-contact-information',
    templateUrl: './contact-information.html',
    styleUrls: ['./contact-information.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavContactInformation {
    @Input() contactName: string;
    @Input() contactEmail: string;
    @Input() contactPhone: string;

    /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
    @Input() PIName: string;
    @Input() PIEmail: string;
    @Input() PIPhone: string;
    /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

    constructor(public router: Router) {}

    isContact(): boolean {
        if (this.contactName) return true;
        if (this.contactEmail) return true;
        if (this.contactPhone) return true;
        return false;
    }

    isPI(): boolean {
        if (this.PIName) return true;
        if (this.PIEmail) return true;
        if (this.PIPhone) return true;
        return false;
    }
}
