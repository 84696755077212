<div class="last-updated has-text-centered">
    <span>{{ event.recordedAt | isavDateTime }} {{ event.eventName | isavEventName }}&nbsp;</span>
    <span *ngIf="event.payload.targetPersonName">
        <a
            [routerLink]="['/people/view']"
            [queryParams]="{ iri: '/api/people/' + event.payload.targetPersonId }"
        >
            <strong>{{ event.payload.targetPersonName }}</strong>
        </a>
        <span>&nbsp;by</span>
    </span>
    <strong *ngIf="event.authPerson">
        <a [routerLink]="['/people/view']" [queryParams]="{ iri: event.authPerson!['@id'] }">
            {{ event.authPerson?.fullName }}
        </a>
    </strong>
</div>
