<h2 class="title is-3">Latest news</h2>
<div class="columns news is-multiline">
    <isav-landing-card *ngFor="let news of news$ | async" [image]="news.image">
        <div isavLandingCardCaption>
            <b>{{ news.createdAt | isavDate }}</b>
        </div>
        <div isavLandingCardHeading>
            <ng-container *ngIf="news.externalLink?.url; else internal">
                <a target="_blank" [href]="news.externalLink?.url">{{ news.title }}</a>
            </ng-container>
            <ng-template #internal>
                <a [routerLink]="['/news/view']" [queryParams]="{ iri: news['@id'] }">
                    {{ news.title }}
                </a>
            </ng-template>
        </div>
        <div isavLandingCardFooter>
            <b>Source:&nbsp;</b><br />
            <p>{{ news.newsBy }}</p>
        </div>
    </isav-landing-card>
</div>
<div class="has-text-right">
    <a data-testid="link-news" routerLink="/news" class="mr-4">View all news</a>
</div>

<div class="columns is-multiline mt-6 mb-6">
    <div class="column is-6-tablet is-3-desktop">
        <h2 class="title is-3">Courses</h2>
        <isav-list-item-view data-testid="course" *ngFor="let course of courses$ | async">
            <p isavItemCaption>{{ course.startDate | isavDate }}</p>
            <img isavItemImage [isavApiImg]="course.image" [useFallbackImg]="true" alt="" />
            <p class="title is-5">
                <a
                    routerLink="/education-and-trainings/view"
                    [queryParams]="{ iri: course['@id'] }"
                >
                    {{ course.title }}
                </a>
            </p>
            <isav-taxonomies-names
                property="title"
                [iris]="[course.organisation]"
            ></isav-taxonomies-names>
        </isav-list-item-view>
        <div class="has-text-right">
            <a data-testid="link-courses" routerLink="/education-and-trainings" class="mr-4"
                >View all courses</a
            >
        </div>
    </div>

    <div class="column is-6-tablet is-3-desktop">
        <h2 class="title is-3">Events</h2>
        <isav-list-item-view data-testid="event" *ngFor="let event of events$ | async">
            <p isavItemCaption>{{ event.startEndDates[0]?.startDate | isavDate }}</p>
            <img isavItemImage [isavApiImg]="event.coverImage" [useFallbackImg]="true" alt="" />
            <p class="title is-5">
                <a routerLink="/events/view" [queryParams]="{ iri: event['@id'] }">
                    {{ event.title }}
                </a>
            </p>
            <p><strong>Location:&nbsp;</strong>{{ event.location }}</p>
            <p>
                <strong>Activity:&nbsp;</strong
                ><isav-taxonomies-names [iris]="[event.activity]"></isav-taxonomies-names>
            </p>
        </isav-list-item-view>
        <div class="has-text-right">
            <a data-testid="link-events" routerLink="/events" class="mr-4">View all events</a>
        </div>
    </div>

    <isav-hint-card
        *ngFor="let card of cards$ | async; let even = even"
        class="column is-6-tablet is-3-desktop has-text-centered"
        [blue]="even"
        [card]="card"
    >
    </isav-hint-card>
</div>
