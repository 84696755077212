import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiResourceDto } from '../dto/api-resource';
import { ContentService } from './content.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class IriResolver<T extends ApiResourceDto> implements Resolve<T> {
    constructor(private contentService: ContentService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if (!route.queryParamMap.has('iri')) return of();

        const iri = route.queryParamMap.get('iri')!;
        return this.contentService.getByIri<T>(iri).pipe(
            catchError((err) => {
                if (err.status === 401)
                    this.router.navigate(['/not-authenticated'], {
                        queryParams: { from: state.url },
                    });
                if (err.status === 403) this.router.navigate(['/forbidden']);
                return of(null);
            })
        );
    }
}
