<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip">
        <label [for]="id" class="has-text-primary has-text-weight-bold">
            {{ label }}<span class="has-text-danger" *ngIf="mandatory">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="field">
            <div class="control">
                <textarea
                    class="textarea"
                    [id]="id"
                    [placeholder]="placeholder"
                    [value]="value"
                    [readonly]="disabled"
                    (input)="onChange($event.target.value)"
                    (focus)="_onTouched()"
                ></textarea>
            </div>
        </div>
    </div>
</div>
