import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app';
import { AuthenticatedGuard } from './core/auth/authenticated.guard';
import { NotAuthenticatedGuard } from './core/auth/not-authenticated.guard';
import { RoleGuard } from './core/auth/role.guard';
import { UserRole } from './core/auth/user-role';
import { CoreModule } from './core/core.module';
import { ContainerPage } from './general/container-page/container-page';
import { ResetPasswordRequestPage } from './general/reset-password-request/reset-password-request';
import { IsavGeneralModule } from './general/general.module';
import { ResetPasswordPage } from './general/reset-password/reset-password';
import { ResetPasswordGuard } from './general/reset-password/reset-password.guard';
import { SignInPage } from './general/sign-in/sign-in';
import { FrontPage } from './general/front-page/front-page';
import { ForbiddenPage } from './general/forbidden-page/forbidden-page';
import { IsavAuthenticationRequired } from './general/authentication-required/authentication-required';
import { ChangesWarningModule } from './core/changes-warning/changes-warning.module';
import { ContentView } from './general/content-view/content-view';
import { NewsletterUnsubscribe } from './general/newsletter-unsubscribe/newsletter-unsubscribe';

const APP_ROUTES: Routes = [
    {
        path: 'sign-in',
        component: SignInPage,
        canActivate: [NotAuthenticatedGuard],
    },
    {
        path: 'reset-password-request',
        component: ResetPasswordRequestPage,
        canActivate: [NotAuthenticatedGuard],
    },
    {
        path: 'reset-password',
        component: ResetPasswordPage,
        canActivate: [NotAuthenticatedGuard, ResetPasswordGuard],
    },
    {
        path: '',
        pathMatch: 'prefix',
        component: ContainerPage,
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: FrontPage,
            },
            {
                path: 'not-authenticated',
                component: IsavAuthenticationRequired,
            },
            {
                path: 'forbidden',
                component: ForbiddenPage,
            },
            {
                path: 'content-view',
                component: ContentView,
            },
            {
                path: 'newsletter/unsubscribe',
                component: NewsletterUnsubscribe,
            },
            {
                path: 'projects',
                loadChildren: () =>
                    import('./features/projects/projects.module').then((m) => m.ProjectsModule),
                pathMatch: 'prefix',
            },
            {
                path: 'infrastructures',
                loadChildren: () =>
                    import('./features/infrastructures/infrastructures.module').then(
                        (m) => m.InfrastructuresModule
                    ),
                pathMatch: 'prefix',
            },
            {
                path: 'people',
                loadChildren: () =>
                    import('./features/people/people.module').then((m) => m.PeopleModule),
                pathMatch: 'prefix',
            },
            {
                path: 'datalinks',
                loadChildren: () =>
                    import('./features/data-links/data-links.module').then(
                        (m) => m.DataLinksModule
                    ),
                pathMatch: 'prefix',
            },
            {
                path: 'logistics',
                loadChildren: () =>
                    import('./features/logistics/logistics.module').then((m) => m.LogisticsModule),
                pathMatch: 'prefix',
            },
            {
                path: 'sar',
                loadChildren: () => import('./features/sar/sar.module').then((m) => m.SarModule),
                pathMatch: 'prefix',
                canActivate: [AuthenticatedGuard, RoleGuard],
                data: {
                    roles: [UserRole.ROLE_ADMIN, UserRole.ROLE_SAR],
                },
            },
            {
                path: 'how-to-guide',
                loadChildren: () =>
                    import('./features/how-to-guide/how-to-guide.module').then(
                        (m) => m.HowToGuideModule
                    ),
            },
            {
                path: 'news',
                loadChildren: () => import('./features/news/news.module').then((m) => m.NewsModule),
            },
            {
                path: 'events',
                loadChildren: () =>
                    import('./features/events/events.module').then((m) => m.EventsModule),
            },
            {
                path: 'education-and-trainings',
                loadChildren: () =>
                    import('./features/education-and-training/education-and-training.module').then(
                        (m) => m.EducationAndTrainingModule
                    ),
            },
            {
                path: 'markers',
                loadChildren: () =>
                    import('./features/markers/markers.module').then((m) => m.MarkersModule),
            },
            {
                path: 'pages',
                loadChildren: () =>
                    import('./features/pages/pages.module').then((m) => m.PagesModule),
            },
            {
                path: 'my-isaaffik',
                loadChildren: () =>
                    import('./features/my-isaaffik/my-isaaffik.module').then(
                        (m) => m.MyIsaaffikModule
                    ),
                canActivate: [AuthenticatedGuard],
            },
            {
                path: 'admin',
                loadChildren: () =>
                    import('./features/administration/administration.module').then(
                        (m) => m.AdministrationModule
                    ),
                canActivate: [AuthenticatedGuard, RoleGuard],
                data: {
                    roles: [UserRole.ROLE_ADMIN],
                },
            },
        ],
    },
];

/**
 * This is root module of the application, it defines root routing.
 * It's purpose is to just bootstrap the application and handover the control to {@link CoreModule}.
 *
 * Do not import shared components here, they belong inside {@link SharedModule}!
 *
 * Features belong inside features folder, create a module for each feature
 */
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(APP_ROUTES, {
            onSameUrlNavigation: 'ignore',
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'top',
            initialNavigation: 'enabledBlocking',
        }),
        CoreModule,
        ChangesWarningModule,
        IsavGeneralModule,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
