import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CKEditor5Wrapper } from './ckeditor5-wrapper';

export const EXPORTED_DECLARATION = [CKEditor5Wrapper];

@NgModule({
    declarations: EXPORTED_DECLARATION,
    exports: [EXPORTED_DECLARATION],
    imports: [CommonModule, CKEditorModule],
})
export class CKEditor5WrapperModule {}
