import { Injectable } from '@angular/core';
import { BaseSearchService } from './base-search-service';
import { ArmapImageDataDto, ArmapImageDto } from '../dto/armap-image';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Iri } from '../dto/iri';

@Injectable()
export class ArmapImagesResourceService extends BaseSearchService<ArmapImageDto> {
    private readonly changed$ = new Subject<void>();

    constructor(http: HttpClient) {
        super('armap_images', http);
    }

    create(dto: ArmapImageDataDto): Observable<ArmapImageDto> {
        return this.http
            .post<ArmapImageDto>(`/api/armap_images`, dto)
            .pipe(tap(() => this.changed$.next()));
    }

    imagesChanged(): Observable<void> {
        return this.changed$.asObservable();
    }

    patchEnabled(iri: Iri, enabled: boolean): Observable<ArmapImageDto> {
        const headers = new HttpHeaders().append('Content-Type', 'application/merge-patch+json');
        return this.http.patch<ArmapImageDto>(iri, { enabled }, { headers });
    }
}
