import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'isav-file-dropzone',
    templateUrl: './file-dropzone.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavFileDropzone {
    @Input() multiple: boolean = false;
    @Output() files = new EventEmitter<File[]>();

    onFileDropped(fileList: FileList): void {
        this.handleFileList(fileList);
    }

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        this.handleFileList(input.files);
    }

    private handleFileList(fileList: FileList | null) {
        if (!fileList) throw new Error(`FileDropzone expected FileList got ${String(fileList)}`);
        this.files.emit(Array.from(fileList));
    }
}
