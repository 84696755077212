import { Pipe, PipeTransform } from '@angular/core';
import { Coordinate } from 'ol/coordinate';

interface IsavCoordinate {
    longitude?: number;
    latitude?: number;
}

function toCoordinate(coordinate: undefined | null | IsavCoordinate): Coordinate | null {
    if (!coordinate) return null;
    if (typeof coordinate.longitude !== 'number' || typeof coordinate.latitude !== 'number')
        return null;
    return [coordinate.longitude, coordinate.latitude];
}

function notNull(value: Coordinate | null): value is Coordinate {
    return value !== null;
}

export type IsavMapCoordinatePipeParam = undefined | null | IsavCoordinate;

@Pipe({
    name: 'isavMapCoordinate',
})
export class IsavMapCoordinate implements PipeTransform {
    transform(value: undefined | null): null;
    transform(value: IsavCoordinate): Coordinate;
    transform(value: IsavCoordinate[]): Coordinate[];
    transform(
        value: undefined | null | IsavCoordinate | IsavCoordinate[]
    ): null | Coordinate | Coordinate[];
    transform(
        value: undefined | null | IsavCoordinate | IsavCoordinate[]
    ): null | Coordinate | Coordinate[] {
        if (!value) return null;
        if (Array.isArray(value)) return value.map(toCoordinate).filter(notNull);
        return toCoordinate(value);
    }
}
