import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    Directive,
    forwardRef,
    Injector,
    Input,
    OnInit,
    TemplateRef,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormArray,
    UntypedFormControl,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { IsavBaseControl } from '../base-control';

export interface ManyInputControlTemplateContext {
    $implicit: UntypedFormControl;
    control: UntypedFormControl;
    index: number;
    position: number;
}

@Directive({
    selector: '[isavManyInputControl]',
})
export class IsavManyInputControl {
    constructor(public template: TemplateRef<ManyInputControlTemplateContext>) {}
}

@UntilDestroy()
@Component({
    selector: 'isav-many-input',
    templateUrl: './many-input.html',
    styleUrls: ['./many-input.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => IsavManyInput) },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => IsavManyInput), multi: true },
    ],
})
export class IsavManyInput extends IsavBaseControl implements OnInit, ControlValueAccessor {
    @Input() label = '';
    @Input() addLabel: string = 'Add';
    @Input() removeLabel: string = 'Remove';
    @ContentChild(IsavManyInputControl) manyInputControl: IsavManyInputControl;

    controls = new UntypedFormArray([]);
    private ignoreChange = false;

    constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.controls.valueChanges
            .pipe(
                filter(() => !this.ignoreChange),
                untilDestroyed(this)
            )
            .subscribe((value) => {
                this._onChange(value);
            });
    }

    writeValue(obj: any): void {
        this.ignoreChange = true;

        this.cdRef.markForCheck();
        this.controls.clear();
        if (obj !== null && Array.isArray(obj)) {
            obj.forEach((v) => this.addControl(v));
        }

        this.ignoreChange = false;
    }

    focusin() {
        this._onTouched();
    }

    trackBy(index: number, item: AbstractControl): any {
        return item;
    }

    addControl(value?: any): void {
        this.controls.push(new UntypedFormControl(value ?? null));
    }

    removeControl(index: number): void {
        this.controls.removeAt(index);
    }

    validate() {
        if (this.controls.invalid) {
            return { manyInput: 'At least one of the inputs is invalid!' };
        }
        return null;
    }
}
