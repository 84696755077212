import { Pipe, PipeTransform } from '@angular/core';
import { formatIsavDateTime } from '../../utils/date';

@Pipe({
    name: 'isavDateTime',
})
export class IsavDateTimePipe implements PipeTransform {
    transform(value: any): string {
        // new Date(null) -> 01/01/1970
        if (value === null) return '';
        const date = new Date(value);
        if (Number.isNaN(date.getTime())) return '';
        return formatIsavDateTime(date);
    }
}
