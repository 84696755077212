import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { HowToGuideDto } from '../dto/how-to-guide';

@Injectable()
export class HowToGuidesResourceService extends BaseSearchService<HowToGuideDto> {
    constructor(http: HttpClient) {
        super('how_to_guides', http);
    }
}
