import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    forwardRef,
    Injector,
    ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IsavBaseControl } from '../base-control';
import { formatStandardDate } from '../../../utils/date';

@Component({
    selector: 'isav-date-input',
    templateUrl: './date-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IsavDateInput), multi: true },
    ],
})
export class IsavDateInput extends IsavBaseControl {
    value: any;

    constructor(_injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    onChange(value: any) {
        this.value = value;
        this._onChange(this.ensureDateFormat(value));
        this._cdRef.markForCheck();
    }

    writeValue(obj: any): void {
        this.value = this.formatDate(obj);
        this._cdRef.markForCheck();
    }

    onFocus() {
        this._onTouched();
    }

    formatDate(obj: any): string | null {
        if (!obj) return null;
        const date = new Date(obj);
        return formatStandardDate(date);
    }

    correctValue() {
        this.value = this.ensureDateFormat(this.value);
        this._cdRef.markForCheck();
    }

    private ensureDateFormat(value: string): string | null {
        if (value === '') return null;
        if (!/[12]\d{3}-\d{2}-\d{2}/.test(value)) return null;
        const date = new Date(value);
        return Number.isNaN(date.getTime()) ? null : value;
    }
}
