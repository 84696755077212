<div class="carousel box">
    <div #images class="carousel-images">
        <ng-container *ngFor="let i of numbers; let last = last">
            <isav-landing-page-image [image]="imagesArray[i]"></isav-landing-page-image>
            <isav-landing-page-image
                *ngIf="last"
                [image]="imagesArray[0]"
            ></isav-landing-page-image>
        </ng-container>
    </div>
</div>
