export class MissingColumnDefError extends Error {
    constructor(column: string) {
        super(`Missing isavColumnDef directive with name '${column}'`);
    }
}

export class MissingHeaderCellDefError extends Error {
    constructor(column: string) {
        super(`Missing *isavHeaderCellDef for column "${column}"`);
    }
}

export class MissingFooterCellDefError extends Error {
    constructor(column: string) {
        super(`Missing *isavFooterCellDef for column "${column}"`);
    }
}

export class MissingCellDefError extends Error {
    constructor(column: string) {
        super(`Missing *isavCellDef for column "${column}"`);
    }
}

export class MissingRowDefError extends Error {
    constructor() {
        super('Missing *isavRowDef inside the table!');
    }
}

export class MissingRowDefColumnsError extends Error {
    constructor() {
        super('Missing columns specified in *isavRowDef!');
    }
}
