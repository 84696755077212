import { Directive, HostBinding, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[isavErrorClass]',
})
export class IsavErrorClass implements OnInit {
    @HostBinding('class.is-danger') isInvalid = false;

    constructor(private ngControl: NgControl) {}

    ngOnInit() {
        const checkIfInvalid = () => this.ngControl.invalid || false;
        this.ngControl.statusChanges
            ?.pipe(untilDestroyed(this), map(checkIfInvalid), startWith(checkIfInvalid()))
            .subscribe((isInvalid) => {
                this.isInvalid = isInvalid;
            });
    }
}
