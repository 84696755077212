import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { createPopper, Instance as PoperInstance } from '@popperjs/core';

export type OpenTrigger = 'hover' | 'click';

@Component({
    selector: 'isav-help-tooltip',
    templateUrl: './help-tooltip.html',
    styleUrls: ['./help-tooltip.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavHelpTooltip implements OnDestroy {
    @ViewChild('tooltip', { static: true }) tooltip!: ElementRef;
    @ViewChild('anchor', { static: true }) anchor!: ElementRef;

    isOpened: OpenTrigger | null = null;

    private poperInstance?: PoperInstance;

    constructor(private cdRef: ChangeDetectorRef, private ngZone: NgZone) {}

    ngOnDestroy() {
        if (this.poperInstance) this.poperInstance.destroy();
    }

    open(trigger: OpenTrigger) {
        if (this.isOpened !== null && trigger !== 'click') return;

        this.isOpened = trigger;
        this.cdRef.markForCheck();

        this.ngZone.runOutsideAngular(() => {
            if (this.poperInstance) this.poperInstance.destroy();
            this.poperInstance = createPopper(
                this.anchor.nativeElement,
                this.tooltip.nativeElement,
                {
                    placement: 'right',
                }
            );

            setTimeout(() => {
                this.tooltip.nativeElement.focus();
            });
        });
    }

    close(trigger: OpenTrigger) {
        if (trigger !== this.isOpened) return;

        this.isOpened = null;
        this.cdRef.markForCheck();

        if (this.poperInstance) {
            this.poperInstance.destroy();
            this.poperInstance = undefined;
        }
    }
}
