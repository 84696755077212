/* eslint-disable @angular-eslint/directive-selector */
import { ContentChild, Directive, HostBinding, Input, OnInit, TemplateRef } from '@angular/core';

export interface ICellDef {
    templateRef: TemplateRef<any>;
}

@Directive({
    selector: 'isav-cell, [isav-cell]',
})
export class IsavCell {
    @HostBinding('attr.role') readonly role: string = 'gridcell';
}

@Directive({
    selector: '[isavCellDef]',
})
export class IsavCellDef<T> {
    constructor(public templateRef: TemplateRef<{ $implicit: T }>) {}
}

@Directive({
    selector: 'isav-header-cell, [isav-header-cell]',
})
export class IsavHeaderCell {
    @HostBinding('attr.role') readonly role = 'columnheader';
}

@Directive({
    selector: '[isavHeaderCellDef]',
})
export class IsavHeaderCellDef<T> implements ICellDef {
    constructor(public templateRef: TemplateRef<{ $implicit: T }>) {}
}

@Directive({
    selector: 'isav-footer-cell, [isav-footer-cell]',
})
export class IsavFooterCell {
    @HostBinding('attr.role') readonly role = 'gridcell';
}

@Directive({
    selector: '[isavFooterCellDef]',
})
export class IsavFooterCellDef<T> implements ICellDef {
    constructor(public templateRef: TemplateRef<{ $implicit: T }>) {}
}

/**
 * Directive that describes column
 */
@Directive({
    selector: '[isavColumnDef]',
})
export class IsavColumnDef<T> implements OnInit {
    @Input('isavColumnDef') name?: string;

    @ContentChild(IsavCellDef, { static: true }) cellDef?: IsavCellDef<T>;
    @ContentChild(IsavHeaderCellDef, { static: true }) headerCellDef?: IsavHeaderCellDef<T>;
    @ContentChild(IsavFooterCellDef, { static: true }) footerCellDef?: IsavFooterCellDef<T>;

    ngOnInit() {
        if (!this.name) {
            throw new Error('Missing name specified on isavColumnDef!');
        }
    }
}
