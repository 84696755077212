import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    isDevMode,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coordinate } from 'ol/coordinate';
import OLMap from 'ol/Map';
import { transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import View from 'ol/View';
import proj4 from 'proj4';
import ResizeObserver from 'resize-observer-polyfill';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { IsavMapControlsService } from './control/controls.service';
import {
    SPATIAL_REFERENCE_3996_PROJECTION,
    toSpatialReference3996,
    WSG84_PROJECTION,
} from './util/projections';
import { IsavMapContextOption } from './context-menu/context-option';
import { IsavMapType, MapType } from './map-type';
import { filter, pluck } from 'rxjs/operators';
import { MapCenterService } from './map-center.service';
import { equals } from 'ramda';
import { MapOptions } from 'ol/PluggableMap';

proj4.defs(
    SPATIAL_REFERENCE_3996_PROJECTION,
    '+proj=stere +lat_0=90 +lat_ts=75 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'
);

proj4.defs('EPSG:32624', '+proj=utm +zone=24 +datum=WGS84 +units=m +no_defs');

proj4.defs(
    'EPSG:3413',
    '+proj=stere +lat_0=90 +lat_ts=70 +lon_0=-45 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'
);

register(proj4);

let UNIQUE = 0;

@UntilDestroy()
@Component({
    selector: 'isav-map',
    templateUrl: './map.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[attr.id]': 'null', // do not propagate id prop to dom node
    },
    exportAs: 'IsavMap',
    providers: [IsavMapControlsService],
})
export class IsavMap implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    public static GREENLAND_COORDINATES: Coordinate = [-43.44, 72.66];

    readonly IsavMapType = IsavMapType;

    @Input() id: string = 'isav-map-' + UNIQUE++;
    @Input() initialZoom: number = 4;
    @Input() type: MapType = IsavMapType.Greenland;
    @Input() showTypeSelect = false;
    @Input() showFullScreenBtn = false;
    @Input() hideControls: boolean = false;
    @Input() centerCoords?: Coordinate;
    @ViewChild('map', { static: true }) mapEl: ElementRef<HTMLDivElement>;

    map: OLMap;
    view: View;
    latestContextMenuCoordinates: Coordinate;
    contextMenuOptions: IsavMapContextOption[] = [];

    isInFullScreenMode = false;

    mapTypeControl = new UntypedFormControl(IsavMapType.Greenland);
    private sizeObserver: ResizeObserver;
    private viewChange = new Subject<void>();

    private escapeClickSub: Subscription;

    constructor(
        private readonly ngZone: NgZone,
        private readonly cdRef: ChangeDetectorRef,
        private readonly mapCenterService: MapCenterService
    ) {
        if (isDevMode()) {
            (<any>window).isavMap = this;
        }
    }

    get viewChanged(): Observable<void> {
        return this.viewChange.asObservable();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.type) {
            this.mapTypeControl.setValue(changes.type.currentValue);
        }
    }

    ngOnInit() {
        this.mapTypeControl.setValue(this.type);
        this.ngZone.runOutsideAngular(() => {
            this.view = this.getViewForType(this.type);
            const config: MapOptions = {
                target: this.mapEl.nativeElement,
                layers: [],
                view: this.view,
            };
            if (this.hideControls) config.controls = [];
            this.map = new OLMap(config);
        });

        (this.mapTypeControl.valueChanges as Observable<MapType>)
            .pipe(
                untilDestroyed(this),
                filter((type) => type !== IsavMapType.GoogleSatellite)
            )
            .subscribe((type) => {
                const centerCoords =
                    this.fromMapCoordinate(this.map.getView().getCenter()) ||
                    IsavMap.GREENLAND_COORDINATES;
                const resolution = this.map.getView().getResolution();
                this.view = this.getViewForType(type);
                this.map.setView(this.view);
                this.view.setCenter(this.toMapCoordinate(centerCoords));
                this.view.setResolution(resolution);
                this.viewChange.next();
                this.cdRef.markForCheck();
            });

        this.mapCenterService.centerChanged.pipe(untilDestroyed(this)).subscribe((newCenter) => {
            this.map.getView().setCenter(this.toMapCoordinate(newCenter));
        });

        this.escapeClickSub = fromEvent(document, 'keydown')
            .pipe(
                pluck('code'),
                filter(equals('Escape')),
                filter(() => this.isInFullScreenMode)
            )
            .subscribe(() => {
                this.toggleFullScreenMode(false);
            });
    }

    ngAfterViewInit() {
        // https://openlayers.org/en/latest/doc/faq.html#user-content-why-is-zooming-or-clicking-off-inaccurate
        this.sizeObserver = new ResizeObserver(() => {
            this.map.updateSize();
        });
        this.sizeObserver.observe(this.mapEl.nativeElement);
    }

    ngOnDestroy(): void {
        this.map.dispose();
        this.sizeObserver.disconnect();
    }

    toMapCoordinate(coordinate: Coordinate): Coordinate {
        const projection = this.view.getProjection().getCode();
        return transform(coordinate, WSG84_PROJECTION, projection);
    }

    fromMapCoordinate(coordinate: Coordinate): Coordinate {
        const projection = this.view.getProjection().getCode();
        return transform(coordinate, projection, WSG84_PROJECTION);
    }

    addContextMenuOption(option: IsavMapContextOption) {
        this.contextMenuOptions.push(option);
    }

    removeContextMenuOption(option: IsavMapContextOption) {
        this.contextMenuOptions = this.contextMenuOptions.filter((o) => o !== option);
    }

    toggleFullScreenMode(forceFullScreenMode?: boolean) {
        this.isInFullScreenMode =
            typeof forceFullScreenMode !== 'undefined'
                ? forceFullScreenMode
                : !this.isInFullScreenMode;
        // Reset position to initial state
        if (this.centerCoords) {
            this.view.setCenter(this.toMapCoordinate(this.centerCoords));
            this.view.setZoom(this.initialZoom);
        }
        this.cdRef.markForCheck();
    }

    private getViewForType(type: MapType = IsavMapType.Greenland): View {
        switch (type) {
            case IsavMapType.TopographicGreenland:
            case IsavMapType.Greenland:
                return this.getGeusMapsView();
            case IsavMapType.Satellite:
            case IsavMapType.Ice:
                return this.getSentinelMapsView();
            case IsavMapType.WorldStreetMap:
                return this.getOSMMapsView();
            case IsavMapType.WorldStreetMapV4:
                return this.getOSMMapsViewV4();
            default:
                throw new Error(`Unknown type '${type}' passed to IsavMap#getViewForType`);
        }
    }

    private getOSMMapsViewV4() {
        return new View({
            projection: 'EPSG:3413',
            zoom: this.initialZoom,
            center: [1324155.7724081269, 6288375.366311571],
        });
    }

    private getOSMMapsView(): View {
        return new View({
            projection: 'EPSG:3857',
            zoom: this.initialZoom || 0,
            center: [1324155.7724081269, 6288375.366311571],
        });
    }

    /**
     * returns view tailored to GEUS Map
     */
    private getGeusMapsView(): View {
        return new View({
            center: toSpatialReference3996(IsavMap.GREENLAND_COORDINATES),
            projection: SPATIAL_REFERENCE_3996_PROJECTION,
            zoom: this.initialZoom,
            minZoom: 0,
            maxZoom: 13,
            resolutions: [
                13229.193125052918, 9260.435187537043, 6614.596562526459, 5291.677250021167,
                3968.7579375158753, 2645.8386250105837, 1984.3789687579376, 1322.9193125052918,
                661.4596562526459, 529.1677250021168, 396.87579375158754, 264.5838625010584,
                132.2919312505292, 66.1459656252646,
            ],
        });
    }

    private getSentinelMapsView(): View {
        return new View({
            projection: 'EPSG:3857',
            zoom: Math.max(8, this.initialZoom),
            minZoom: 6,
            center: transform(IsavMap.GREENLAND_COORDINATES, WSG84_PROJECTION, 'EPSG:3857'),
        });
    }
}
