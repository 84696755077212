<div class="modal is-active" (isavDialog)="close()">
    <div class="modal-background"></div>
    <div class="modal-card is-relative">
        <div class="modal-card-head">
            <p class="modal-card-title">
                <span>Image upload</span>
            </p>
            <button class="delete" aria-label="close" type="button" (click)="close()"></button>
        </div>

        <div class="modal-card-body">
            <article
                *ngIf="!loading && imageIri && authorIri !== userIri"
                class="message is-warning"
            >
                <div class="message-body">
                    Only author can edit image metadata. In order to change the description, you
                    need to reupload the image.
                </div>
            </article>
            <div class="field is-horizontal mb-2">
                <div class="field-label field-label-tooltip">
                    <label class="has-text-weight-bold has-text-primary"> Image </label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <isav-image-uploader
                            [imageIri]="imageIri"
                            (imageIriChange)="onChange($event)"
                        ></isav-image-uploader>
                        <p *ngIf="!imageIri" class="help">Maximum file size: {{ maxImageSize }}</p>
                    </div>
                </div>
            </div>
            <form [formGroup]="form">
                <isav-text-input label="Caption" formControlName="caption"></isav-text-input>
                <isav-text-input label="Copyright" formControlName="copyright"></isav-text-input>
            </form>
        </div>

        <div class="modal-card-foot">
            <button
                [disabled]="!imageIri || userIri !== authorIri"
                data-testid="btn-save"
                isavBtn
                color="success"
                (click)="save()"
            >
                Save
            </button>
            <button data-testid="btn-close" isavBtn (click)="close()">Close</button>
        </div>
        <isav-loader-overlay *ngIf="loading"></isav-loader-overlay>
    </div>
</div>
