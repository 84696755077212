<div class="message is-danger" *ngIf="!published">
    <div class="message-body" data-testid="unpublished-warning">
        <strong>
            {{ contentName | capitalize }} is not published yet. Other people will not be able to see
            it, until you have published it.Select Edit mode to publish your content.
            <br>
            Note that all mandatory fields must be filled in
            (text shown in green colour in right side panel), before publishing is possible.
            <br>
            If you want to see your unpublished contents you need to go
            to <a [routerLink]="['/my-isaaffik', myContentPath]">My Isaaffik</a> and select proper
            tab.
        </strong>
    </div>
</div>
