import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';

@Injectable()
export class IriWithSearchFallbackGuard implements CanActivate {
    constructor(private router: Router) {}

    /**
     * Check if we have iri inside the query params or redirect to search
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const urls: string[] = state.url.replace(/\?.*$/, '').split('/');
        const fallbackUrl = [urls.slice(0, -2).join('/'), 'search'];
        const fallbackUrlTree = this.router.createUrlTree(fallbackUrl);

        return route.queryParamMap.has('iri') || fallbackUrlTree;
    }
}
