import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

interface LandingPageImage {
    name: string;
    sloganTitle: string;
    sloganText: string;
    placement: 'top' | 'bottom';
}

@Component({
    selector: 'isav-landing-page-image',
    templateUrl: './landing-page-image.html',
    styleUrls: ['./landing-page-image.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavLandingPageImage {
    @Input() image: LandingPageImage;
}
