import {
    Component,
    ChangeDetectionStrategy,
    Injector,
    ChangeDetectorRef,
    forwardRef,
} from '@angular/core';
import { IsavBaseControl } from '../base-control';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

interface PasswordInput {
    password: string;
    confirmPassword: string;
}

@Component({
    selector: 'isav-password-input',
    templateUrl: './password-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavPasswordInput),
            multi: true,
        },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => IsavPasswordInput), multi: true },
    ],
})
export class IsavPasswordInput extends IsavBaseControl {
    value: PasswordInput = { password: '', confirmPassword: '' };

    constructor(_injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    onChange(prop, value) {
        this.value[prop] = value;
        this._onChange(this.value.password);
        this._cdRef.markForCheck();
    }

    writeValue(obj: any): void {
        if (obj !== null && obj !== undefined) {
            if (typeof obj !== 'string') obj = String(obj);
            this.value.password = obj;
            this.value.confirmPassword = obj;
        }
        this._cdRef.markForCheck();
    }

    onFocus() {
        this._onTouched();
    }

    validate() {
        if (this.value.password.length < 8)
            return { password: 'Password must be at least 8 characters' };
        if (this.value.password !== this.value.confirmPassword)
            return { password: 'Passwords do not match' };
        return null;
    }
}
