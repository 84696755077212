import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class FileService {
    saveCsv(content: string, fileName: string): void {
        const blob = new Blob([content], { type: 'text/csv' });
        saveAs(blob, fileName);
    }
}
