import { Injectable } from '@angular/core';
import { optionsFromCapabilities } from 'ol/source/WMTS';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { formatStandardDate, parseStandardDate } from '../../../utils/date';
import { subMonths } from 'date-fns';
import { SentinelService } from '../../sentinel.service';

const DEFAULT_END_DATE = formatStandardDate(new Date());
const DEFAULT_CLOUD_COVERAGE = 20;

export interface IceLayerSettings {
    cloudCoverage: number;
    date: string;
}

/**
 * Start date will be half year before the passed date, fixed on first day of the month
 * at 00:00:00 local time
 */
function getStartDate(dateStr: string): Date {
    const date = parseStandardDate(dateStr);
    date.setHours(0, 0, 0, 0);
    date.setDate(1);
    return subMonths(date, 6);
}

@Injectable({ providedIn: 'root' })
export class IceLayerService {
    private readonly settings$ = new BehaviorSubject<IceLayerSettings>({
        cloudCoverage: DEFAULT_CLOUD_COVERAGE,
        date: DEFAULT_END_DATE,
    });
    private readonly options$ = new ReplaySubject<any>(1);

    constructor(readonly sentinelService: SentinelService) {
        const capabilities$ = sentinelService.getCapabilities();

        combineLatest([this.settings$, capabilities$]).subscribe(
            ([settings, capabilities]) => {
                const options = optionsFromCapabilities(capabilities, {
                    layer: 'ICE',
                    matrixSet: 'PopularWebMercator512',
                });
                const tileLoadFunction = (imageTile, src) => {
                    const { date, cloudCoverage } = settings;
                    const time = '&TIME=' + encodeURIComponent(`${getStartDate(date)}/${date}`);
                    const maxcc = `&MAXCC=${cloudCoverage}`;
                    const additionalParams = [maxcc, time].join('');
                    imageTile.getImage().src = src + additionalParams;
                };
                this.options$.next({ ...options, tileLoadFunction });
            },
            (err) => {
                console.error(err);
            }
        );
    }

    get options(): Observable<any> {
        return this.options$.asObservable();
    }

    get settings(): Observable<IceLayerSettings> {
        return this.settings$.asObservable();
    }

    changeSettings(value: IceLayerSettings) {
        this.settings$.next(value);
    }
}
