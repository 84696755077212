<header class="isaaffik-header">
    <div class="isaaffik-header__top">
        <a routerLink="/" aria-label="Homepage" class="logo">
            <img isavLogo />
            <span class="ml-2 logo-slogan"> - the arctic gateway</span>
        </a>
        <isav-mobile-navigation></isav-mobile-navigation>
        <isav-top-navigation class="is-hidden-touch"></isav-top-navigation>
    </div>
    <div class="isaaffik-header__bottom">
        <isav-main-navigation class="is-hidden-touch"></isav-main-navigation>
        <isav-header-actions></isav-header-actions>
    </div>
    <div class="isaaffik-header__backdrop"></div>
</header>
