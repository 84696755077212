<div
    class="uploader-zone dropzone"
    data-testid="dropzone"
    (isavFileDropped)="onFileDropped($event)"
>
    <div class="uploader-content">
        <div>Drag and drop file here</div>
        <div>or</div>
        <div class="file is-centered is-primary">
            <label class="file-label">
                <input
                    [multiple]="multiple"
                    class="file-input"
                    type="file"
                    (change)="onFileSelected($event)"
                />
                <span class="file-cta">
                    <span class="file-icon">
                        <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label"> Choose a file… </span>
                </span>
            </label>
        </div>
    </div>
</div>
