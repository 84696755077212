import { Pipe, PipeTransform, Inject, SecurityContext } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'isavFirstParagraph',
})
export class IsavFirstParagraphPipe implements PipeTransform {
    private source: HTMLDivElement = this.document.createElement('div');

    constructor(private sanitizer: DomSanitizer, @Inject(DOCUMENT) private document: Document) {}

    transform(value: any): string {
        if (typeof value !== 'string') return '';

        // we need to sanitize value because of security reasons
        this.source.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, value) ?? '';
        const paragraph =
            (this.source.querySelector('p')?.textContent || this.source.textContent) ?? '';

        if (paragraph.length > 150) {
            return paragraph.substring(0, 150) + '...';
        }

        return paragraph;
    }
}
