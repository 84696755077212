import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';
import { Iri } from '../../../core/dto/iri';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { IsavImagePopup } from '../image-popup/image-popup';

@Component({
    selector: 'isav-image-display',
    templateUrl: './image-display.html',
    styleUrls: ['./image-display.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavImageDisplay {
    @Input() imageIri: Iri;
    @Input() editable: boolean = true;
    @Output() removeIri = new EventEmitter<Iri>();

    showButtons = false;

    constructor(private dialogService: DialogService) {}

    onImageRemove(): void {
        this.removeIri.emit(this.imageIri);
    }

    onImageEdit() {
        this.dialogService.spawnComponent(IsavImagePopup, this.imageIri);
    }
}
