<div class="pagination is-small is-centered" role="navigation" aria-label="Pagination">
    <div *ngIf="numberOfPages > 3" class="pagination-input-container">
        <label for="changePageInput">Go to page:&nbsp;</label>
        <input
            id="changePageInput"
            type="number"
            class="pagination-link pagination-input"
            [value]="changePageInputValue"
            (input)="changePageInputValue = $event.target.value"
            (keydown.enter)="handleEnter()"
        />
    </div>

    <ul class="pagination-list">
        <li *ngIf="page > 1">
            <a
                class="pagination-link"
                aria-label="Goto previous page"
                (click)="changePage(page - 1)"
            >
                &lt;
            </a>
        </li>
        <li *ngFor="let element of pagination">
            <a
                *ngIf="element !== '...'; else ellipsis"
                class="pagination-link"
                [attr.aria-label]="'Goto page ' + element"
                [class.is-current]="element === page"
                (click)="changePage(element)"
            >
                {{ element }}
            </a>
        </li>
        <li *ngIf="page < numberOfPages">
            <a class="pagination-link" aria-label="Goto next page" (click)="changePage(page + 1)">
                &gt;
            </a>
        </li>

        <li *ngIf="showAllEnable">
            <a *ngIf="queryPagination === '1'" class="ml-3" (click)="showAll()">
                Show all results ({{total}})
            </a>
            <a *ngIf="queryPagination === '0'" class="ml-3" (click)="showPages()">
                Show paginated results
            </a>
        </li>
    </ul>
</div>

<ng-template #ellipsis>
    <span class="pagination-ellipsis">&hellip;</span>
</ng-template>
