import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { DialogManager } from '../../../shared/dialog/dialog.service';

export interface ContentEditorData {
    title: string;
    value: string;
}

@Component({
    selector: 'isav-content-editor-dialog',
    templateUrl: './content-editor-dialog.html',
    styleUrls: ['./content-editor-dialog.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavContentEditorDialog implements OnInit {
    title: string;
    value: string;

    constructor(private dialogManager: DialogManager<ContentEditorData, string | null>) {}

    ngOnInit() {
        if (this.dialogManager.data) {
            this.value = this.dialogManager.data.value;
            this.title = this.dialogManager.data.title;
        } else {
            this.close(null);
        }
    }

    save() {
        this.close(this.value);
    }

    close(value: string | null = null): void {
        this.dialogManager.close(value);
    }
}
