import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { DialogService, DialogManager } from '../../../shared/dialog/dialog.service';
import { IsavMapMarkersDialog } from '../map-markers-dialog/map-markers-dialog';
import { IsavMap } from '../../../map/map';
import { MarkersResourceService } from '../../../core/api/markers-resource.service';

@Component({
    selector: 'isav-map-markers-creation',
    templateUrl: './map-markers-creation.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavMapMarkersCreation {
    dialogManager: DialogManager;

    constructor(
        private dialogService: DialogService,
        private isavMap: IsavMap,
        private markersService: MarkersResourceService
    ) {}

    open() {
        this.dialogManager = this.dialogService.spawnComponent(
            IsavMapMarkersDialog,
            this.isavMap.latestContextMenuCoordinates
        );
        this.dialogManager.closed$.subscribe((created) => {
            if (!created) return;
            this.markersService.next();
        });
    }
}
