import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { ContentCreateAccessService } from '../../core/api/content-create-access.service';

export type CloneableContentName =
    | 'Project'
    | 'Page'
    | 'Infrastructure'
    | 'EducationAndTraining'
    | 'Logistic'
    | 'Datalink';

@Directive({
    selector: '[isavHasCreateAccess]',
})
export class IsavHasCreateAccessDirective implements OnInit {
    @Input('isavHasCreateAccess') name: CloneableContentName;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private contentCreateAccessService: ContentCreateAccessService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.contentCreateAccessService.getAccess(this.name).subscribe((access) => {
            if (access) {
                this.render();
            } else {
                this.remove();
            }
        });
    }

    private render() {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.cdr.markForCheck();
    }

    private remove() {
        this.viewContainer.clear();
        this.cdr.markForCheck();
    }
}
