import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { MarkerDto } from '../../core/dto/marker';

@Component({
    selector: 'isav-marker',
    templateUrl: './marker.html',
    styleUrls: ['./marker.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavMarker {
    @Input() marker: MarkerDto;
    @Output() remove = new EventEmitter<MarkerDto>();

    onDelete() {
        this.remove.emit(this.marker);
    }

    editLink() {
        return ['/markers/edit', this.marker.title || ''];
    }
}
