import { Directive, HostBinding, HostListener } from '@angular/core';
import { SearchPopupService } from './search-popup.service';

@Directive({
    selector: '[isavSearchBtn]',
})
export class SearchButton {
    @HostBinding('attr.aria-haspopup') readonly haspopup = 'true';

    constructor(private searchPopupService: SearchPopupService) {}

    @HostListener('click', ['$event'])
    onClickListener() {
        this.searchPopupService.open();
    }
}
