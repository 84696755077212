import { EventsKey } from 'ol/events';
import { Observable } from 'rxjs';

export type ListenerFunction<T> = (value: T) => void;
export interface EventTarget<K, T> {
    on(type: K, listener: ListenerFunction<T>): EventsKey;
    un(type: K, listener: ListenerFunction<T>): void;
}

export function fromMapEvent<K, T>(target: EventTarget<K, T>, event: K): Observable<T> {
    return new Observable<T>((subscriber) => {
        const _listener: ListenerFunction<T> = (value) => subscriber.next(value);
        target.on(event, _listener);
        return () => target.un(event, _listener);
    });
}
