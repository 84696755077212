import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { ContentService } from '../api/content.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { dasherize } from '../../utils/strings';

@Injectable()
export class ContentCreateGuard implements CanActivate {
    constructor(private contentService: ContentService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const content = route.data.content;

        if (!content) return false;
        return this.contentService.create(content).pipe(
            map((data: any) => {
                const url = state.url.replace('create', 'edit');
                const name = dasherize(data.name || '');
                return this.router.createUrlTree([url, name], {
                    queryParams: { iri: data['@id'] },
                });
            }),
            catchError(() => of(false))
        );
    }
}
