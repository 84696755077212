import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArmapMappingDto } from '../dto/armap-mapping';
import { BaseSearchService } from './base-search-service';
import { uncapitalize, camelCaseToKebabCase } from '../../utils/strings';

@Injectable()
export class ArmapMappingsResourceService extends BaseSearchService<ArmapMappingDto> {
    constructor(http: HttpClient) {
        super('armap_mappings', http);
    }

    map(taxonomyClass: string, armapMapping: string, taxonomy: string) {
        const property = uncapitalize(taxonomyClass);
        const body = { armapMapping: armapMapping, [property]: taxonomy };
        const url = camelCaseToKebabCase(taxonomyClass);
        return this.http.post(`/api/armap-mapping/map-${url}`, body);
    }
}
