import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Iri } from '../../../core/dto/iri';
import { ContentService } from '../../../core/api/content.service';
import { DocumentDto } from '../../../core/dto/document';
import { Observable } from 'rxjs';

@Component({
    selector: 'isav-file-display',
    templateUrl: './file-display.html',
    styleUrls: ['./file-display.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'columns is-mobile',
    },
})
export class IsavFileDisplay implements OnChanges {
    @Input() iri: Iri;
    file$: Observable<DocumentDto>;

    constructor(private contentService: ContentService) {}

    get downloadIri() {
        return `${this.iri}/download`;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.iri && this.iri) {
            this.file$ = this.contentService.getByIri<DocumentDto>(this.iri);
        }
    }

    icon(file: DocumentDto): string {
        if (/pdf/.test(file.mimeType)) return 'file-pdf';
        if (/image/.test(file.mimeType)) return 'file-image';
        return 'file';
    }
}
