import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    SimpleSearchContainer,
    SimpleSearchContent,
    SimpleSearchForm,
    SimpleSearchMap,
    SimpleSearchSubmit,
} from './simple-search-container';
import { IsavLandingPageCarousel } from './landing-page-carousel/landing-page-carousel';
import { IsavLandingPageImage } from './landing-page-image/landing-page-image';

export const EXPORTED_DECLARATIONS = [
    SimpleSearchContainer,
    SimpleSearchContent,
    SimpleSearchMap,
    SimpleSearchSubmit,
    SimpleSearchForm,
    IsavLandingPageCarousel,
    IsavLandingPageImage,
];

@NgModule({
    imports: [CommonModule],
    declarations: [EXPORTED_DECLARATIONS],
    exports: [EXPORTED_DECLARATIONS],
})
export class SimpleSearchContainerModule {}
