import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PersonService } from '../../core/api/person.service';
import { NotificationService } from '../../core/services/notification.service';

@UntilDestroy()
@Component({
    selector: 'isav-newsletter-unsubscribe',
    templateUrl: './newsletter-unsubscribe.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterUnsubscribe implements OnInit {
    constructor(
        private personService: PersonService,
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
            const tokenId = params.token;
            const personIri = params.personIri;
            if (!tokenId || !personIri) this.router.navigate(['/']);
            this.personService.unsubscribeNewsletter(tokenId, personIri).subscribe(() => {
                this.notificationService.add({
                    title: 'Success',
                    message: 'Newsletter has been unsubscribed successfully.',
                    type: 'success',
                    delay: 5000,
                });
            });
        });
    }
}
