<div class="content-ownership box content-type-form">
    <div class="is-flex">
        <h1 class="title">Ownership</h1>
        <isav-tooltip-display class="mt-1" tooltipKey="Content:ownership"></isav-tooltip-display>
    </div>
    <div class="owners">
        <isav-user-badge
            *ngFor="let owner of owners"
            [imageUrl]="owner.profileImage ? owner.profileImage : null"
            (remove)="revoke(owner)"
        >
            {{ owner.fullName }}
        </isav-user-badge>
        <isav-person-selector (selected)="share($event)" [exclude]="owners"></isav-person-selector>
    </div>
</div>
