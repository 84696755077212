import { Injectable } from '@angular/core';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SearchResource } from './search-resource';
import { beginSearch, saveResults, searchSort } from './search.actions';
import { selectGlobalSearch } from './search.selectors';
import { Store } from '@ngrx/store';
import { debounceTick } from '../../utils/rxjs/debounce-tick';

@Injectable()
export class SearchEffects {
    private readonly resources: { [key: string]: SearchResource<any> } = {};

    search$ = createEffect(() => {
        return this.actions.pipe(
            ofType(beginSearch),
            withLatestFrom(this.store.select(selectGlobalSearch)),
            debounceTick(),
            mergeMap(([action, search]) => {
                const namespace = action.namespace;
                const state = search[namespace];
                const query = state.query;
                const resource = this.resources[namespace];

                if (!resource) {
                    console.error(
                        `Missing resource for namespace '${namespace}' in SearchEffects!`
                    );
                    return EMPTY;
                }

                const nextSearch = this.actions.pipe(ofType(beginSearch), take(1));

                return resource.getAll(query, state.self).pipe(
                    takeUntil(nextSearch),
                    map((results) => saveResults({ namespace, results }))
                );
            })
        );
    });

    searchOnSort$ = createEffect(() =>
        this.actions.pipe(
            ofType(searchSort),
            mergeMap((action) => of(beginSearch({ namespace: action.namespace, query: {} })))
        )
    );

    constructor(private actions: Actions, private store: Store) {}

    registerResource(namespace: string, resource: SearchResource<any>) {
        if (this.resources[namespace]) {
            throw new Error(`Already registered namespace '${namespace}'!`);
        }

        this.resources[namespace] = resource;
    }
}
