/**
 * This enum needs to have keys equal it's value, so we can safely
 * use strings instead of the enum
 */
export enum IsavMapType {
    WorldStreetMap = 'WorldStreetMap',
    WorldStreetMapV4 = 'WorldStreetMapV4',
    Greenland = 'Greenland',
    Ice = 'Ice',
    TopographicGreenland = 'TopographicGreenland',
    Satellite = 'Satellite',
    GoogleSatellite = 'GoogleSatellite',
}

export type MapType = IsavMapType | keyof typeof IsavMapType;
