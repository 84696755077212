import {
    Component,
    ChangeDetectionStrategy,
    Input,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { Iri } from '../../core/dto/iri';
import { DocumentDto } from '../../core/dto/document';
import { Observable, forkJoin } from 'rxjs';
import { ContentService } from '../../core/api/content.service';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'isav-files-link',
    templateUrl: './files-link.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavFilesLink implements OnChanges {
    @Input() iris: Iri[];
    @Input() showLoaderWhileLoading: boolean = false;
    files$: Observable<DocumentDto[]>;
    loading: boolean = false;

    constructor(private contentService: ContentService, private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.iris && this.iris) {
            this.loading = true;
            const requests = this.iris
                .filter((iri) => iri !== null)
                .map((iri) => this.contentService.getByIri<DocumentDto>(iri));
            this.files$ = forkJoin(requests).pipe(
                tap(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })
            );
        }
    }

    downloadUrl(iri: Iri) {
        return `${iri}/download`;
    }
}
