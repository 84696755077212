import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IceLayerService } from './ice-layer.service';

@UntilDestroy()
@Component({
    selector: 'isav-map-ice-layer-settings',
    templateUrl: './ice-layer-settings.html',
    styleUrls: ['./ice-layer-settings.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavMapIceLayerSettings implements OnInit {
    visible: boolean = false;
    sliderDisplayValue: number = 20;
    readonly settings = new UntypedFormGroup({
        cloudCoverage: new UntypedFormControl(20),
        date: new UntypedFormControl(new Date()),
    });

    @ViewChild('relativeContainer', { static: false })
    relativeContainer: ElementRef<HTMLDivElement>;

    constructor(
        private readonly iceLayerService: IceLayerService,
        private readonly cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.settings.valueChanges
            .pipe(untilDestroyed(this), debounceTime(500))
            .subscribe((value) => {
                this.iceLayerService.changeSettings(value);
            });

        this.iceLayerService.settings
            .pipe(untilDestroyed(this))
            .subscribe(({ date, cloudCoverage }) => {
                this.sliderDisplayValue = cloudCoverage;
                this.settings.setValue({ date, cloudCoverage }, { emitEvent: false });
                this.cdRef.markForCheck();
            });

        fromEvent(document, 'click')
            .pipe(untilDestroyed(this))
            .subscribe((event: MouseEvent) => {
                if (!this.relativeContainer?.nativeElement?.contains(event.target as Node)) {
                    this.visible = false;
                    this.cdRef.markForCheck();
                }
            });
    }

    toggleSettings() {
        this.visible = !this.visible;
        this.cdRef.markForCheck();
    }
}
