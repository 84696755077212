<ng-container *ngIf="fieldworks.length > 0">
    <h2 class="title is-1">Fieldworks</h2>
    <ng-container *ngFor="let fieldwork of fieldworks; let i = index">
        <isav-project-fieldwork
            *ngIf="i < shownFieldworks"
            [fieldwork]="fieldwork"
            [fieldworkIncludeOutsideSettlements]="fieldworkIncludeOutsideSettlements"
        ></isav-project-fieldwork>
    </ng-container>
    <p class="has-text-right">
        <a *ngIf="fieldworks.length > shownFieldworks" (click)="showAll(fieldworks.length)">
            Show all fieldworks ({{fieldworks.length}})
        </a>
        <a *ngIf="shownFieldworks > 2" (click)="hide()">Hide fieldworks</a>
    </p>
</ng-container>
