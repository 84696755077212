<div class="modal is-active" (isavDialog)="close()">
    <div class="modal-background"></div>
    <div class="modal-card">
        <div class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button class="delete" aria-label="close" type="button" (click)="close()"></button>
        </div>

        <div class="modal-card-body">
            <isav-ckeditor-wrapper
                [dialog]="true"
                [value]="value"
                (valueChange)="value = $event"
            ></isav-ckeditor-wrapper>
        </div>

        <div class="modal-card-foot">
            <button data-testid="btn-save" color="success" isavBtn (click)="save()">Save</button>
            <button data-testid="btn-close" isavBtn (click)="close()">Close</button>
        </div>
    </div>
</div>
