import { Iri } from './iri';
import { StringDateTime } from './string-date-time';
import { LinkDto } from './link';

export enum LocationType {
    ABOUT = 1,
    QUICK_GUIDES = 2,
    SHORTCUTS = 3,
    LANDING_CARDS = 4,
    UNLINKED = 5,
}

export interface PageDto {
    readonly '@context': string;
    readonly '@id': string;
    readonly '@type': string;
    readonly attachments?: readonly Iri[];
    readonly author?: Iri;
    readonly body?: string;
    readonly createdAt: StringDateTime;
    readonly draft: boolean;
    readonly externalLink?: LinkDto;
    readonly locationType: number;
    readonly id: string;
    readonly image?: Iri;
    readonly internal?: boolean;
    readonly lastModified?: StringDateTime;
    readonly owners: readonly Iri[];
    readonly requireAuthentication: boolean;
    readonly title?: string;
    readonly weight?: number;
    readonly logoType?: number;
}
