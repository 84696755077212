import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    forwardRef,
    Injector,
    ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IsavBaseControl } from '../base-control';
import { formatStandardDateTime } from '../../../utils/date';
import { format } from 'date-fns';

const SUPPORTS_NATIVE_DATETIME_LOCAL = (function () {
    const input = document.createElement('input');
    input.setAttribute('type', 'datetime-local');
    const notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);
    return input.value !== notADateValue;
})();

@Component({
    selector: 'isav-datetime-input',
    templateUrl: './datetime-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavDateTimeInput),
            multi: true,
        },
    ],
})
export class IsavDateTimeInput extends IsavBaseControl {
    EXAMPLE_DATE = this.formatDate(new Date());
    SUPPORTS_NATIVE_DATETIME_LOCAL = SUPPORTS_NATIVE_DATETIME_LOCAL;
    value: any;

    constructor(_injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    onChange(value: any) {
        this.value = value;
        const date = this.getDate(this.value);
        this._onChange(date ? formatStandardDateTime(date) : null);
        this._cdRef.markForCheck();
    }

    writeValue(obj: any): void {
        this.value = this.formatDate(obj);
        this._cdRef.markForCheck();
    }

    onFocus() {
        this._onTouched();
    }

    formatDate(obj: any): string | null {
        if (!obj) return null;
        const date = new Date(obj);
        return format(date, "yyyy-MM-dd'T'HH:mm:ss");
    }

    correctValue() {
        this.value = this.ensureDateTimeFormat(this.value);
        this._cdRef.markForCheck();
    }

    private getDate(value: string): Date | null {
        if (value === '') return null;
        if (!/[12]\d{3}-\d{2}-\d{2}[T ]\d{2}:\d{2}(:\d{2})?/.test(value)) return null;
        // we need replace space for T for more convenient input in browsers which does not support
        // native datetime-local input so we allow user to input space instead of the T
        // as we ensure with previous regex that the datetime string will be in correct format
        const date = new Date(value.replace(' ', 'T'));
        return Number.isNaN(date.getTime()) ? null : date;
    }

    private ensureDateTimeFormat(value: string): string | null {
        return this.getDate(value) ? value : null;
    }
}
