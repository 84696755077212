import {
    Component,
    ChangeDetectionStrategy,
    forwardRef,
    Injector,
    ChangeDetectorRef,
    Input,
} from '@angular/core';
import { IsavBaseControl } from '../base-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Iri } from '../../../core/dto/iri';

@Component({
    selector: 'isav-document-input',
    templateUrl: './document-input.html',
    styleUrls: ['./document-input.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavDocumentInput),
            multi: true,
        },
    ],
})
export class IsavDocumentInput extends IsavBaseControl {
    @Input() multiple: boolean = false;
    value: Iri[] = [];

    constructor(_injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    writeValue(obj: any): void {
        if (Array.isArray(obj)) this.value = obj;
        if (typeof obj === 'string') this.value = [obj];
    }

    onChange(files: Iri[]): void {
        this.value = files;
        if (this.multiple) {
            this._onChange(this.value);
        } else {
            this._onChange(this.value[0] || null);
        }
        this._cdRef.markForCheck();
    }
}
