<div>
    <article class="message is-primary mt-5" *ngIf="filteredCounter > 0">
        <div class="message-body">
            We hide {{ filteredCounter }} results from the timeline due to unknown start and/or end
            date, or start date is equal to end date
        </div>
    </article>
    <article class="message is-primary mt-5" *ngIf="sarFilter > 0">
        <div class="message-body">
            We hide {{ sarFilter }} results from the timeline, because infrastructure does not have
            time period
        </div>
    </article>
    <p class="help">How to zoom: Click on the timeline and scroll with your mouse</p>
    <div
        class="mt-1"
        [visTimelineOptions]="options"
        [visTimeline]="id"
        [visTimelineItems]="visTimelineItems"
        (initialized)="timelineInitialized()"
    ></div>
</div>
