import { Action } from '@ngrx/store';
import produce, { Draft } from 'immer';

export type IProducer<S, A> = (state: Draft<S>, action: A) => Draft<S> | void;

/**
 * Used to produce immutable state inside the store reducer, takes draft state as first argument
 * and action as 2nd
 *
 * @see {produce}
 *
 * @param producer
 */
export function produceState<S, A extends Action>(producer: IProducer<S, A>) {
    return (state: S, action: A) =>
        produce(state, (draft) => {
            return producer(draft, action);
        });
}
