import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { EventDto } from '../dto/event';

@Injectable()
export class EventsResourceService extends BaseSearchService<EventDto> {
    constructor(http: HttpClient) {
        super('events', http);
    }
}
