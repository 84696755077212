import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Iri } from '../../core/dto/iri';
import { dasherize } from '../../utils/strings';

@Directive({
    selector: 'a[isavEditLink]',
})
export class IsavEditLink {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('isavEditLink') content?: { '@id': Iri; name?: string; title?: string };
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('isavEditLinkProperty') nameProperty = 'name';

    constructor(private router: Router, private route: ActivatedRoute) {}

    private get path() {
        return ['../edit', dasherize(this.content?.[this.nameProperty] || '')];
    }

    private get params() {
        return {
            queryParams: { iri: this.content?.['@id'] || '' },
            relativeTo: this.route,
        };
    }

    @HostBinding('attr.href')
    get href() {
        return this.router.createUrlTree(this.path, this.params).toString();
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigate(this.path, this.params);
    }
}
