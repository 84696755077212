import {
    Component,
    ChangeDetectionStrategy,
    Injector,
    ChangeDetectorRef,
    Input,
    forwardRef,
} from '@angular/core';
import { IsavBaseControl } from '../base-control';
import { Iri } from '../../../core/dto/iri';
import { DialogManager, DialogService } from '../../../shared/dialog/dialog.service';
import { IsavImagePopup } from '../image-popup/image-popup';
import { environment } from '../../../../environments/environment';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IsavImageBatchUploadPopup } from '../image-batch-upload-popup/image-batch-upload-popup';
import { ContentService } from '../../../core/api/content.service';
import { NotificationService } from '../../../core/services/notification.service';
import { IsavImageSimplePopup } from '../image-simple-popup/image-simple-popup';

@Component({
    selector: 'isav-image-form',
    templateUrl: './image-form.html',
    styleUrls: ['./image-form.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavImageForm),
            multi: true,
        },
    ],
})
export class IsavImageForm extends IsavBaseControl {
    @Input() multiple: boolean = false;
    // null for people-register-form and isav-news-sync-images-create-dialog
    @Input() contentIri: Iri | null = null;
    @Input() property: string;
    @Input() editable: boolean = true;

    value: Iri[] = [];
    dialogManager: DialogManager;
    batchDialogManager: DialogManager<Iri[], Iri[]>;
    maxImageSize = environment.maxImageSize;

    constructor(
        _injector: Injector,
        private _cdRef: ChangeDetectorRef,
        private dialogService: DialogService,
        private contentService: ContentService,
        private notificationService: NotificationService
    ) {
        super(_injector);
    }

    writeValue(obj: any): void {
        if (Array.isArray(obj)) this.value = obj;
        if (typeof obj === 'string') this.value = [obj];
        this._cdRef.markForCheck();
    }

    onChange(value): void {
        if (this.multiple) {
            this.onChangeMultiple(value);
        } else {
            this.value = [value];
            this._onChange(this.value[0] || null);
        }
        this._cdRef.markForCheck();
    }

    onChangeMultiple(values) {
        this.value = this.value.concat(values);
        this._onChange(this.value);
        this._cdRef.markForCheck();
    }

    remove(iri: Iri) {
        this.value = this.value.filter((i) => i !== iri);
        if (this.multiple) {
            this._onChange(this.value);
        } else {
            this._onChange(null);
        }
        this._cdRef.markForCheck();
    }

    upload() {
        if (this.editable) {
            this.dialogManager = this.dialogService.spawnComponent(IsavImagePopup, null);
        } else {
            this.dialogManager = this.dialogService.spawnComponent(IsavImageSimplePopup, null);
        }
        this.dialogManager.closed$.subscribe((iri) => {
            if (iri !== null) {
                this.onChange(iri);
                if (this.contentIri) {
                    this.contentService
                        .patch(this.contentIri, {
                            [this.property]: this.multiple ? this.value : iri,
                        })
                        .subscribe(() => {
                            this.notificationService.success('Successfully saved an image');
                        });
                }
            }
        });
    }

    batchUpload() {
        this.batchDialogManager = this.dialogService.spawnComponent<
            Iri[],
            Iri[],
            IsavImageBatchUploadPopup
        >(IsavImageBatchUploadPopup, []);
        this.batchDialogManager.closed$.subscribe((iris: Iri[]) => {
            if (iris.length) {
                this.onChangeMultiple(iris);
                if (this.contentIri) {
                    this.contentService
                        .patch(this.contentIri, { [this.property]: this.value })
                        .subscribe(() => {
                            this.notificationService.success('Successfully saved an images');
                        });
                }
            }
        });
    }
}
