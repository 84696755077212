import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { SearchService } from '../../core/search/search.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchState } from '../../core/search/search-state';

@Component({
    selector: 'isav-mini-content-search',
    templateUrl: './mini-content-search.html',
    styleUrls: ['./mini-content-search.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniContentSearch<T> implements OnInit {
    @Input() state: SearchState<T>;

    @Input() shownPerPage = 20;

    constructor(
        private searchService: SearchService<T>,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    get page(): number {
        return Number(this.state.query.page || '1');
    }

    get indexesShown(): string {
        if (this.state.results.member.length === 0) return '0';

        const from = (this.page - 1) * this.shownPerPage + 1;
        const till = from + this.state.results.member.length - 1;

        return `${from}-${till}`;
    }

    ngOnInit() {
        this.update({
            page: this.route.snapshot.queryParams.page || '1',
            pagination: '1',
        });
    }

    updatePage(page: number) {
        this.update({ page: page.toString(10), pagination: '1' });
    }

    update(query) {
        this.searchService.search(query);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: query,
            queryParamsHandling: 'merge',
        });
    }
}
