import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { compose, equals, filter, prop } from 'ramda';
import { Observable } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { PagesResourceService } from '../../../core/api/pages-resource.service';
import { EventDto } from '../../../core/dto/event';
import { EducationAndTrainingDto } from '../../../core/dto/education-and-training';
import { NewsDto } from '../../../core/dto/news';
import { EventsResourceService } from '../../../core/api/events-resource.service';
import { EducationAndTrainingsResourceService } from '../../../core/api/education-and-trainings-resource.service';
import { NewsResourceService } from '../../../core/api/news-resource.service';
import { LocationType, PageDto } from '../../../core/dto/page';
import { getToday } from '../../../utils/date';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
    selector: 'isav-latest-content',
    templateUrl: './latest-content.html',
    styleUrls: ['./latest-content.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavLatestContent implements OnInit {
    events$: Observable<EventDto[]>;
    courses$: Observable<EducationAndTrainingDto[]>;
    news$: Observable<NewsDto[]>;
    cards$: Observable<PageDto[]>;

    constructor(
        private eventsService: EventsResourceService,
        private coursesService: EducationAndTrainingsResourceService,
        private newsService: NewsResourceService,
        private pageService: PagesResourceService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.events$ = this.eventsService
            .getAll({
                'order[startEndDates.startDate]': 'asc',
                'startEndDates.startDate[after]': getToday(),
                itemsPerPage: '2',
            })
            .pipe(pluck('member'));

        this.courses$ = this.coursesService
            .getAll({
                'order[startDate]': 'asc',
                'startDate[after]': getToday(),
                itemsPerPage: '2',
            })
            .pipe(pluck('member'));

        this.news$ = this.newsService
            .getAll({
                itemsPerPage: '4',
            })
            .pipe(pluck('member'));

        this.cards$ = this.authService.authenticated$().pipe(
            switchMap(() => {
                return this.pageService
                    .getAll({ pagination: '0', 'order[weight]': 'asc' })
                    .pipe(
                        pluck('member'),
                        map(
                            filter(
                                compose(equals(LocationType.LANDING_CARDS), prop('locationType'))
                            )
                        )
                    );
            })
        );
    }
}
