import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { IsavFieldAccessor } from '../../shared/field-index/field-accessor';
import {
    ChangeDetectorRef,
    HostBinding,
    InjectFlags,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Directive,
} from '@angular/core';
import { IsavFieldIndexDirective } from '../../shared/field-index/field-index.directive';

// eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
function noop() {}

let UNIQUE_ID = 0;

@Directive()
export abstract class IsavBaseControl
    implements ControlValueAccessor, IsavFieldAccessor, OnInit, OnDestroy
{
    @Input() id = 'isav-input-' + UNIQUE_ID++;
    @Input() label = '';
    @Input() mandatory = false;
    @Input() tooltipKey: string;

    disabled = false;

    _onChange: any = noop;
    _onTouched: any = noop;

    @HostBinding('attr.id')
    readonly hostId = null;

    protected constructor(protected _injector: Injector) {}

    get control(): AbstractControl | undefined {
        return (
            // eslint-disable-next-line no-bitwise
            this._injector.get(NgControl, null, InjectFlags.Self | InjectFlags.Optional)?.control ||
            undefined
        );
    }

    get name(): string {
        return this.label;
    }

    get isMandatory(): boolean {
        return this.mandatory;
    }

    ngOnInit() {
        this._injector
            .get(IsavFieldIndexDirective, null, InjectFlags.Optional)
            ?.registerField(this);
    }

    ngOnDestroy() {
        this._injector
            .get(IsavFieldIndexDirective, null, InjectFlags.Optional)
            ?.unregisterField(this);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._injector.get(ChangeDetectorRef, null, InjectFlags.Self)!.markForCheck();
    }

    abstract writeValue(obj: any): void;
}
