import {
    ApplicationRef,
    Directive,
    EmbeddedViewRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[isavBodyChild]',
})
export class BodyChildDirective implements OnInit, OnChanges, OnDestroy {
    @Input('isavBodyChild') shouldRender: any = true;

    private _renderedView?: EmbeddedViewRef<any>;

    constructor(
        private _templateRef: TemplateRef<any>,
        private _renderer2: Renderer2,
        private _appRef: ApplicationRef,
        @Inject(DOCUMENT) private _document: Document
    ) {}

    ngOnInit() {
        this.showOrHide();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.shouldRender && !changes.shouldRender?.isFirstChange()) {
            this.showOrHide();
        }
    }

    ngOnDestroy() {
        this.remove();
    }

    private showOrHide() {
        if (this.shouldRender) {
            this.append();
        } else {
            this.remove();
        }
    }

    private append() {
        if (this._renderedView) return;

        this._renderedView = this._templateRef.createEmbeddedView({});
        this._appRef.attachView(this._renderedView);
        this._renderedView.rootNodes.forEach((node) => {
            this._renderer2.appendChild(this._document.body, node);
        });
    }

    private remove() {
        if (!this._renderedView) return;

        this._renderedView.rootNodes.forEach((node) => {
            const parent = this._renderer2.parentNode(node);
            this._renderer2.removeChild(parent, node);
        });
        this._renderedView.destroy();
        this._renderedView = undefined;
    }
}
