import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'isav-advanced-search-filters',
    templateUrl: './advanced-search-filters.html',
    styleUrls: ['./advanced-search-filters.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'advanced-search__filters',
    },
    exportAs: 'isavAdvancedSearchFilters',
})
export class AdvancedSearchFilters {
    @Input() expanded = false;
    @Output() readonly expandedChange = new EventEmitter<boolean>();

    constructor(private _changeDetectorRef: ChangeDetectorRef) {}

    collapse() {
        this.expanded = false;
        this.expandedChange.emit(false);
        this._changeDetectorRef.markForCheck();
    }

    expand() {
        this.expanded = true;
        this.expandedChange.emit(true);
        this._changeDetectorRef.markForCheck();
    }

    toggle() {
        if (this.expanded) {
            this.collapse();
        } else {
            this.expand();
        }
    }
}
