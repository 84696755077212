import { Directive, HostListener, Self } from '@angular/core';
import { IsavButton } from '../button/button';

@Directive({
    selector: '[isavBtn][isavPrintPage]',
})
export class PrintPageDirective {
    constructor(@Self() private isavBtn: IsavButton) {}

    @HostListener('click')
    onClick() {
        if (!this.isavBtn.disabled) {
            window.print();
        }
    }
}
