import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Constructor } from '../../utils/types/constructor';

/**
 * Interface for components that accept disabled property
 */
export interface CanDisable {
    disabled: boolean;
}

/**
 * @ignore
 */
export type CanDisableCtor = Constructor<CanDisable>;

/*
 * Adds disabled property to component class and ensures that it is always a boolean
 *
 * @param base - Base class that should be extended with disabled mixin
 */
export function mixinDisabled<T extends Constructor<{}>>(base: T): CanDisableCtor & T {
    return class extends base implements CanDisable {
        private _disabled = false;

        get disabled() {
            return this._disabled;
        }
        set disabled(value: boolean) {
            this._disabled = coerceBooleanProperty(value);
        }

        constructor(...args: any[]) {
            super(...args);
        }
    };
}
