<div class="field is-horizontal">
    <div class="field-label field-label-tooltip">
        <button
            isavBtn
            (click)="expandMap()"
            color="primary"
            size="small"
            class="mr-1"
            aria-label="Enlarge map view"
        >
            <isav-icon icon="arrows-alt-h"></isav-icon>
        </button>
        <label [for]="id" class="has-text-weight-bold has-text-primary">
            {{ label }}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="control">
            <isav-map [initialZoom]="1" [type]="mapType">
                <isav-map-location-search-control
                    (mapLocation)="setCoordinatesFromSearch($event)"
                ></isav-map-location-search-control>
                <isav-map-vector-layer>
                    <isav-map-reactive-marker
                        [coordinate]="coordinate"
                        (coordinateChange)="changeCoordinate($event)"
                        display="marker"
                        [center]="true"
                        [allowDelete]="true"
                    ></isav-map-reactive-marker>
                </isav-map-vector-layer>
            </isav-map>
            <p class="help">You can set coordinates by clicking on the map</p>
        </div>
    </div>
</div>
<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip"></div>
    <div class="field-body">
        <div class="field">
            <div class="control">
                <input
                    type="number"
                    class="input has-hidden-number-controls"
                    [value]="value.latitude"
                    (input)="update('latitude', $event.target.value)"
                    (focus)="onTouched()"
                    placeholder="latitude"
                    data-testid="latitude"
                    [attr.aria-labelledby]="id + '-latitude'"
                    isavErrorClass
                />
            </div>
            <p class="help" [id]="id + '-latitude'">latitude</p>
        </div>
        <div class="field">
            <div class="control">
                <input
                    type="number"
                    class="input has-hidden-number-controls"
                    [id]="id"
                    [value]="value.longitude"
                    (input)="update('longitude', $event.target.value)"
                    (focus)="onTouched()"
                    placeholder="longitude"
                    data-testid="longitude"
                    [attr.aria-labelledby]="id + '-longitude'"
                    isavErrorClass
                />
            </div>
            <p class="help" [id]="id + '-longitude'">longitude</p>
        </div>
    </div>
</div>
<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip"></div>
    <div class="field-body">
        <div class="field">
            <div class="control">
                <input
                    type="text"
                    class="input"
                    [value]="value.name"
                    (input)="updateName($event.target.value)"
                    (focus)="onTouched()"
                    placeholder="name"
                    data-testid="name"
                    aria-label="name"
                    isavErrorClass
                />
            </div>
        </div>
    </div>
</div>
