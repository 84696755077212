/* eslint-disable @angular-eslint/component-selector */

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    EmbeddedViewRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import {
    IsavCellOutlet,
    IsavFooterRowDef,
    IsavHeaderRowDef,
    IsavNoDataRow,
    IsavNoDataRowDef,
    IsavRowDef,
} from './row';
import { IsavColumnDef } from './cell';
import {
    MissingCellDefError,
    MissingColumnDefError,
    MissingFooterCellDefError,
    MissingHeaderCellDefError,
    MissingRowDefColumnsError,
    MissingRowDefError,
} from './errors';
import { ViewportObserver } from '../viewport/viewport';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * Describes rendered row by the table
 * Data is used to check if we need to run update, if so we do update the rendered row
 */
export class RenderedRow {
    constructor(
        public readonly data: any,
        public readonly row: EmbeddedViewRef<any>,
        public readonly cols: EmbeddedViewRef<any>[]
    ) {}
}

/**
 * Reusable table component that can be used to render tables in the system
 *
 * @example
 * <table isav-table [data]="todos" class="is-striped is-hoverable has-actions">
 *
 *     <ng-container isavColumnDef="userId">
 *     <th isav-header-cell *isavHeaderCellDef> User ID </th>
 *     <td isav-cell *isavCellDef="let row">{{ row.userId }}</td>
 *     </ng-container>
 *
 *     <ng-container isavColumnDef="todoId">
 *     <th isav-header-cell *isavHeaderCellDef> Todo ID </th>
 *     <td isav-cell *isavCellDef="let row">{{ row.id }}</td>
 *     </ng-container>
 *
 *     <ng-container isavColumnDef="title">
 *     <th isav-header-cell *isavHeaderCellDef> Title </th>
 *     <td isav-cell *isavCellDef="let row">{{ row.title }}</td>
 *     </ng-container>
 *
 *     <ng-container isavColumnDef="completed">
 *     <th isav-header-cell *isavHeaderCellDef> Is Completed </th>
 *     <td isav-cell *isavCellDef="let row">{{ row.completed ? 'yes' : 'no' }}</td>
 *     </ng-container>
 *
 *     <tr isav-row *isavRowDef="let row; columns: ['todoId', 'userId', 'title', 'completed']"></tr>
 *     <tr isav-header-row *isavHeaderRowDef></tr>
 *
 * </table>
 */
@UntilDestroy()
@Component({
    selector: 'table[isav-table]',
    templateUrl: './table.html',
    host: {
        class: 'table is-striped',
    },
    encapsulation: ViewEncapsulation.None,
    // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
    changeDetection: ChangeDetectionStrategy.Default,
})
export class IsavTable<T extends any> implements OnInit, OnDestroy, AfterViewInit {
    @ContentChild(IsavHeaderRowDef, { static: true }) headerRowDef?: IsavHeaderRowDef;
    @ContentChild(IsavFooterRowDef, { static: true }) footerRowDef?: IsavFooterRowDef;
    @ContentChild(IsavRowDef, { static: true }) rowDef?: IsavRowDef<T>;
    @ContentChild(IsavNoDataRow, { static: true }) noDataRow?: IsavNoDataRowDef;

    @ContentChildren(IsavColumnDef) columnDefs!: QueryList<IsavColumnDef<T>>;

    @ViewChild('contentContainer', { static: true, read: ViewContainerRef })
    contentContainer!: ViewContainerRef;

    @ViewChild('headerContainer', { static: true, read: ViewContainerRef })
    headerContainer!: ViewContainerRef;

    @ViewChild('footerContainer', { static: true, read: ViewContainerRef })
    footerContainer!: ViewContainerRef;

    @HostBinding('class.is-on-mobile-view')
    isMobile = false;

    private _data: T[] = [];
    private readonly _refresh = new Subject<boolean>();

    private _renderedHeader: EmbeddedViewRef<any> | null = null;
    private _renderedFooter: EmbeddedViewRef<any> | null = null;
    private _renderedRows: RenderedRow[] = [];

    constructor(private viewport: ViewportObserver, private cdRef: ChangeDetectorRef) {}

    @Input('data')
    get data(): T[] {
        return this._data;
    }

    set data(value: T[]) {
        const force = value !== this._data;
        this._data = Array.isArray(value) ? value : [];
        this._refresh.next(force);
    }

    ngOnInit() {
        this.viewport
            .isMobile$()
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.isMobile = value;
            });
    }

    ngAfterViewInit() {
        if (!this.rowDef) {
            throw new MissingRowDefError();
        }

        if (!this.rowDef?.columns) {
            throw new MissingRowDefColumnsError();
        }

        this.rowDef.changed$.pipe(untilDestroyed(this)).subscribe(() => this.render(true));

        this.viewport.isMobile$().pipe(untilDestroyed(this), mapTo(true)).subscribe(this._refresh);

        this._refresh.subscribe((useForce) => {
            this.render(useForce);
        });

        setTimeout(() => {
            this.render(true);
        });
    }

    ngOnDestroy() {
        this._refresh.complete();
    }

    render(forceRerender?: boolean) {
        if (!this.rowDef?.templateRef) {
            throw new MissingRowDefError();
        }

        if (forceRerender) {
            this.contentContainer.clear();
            this.headerContainer.clear();
            this.footerContainer.clear();

            this._renderedRows = [];
            this._renderedFooter = null;
            this._renderedHeader = null;
        }

        if (!this.isMobile && !this._renderedHeader && this.headerRowDef?.templateRef) {
            this._renderedHeader = this.headerContainer.createEmbeddedView(
                this.headerRowDef.templateRef
            );
            this.renderHeaderCells();
        }

        for (let i = 0; i < this.data.length; i++) {
            if (i < this._renderedRows.length) {
                if (this.data[i] !== this._renderedRows[i].data) {
                    // data changed so we exchange row with a new one
                    const prevRow = this._renderedRows[i];
                    const rowIndex = this.contentContainer.indexOf(prevRow.row);
                    this.contentContainer.remove(rowIndex);
                    const row = this.contentContainer.createEmbeddedView(
                        this.rowDef.templateRef,
                        { $implicit: this.data[i] },
                        rowIndex
                    );
                    const cols = this.renderDataCols(this.data[i]);
                    this._renderedRows[i] = new RenderedRow(this.data[i], row, cols);
                } else {
                    // data did not change, but maybe we changed some properties inside lets update refs
                    const prevRow = this._renderedRows[i];
                    prevRow.row.context.$implicit = this.data[i];
                    prevRow.cols.forEach((col) => (col.context.$implicit = this.data[i]));
                    this._renderedRows[i] = new RenderedRow(
                        this.data[i],
                        prevRow.row,
                        prevRow.cols
                    );
                }
            } else {
                // render new row cause the data array is longer then the rendered list
                const row = this.contentContainer.createEmbeddedView(this.rowDef.templateRef, {
                    $implicit: this.data[i],
                });
                const cols = this.renderDataCols(this.data[i]);
                this._renderedRows.push(new RenderedRow(this.data[i], row, cols));
            }
        }

        if (this._renderedRows.length > this.data.length) {
            const toBeRemoved = this._renderedRows.slice(this.data.length);
            this._renderedRows = this._renderedRows.slice(0, this.data.length);

            toBeRemoved.forEach((renderedRow) => {
                const rowIndex = this.contentContainer.indexOf(renderedRow.row);
                this.contentContainer.remove(rowIndex);
            });
        }

        if (!this.isMobile && !this._renderedFooter && this.footerRowDef?.templateRef) {
            this._renderedFooter = this.footerContainer.createEmbeddedView(
                this.footerRowDef.templateRef
            );
            this.renderFooterCells();
        }

        // needed here or otherwise we get empty rows rendered!
        this.cdRef.markForCheck();
    }

    private getColumnDef(column: string): IsavColumnDef<T> {
        const columnDef = this.columnDefs.find((cd) => cd.name === column);
        if (!columnDef) throw new MissingColumnDefError(column);
        if (!columnDef.cellDef) throw new MissingCellDefError(column);
        if (!columnDef.headerCellDef) throw new MissingHeaderCellDefError(column);

        return columnDef;
    }

    private renderDataCols(data: any): EmbeddedViewRef<any>[] {
        const cols: EmbeddedViewRef<any>[] = [];
        const columns = this.rowDef?.columns || [];
        if (columns.length < 0) {
            console.warn('No columns to render inside the table!');
            return [];
        }

        for (const column of columns) {
            const colDef = this.getColumnDef(column);

            // when on mobile we need to add a header before
            if (!colDef.headerCellDef) throw new MissingHeaderCellDefError(column);
            if (this.isMobile) {
                const headerDef = colDef.headerCellDef!;
                IsavCellOutlet.render(headerDef.templateRef);
            }

            if (!colDef.cellDef) throw new MissingCellDefError(column);
            const cellDef = colDef.cellDef!;
            const col = IsavCellOutlet.render(cellDef.templateRef, { $implicit: data });
            cols.push(col);
        }

        return cols;
    }

    private renderHeaderCells(): void {
        const columns = this.rowDef?.columns || [];
        if (columns.length < 0) {
            console.warn('No columns to render inside the table!');
            return;
        }

        for (const column of columns) {
            const colDef = this.getColumnDef(column);

            if (!colDef.headerCellDef) throw new MissingHeaderCellDefError(column);

            const headerCellDef = colDef.headerCellDef!;
            IsavCellOutlet.render(headerCellDef.templateRef);
        }
    }

    private renderFooterCells(): void {
        const columns = this.rowDef?.columns || [];
        if (columns.length < 0) {
            console.warn('No columns to render inside the table!');
            return;
        }

        for (const column of columns) {
            const cellDef = this.getColumnDef(column);

            if (!cellDef.footerCellDef) throw new MissingFooterCellDefError(column);

            const footerCellDef = cellDef.footerCellDef!;
            IsavCellOutlet.render(footerCellDef.templateRef);
        }
    }
}
