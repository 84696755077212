import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { MetadataService } from '../../core/services/metadata.service';

@Component({
    selector: 'isav-front-page',
    templateUrl: './front-page.html',
    styleUrls: ['./front-page.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrontPage implements OnInit {
    constructor(private metaService: MetadataService) {}

    ngOnInit() {
        this.metaService.setTitle('Welcome');
    }
}
