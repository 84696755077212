import { Pipe, PipeTransform } from '@angular/core';
import { IMapControl, IsavMapControlPosition } from './control';

@Pipe({
    name: 'filterWithPosition',
    pure: true,
})
export class FilterWithPosition implements PipeTransform {
    transform(controls: IMapControl[], position: IsavMapControlPosition): IMapControl[] {
        return controls.filter((c) => c.getPosition() === position);
    }
}
