import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { Highlightable, ListKeyManagerOption } from '@angular/cdk/a11y';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

let UNIQUE_ID = 0;

export interface IsavOptionSelectedChange {
    origin: IsavOption;
    selected: boolean;
}

@Component({
    selector: 'isav-option',
    templateUrl: './option.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavOption implements Highlightable, ListKeyManagerOption, OnChanges, OnDestroy {
    @Input() value: any;

    @Input() selected = false;
    @Output() selectedChange = new EventEmitter<IsavOptionSelectedChange>();

    id = 'isav-option-' + UNIQUE_ID++;
    active = false;

    // used to get notified whenever value changes (can be through input too)
    _internalSelectedChange = new Subject<IsavOptionSelectedChange>();

    private _disabled = false;
    private selectDisabled = false;

    constructor(private elementRef: ElementRef, private cdRef: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selected) {
            this._internalSelectedChange.next({ origin: this, selected: this.selected });
        }
    }

    @Input() get disabled(): boolean {
        return this.selectDisabled || this._disabled;
    }

    set disabled(disabled: boolean) {
        this._disabled = coerceBooleanProperty(disabled);
        this.cdRef.markForCheck();
    }

    get ariaSelected(): null | string {
        if (this.disabled) return null;
        return this.selected.toString();
    }

    ngOnDestroy() {
        this._internalSelectedChange.complete();
    }

    getLabel(): string {
        return (this.elementRef.nativeElement?.textContent || '').trim();
    }

    setActiveStyles(): void {
        this.active = true;
        this.cdRef.markForCheck();
    }

    setInactiveStyles(): void {
        this.active = false;
        this.cdRef.markForCheck();
    }

    setSelected(selected: boolean) {
        this.selected = selected;
        this.cdRef.markForCheck();
    }

    setSelectDisabled(isDisabled: boolean) {
        this.selectDisabled = isDisabled;
    }

    select() {
        if (this.disabled) return;
        this.selected = !this.selected;
        this._internalSelectedChange.next({ origin: this, selected: this.selected });
        this.selectedChange.next({ origin: this, selected: this.selected });
    }
}
