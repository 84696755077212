import { ElementRef } from '@angular/core';

/**
 * Adds is- prefix to the classname
 * @param className - name of the class to which apply prefix
 */
export function addIsPrefix(className: string): string {
    return `is-${className}`;
}

/**
 * Safely uses classList on passed element ref to add class
 *
 * @param elementRef {ElementRef} - angular ref to the element
 * @param classToAdd {string} - class that should be added to the element
 */
export function safeAddClass(elementRef: ElementRef, classToAdd: string) {
    if (elementRef && elementRef.nativeElement && elementRef.nativeElement.classList) {
        if ('add' in elementRef.nativeElement.classList) {
            elementRef.nativeElement.classList.add(classToAdd);
        }
    }
}

/**
 * Safely uses classList on passed element ref to remove class
 *
 * @param elementRef {ElementRef} - angular ref to the element
 * @param classToRemove {string} - class that should be removed from the element
 */
export function safeRemoveClass(elementRef: ElementRef, classToRemove: string): void {
    if (elementRef && elementRef.nativeElement && elementRef.nativeElement.classList) {
        if ('remove' in elementRef.nativeElement.classList) {
            elementRef.nativeElement.classList.remove(classToRemove);
        }
    }
}
