import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
} from '@angular/core';
import { IsavTab } from './tab/tab';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
    selector: 'isav-tabs',
    templateUrl: './tabs.html',
    styleUrls: ['./tabs.sass'],
    // we intentionally want default change detection here
    // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
    changeDetection: ChangeDetectionStrategy.Default,
})
export class IsavTabs implements AfterContentInit, AfterViewInit, OnChanges {
    @ContentChildren(IsavTab, { descendants: false }) tabs: QueryList<IsavTab>;

    @Input() activeTab = '';
    @Output() activeTabChange = new EventEmitter<string>();
    @Input() aligment: 'left' | 'centered' | 'right' = 'left';
    @Input() size: 'small' | 'normal' | 'medium' | 'large' = 'normal';
    @Input() borders = false;
    @Input() toggle = false;
    @Input() toggleRounded = false;
    @Input() panelClass = '';

    private isInputControlled = false;

    constructor(private readonly cdRef: ChangeDetectorRef) {}

    get contentPortal(): TemplatePortal | null {
        if (!this.tabs.length) return null;
        const tab = this.tabs.find((t) => t.title === this.activeTab);
        return tab ? tab.contentPortal : this.tabs.first.contentPortal;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.activeTab && changes.activeTab.isFirstChange()) {
            this.isInputControlled = true;
        }

        if (changes.activeTab && !changes.activeTab.isFirstChange()) {
            this.setupActiveTab();
        }
    }

    ngAfterContentInit() {
        this.setupActiveTab();
    }

    ngAfterViewInit() {
        // we will not render any template on first render, so we need to run
        // change detection for the children once again when we have the ref
        // to the templates to render on initial render
        this.cdRef.detectChanges();
    }

    update(tab: IsavTab) {
        if (!this.isInputControlled) this.activeTab = tab.title;
        this.activeTabChange.emit(tab.title);
    }

    markAsControlled() {
        this.isInputControlled = true;
    }

    private setupActiveTab() {
        if (this.tabs.length && this.activeTab === '') {
            this.activeTab = this.tabs.first.title;
            this.update(this.tabs.first);
        }
    }
}
