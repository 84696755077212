import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | false {
        return this.hasRequiredRole(route.data.roles);
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | false {
        return this.hasRequiredRole(route.data.roles);
    }

    private hasRequiredRole(roles: any | any[]): Observable<boolean> | false {
        if (!this.isStringArray(roles)) {
            if (!environment.production) {
                throw new Error('Roles passed to RoleGuard are not an array of string roles!');
            }

            return false;
        }

        return this.authService
            .roles$()
            .pipe(map((userRoles: string[]) => roles.some((role) => userRoles.includes(role))));
    }

    private isStringArray(roles: any | any[]): roles is string[] {
        return Array.isArray(roles) && roles.every((role) => typeof role === 'string');
    }
}
