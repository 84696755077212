import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ContentService } from '../../core/api/content.service';
import { FieldworkProjectReadFieldworkReadSarReadDto } from '../../core/dto/fieldwork-project-read-fieldwork-read-sar-read';
import { SarSarReadDto } from '../../core/dto/sar-sar-read';

@Component({
    selector: 'isav-project-fieldwork',
    templateUrl: './project-fieldwork.html',
    styleUrls: ['./project-fieldwork.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavProjectFieldwork implements OnChanges {
    @Input() fieldwork: FieldworkProjectReadFieldworkReadSarReadDto;
    @Input() fieldworkIncludeOutsideSettlements: boolean;
    sar$: Observable<SarSarReadDto | null>;

    constructor(private contentService: ContentService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fieldwork) {
            if (this.fieldwork.sarAsset) {
                this.sar$ = this.contentService.getByIri<SarSarReadDto>(this.fieldwork.sarAsset);
            } else {
                this.sar$ = of(null);
            }
        }
    }
}
