<div class="notifications-container">
    <isav-notification
        *ngFor="let notification of notifications$ | async"
        [title]="notification.title"
        [message]="notification.message"
        [type]="notification.type"
        (remove)="remove(notification)"
    >
        <ng-container *ngIf="notification.template">
            <ng-container *ngTemplateOutlet="notification.template"></ng-container>
        </ng-container>
    </isav-notification>
</div>
