<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip">
        <button
            isavBtn
            (click)="expandMap()"
            color="primary"
            size="small"
            class="mr-1"
            aria-label="Enlarge map view"
        >
            <isav-icon icon="arrows-alt-h"></isav-icon>
        </button>
        <label [for]="id" class="has-text-weight-bold has-text-primary">
            {{ label }}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="control">
            <isav-map [initialZoom]="1" [id]="id">
                <isav-map-draw-area
                    [disabled]="disabled"
                    [coordinate]="value"
                    (coordinateChange)="update($event)"
                >
                </isav-map-draw-area>
                <isav-map-vector-layer>
                    <isav-map-marker
                        *ngIf="pointCoordinate"
                        [coordinate]="pointCoordinate"
                        display="marker"
                    ></isav-map-marker>
                </isav-map-vector-layer>
            </isav-map>
            <p *ngIf="value.length === 0" class="help">
                Use map to draw an area.<br />
                Press escape to restart.<br />
                Press backspace to remove last added point.
            </p>
            <p *ngIf="value.length > 0" class="help">
                You can edit area by moving points, or clicking on a line to add new point between
                them.
                <br />
                To start drawing again, click the bin icon in top right corner of a map.
            </p>
        </div>
    </div>
</div>
