<ng-template #contentTemplate>
    <div
        class="dropdown-menu"
        role="menu"
        [id]="id"
        (isavClickedOutside)="closed.emit(false)"
        (isavEscapeClicked)="closed.emit(true)"
    >
        <div class="dropdown-content">
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
