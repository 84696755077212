import { ChangeDetectionStrategy, Component, Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[isavContentTypeDetails]',
})
export class ContentTypeDetails {}

@Directive({
    selector: '[isavContentTypeAside]',
})
export class ContentTypeAside {}

@Component({
    selector: 'isav-content-type-view',
    templateUrl: './content-type-view.html',
    styleUrls: ['./content-type-view.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentTypeView {
    @HostBinding('class.is-vertical-in-print')
    @Input()
    isVerticalInPrint: boolean = false;
    @Input() isAsideWider: boolean = false;
    @Input() singleColumn: boolean = false;
}
