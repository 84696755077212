import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { SanitizerService } from '../../core/sanitizer/sanitizer.service';

let UNIQUE_ID = 0;

@Component({
    selector: 'isav-description-display',
    templateUrl: './description-display.html',
    styleUrls: ['./description-display.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavDescriptionDisplay implements OnChanges {
    @Input() content: string = '';
    @Input() title: string = '';
    @Input() project: boolean = false;
    safeExcerpt?: SafeHtml;
    safeContent?: SafeHtml;
    canCollapse = false;
    isCollapsed = true;
    id = 'isav-description-display--' + UNIQUE_ID++;

    constructor(private readonly sanitizer: SanitizerService) {}

    get buttonLabel(): string {
        const expandOrCollapse = this.isCollapsed ? 'Expand' : 'Collapse';
        return this.project
            ? `${expandOrCollapse} project plan for ${this.title}`
            : `${expandOrCollapse} description for ${this.title}`;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.content) {
            this.updateSafeContent(changes.content.currentValue);
        }
    }

    private updateSafeContent(content?: string): void {
        if (!content) {
            delete this.safeContent;
            delete this.safeExcerpt;
        } else {
            const shortenedContent = content.substring(0, 500);
            this.canCollapse = shortenedContent.length < content.length;
            this.safeContent = this.sanitizer.sanitize(content);
            this.safeExcerpt = this.sanitizer.sanitize(shortenedContent + '...');
        }
    }
}
