import { Pipe, PipeTransform } from '@angular/core';
import { separateWithSpaces } from '../../utils/strings';

@Pipe({
    name: 'isavSpace',
})
export class IsavSpacePipe implements PipeTransform {
    transform(value: any): string {
        return separateWithSpaces(value);
    }
}
