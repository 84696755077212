import {
    Directive,
    EmbeddedViewRef,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { Iri } from '../../core/dto/iri';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ContentService } from '../../core/api/content.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[isavCanViewSar]',
})
export class IsavCanViewSarDirective implements OnInit, OnDestroy {
    @Input('isavCanViewSar') contentIri: Iri;

    match: string[] = ['ROLE_SAR', 'ROLE_ADMIN'];

    private _renderedView: EmbeddedViewRef<any> | null = null;

    constructor(
        private readonly _templateRef: TemplateRef<any>,
        private readonly _authService: AuthService,
        private readonly _viewContainerRef: ViewContainerRef,
        private readonly _contentService: ContentService,
        private readonly _cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const roles$ = this._authService.roles$().pipe(
            untilDestroyed(this),
            map((roles) => {
                return this.hasRole(roles) ? true : false;
            })
        );

        const editAcess$ = this._authService.userIri$().pipe(
            untilDestroyed(this),
            switchMap((iri) => {
                return iri ? this._contentService.getEditAccess(this.contentIri) : of(false);
            })
        );

        combineLatest([roles$, editAcess$]).subscribe((canView) => {
            if (canView[0] || canView[1]) {
                this.render();
            } else {
                this.clearView();
            }
        });
    }

    ngOnDestroy() {
        this.clearView();
    }

    render() {
        this.clearView();
        this._renderedView = this._viewContainerRef.createEmbeddedView(this._templateRef);
        this._cdRef.markForCheck();
    }

    clearView() {
        this._renderedView?.destroy();
        this._renderedView = null;
        this._cdRef.markForCheck();
    }

    hasRole(roles: string[]): boolean {
        return roles.some((role) => this.match.includes(role));
    }
}
