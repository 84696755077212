import { Directive, HostListener, Input } from '@angular/core';
import { Iri } from '../../core/dto/iri';
import { Router } from '@angular/router';
import { ContentService } from '../../core/api/content.service';
import { typeFromIri } from '../../core/type-from-iri';

@Directive({
    selector: '[isavCloneContent]',
})
export class IsavCloneContentDirective {
    @Input('isavCloneContent') iri: Iri;

    constructor(private router: Router, private contentService: ContentService) {}

    @HostListener('click') clone() {
        this.contentService.clone(this.iri).subscribe((content) => {
            const contentType = typeFromIri(content['@id']).replace(/_/g, '-').toLowerCase();
            this.router.navigate([`/${contentType}/edit`], {
                queryParams: {
                    iri: content['@id'],
                },
            });
        });
    }
}
