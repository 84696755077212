import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsavTooltipDisplay } from './tooltip-display/tooltip-display';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [IsavTooltipDisplay],
    imports: [CommonModule, SharedModule],
    exports: [IsavTooltipDisplay],
})
export class TooltipsSharedModule {}
