import { HasElementRef } from './has-element-ref';
import { safeAddClass, safeRemoveClass } from './safe-classlist';
import { Constructor } from '../../utils/types/constructor';

const AVAILABLE_COLORS = [
    'primary',
    'success',
    'secondary',
    'danger',
    'warning',
    'info',
    'link',
    'white',
    'dark',
];

/**
 * Interface for components that should have color input that is reflected with a class is-color
 */
export interface HasColor {
    /**
     * Color property that stores color. For available colors see {@link AVAILABLE_COLORS}
     */
    color: string;
}

/**
 * @ignore
 */
export type HasColorCtor = Constructor<HasColor>;

/**
 * Adds is- prefix to color name
 *
 * @param color - color name to which is prefix is added
 *
 * @ignore
 */
function prefix(color: string): string {
    return `is-${color}`;
}

/**
 * Extends base class with a color property that also adds class is-color
 *
 * @param base - Base class to extend with a color mixin
 */
export function mixinColor<T extends Constructor<HasElementRef>>(base: T): T & HasColorCtor {
    return class extends base implements HasColor {
        private _color = '';

        get color(): string {
            return this._color;
        }
        set color(value: string) {
            const prev = this.color;

            if (AVAILABLE_COLORS.includes(value)) {
                this._color = value;
            }

            safeRemoveClass(this._elementRef, prefix(prev));
            if (value) safeAddClass(this._elementRef, prefix(value));
        }

        constructor(...args: any[]) {
            super(...args);
            if (this.color) safeAddClass(this._elementRef, prefix(this._color));
        }
    };
}
