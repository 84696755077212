import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, pluck, switchMap } from 'rxjs/operators';
import { PeopleResourceService } from '../../../core/api/people-resource.service';
import { PersonDto } from '../../../core/dto/person';

@Component({
    selector: 'isav-person-selector',
    templateUrl: './person-selector.html',
    styleUrls: ['./person-selector.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavPersonSelector {
    @Input() exclude: PersonDto[];
    @Output() selected = new EventEmitter<PersonDto>();
    searchFocused: boolean;

    private searchPhraseSubject = new Subject<string>();

    readonly people$: Observable<PersonDto[]> = this.searchPhraseSubject.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap((searchPhrase) => {
            if (searchPhrase.length === 0) return of({ member: [] });
            return this.personService.getAll({
                fullName: searchPhrase,
                page: '1',
            });
        }),
        pluck('member'),
        map((people) => this.excludePeople(people)),
        map((people) => people.slice(0, 5))
    );

    public constructor(private personService: PeopleResourceService) {}

    /**
     * https://medium.com/javascript-everyday/a-live-search-example-angular-and-react-solutions-bd42a4d5dd7e
     */
    searchPeople(phrase: string): void {
        this.searchPhraseSubject.next(phrase);
    }

    onSelected(person: PersonDto): void {
        this.searchFocused = false;
        this.selected.emit(person);
    }

    private excludePeople(people: PersonDto[]) {
        const iris = (this.exclude ?? []).map((p) => p['@id']);
        const excluded = new Set(iris);
        return people.filter((person) => !excluded.has(person['@id']));
    }
}
