import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfDefined } from '../core/module-import-guard';
import { ContainerPage } from './container-page/container-page';
import { IsavFooter } from './footer/footer';
import { IsavHeader } from './header/header';
import { MainNavigation } from './main-navigation/main-navigation';
import { TopNavigation } from './top-navigation/top-navigation';
import { MobileNavigation } from './mobile-navigation/mobile-navigation';
import { SharedModule } from '../shared/shared.module';
import { SearchButton } from './search-popup/search-button';
import { SearchPopup } from './search-popup/search-popup';
import { HeaderActions } from './header-actions/header-actions';
import { SignInPage } from './sign-in/sign-in';
import { ResetPasswordPage } from './reset-password/reset-password';
import { ResetPasswordGuard } from './reset-password/reset-password.guard';
import { IsavCenteredFormContainer } from './centered-form-container/centered-form-container';
import { ResetPasswordRequestPage } from './reset-password-request/reset-password-request';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IsavCommonModule } from '../common/common.module';
import { NotificationsContainer } from './notifications-container/notifications-container';
import { IEWarning } from './ie-warning/ie-warning';
import { DialogService } from '../shared/dialog/dialog.service';
import { BrowserDetectionService } from '../core/services/browser-detection.service';
import { FrontPage } from './front-page/front-page';
import { ProjectsSharedModule } from '../features/projects-shared/projects-shared.module';
import { Partners } from './partners/partners';
import { ForbiddenPage } from './forbidden-page/forbidden-page';
import { IsavAuthenticationRequired } from './authentication-required/authentication-required';
import { ContentView } from './content-view/content-view';
import { NewsletterUnsubscribe } from './newsletter-unsubscribe/newsletter-unsubscribe';

export const COMPONENTS = [
    IsavFooter,
    IsavHeader,
    MainNavigation,
    TopNavigation,
    MobileNavigation,
    SearchButton,
    HeaderActions,
    IsavCenteredFormContainer,
    FrontPage,
    Partners,
    ForbiddenPage,
    ContentView,
    NewsletterUnsubscribe,
];

export const ENTRY_COMPONENTS = [SearchPopup, IEWarning];

export const EXPORTED_COMPONENTS = [
    ContainerPage,
    SignInPage,
    ResetPasswordPage,
    ResetPasswordRequestPage,
    NotificationsContainer,
    FrontPage,
    IsavAuthenticationRequired,
];

/**
 * A general module with all components and pages that are present on every subpage of the portal
 *
 * Those components suit a certain purpose and some shouldn't be treated as reusable components
 * outside of the IsavGeneralModule. If some components should be reused move them to shared module
 * or common module.
 */
@NgModule({
    declarations: [COMPONENTS, ENTRY_COMPONENTS, EXPORTED_COMPONENTS],
    exports: [EXPORTED_COMPONENTS],
    imports: [
        CommonModule,
        SharedModule,
        IsavCommonModule,
        ReactiveFormsModule,
        RouterModule,
        ProjectsSharedModule,
    ],
    providers: [ResetPasswordGuard],
})
export class IsavGeneralModule {
    constructor(
        @SkipSelf() @Optional() isavGeneralModule: IsavGeneralModule,
        browserDetectionService: BrowserDetectionService,
        dialogService: DialogService
    ) {
        throwIfDefined(isavGeneralModule, 'IsavGeneralModule');

        if (browserDetectionService.isIE()) {
            dialogService.spawnComponent(IEWarning);
        }
    }
}
