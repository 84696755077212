<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip">
        <label [for]="id" class="has-text-weight-bold has-text-primary">
            {{label}}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="field">
            <isav-document-uploader
                [files]="value"
                (filesChange)="onChange($event)"
                [multiple]="multiple"
            ></isav-document-uploader>
        </div>
    </div>
</div>
