import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IsavUserHasRoleDirective } from './user-has-role/user-has-role.directive';
import { MatchAuthenticatedDirective } from './match-authenticated/match-authenticated';

export const EXPORTED_DECLARATIONS = [MatchAuthenticatedDirective, IsavUserHasRoleDirective];

@NgModule({
    declarations: [EXPORTED_DECLARATIONS],
    imports: [CommonModule, RouterModule],
    providers: [],
    exports: [EXPORTED_DECLARATIONS],
})
export class IsavCommonAuthModule {}
