<isav-centered-form-container>
    <h1 class="subtitle is-5 has-text-centered">Type new password for your account</h1>

    <div *ngIf="backendError" class="notification is-danger" role="alert" aria-atomic="true">
        <button class="delete" type="button" (click)="backendError = ''">
            <span class="is-sr-only">Hide notification</span>
        </button>
        {{ backendError }}
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="changePassword()" class="mb-4">
        <input type="text" autocomplete="username" [value]="email" hidden />

        <div class="field">
            <label for="passwordInput" class="label">Password:</label>
            <div class="control">
                <input
                    type="password"
                    id="passwordInput"
                    class="input"
                    formControlName="password"
                    autocomplete="new-password"
                    [class.is-invalid]="backendError"
                />
            </div>
        </div>
        <div class="field">
            <label for="repeatPasswordInput" class="label">Repeat password:</label>
            <div class="control">
                <input
                    type="password"
                    id="repeatPasswordInput"
                    class="input"
                    formControlName="passwordRepeat"
                    autocomplete="new-password"
                    [class.is-invalid]="backendError"
                />
                <p class="help is-danger">
                    <isav-error-display [control]="formGroup"></isav-error-display>
                </p>
            </div>
        </div>
        <div class="field is-grouped is-grouped-between">
            <div class="control">
                <button
                    isavBtn
                    color="primary"
                    type="submit"
                    [disabled]="!formGroup.valid || loading"
                    [class.is-loading]="loading"
                >
                    Reset password
                </button>
            </div>
            <div class="control">
                <a isavBtn color="primary" routerLink="/" class="is-light">Go to Homepage</a>
            </div>
        </div>
    </form>
</isav-centered-form-container>
