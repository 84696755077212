import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FieldworkProjectReadFieldworkReadSarReadDto } from '../dto/fieldwork-project-read-fieldwork-read-sar-read';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Iri } from '../dto/iri';
import { ProjectDto } from '../dto/project';
import { ProjectReadCollectionDto } from '../dto/project-read-collection';
import { BaseSearchService } from './base-search-service';
import { FieldworkReadCollectionDto } from '../dto/fieldwork-read-collection';
import { Hydra, HydraProperties } from '../dto/hydra';
import { map } from 'rxjs/operators';

@Injectable()
export class ProjectsResourceService extends BaseSearchService<
    ProjectReadCollectionDto | FieldworkReadCollectionDto
> {
    constructor(http: HttpClient) {
        super('projects', http);
    }

    getAll(
        queryParams: { [key: string]: string | string[] } = {},
        isSelf?: boolean
    ): Observable<Hydra<ProjectReadCollectionDto | FieldworkReadCollectionDto>> {
        const { searchIn, ...httpParams } = queryParams;
        const params = new HttpParams({ fromObject: httpParams });
        const prefix = isSelf ? 'self/' : '';
        const content = searchIn === 'fieldworks' ? 'fieldworks' : 'projects';

        return this.http
            .get<HydraProperties<ProjectReadCollectionDto | FieldworkReadCollectionDto>>(
                `/api/${prefix}${content}`,
                { params }
            )
            .pipe(map(Hydra.from));
    }

    getAllAsCsv(
        queryParams: { [key: string]: string | string[] } = {},
        isSelf?: boolean
    ): Observable<string> {
        const { searchIn, ...httpParams } = queryParams;
        const params = new HttpParams({ fromObject: httpParams });
        const prefix = isSelf ? 'self/' : '';
        const content = searchIn === 'fieldworks' ? 'fieldworks' : 'projects';
        const headers = new HttpHeaders({
            accept: 'text/csv',
        });

        return this.http.get(`/api/${prefix}${content}`, { headers, params, responseType: 'text' });
    }

    changeArmapStatus(status: number, projectIri: Iri) {
        return this.http.post('/api/projects/armap-status', {
            status: status,
            project: projectIri,
        });
    }

    create(data: {
        fieldworkIncludeOutsideSettlements: boolean;
        fieldworkArctic: boolean;
        fieldworkCountry: string;
    }): Observable<ProjectDto> {
        return this.http.post<ProjectDto>('/api/projects', data);
    }

    createFieldwork(project: Iri): Observable<FieldworkProjectReadFieldworkReadSarReadDto> {
        return this.http.post<FieldworkProjectReadFieldworkReadSarReadDto>(`/api/fieldworks`, {
            project,
        });
    }

    cloneFieldwork(iri: Iri) {
        return this.http.post<any>(`/api/fieldworks/clone`, { fieldwork: iri });
    }
}
