import { ChangeDetectionStrategy, Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'isav-main-navigation',
    templateUrl: './main-navigation.html',
    styleUrls: ['./main-navigation.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavigation implements OnInit {
    url: string;

    constructor(private router: Router, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.url = this.router.url;
        this.router.events.pipe(untilDestroyed(this)).subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.url = ev.url;
                this.cdr.markForCheck();
            }
        });
    }

    isProjects() {
        if (this.url === '/' || this.url.includes('/projects')) return true;
        return false;
    }

    isSar() {
        if (this.url.includes('/sar')) return true;
        return false;
    }
}
