import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { uniq } from 'ramda';
import { Observable } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';
import { ApiResourceDto } from '../dto/api-resource';
import { Hydra, HydraProperties } from '../dto/hydra';
import { Iri } from '../dto/iri';
import { patchTaxonomy, storeTaxonomies, storeTaxonomy } from './taxonomies.actions';
import { selectTaxonomiesByIri, selectTaxonomyByType } from './taxonomies.selectors';
import { pluralizeTaxonomyName, TaxonomyTypesMap } from './taxonomy-types';
import { typeFromIri } from '../type-from-iri';

@Injectable()
export class TaxonomiesService {
    readonly loadedTypes: Set<string> = new Set();

    constructor(private store: Store, private http: HttpClient) {}

    resolve<T extends ApiResourceDto>(iri: Iri): Observable<T> {
        return this.resolveMany([iri]).pipe(pluck('0'));
    }

    resolveMany<T extends ApiResourceDto>(iris: Iri[]): Observable<T[]> {
        iris = iris.filter(Boolean); // filter out empty iris
        const types = uniq(iris.map(typeFromIri)); // gather unique types and load those types
        types.forEach((taxonomyType) => this.loadType(taxonomyType));
        return this.store.select(selectTaxonomiesByIri, iris);
    }

    getTaxonomiesByType<K extends keyof TaxonomyTypesMap, T = TaxonomyTypesMap[K]>(
        type: K
    ): Observable<T[]> {
        const taxonomyType = pluralizeTaxonomyName(type); // we need to pluralize name here
        this.loadType(taxonomyType);
        return this.store.select(selectTaxonomyByType, type);
    }

    patch<T>(iri: Iri, data: T): Observable<T> {
        const headers = new HttpHeaders().append('Content-Type', 'application/merge-patch+json');
        return this.http
            .patch<T>(iri, data, { headers })
            .pipe(tap(() => this.store.dispatch(patchTaxonomy({ iri, data }))));
    }

    create<T extends ApiResourceDto>(taxonomyClass: string, data: Partial<T>) {
        return this.http
            .post(`/api/taxonomy/${pluralizeTaxonomyName(taxonomyClass)}`, data)
            .pipe(
                tap((res) => this.store.dispatch(storeTaxonomy({ payload: res as ApiResourceDto })))
            );
    }

    refresh(type: string): void {
        this.loadType(type, true);
    }

    private loadType(type: string, force = false) {
        if (!force && this.loadedTypes.has(type)) return;
        this.loadedTypes.add(type);

        this.http
            .get<HydraProperties<ApiResourceDto>>(`/api/taxonomy/${type}`)
            .pipe(map(Hydra.from), pluck('member'))
            .subscribe((taxonomies) => {
                this.store.dispatch(storeTaxonomies({ taxonomies }));
            });
    }
}
