import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    CardFooterDirective,
    CardHeaderDirective,
    CardImageCaptionDirective,
    CardImageDirective,
    IsavCard,
} from './card';

export const EXPORTED_DECLARATIONS = [
    IsavCard,
    CardImageDirective,
    CardImageCaptionDirective,
    CardHeaderDirective,
    CardFooterDirective,
];

@NgModule({
    imports: [CommonModule],
    declarations: [EXPORTED_DECLARATIONS],
    exports: [EXPORTED_DECLARATIONS],
})
export class IsavCardModule {}
