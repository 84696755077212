<a
    role="option"
    class="dropdown-item"
    [class.is-active]="selected"
    [class.is-activedescendant]="active"
    [id]="id"
    [attr.aria-selected]="ariaSelected"
    [attr.aria-disabled]="disabled"
    (click)="select()"
>
    <input
        type="checkbox"
        class="mr-3"
        aria-hidden="true"
        tabindex="-1"
        [checked]="selected"
        [disabled]="disabled"
        readonly
    />
    <span [class.has-text-grey-light]="disabled">
        <ng-content></ng-content>
    </span>
</a>
