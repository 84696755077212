import {
    AbstractControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';

function required(message: string): (control: AbstractControl) => ValidationErrors | null {
    return function (control: AbstractControl): { required: string } | null {
        return Validators.required(control) === null ? null : { required: message };
    };
}

function email(message: string): (control: AbstractControl) => ValidationErrors | null {
    return function (control: AbstractControl): { email: string } | null {
        if (Validators.email(control) === null) {
            return null;
        }

        return { email: message };
    };
}

function coordinates(
    message: string = 'Coordinates are required!'
): (control: AbstractControl) => ValidationErrors | null {
    return function (control: AbstractControl): { coordinates: string } | null {
        const value = control.value;
        const error = { coordinates: message };
        if (value === null || typeof value !== 'object') return error;

        const { latitude, longitude } = value;
        if (typeof latitude !== 'number' || typeof longitude !== 'number') return error;

        return null;
    };
}

function fieldworks(
    message: string = 'Fieldworks are required!'
): (control: AbstractControl) => ValidationErrors | null {
    return function (control: AbstractControl): { fieldworks: string } | null {
        const value = control.value;
        const error = { fieldworks: message };

        if (!value) return error;
        if (!Array.isArray(value) || !value.length) return error;

        const isFieldworkValid = (fieldwork) => {
            if (!fieldwork.start) return false;
            if (!fieldwork.end) return false;
            if (!fieldwork.country) return false;
            if (!fieldwork.region) return false;
            if (!fieldwork.location && !fieldwork.routePoints?.length) return false;
            return true;
        };

        const fieldworksState = value.map((fieldwork) => isFieldworkValid(fieldwork));

        if (fieldworksState.includes(false)) return error;

        return null;
    };
}

function notEmpty(
    message: string = 'You must select at least one option!'
): (control: AbstractControl) => ValidationErrors | null {
    return function (control: AbstractControl): { notEmpty: string } | null {
        const value = control.value;
        const error = { notEmpty: message };
        if (value === null || value === undefined || !Array.isArray(value) || value.length === 0)
            return error;
        return null;
    };
}

/**
 * Check length property of value if it is more then passed minimum length otherwise returns error
 * @param minimumLength - minimum value of length property of control's value that will satisfy
 * this validator
 * @param message - message that will be returned as error
 */
function minLength(
    minimumLength: number,
    message: string
): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): { minLength: string } | null => {
        const value = control.value;
        if (value?.length >= minimumLength) return null;
        return { minLength: message };
    };
}

function routePoints(message: string): ValidatorFn {
    return (control: AbstractControl): { routePoints: string } | null => {
        const value = control.value;
        const hasDate = (obj: any): boolean =>
            'date' in obj && !Number.isNaN(new Date(obj.date).getTime());
        if (
            value === null ||
            (Array.isArray(value) && (value.every(hasDate) || value.length === 0))
        )
            return null;
        return { routePoints: message };
    };
}

function setError(control: AbstractControl, key: string, value: string): void {
    const errors = control.errors ?? {};
    control.setErrors({
        ...errors,
        [key]: value,
    });
}

function removeError(control: AbstractControl, key: string): void {
    const errors = control.errors ?? {};
    const newErrors = { ...errors };
    if (newErrors.hasOwnProperty(key)) {
        delete newErrors[key];
    }
    control.setErrors(Object.keys(newErrors).length === 0 ? null : newErrors);
}

function ensureDate(someValue: any): Date | null {
    const date = new Date(someValue);
    return someValue === null || Number.isNaN(date.getTime()) ? null : date;
}

function dates(start: string, end: string, message: string): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } | null => {
        const startControl = group.controls[start];
        const endControl = group.controls[end];

        const startDate = ensureDate(startControl.value);
        const endDate = ensureDate(endControl.value);

        if (startDate && endDate && startDate > endDate) {
            setError(startControl, 'dates', message);
            setError(endControl, 'dates', message);
            return { dates: message };
        }

        removeError(startControl, 'dates');
        removeError(endControl, 'dates');
        return null;
    };
}

export const IsavValidators = {
    required,
    email,
    coordinates,
    fieldworks,
    minLength,
    notEmpty,
    routePoints,
    dates,
};
