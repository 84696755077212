import { Directive, OnDestroy, OnInit } from '@angular/core';
import OLMap from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import XYZSource from 'ol/source/XYZ';
import TileGrid from 'ol/tilegrid/TileGrid';
import { IsavMap } from '../map';
import { SPATIAL_REFERENCE_3996_PROJECTION } from '../util/projections';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'isav-map-greenland-layer',
})
export class IsavMapGeusGreenlandLayer implements OnInit, OnDestroy {
    private readonly greenlandLayer = new TileLayer({
        visible: true,
        extent: [-18000000, -9000000, 18000000, 9000000],
        source: new XYZSource({
            url: 'https://imagedata.geus.dk/arcgis/rest/services/Basemaps/GEBCO_2019_PolarStereo/MapServer/tile/{z}/{y}/{x}',
            tileGrid: new TileGrid({
                extent: [
                    -4571969.399610326, -4572212.600389674, 4572212.600389674, 4571969.399610326,
                ],
                resolutions: [
                    13229.193125052918, 9260.435187537043, 6614.596562526459, 5291.677250021167,
                    3968.7579375158753, 2645.8386250105837, 1984.3789687579376, 1322.9193125052918,
                    661.4596562526459, 529.1677250021168, 396.87579375158754, 264.5838625010584,
                    132.2919312505292, 66.1459656252646,
                ],
                tileSize: 512,
                origin: [-30603600, 900689321874.099],
            }),
            projection: SPATIAL_REFERENCE_3996_PROJECTION,
            wrapX: false,
        }),
    });
    private readonly labelsLayer = new TileLayer({
        visible: true,
        extent: [-18000000, -9000000, 18000000, 9000000],
        zIndex: 1,
        source: new XYZSource({
            url: 'https://data.geus.dk/arcgis/rest/services/GrlPortal/grl_stednavne_dynamisk/MapServer/tile/{z}/{y}/{x}',
            tileGrid: new TileGrid({
                extent: [-337668.9397, 6619580.601399999, 1332899.8782000002, 9389927.4393],
                resolutions: [
                    4233.341800016934, 2116.670900008467, 1058.3354500042335, 529.1677250021168,
                    264.5838625010584, 132.2919312505292, 66.1459656252646, 33.0729828126323,
                ],
                tileSize: 512,
                origin: [-5120900, 9998100],
            }),
            projection: 'EPSG:32624',
            wrapX: false,
        }),
    });
    private readonly map: OLMap;

    constructor(isavMap: IsavMap) {
        this.map = isavMap.map;
    }

    ngOnInit(): void {
        this.map.addLayer(this.greenlandLayer);
        this.map.addLayer(this.labelsLayer);
    }

    ngOnDestroy(): void {
        [this.greenlandLayer, this.labelsLayer].map((l) => this.map.removeLayer(l));

        this.greenlandLayer.dispose();
        this.labelsLayer.dispose();
    }
}
