import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DialogManager } from '../dialog/dialog.service';

@Component({
    selector: 'isav-image-lightbox',
    templateUrl: 'image-lightbox.html',
    styleUrls: ['image-lightbox.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavImageLightbox implements OnInit {
    src?: string;

    constructor(private dialogManager: DialogManager<string, void>) {}

    get image() {
        if (!this.src) return '';
        return this.src + '/download';
    }

    ngOnInit() {
        if (this.dialogManager.data) {
            this.src = this.dialogManager.data;
        } else {
            this.close();
        }
    }

    close(): void {
        this.dialogManager.close();
    }
}
