<div class="is-relative">
    <ng-content></ng-content>
    <isav-loader-overlay *ngIf="state.loading"></isav-loader-overlay>
</div>
<isav-pagination
    *ngIf="state.results.totalItems > 0"
    [total]="state.results.totalItems"
    [queryPagination]="'1'"
    [perPage]="shownPerPage"
    [page]="page"
    (pageChange)="updatePage($event)"
    [showAllEnable]="false"
    class="mt-5"
></isav-pagination>
