import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Optional,
    Output,
    QueryList,
    Self,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { createPopper, Instance as PopperInstance } from '@popperjs/core';
import { merge, Subscription } from 'rxjs';
import { mapTo, startWith, switchMap } from 'rxjs/operators';
import { IsavOption, IsavOptionSelectedChange } from './option';

let UNIQUE_ID = 0;

// eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
function noop() {}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'isav-select',
    templateUrl: './select.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        // if someone passes id angular will render it and we do not want it to be rendered!
        '[attr.id]': 'null',
    },
})
export class IsavSelect
    implements ControlValueAccessor, OnChanges, AfterContentInit, AfterViewInit, OnDestroy
{
    @Input() id = 'isav-select-' + UNIQUE_ID++;
    @Input() value: any[] = [];
    @Input() placeholder = 'Select options';
    @Input() readonly = false;
    @Input() dropdownInBody = false;
    @Input() short: boolean = false;
    @Output() valueChange = new EventEmitter<any>();
    @Output() touched = new EventEmitter<void>();

    @ContentChildren(IsavOption, { descendants: true }) options: QueryList<IsavOption>;
    @ViewChild('buttonEl', { static: true }) buttonEl: ElementRef<HTMLDivElement>;
    @ViewChild('listboxEl', { static: true }) listboxEl: ElementRef<HTMLDivElement>;

    model: SelectionModel<IsavOption>;
    modelSub = Subscription.EMPTY;
    activeDescendantManager: ActiveDescendantKeyManager<IsavOption>;
    open = false;
    popperInstance?: PopperInstance;

    private onChange: any = noop;
    private onTouched: any = noop;
    private _multiple = false;
    private skipValueChangeEmit: boolean = false;
    private _typedKeys = '';
    private _typedKeysTimeout: any;

    constructor(
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: Document,
        @Self() @Optional() private ngControl: NgControl
    ) {
        if (ngControl) ngControl.valueAccessor = this;
    }

    @Input() get multiple(): boolean {
        return this._multiple;
    }

    set multiple(multiple: boolean) {
        this._multiple = coerceBooleanProperty(multiple);
    }

    get viewText(): string {
        const viewText = this.model.selected.map((o) => o.getLabel()).join(', ');
        return viewText;
    }

    get listboxId(): string {
        return `${this.id}-listbox`;
    }

    get labelId(): string {
        return `${this.id}-label`;
    }

    get textId(): string {
        return `${this.id}-text`;
    }

    get labelledBy(): string {
        if (this.model?.selected?.length === 0) return this.labelId;
        return `${this.labelId} ${this.textId}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.multiple && !changes.multiple.isFirstChange()) {
            this.initModel(this.model.selected);
        }

        if (changes.readonly && !changes.readonly.isFirstChange()) {
            this.updateReadonlyState();
        }

        if (changes.value && !changes.value.isFirstChange()) {
            this.updateModelFromValue();
        }
    }

    ngAfterContentInit(): void {
        this.initModel(this.getOptionsFromValue());
        this.options.forEach((o) => o.setSelected(this.model.selected.includes(o)));
        this.activeDescendantManager = new ActiveDescendantKeyManager(this.options);

        this.updateReadonlyState();
        this.options.changes.pipe(untilDestroyed(this)).subscribe(() => {
            // run this in setTimeout as this is called right after change detection
            setTimeout(() => {
                this.updateReadonlyState();
                this.updateModelFromValue();
                this.activeDescendantManager = new ActiveDescendantKeyManager(this.options);
                this.cdRef.markForCheck();
            });
        });

        this.options.changes
            .pipe(
                mapTo(this.options),
                startWith(this.options),
                switchMap((opts) => merge(...opts.map((o) => o._internalSelectedChange))),
                untilDestroyed(this)
            )
            .subscribe((change: IsavOptionSelectedChange) => {
                if (change.selected) {
                    this.model.select(change.origin);
                } else {
                    this.model.deselect(change.origin);
                }

                if (!this.multiple) this.closeDropdown();
                this.cdRef.markForCheck();
            });
    }

    ngAfterViewInit(): void {
        if (this.dropdownInBody) {
            this.document.body.appendChild(this.listboxEl.nativeElement);
        }
    }

    ngOnDestroy(): void {
        this.listboxEl.nativeElement.parentNode?.removeChild(this.listboxEl.nativeElement);
        this.destroyPopper();
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.updateModelFromValue();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.readonly = isDisabled;
        this.updateReadonlyState();
    }

    handleKeydown(event: KeyboardEvent): void {
        if (event.key === 'Enter') return;
        this.activeDescendantManager.onKeydown(event);
        this.setActiveBasedOnKey(event.key);
        this.scrollListboxToActiveDescendantOption();
    }

    handleOutsideClick(e: MouseEvent | FocusEvent) {
        // we only want to close the dropdown now when we are not clicking on a button
        if (
            !this.buttonEl.nativeElement ||
            !this.buttonEl.nativeElement.contains(e.target as Node)
        ) {
            this.closeDropdown(true);
        }
    }

    openDropdown(activateLast = false): void {
        if (this.open) return;

        this.open = true;
        this.onTouched();
        this.touched.emit();
        this.createPopper();
        this.focusListbox();

        if (!this.model.isEmpty()) {
            const index = this.options.toArray().indexOf(this.model.selected[0]);
            this.activeDescendantManager.setActiveItem(index);
        } else if (activateLast) {
            this.activeDescendantManager.setLastItemActive();
        } else {
            this.activeDescendantManager.setFirstItemActive();
        }
    }

    closeDropdown(focusButton = true): void {
        if (!this.open) return;
        this.open = false;
        if (focusButton) this.focusButton();

        this.destroyPopper();
    }

    toggleDropdown(): void {
        if (this.open) this.closeDropdown();
        else this.openDropdown();
    }

    selectActivated(): void {
        if (!this.multiple) this.closeDropdown();
        const opt: IsavOption | null = this.activeDescendantManager.activeItem;
        if (opt === null) return;
        this.model.toggle(opt);
    }

    private initModel(initialValues: IsavOption[]) {
        this.modelSub.unsubscribe();
        this.model = new SelectionModel<IsavOption>(this.multiple, initialValues, true);
        this.modelSub = this.model.changed.subscribe((change: SelectionChange<IsavOption>) => {
            change.added.forEach((o) => o.setSelected(true));
            change.removed.forEach((o) => o.setSelected(false));

            const values = this.model.selected.map((o) => o.value);
            const value = this.multiple ? values : values[0] ?? null;

            this.value = value;

            // if this is internal change then we do not want to notify parent as we just
            // reacted to the change that went from upstream
            if (this.skipValueChangeEmit) return;
            this.valueChange.emit(this.value);
            this.onChange(this.value);
        });
    }

    private updateModelFromValue(): void {
        if (!this.options) return;

        const options = this.getOptionsFromValue();

        // we want to mark the change as coming from parent so we ignore the change event emit
        this.skipValueChangeEmit = true;

        this.model.clear();
        if (options.length) this.model.select(...options);

        this.skipValueChangeEmit = false;
    }

    private getOptionsFromValue(): IsavOption[] {
        let value = this.ngControl?.value || this.value;
        if (this.isNoneValue(value)) value = [];
        if (!Array.isArray(value)) value = [value];
        return this.options.filter((o) => value.includes(o.value));
    }

    private updateReadonlyState(): void {
        if (!this.options) return;
        this.options.forEach((o) => o.setSelectDisabled(this.readonly));
    }

    private focusButton(): void {
        this.ngZone.runOutsideAngular(() => setTimeout(() => this.buttonEl.nativeElement.focus()));
    }

    private focusListbox(): void {
        this.ngZone.runOutsideAngular(() => setTimeout(() => this.listboxEl.nativeElement.focus()));
    }

    private createPopper(): void {
        this.popperInstance?.destroy();

        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.popperInstance = createPopper(
                    this.buttonEl.nativeElement,
                    this.listboxEl.nativeElement,
                    {
                        placement: 'bottom-start',
                    }
                );
            });
        });
    }

    private destroyPopper(): void {
        this.popperInstance?.destroy();
        this.popperInstance = undefined;
    }

    private scrollListboxToActiveDescendantOption(): void {
        if (this.activeDescendantManager.activeItem) {
            const listbox = this.listboxEl.nativeElement;
            const activeOption = this.document.getElementById(
                this.activeDescendantManager.activeItem.id
            )!;

            const isTopVisible = listbox.scrollTop < activeOption.offsetTop;
            const isBottomVisible =
                listbox.scrollTop + listbox.offsetHeight >
                activeOption.offsetTop + activeOption.offsetHeight;

            if (!isTopVisible) {
                listbox.scrollTop = activeOption.offsetTop;
            } else if (!isBottomVisible) {
                listbox.scrollTop =
                    activeOption.offsetTop - listbox.offsetHeight + activeOption.offsetHeight;
            }
        }
    }

    /**
     * Checks whether we received a value that is an empty value for us.
     * We treat as an empty value `undefined`, `null`, empty string `''` or empty array `[]`
     * @param value - value to check whether it should be treated as empty value
     * @private
     */
    private isNoneValue(value: any): boolean {
        return (
            typeof value === 'undefined' ||
            (typeof value === 'string' && value === '') ||
            (typeof value === 'object' && value === null) ||
            (Array.isArray(value) && value.length === 0)
        );
    }

    private setActiveBasedOnKey(key: string): void {
        this._typedKeys += key.toLowerCase();
        this.clearTypedKeysAfterDelay();

        const options = this.options.toArray();
        const activeOptionIndex = this.activeDescendantManager.activeItemIndex || 0;
        const nextCandidate = this.findOptionCandidateFromTypedKeys(
            this._typedKeys,
            options,
            activeOptionIndex
        );

        if (nextCandidate !== -1) {
            this.activeDescendantManager.setActiveItem(nextCandidate);
        }
    }

    private findOptionCandidateFromTypedKeys(
        keys: string,
        options: IsavOption[],
        activeOptionIndex: number
    ): number {
        const check = (index: number) => options[index].getLabel().toLowerCase().startsWith(keys);
        for (let i = activeOptionIndex + 1; i < options.length; i++) {
            if (check(i)) return i;
        }
        for (let i = 0; i < activeOptionIndex; i++) {
            if (check(i)) return i;
        }
        return -1;
    }

    private clearTypedKeysAfterDelay(): void {
        this.ngZone.runOutsideAngular(() => {
            if (this._typedKeysTimeout) clearTimeout(this._typedKeysTimeout);

            this._typedKeysTimeout = setTimeout(() => {
                this._typedKeysTimeout = null;
                this._typedKeys = '';
            }, 500);
        });
    }
}
