<div class="help-tooltip-anchor" [class.is-opened]="isOpened" #anchor>
    <button
        (click)="open('click')"
        (mouseover)="open('hover')"
        (mouseout)="close('hover')"
        class="button help-tooltip-icon"
        aria-haspopup="true"
    >
        <isav-icon icon="info-circle"></isav-icon>
    </button>

    <div
        class="help-tooltip"
        tabindex="-1"
        [attr.aria-hidden]="isOpened ? 'true' : 'false'"
        (blur)="close('click')"
        #tooltip
    >
        <div class="help-tooltip-body">
            <ng-content></ng-content>
        </div>
    </div>
</div>
