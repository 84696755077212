<div class="actions is-flex">
    <span class="status">{{ enabled ? 'Enabled' : 'Disabled' }}</span>
    <div *ngIf="isDefined" class="buttons is-not-wrapping-tablet">
        <button
            data-testid="enable"
            color="success"
            [disabled]="enabled || loading"
            isavBtn
            (click)="handleEnable()"
            [class.is-loading]="loading"
        >
            Enable
        </button>
        <button
            data-testid="disable"
            color="danger"
            [disabled]="!enabled || loading"
            isavBtn
            (click)="handleDisable()"
            [class.is-loading]="loading"
        >
            Disable
        </button>
    </div>
</div>
