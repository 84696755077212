import { animate, style, transition, trigger } from '@angular/animations';
import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    forwardRef,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Iri } from '../../../core/dto/iri';
import { TaxonomiesService } from '../../../core/taxonomies/taxonomies.service';
import { TaxonomyTypesMap } from '../../../core/taxonomies/taxonomy-types';

function getTitleOrName(obj: any): string {
    if ('name' in obj && typeof obj.name === 'string') return obj.name;
    if ('title' in obj && typeof obj.title === 'string') return obj.title;
    return '';
}

function sortByTitleOrName(taxonomies: any[]): any[] {
    return taxonomies.sort((a, b) => {
        return getTitleOrName(a).localeCompare(getTitleOrName(b));
    });
}

let UNIQUE_ID = 0;

@Component({
    selector: 'isav-taxonomy-checkbox-list',
    templateUrl: './taxonomy-checkbox-list.html',
    styleUrls: ['./taxonomy-checkbox-list.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavTaxonomyCheckboxList),
            multi: true,
        },
    ],
    host: {
        '[attr.id]': 'null',
    },
    animations: [
        trigger('taxonomyCheckboxColumns', [
            transition('* => true', [
                style({ height: 60 }),
                animate('450ms ease-out', style({ height: '*' })),
            ]),
            transition('* => false', [
                style({ height: '*' }),
                animate('450ms ease-in', style({ height: 60 })),
            ]),
        ]),
    ],
})
export class IsavTaxonomyCheckboxList implements OnChanges, ControlValueAccessor {
    @Input() value: Iri[];
    @Output() valueChange = new EventEmitter<Iri[]>();
    @Input() taxonomy: keyof TaxonomyTypesMap;
    @Input() disabled: boolean;
    @Input() id = 'taxonomy-checkbox-list' + UNIQUE_ID++;
    @Input() label = '';
    @Input() readonly = false;

    @ViewChild('expandableBlock', { static: true }) expandableBlockRef: ElementRef<HTMLDivElement>;

    expanded = false;

    /**
     * Just controls adding or removing collapsed class, assigned after animation
     * is completed to preserve state of the animation
     * @type {boolean}
     */
    collapsedClass = true;

    _isDisabled: boolean = false;
    _taxonomies$: Observable<Iri[]>;
    _selected: Set<Iri> = new Set();
    _onChange: (value: Iri[]) => void = noop;
    _onTouched: () => void = noop;

    constructor(private taxonomiesService: TaxonomiesService, private cdRef: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.taxonomy) {
            const getId = (taxonomies: any[]): Iri[] => taxonomies.map((t) => t['@id']);
            this._taxonomies$ = this.taxonomiesService
                .getTaxonomiesByType(this.taxonomy)
                .pipe(map(sortByTitleOrName), map(getId));
            this._selected = new Set();
        }

        if (changes.value) {
            this.writeValue(this.value);
        }

        if (changes.disabled) {
            this.setDisabledState(this.disabled);
        }
    }

    has(taxonomy: Iri): boolean {
        return this._selected.has(taxonomy);
    }

    update(taxonomy: Iri): void {
        if (this.has(taxonomy)) {
            this._selected.delete(taxonomy);
        } else {
            this._selected.add(taxonomy);
        }

        this.emitValue();
    }

    writeValue(obj: any): void {
        this._selected.clear();
        if (Array.isArray(obj) && obj.every((s) => typeof s === 'string')) {
            obj.forEach((t) => this._selected.add(t));
        }

        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
        this.cdRef.markForCheck();
    }

    iriTrackBy(index: number, taxonomy: Iri): Iri {
        return taxonomy;
    }

    toggleExpand(): void {
        this.expanded = !this.expanded;
        if (this.expanded) {
            this.expandableBlockRef.nativeElement.focus();
        }
    }

    private emitValue() {
        const value = Array.from(this._selected);
        this._onChange(value);
        this.valueChange.emit(value);
    }
}
