<div class="field is-horizontal mb-2 is-relative">
    <div class="field-label field-label-tooltip">
        <label [for]="id" class="has-text-primary has-text-weight-bold">
            {{ label }}<span class="has-text-danger" *ngIf="mandatory">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="field">
            <div class="control is-flex">
                <input
                    #input
                    type="text"
                    [id]="id"
                    class="input person-input__search"
                    [formControl]="search"
                    [readonly]="disabled || value"
                    (focus)="onFocus()"
                    autocomplete="off"
                />
                <span class="input person-input__value">
                    {{ value }}
                    <button *ngIf="value" (click)="removePerson()">
                        <isav-icon icon="trash" class="is-small"></isav-icon>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div #dropdown *ngIf="people$ | async as people">
        <div class="person-input__autocomplete dropdown-content" *ngIf="open">
            <ul>
                <li *ngFor="let person of people">
                    <button class="dropdown-item" (click)="onChange(person.email)">
                        {{ person.fullName }} ({{ person.email }})
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
