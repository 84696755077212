<div class="field-index-container">
    <div class="field-index">
        <button
            *ngFor="let field of fields$ | async"
            class="field-index-link"
            [class.has-text-danger]="field.status === 'INVALID'"
            [class.has-text-success]="field.status === 'VALID'"
            [class.has-text-weight-bold]="field.status !== 'NONE'"
            (click)="tryToFocus(field.id)"
        >
            {{ field.name }}
        </button>
    </div>
</div>
