import { Iri } from '../dto/iri';

export interface WithTitleOrName {
    readonly title?: string;
    readonly name?: string;
}

export interface Taxonomy extends WithTitleOrName {
    readonly '@id': Iri;
    readonly children?: readonly Iri[];
    readonly parent?: Iri | null;
}

export type GroupedTaxonomyChildren<T extends Taxonomy> = Omit<T, 'children'> & {
    readonly children?: readonly GroupedTaxonomyChildren<T>[];
};

export function compareTitleOrName(a: WithTitleOrName, b: WithTitleOrName): number {
    const getTitleOrName = (obj: WithTitleOrName): string => obj.title || obj.name || '';
    return getTitleOrName(a).localeCompare(getTitleOrName(b));
}

export function groupTaxonomyWithChildren<T extends Taxonomy>(
    taxonomies: T[]
): GroupedTaxonomyChildren<T>[] {
    const byIri = (iri: Iri): T => taxonomies.find((t) => t['@id'] === iri)!;
    const mapTaxonomy = (taxonomy: T): GroupedTaxonomyChildren<T> => {
        if (typeof taxonomy.children === 'undefined')
            return { ...taxonomy, children: [] } as GroupedTaxonomyChildren<T>;

        const children = taxonomy.children.map(byIri).map(mapTaxonomy).sort(compareTitleOrName);
        return {
            ...taxonomy,
            children,
        };
    };

    return taxonomies
        .filter((t) => !t.parent)
        .map(mapTaxonomy)
        .sort(compareTitleOrName);
}
