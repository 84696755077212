<div *ngIf="image" class="card-image" [class.has-caption]="caption">
    <figure class="image is-4by3">
        <ng-container *ngTemplateOutlet="image.templateRef"></ng-container>
        <ng-container *ngTemplateOutlet="caption?.templateRef"></ng-container>
    </figure>
</div>
<ng-content select="[isavCardHeader]"></ng-content>
<div class="card-content">
    <ng-content></ng-content>
</div>
<div *ngIf="footer" class="card-footer">
    <div class="card-footer-content">
        <ng-content select="[isavCardFooter]"></ng-content>
    </div>
</div>
