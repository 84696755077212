import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, pluck, switchMap } from 'rxjs/operators';
import { ContentService } from '../../core/api/content.service';
import { Iri } from '../../core/dto/iri';
import { PersonDto } from '../../core/dto/person';
import { NotificationService } from '../../core/services/notification.service';

@UntilDestroy()
@Component({
    selector: 'isav-content-ownership',
    templateUrl: './content-ownership.html',
    styleUrls: ['./content-ownership.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavContentOwnership implements OnInit, OnChanges {
    @Input() readonly contentIri: Iri;
    public owners: PersonDto[] = [];

    private contentIriChange = new Subject<void>();

    public constructor(
        private contentService: ContentService,
        private notificationService: NotificationService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.contentIriChange
            .pipe(
                untilDestroyed(this),
                filter(() => !!this.contentIri),
                switchMap(() => {
                    return this.contentService.getByIri<{ owners: Iri[] }>(this.contentIri);
                }),
                pluck('owners'),
                switchMap((owners = []) => {
                    const data = owners.map((o) =>
                        this.contentService.getByIri<PersonDto>(o['@id'])
                    );
                    return forkJoin(data as Observable<PersonDto>[]);
                })
            )
            .subscribe((owners) => {
                this.owners = owners;
                this.cdRef.markForCheck();
            });

        if (this.contentIri) {
            this.contentIriChange.next();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contentIri && this.contentIri) {
            this.contentIriChange.next();
        }
    }

    share(newOwner: PersonDto): void {
        this.contentService.shareOwnership(this.contentIri, newOwner['@id']).subscribe(() => {
            this.owners = [...this.owners, newOwner];
            this.cdRef.markForCheck();
            this.notificationService.add({
                title: 'Success',
                message: 'Successfully shared ownership',
                type: 'success',
                delay: 5000,
            });
        });
    }

    revoke(removedOwner: PersonDto): void {
        this.contentService.revokeOwnership(this.contentIri, removedOwner['@id']).subscribe(() => {
            this.owners = this.owners.filter((owner) => owner['@id'] !== removedOwner['@id']);
            this.cdRef.markForCheck();
            this.notificationService.add({
                title: 'Success',
                message: 'Successfully revoked ownership',
                type: 'success',
                delay: 5000,
            });
        });
    }
}
