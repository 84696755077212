import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators';
import { ContentService } from '../../core/api/content.service';
import { slug } from '../../core/slug';
import { DialogManager } from '../../shared/dialog/dialog.service';
import { typeFromIri } from '../../core/type-from-iri';

interface ContentDisplay {
    '@id': string;
    '@type': string;
    name?: string;
    fullNameOfDataSet?: string;
    title?: string;
    fullName?: string;
    lastModified: string;
    description?: string;
}

@UntilDestroy()
@Component({
    selector: 'isav-search-popup',
    templateUrl: './search-popup.html',
    styleUrls: ['./search-popup.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPopup implements OnInit, AfterViewInit {
    static MIN_SCROLL_TOP_TO_SHOW_BUTTON = 94; // hand picked value in px

    content$: Observable<Array<ContentDisplay>>;
    forceSearch = new Subject<any>();
    extractedText: UntypedFormControl = new UntypedFormControl('');
    loading: boolean = false;

    @ViewChild('dialog', { static: true, read: ElementRef }) dialogRef: ElementRef<HTMLDivElement>;
    @ViewChild('floatingCloseButton', { static: true, read: ElementRef })
    floatingCloseButtonRef: ElementRef<HTMLButtonElement>;

    constructor(
        private dialogManager: DialogManager<void, void>,
        private contentService: ContentService,
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        const changes$ = this.extractedText.valueChanges.pipe(
            filter((text) => text.length > 2),
            debounceTime(500)
        );
        const force$ = this.forceSearch.pipe(
            tap((e) => e.preventDefault()),
            map(() => this.extractedText.value)
        );
        this.content$ = merge(changes$, force$).pipe(
            tap(() => {
                this.loading = true;
                this.cdRef.markForCheck();
            }),
            switchMap((text: string) =>
                this.contentService
                    .find<ContentDisplay>({
                        extractedText: text,
                        'order[lastModified]': 'desc',
                    })
                    .pipe(catchError(() => []))
            ),
            tap(() => {
                this.loading = false;
                this.cdRef.markForCheck();
            })
        );
    }

    ngAfterViewInit(): void {
        const button = this.floatingCloseButtonRef.nativeElement;
        const dialog = this.dialogRef.nativeElement;

        // we do not want to trigger change detection with this event listener
        this.ngZone.runOutsideAngular(() => {
            fromEvent(dialog, 'scroll', { passive: true })
                .pipe(
                    untilDestroyed(this),
                    map(() => dialog.scrollTop),
                    startWith(dialog.scrollTop),
                    map((scrolled) => scrolled >= SearchPopup.MIN_SCROLL_TOP_TO_SHOW_BUTTON),
                    distinctUntilChanged()
                )
                .subscribe((show) => {
                    button.style.display = show ? 'inline-block' : 'none';
                });
        });
    }

    close() {
        this.dialogManager.close();
    }

    viewLink(row: ContentDisplay): string[] {
        const type = typeFromIri(row['@id']).replace(/_/g, '-');
        return [`/${type}/view`, slug(this.getName(row))];
    }

    getName(row: ContentDisplay): string {
        return row.name || row.title || row.fullNameOfDataSet || row.fullName || '';
    }

    trackByFn(index: number, item: ContentDisplay) {
        return item['@id'];
    }
}
