import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'isav-map-placeholder',
    template: `<ng-content></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'isav-map-container isav-map-placeholder is-relative',
        '[class.is-fullsize]': 'fullSize',
    },
})
export class IsavMapPlaceholder {
    @Input() fullSize = false;
}
