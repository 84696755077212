<div class="contact-information mb-5">
    <ng-container *isavAuthenticated="true; else notLogged">
        <ng-container *ngIf="isContact()">
            <h2><strong>Contact Information</strong></h2>
            <div class="columns">
                <div class="column">
                    <p><b>{{contactName}}</b></p>
                </div>
                <div class="column">
                    <p>{{contactEmail}}</p>
                    <p>{{contactPhone}}</p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isPI()">
            <h2><strong>PI Information</strong></h2>
            <div class="columns">
                <div class="column">
                    <p><b>{{PIName}}</b></p>
                </div>
                <div class="column">
                    <p>{{PIEmail}}</p>
                    <p>{{PIPhone}}</p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isPI() && !isContact()">
            <h2 class="has-text-centered">
                <strong>Author has not left any contact infromation</strong>
            </h2>
        </ng-container>
    </ng-container>
</div>
<ng-template #notLogged>
    <h2 class="has-text-centered is-print-hidden">
        <strong>
            Please
            <a routerLink="/sign-in" [queryParams]="{ returnUrl: router.url }">login</a>
            to see contact information
        </strong>
    </h2>
</ng-template>
