import { Directive, OnDestroy, OnInit } from '@angular/core';
import XYZSource from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import TileGrid from 'ol/tilegrid/TileGrid';
import { IsavMap } from '../map';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'isav-map-topographic-geus-layer',
})
export class IsavMapGeusTopographicLayer implements OnInit, OnDestroy {
    private readonly layer = new TileLayer({
        visible: true,
        extent: [-18000000, -9000000, 18000000, 9000000],
        zIndex: 1,
        source: new XYZSource({
            url: 'https://imagedata.geus.dk/arcgis/rest/services/Basemaps/g250_topographic_map_polarstereo/MapServer/tile/{z}/{y}/{x}',
            tileGrid: new TileGrid({
                extent: [
                    -3862892.6858445257, -3985127.2729799664, 441340.1556000002, 305225.1689000018,
                ],
                resolutions: [
                    8466.683600033868, 4233.341800016934, 2116.670900008467, 1058.3354500042335,
                    529.1677250021168, 264.5838625010584, 132.2919312505292, 66.1459656252646,
                    33.0729828126323, 16.53649140631615,
                ],
                tileSize: 512,
                origin: [-30603600, 900689321874.099],
            }),
            projection: 'EPSG:3996',
            wrapX: false,
        }),
    });

    constructor(private readonly isavMap: IsavMap) {}

    ngOnInit() {
        this.isavMap.map.addLayer(this.layer);
    }

    ngOnDestroy() {
        this.isavMap.map.removeLayer(this.layer);
        this.layer.dispose();
    }
}
