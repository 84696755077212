import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsSharedLandingPage } from './projects-shared-landing-page/projects-shared-landing-page';
import { RouterModule } from '@angular/router';
import { IsavCommonModule } from '../../common/common.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    declarations: [ProjectsSharedLandingPage],
    imports: [CommonModule, RouterModule, IsavCommonModule, ReactiveFormsModule, SharedModule],
    exports: [ProjectsSharedLandingPage],
})
export class ProjectsSharedModule {}
