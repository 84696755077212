import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeEvent, CKEditor5, FocusEvent } from '@ckeditor/ckeditor5-angular';
import IsavClassicEditor from '../../../../../ckeditor-build/dist/bundle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { noop } from 'rxjs';
import { ContentEditorDialogService } from '../forms/content-editor-dialog/content-editor-dialog.service';

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
const IsavCKEditorSimpleConfig: CKEditor5.Config = {
    toolbar: [
        'bold',
        'italic',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'undo',
        'redo',
        '|',
        'removeFormat',
    ],
};

let UNIQUE_ID = 0;

/**
 * Our shared configuration for ckeditor. Each instance should have the same configuration to
 * provide consistent behaviour between multiple instances of the editor, thus providing a ckeditor
 * wrapper that should be used instead of ckeditor directly.
 */
@UntilDestroy()
@Component({
    selector: 'isav-ckeditor-wrapper',
    templateUrl: './ckeditor5-wrapper.html',
    styleUrls: ['./ckeditor5-wrapper.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CKEditor5Wrapper),
            multi: true,
        },
    ],
    host: {
        class: 'ckeditor5-wrapper',
        '[attr.id]': 'null',
        '[class.dialog-wrapper]': 'dialog',
    },
})
export class CKEditor5Wrapper implements ControlValueAccessor, OnChanges, OnInit {
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    Editor = IsavClassicEditor;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    SimpleConfig = IsavCKEditorSimpleConfig;

    @Input() dialog: boolean = false;
    @Input() simple: boolean = false;
    @Input() id = 'ckeditor5-wrapper--' + UNIQUE_ID++;
    @Input() value: string;
    @Input() initialValue: string;
    @Input() disabled: boolean = false;
    @Output() valueChange = new EventEmitter<string>();
    @Output() focused = new EventEmitter<FocusEvent>();

    private changeCallback: any = noop;
    private touchedCallback: any = noop;
    private _editor?: CKEditor5.Editor;

    constructor(
        private cdRef: ChangeDetectorRef,
        private contentEditorDialogService: ContentEditorDialogService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.id && !changes.id.isFirstChange()) {
            this.updateView();
        }
    }

    ngOnInit() {
        this.initialValue = this.value;
        this.contentEditorDialogService
            .notify()
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this._editor?.setData(value);
                this.cdRef.markForCheck();
            });
    }

    onChange(event: ChangeEvent): void {
        this.value = event.editor.getData();
        this.valueChange.emit(this.value);
        this.changeCallback(this.value);
    }

    registerOnChange(fn: any): void {
        this.changeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cdRef.markForCheck();
    }

    writeValue(obj: any): void {
        this.value = obj ?? '';
        this.cdRef.markForCheck();
    }

    onFocus(event: FocusEvent): void {
        this.touchedCallback();
        this.focused.emit(event);
    }

    onReady(editor: CKEditor5.Editor): void {
        this._editor = editor;
        this.updateView();
    }

    /**
     * Updates editor view (contenteditable element) by adding a class needed to display content
     * properly and setting id.
     *
     * @see https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_view_downcastwriter-DowncastWriter.html
     * @private
     */
    private updateView(): void {
        this._editor?.editing.view.change((writer) => {
            const editableElement = this._editor?.editing.view.document.getRoot();
            writer.setAttribute('id', this.id, editableElement);
            writer.addClass('content', editableElement);
        });
    }
}
