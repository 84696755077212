import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { Fill, Icon, Stroke, Style, Circle } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { IsavMap } from '../map';
import { IsavMapOverlayContent } from '../overlay/popup';
import { IMapMarker } from './marker.interface';
import { IsavMapVectorSource } from './vector-source';

export const DEFAULT_COLOR = '#1b7faf';

const POINT_STYLE_CACHE = {};
export function pointStyle(color: string = DEFAULT_COLOR): Style {
    color = color || DEFAULT_COLOR;

    if (!POINT_STYLE_CACHE[color]) {
        POINT_STYLE_CACHE[color] = new Style({
            image: new Circle({
                radius: 6,
                stroke: new Stroke({
                    color: '#fff',
                }),
                fill: new Fill({
                    color: color,
                }),
            }),
        });
    }

    return POINT_STYLE_CACHE[color];
}

let MARKER_STYLE_CACHE: Style;
export function markerStyle(): Style {
    if (!MARKER_STYLE_CACHE) {
        MARKER_STYLE_CACHE = new Style({
            image: new Icon({
                src: '/assets/map/map-marker-alt.png',
                color: '#FF3860',
                anchor: [11, 22],
                anchorXUnits: IconAnchorUnits.PIXELS,
                anchorYUnits: IconAnchorUnits.PIXELS,
            }),
        });
    }

    return MARKER_STYLE_CACHE;
}

export const STYLES = {
    marker: markerStyle,
    point: pointStyle,
};

export type IsavFeatureStyle = keyof typeof STYLES;

@UntilDestroy()
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'isav-map-marker',
})
export class IsavMapMarker implements IMapMarker, OnInit, OnDestroy {
    @Input() center: boolean = false;

    @Input() get color(): string {
        return this._color;
    }
    set color(color: string) {
        this._color = color;
        this._feature.setStyle(STYLES[this.display](this.color));
    }

    @Input() get display(): IsavFeatureStyle {
        return this._display;
    }
    set display(value: IsavFeatureStyle) {
        this._display = value;
        this._feature.setStyle(STYLES[this.display](this.color));
    }

    @Input() get coordinate(): Coordinate | null {
        return this._coordinate;
    }
    set coordinate(coordinate: Coordinate | null) {
        if (coordinate && typeof coordinate[0] === 'number' && typeof coordinate[1] === 'number') {
            this._coordinate = coordinate;
        } else {
            this._coordinate = null;
        }

        if (this.coordinate) {
            const spatial3996 = this.vectorSource.toSourceCoordinate(this.coordinate);
            if (!this._feature.getGeometry()) {
                this._feature.setGeometry(new Point(spatial3996));
                this.vectorSource.getVectorSource().addFeature(this._feature);
            } else {
                this._feature.getGeometry().setCoordinates(spatial3996);
            }
        } else {
            if (this._feature.getGeometry()) {
                this.vectorSource.getVectorSource().removeFeature(this._feature);
            }
            this._feature.setGeometry(undefined);
        }
    }

    private _display: IsavFeatureStyle = 'point';
    private _coordinate: Coordinate | null;
    private _color: string = '#1b7faf';
    public readonly _feature: Feature<Point>;

    constructor(protected vectorSource: IsavMapVectorSource, private isavMap: IsavMap) {
        this._feature = new Feature<Point>();
        this.display = this.display; // trigger setter
    }

    ngOnInit() {
        if (this.center && this.coordinate) {
            const coordinate = this.vectorSource.toSourceCoordinate(this.coordinate);
            this.vectorSource.center(coordinate);
        }

        this.isavMap.viewChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.coordinate = this.coordinate;
        });
    }

    ngOnDestroy(): void {
        if (this._feature.getGeometry()) {
            this.vectorSource.getVectorSource().removeFeature(this._feature);
        }
    }

    addPopup(popup: IsavMapOverlayContent): void {
        this._feature.set('isavMapOverlayContent', popup);
    }
}
