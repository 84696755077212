import {
    ChangeDetectionStrategy,
    Component,
    Directive,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { Iri } from '../../core/dto/iri';

@Directive({
    selector: '[isavLandingCardHeading],[isavLandingCardFooter],[isavLandingCardCaption]',
})
export class IsavLandingCardContentDirective {}

@Component({
    selector: 'isav-landing-card',
    templateUrl: './landing-card.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'column is-12-mobile is-6-tablet is-3-desktop card-container',
    },
})
export class IsavLandingCard {
    @Input() image?: Iri;
    @Input() covering: boolean = true;
}
