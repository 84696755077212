<nav class="top-navigation">
    <a routerLink="/how-to-guide">How to guide</a>
    <a routerLink="/news">News</a>
    <a routerLink="/events">Events</a>
    <a routerLink="/education-and-trainings">Education & Training</a>
    <button class="button is-icon" aria-label="Open search" isavSearchBtn>
        <isav-icon icon="search"></isav-icon>
    </button>
    <ng-container *isavAuthenticated="true; else: signInUpButtons">
        <a
            routerLink="/my-isaaffik"
            isavBtn
            [outlined]="true"
            color="darkblue"
            class="has-small-padding"
        >
            My Isaaffik
        </a>
        <a
            routerLink="/"
            (click)="logout()"
            isavBtn
            [outlined]="true"
            color="darkblue"
            class="has-small-padding"
        >
            Sign-out
        </a>
    </ng-container>
</nav>

<ng-template #signInUpButtons>
    <a
        routerLink="/sign-in"
        [queryParams]="{ returnUrl: router.url }"
        isavBtn
        [outlined]="true"
        color="darkblue"
        class="has-small-padding"
        >Sign-in</a
    >
    <a
        routerLink="/people/register"
        isavBtn
        [outlined]="true"
        color="darkblue"
        class="has-small-padding"
        >Sign-up</a
    >
</ng-template>
