import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map } from 'rxjs/operators';

@UntilDestroy()
@Pipe({
    name: 'screenSizeSplit',
    pure: false,
})
export class ScreenSizeSplitPipe implements PipeTransform {
    private columns: number = this.calculateColumns();

    constructor(private readonly cdRef: ChangeDetectorRef) {
        fromEvent(window, 'resize')
            .pipe(
                untilDestroyed(this),
                map(() => this.calculateColumns()),
                distinctUntilChanged()
            )
            .subscribe((columns: number) => {
                this.columns = columns;
                this.cdRef.markForCheck();
            });
    }

    transform<T>(value: T[]): T[][] {
        const columns = this.columns;
        const size = Math.ceil(value.length / columns);
        const res: T[][] = [];
        for (let i = 0; i < columns; i++) {
            res.push(value.slice(i * size, i * size + size));
        }
        return res;
    }

    private calculateColumns(): number {
        if (window.innerWidth >= 1024) return 3;
        if (window.innerWidth >= 768) return 2;
        return 1;
    }
}
