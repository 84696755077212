import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PersonDto } from '../dto/person';
import { BaseSearchService } from './base-search-service';
import { Observable } from 'rxjs';
import { RegisterPersonCommandDto } from '../dto/register-person-command';

@Injectable()
export class PeopleResourceService extends BaseSearchService<PersonDto> {
    constructor(http: HttpClient) {
        super('people', http);
    }

    register(data: RegisterPersonCommandDto): Observable<void> {
        return this.http.post<void>(this.resourceUrl(), data);
    }
}
