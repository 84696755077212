<button
    class="mobile-navigation-open button is-primary is-outlined is-hidden-desktop"
    aria-label="Open mobile menu"
    aria-haspopup="dialog"
    (click)="toggle(true)"
>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
</button>

<div
    *isavBodyChild="visible"
    (isavDialog)="toggle(false)"
    id="mobile-navigation-dialog"
    class="dialog"
    aria-labelledby="mobile-navigation-label"
>
    <nav class="mobile-navigation">
        <span id="mobile-navigation-label" class="is-sr-only">Mobile navigation</span>

        <div class="mobile-navigation-actions">
            <button isavBtn isavSearchBtn color="white" outlined aria-label="Open search">
                <isav-icon icon="search"></isav-icon>
            </button>
            <button
                isavBtn
                id="mobile-navigation-close"
                color="primary"
                aria-controls="mobile-navigation-dialog"
                aria-label="Close mobile menu"
                (click)="toggle(false)"
            >
                <isav-icon icon="times"></isav-icon>
            </button>
        </div>

        <div class="buttons has-addons is-vertical">
            <a isavBtn color="primary" routerLink="/projects" [class.is-link]="isProjects()"
                >Projects</a
            >
            <a isavBtn color="primary" routerLink="/infrastructures" routerLinkActive="is-link"
                >Infrastructures</a
            >
            <a isavBtn color="primary" routerLink="/logistics" routerLinkActive="is-link"
                >Logistics</a
            >
            <a isavBtn color="primary" routerLink="/datalinks" routerLinkActive="is-link"
                >Data-Links</a
            >
            <a isavBtn color="primary" routerLink="/people" routerLinkActive="is-link">People</a>
            <a
                *isavUserHasRole="['ROLE_SAR', 'ROLE_ADMIN']"
                isavBtn
                color="primary"
                routerLink="/sar"
                routerLinkActive="is-link"
                [queryParams]="{ '__view': '3' }"
                >SAR</a
            >
            <div class="buttons-vertical-divider"></div>
            <a isavBtn color="primary" routerLink="/how-to-guide" routerLinkActive="is-link"
                >How to guide</a
            >
            <a isavBtn color="primary" routerLink="/news" routerLinkActive="is-link">News</a>
            <a isavBtn color="primary" routerLink="/events" routerLinkActive="is-link">Events</a>
            <a
                isavBtn
                color="primary"
                routerLink="/education-and-trainings"
                routerLinkActive="is-link"
            >
                Education & Training
            </a>
            <div class="buttons-vertical-divider"></div>
            <a
                *isavAuthenticated="true; else: signInUpLinks"
                isavBtn
                color="primary"
                routerLink="/my-isaaffik"
                routerLinkActive="is-link"
            >
                My Isaaffik
            </a>
            <a *isavAuthenticated="true" isavBtn color="primary" routerLink="/" (click)="logout()">
                Sign-out
            </a>
            <ng-template #signInUpLinks>
                <a
                    isavBtn
                    color="primary"
                    routerLink="/sign-in"
                    [queryParams]="{ returnUrl: router.url }"
                    routerLinkActive="is-link"
                    >Sign-in</a
                >
                <a isavBtn color="primary" routerLink="/people/register" routerLinkActive="is-link"
                    >Sign-up</a
                >
            </ng-template>
        </div>
    </nav>
</div>
