import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { IMapControl, IsavMapControlPosition } from './control';
import { IsavMapControlsService } from './controls.service';

function positionClassMapFactory(positions: string[]): Record<string, string> {
    return positions.reduce((acc, position) => {
        acc[position] = 'is-' + position.replace(' ', '-');
        return acc;
    }, {});
}

@Component({
    selector: 'isav-map-controls-display',
    templateUrl: './controls-display.html',
    styleUrls: ['./controls-display.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavMapControlsDisplay {
    // adding option to this array requires update in sass styles
    readonly positions: IsavMapControlPosition[] = [
        'top left',
        'top right',
        'bottom left',
        'bottom right',
    ];
    readonly positionClass = positionClassMapFactory(this.positions);
    readonly controls$: Observable<IMapControl[]>;

    constructor(private readonly controlsService: IsavMapControlsService) {
        this.controls$ = this.controlsService.getControls();
    }
}
