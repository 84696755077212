<isav-centered-form-container>
    <h1 class="subtitle is-5 has-text-centered">Reset password to your Isaaffik account</h1>

    <div *ngIf="backendError" class="notification is-danger" role="alert" aria-atomic="true">
        <button class="delete" type="button" (click)="backendError = ''">
            <span class="is-sr-only">Hide notification</span>
        </button>
        {{ backendError }}
    </div>

    <form *ngIf="!success" [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="mb-4">
        <div class="field">
            <label for="emailInput" class="label">E-mail:</label>
            <div class="control">
                <input
                    type="text"
                    class="input"
                    formControlName="email"
                    id="emailInput"
                    autocomplete="username"
                    [class.is-invalid]="backendError"
                />
                <p class="help is-danger">
                    <isav-error-display [control]="formGroup.get('email')"></isav-error-display>
                </p>
            </div>
        </div>
        <div class="field is-grouped is-grouped-between">
            <div class="control">
                <button
                    isavBtn
                    color="primary"
                    type="submit"
                    [disabled]="!formGroup.valid || loading"
                    [class.is-loading]="loading"
                >
                    Reset password
                </button>
            </div>
            <div class="control">
                <a isavBtn color="primary" routerLink="/" class="is-light">Go to Homepage</a>
            </div>
        </div>

        <div class="content">
            <p>You do not have an account? <a routerLink="/people/register">Create new one</a>.</p>
        </div>
    </form>

    <div *ngIf="success">
        <p class="mb-2">
            E-mail with instructions on how to reset your password has been sent to your mailbox.
        </p>
        <div class="buttons is-right">
            <div class="control">
                <a isavBtn color="primary" routerLink="/" class="is-light">Go to Homepage</a>
            </div>
        </div>
    </div>
</isav-centered-form-container>
