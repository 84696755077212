import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MapLocationDto } from '../../core/dto/map-location';
import Control from 'ol/control/Control';
import { IsavMap } from '../../map/map';
import { MapLocationSearchService } from './map-location-search.service';
import { UntypedFormControl } from '@angular/forms';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { fromMapEvent } from '../../map/util/from-map-event';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

let UNIQUE_ID = 0;

@UntilDestroy()
@Component({
    selector: 'isav-map-location-search-control',
    templateUrl: './map-location-search-control.html',
    styleUrls: ['./map-location-search-control.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MapLocationSearchService],
})
export class IsavMapLocationSearchControl implements OnInit, AfterViewInit, OnDestroy {
    @Output() mapLocation = new EventEmitter<MapLocationDto>();
    @ViewChild('inputElement', { static: true }) inputRef: ElementRef<HTMLInputElement>;
    results: MapLocationDto[] = [];
    expanded = false;
    control = new UntypedFormControl('');
    selected = 0;
    isOpen = false;
    isLoading = false;
    private olControl: Control;
    private readonly uniqueId = UNIQUE_ID++;

    @ViewChild('resultListElement', { static: false, read: ElementRef })
    resultListElement: ElementRef<HTMLElement>;

    constructor(
        private readonly elRef: ElementRef,
        private readonly isavMap: IsavMap,
        private readonly searchService: MapLocationSearchService,
        private readonly cdRef: ChangeDetectorRef
    ) {}

    get results$(): Observable<MapLocationDto[]> {
        return this.searchService.results;
    }

    ngOnInit() {
        this.searchService.results.pipe(untilDestroyed(this)).subscribe((results) => {
            this.results = results;
            this.isLoading = false;
            this.selected = 0;
            this.cdRef.markForCheck();
        });

        const strLongerThen3 = (str: string): boolean => str.length >= 3;
        this.control.valueChanges
            .pipe(untilDestroyed(this), debounceTime(300), filter(strLongerThen3))
            .subscribe((name) => {
                this.search(name);
            });

        const documentClick = fromEvent(document, 'click').pipe(
            filter((e) => !this.elRef.nativeElement.contains(e.target as Node))
        );
        merge(documentClick, fromMapEvent(this.isavMap.map, 'click'))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isOpen = false;
                this.cdRef.markForCheck();
            });
    }

    ngAfterViewInit(): void {
        this.olControl = new Control({ element: this.elRef.nativeElement });
        this.isavMap.map.addControl(this.olControl);
    }

    ngOnDestroy(): void {
        this.olControl.dispose();
    }

    toggle() {
        if (this.expanded) this.close();
        else this.open();
    }

    open() {
        this.expanded = true;
        this.isOpen = true;
        this.inputRef.nativeElement.focus();
    }

    close() {
        this.expanded = false;
        this.isOpen = false;
        this.searchService.cancel();
    }

    search(name?: string) {
        this.isLoading = true;
        this.searchService.search(name ?? this.control.value);
        this.cdRef.markForCheck();
    }

    select(result?: MapLocationDto) {
        if (!result) return;

        this.isOpen = false;
        this.mapLocation.emit(result);
        this.close();
    }

    changeSelected(modifier: number) {
        if (this.results.length === 0) return;
        this.selected = (this.results.length + this.selected + modifier) % this.results.length;
    }

    getUniqueId(suffix?: string): string {
        return `map-location-search-control-${this.uniqueId}` + (suffix ? '--' + suffix : '');
    }

    getUniqueOptionId(option: number): string {
        return this.getUniqueId('option-' + option);
    }

    handleArrowKeydown(event: KeyboardEvent, modifier: number): void {
        event.stopPropagation();
        event.preventDefault();
        this.changeSelected(modifier);
        this.scrollOptionIntoView();
    }

    scrollOptionIntoView() {
        const container = this.resultListElement.nativeElement;
        if (container) {
            const option = container.querySelector<HTMLButtonElement>(
                `button:nth-child(${this.selected + 1})`
            );
            if (!option) return;

            // element is out in the top
            if (option.offsetTop < container.scrollTop) {
                container.scrollTop = Math.max(option.offsetTop - 1, 0);
            }

            // element is out in the bottom
            if (
                option.offsetTop + option.offsetHeight >
                container.scrollTop + container.offsetHeight
            ) {
                container.scrollTop =
                    option.offsetTop + option.offsetHeight - container.offsetHeight + 1;
            }
        }
    }
}
