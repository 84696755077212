<swiper-container
    class="swiper carousel-container"
    slides-per-group="1"
    space-between="10"
    navigation="true"
    lazy="true"
    #swiper
>
    <swiper-slide *ngFor="let image of images; let i = index" class="carousel-image">
        <img
            loading="lazy"
            [isavApiImg]="image"
            class="is-max-vh"
            [isavAltImageCaption]="image"
            (click)="setCurrentIndex(i)"
        />
    </swiper-slide>
</swiper-container>
