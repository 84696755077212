<div class="taxonomy-checkbox is-relative">
    <div class="is-relative">
        <div
            [id]="id"
            class="field taxonomy-checkbox__columns"
            [class.is-collapsed]="collapsedClass && !expanded"
            [attr.aria-hidden]="(!expanded).toString()"
            [attr.aria-labelledby]="label + ' option list'"
            [@taxonomyCheckboxColumns]="expanded.toString()"
            (@taxonomyCheckboxColumns.done)="collapsedClass = !expanded"
            tabindex="-1"
            #expandableBlock
        >
            <div
                *ngFor="let taxonomies of _taxonomies$ | async | screenSizeSplit"
                class="taxonomy-checkbox__column"
            >
                <div
                    *ngFor="let taxonomy of taxonomies; trackBy: iriTrackBy"
                    class="control taxonomy-checkbox__control"
                >
                    <label class="checkbox">
                        <input
                            type="checkbox"
                            [checked]="has(taxonomy)"
                            [disabled]="_isDisabled"
                            (change)="update(taxonomy)"
                            [attr.data-testid]="taxonomy"
                            [attr.tabindex]="expanded ? 0 : -1"
                        />
                        <span>
                            <isav-taxonomies-names [iris]="[taxonomy]"></isav-taxonomies-names>
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="!expanded" class="taxonomy-checkbox__overlay"></div>
    </div>
    <div class="has-text-centered">
        <button
            type="button"
            class="taxonomy-checkbox__expand-btn"
            (click)="toggleExpand()"
            [attr.aria-controls]="id"
            [attr.aria-expanded]="expanded.toString()"
            [disabled]="readonly"
        >
            <span aria-hidden="true">{{ expanded ? 'Collapse' : 'Expand' }}</span>
            <span class="is-sr-only">
                {{ expanded ? 'Collapse' : 'Expand' }} {{ label }} option list
            </span>
            <isav-icon [icon]="expanded ? 'chevron-up' : 'chevron-down'"></isav-icon>
        </button>
    </div>
    <div *ngIf="readonly" class="taxonomy-checkbox__dimmed"></div>
</div>
