<div class="modal is-active" (isavDialog)="close()">
    <div class="modal-background">
        <div class="has-text-right mt-5 mr-5">
            <button class="delete" aria-label="close" type="button" (click)="close()"></button>
        </div>
    </div>
    <div class="modal-card">
        <img [src]="image" alt="" />
    </div>
</div>
