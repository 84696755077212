import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MapLocationDto } from '../../core/dto/map-location';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { IsavMap } from '../../map/map';
import Overlay from 'ol/Overlay';
import { fromMapEvent } from '../../map/util/from-map-event';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import OverlayPositioning from 'ol/OverlayPositioning';
import { Maybe } from 'true-myth';
import { STYLES } from '../../map/features/marker';
import { toStringXY } from 'ol/coordinate';

@UntilDestroy()
@Component({
    selector: 'isav-map-location-search-highlight',
    templateUrl: './map-location-search-highlight.html',
    styleUrls: ['./map-location-search-highlight.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavMapLocationSearchHighlight implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('overlayElement', { static: true }) overlayRef: ElementRef<HTMLDivElement>;
    location?: MapLocationDto;
    private olOverlay?: Overlay;

    private readonly layer = new VectorLayer({
        style: STYLES.marker(),
        source: new VectorSource<Point>({
            wrapX: true,
        }),
        zIndex: 100,
    });

    constructor(private readonly isavMap: IsavMap) {}

    get userFriendlyCoordinates(): string {
        if (!this.location) return '';
        return toStringXY([this.location.latitude, this.location.longitude], 5);
    }

    ngOnInit() {
        this.isavMap.map.addLayer(this.layer);
        this.isavMap.viewChanged.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.location) {
                this.mark(this.location);
                if (this.olOverlay?.getPosition()) {
                    const coords = this.isavMap.toMapCoordinate([
                        this.location.longitude,
                        this.location.latitude,
                    ]);
                    this.olOverlay?.setPosition(coords);
                }
            }
        });
    }

    ngAfterViewInit() {
        this.olOverlay = new Overlay({
            element: this.overlayRef.nativeElement,
            offset: [0, -32],
            positioning: OverlayPositioning.BOTTOM_CENTER,
        });
        this.isavMap.map.addOverlay(this.olOverlay);

        fromMapEvent(this.isavMap.map, 'click')
            .pipe(untilDestroyed(this))
            .subscribe((e: any) => {
                const layerFilter = (layer) => layer === this.layer;
                const features = this.isavMap.map.getFeaturesAtPixel(e.pixel, {
                    layerFilter,
                }) as Feature<Point>[];
                const coordinate = Maybe.of(features[0])
                    .map((f) => f.getGeometry().getCoordinates())
                    .unwrapOr(undefined as any); // we want Coordinate | undefined
                this.olOverlay?.setPosition(coordinate);
            });
    }

    ngOnDestroy() {
        this.olOverlay?.dispose();
        this.layer.dispose();
    }

    zoomAndMark(location: MapLocationDto): void {
        const coordinates = this.isavMap.toMapCoordinate([location.longitude, location.latitude]);
        this.location = location;
        this.mark(this.location);
        this.isavMap.view.setCenter(coordinates);
        this.isavMap.view.setZoom(8);
        this.olOverlay?.setPosition(coordinates);
    }

    mark(location: MapLocationDto): void {
        const coordinates = this.isavMap.toMapCoordinate([location.longitude, location.latitude]);
        this.layer.getSource().clear();
        this.layer.getSource().addFeature(new Feature<Point>(new Point(coordinates)));
    }
}
