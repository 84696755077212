<a
    isavBtn
    color="primary"
    [outlined]="true"
    size="small"
    class="apply-for-support has-small-padding has-text-weight-bold mr-1"
    routerLink="/pages/view"
    [queryParams]="{ iri: '/api/pages/2e3bd6a4-0cd5-4426-a82f-c5dec675774a'}"
>
    <isav-icon icon="plus"></isav-icon>
    <span>Apply for support</span>
</a>

<ng-container *ngIf="allowedActions$ | async as allowed">
    <div *ngIf="allowed.length > 0">
        <div class="buttons has-addons has-small-padding">
            <a
                *ngIf="promotedAction$ | async as promotedAction"
                isavBtn
                color="success"
                size="small"
                class="has-text-weight-bold"
                [routerLink]="promotedAction.link"
            >
                <isav-icon icon="plus"></isav-icon>
                <span>{{ promotedAction.text }}</span>
            </a>
            <button
                isavBtn
                color="success"
                size="small"
                [isavDropdownTriggerFor]="actionsMenu"
                [isRight]="true"
                class="has-text-weight-bold has-text-left"
                aria-label="Expand more options"
            >
                <isav-icon icon="chevron-down" [tight]="true"></isav-icon>
            </button>
        </div>
    </div>
    <isav-dropdown #actionsMenu="isavDropdown">
        <a *ngFor="let action of actions$ | async" [routerLink]="action.link" isavDropdownItem>
            {{ action.text }}
        </a>
    </isav-dropdown>
</ng-container>
