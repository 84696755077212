<isav-select
    [id]="id"
    [placeholder]="placeholder"
    [value]="value"
    (valueChange)="onChange($event)"
    [multiple]="multiple"
    (touched)="onTouched()"
    [dropdownInBody]="dropdownInBody"
    [short]="short"
>
    <ng-container *ngFor="let taxonomy of taxonomies; trackBy: taxonomyTrackBy">
        <isav-option
            [class.has-text-weight-bold]="isTaxonomyTypeWithChildren"
            [value]="taxonomy['@id']"
            [disabled]="!!(taxonomy.children?.length && disableParents)"
        >
            {{ taxonomy.title || taxonomy.name }}
        </isav-option>
        <isav-optgroup *ngIf="taxonomy.children?.length">
            <isav-option
                *ngFor="let child of taxonomy.children; trackBy: taxonomyTrackBy"
                [value]="child['@id']"
            >
                {{ child.title || child.name }}
            </isav-option>
        </isav-optgroup>
    </ng-container>
</isav-select>
