import { Portal } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation,
    NgZone,
} from '@angular/core';
import { MapBrowserEvent, Overlay } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Point } from 'ol/geom';
import OverlayPositioning from 'ol/OverlayPositioning';
import { compose, flatten, map } from 'ramda';
import { IsavMap } from '../map';
import { IsavMapOverlayContent } from './popup';
import { Coordinate } from 'ol/coordinate';

@Component({
    selector: 'isav-map-overlay-handler',
    templateUrl: './handler.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavMapOverlayHandler implements AfterViewInit, OnDestroy {
    @ViewChild('popup', { static: true }) popupEl: ElementRef<HTMLDivElement>;
    popupFeatures: Portal<any>[] = [];
    private _overlay: Overlay;

    constructor(
        private isavMap: IsavMap,
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone
    ) {}

    ngAfterViewInit(): void {
        this._overlay = new Overlay({
            element: this.popupEl.nativeElement,
            positioning: OverlayPositioning.BOTTOM_CENTER,
            stopEvent: false,
            offset: [0, -10],
        });

        this.isavMap.map.addOverlay(this._overlay);
        this.isavMap.map.addEventListener('click', this.handleClick);
    }

    ngOnDestroy(): void {
        this.isavMap.map.removeOverlay(this._overlay);
        this.isavMap.map.removeEventListener('click', this.handleClick);
    }

    handleClick = (event: MapBrowserEvent) => {
        const getFeatures = compose(
            flatten,
            map<FeatureLike, FeatureLike | FeatureLike[]>((f) => f.get('features') || f)
        );

        const featuresAtPixel = this.isavMap.map.getFeaturesAtPixel(event.pixel);
        const features = getFeatures(featuresAtPixel);
        const addedFeatureSet = new Set<any>();
        const newFeatures: Portal<any>[] = [];
        let newCoordinate: Coordinate;

        if (features.length > 0) {
            newCoordinate = (featuresAtPixel[0]?.getGeometry() as Point).getCoordinates();
            const overlayContents: IsavMapOverlayContent[] = features
                .map((f) => f.get('isavMapOverlayContent'))
                .filter(Boolean);
            overlayContents.forEach((overlay) => {
                const uuid = overlay.getUniqueIdentifier();
                const has = addedFeatureSet.has(uuid);
                if (!uuid || !has) newFeatures.push(overlay.getPortal());
                if (uuid) addedFeatureSet.add(uuid);
            });
        }

        // to allow interaction with overlay we delay change
        this.ngZone.runGuarded(() => {
            setTimeout(() => {
                this.popupFeatures = newFeatures;
                if (newFeatures.length) {
                    this._overlay.setPosition(newCoordinate);
                } else {
                    this._overlay.setPosition(undefined);
                }
                this.cdRef.markForCheck();
            });
        });
    };
}
