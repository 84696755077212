import {
    Directive,
    EmbeddedViewRef,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * Matches authentication status and either renders the template or else template if given
 *
 * Parameter passed to the directive decides whether to render this template when matching true
 * or false on the authentication state. You can provide else template to render when state is
 * not matched. By default when nothing passed matches true (so when user is authenticated)
 *
 * @example
 * <div *isavAuthenticated="true; else: notAuthenticated">
 *     I am rendered when authenticated
 * </div>
 * <ng-template #notAuthenticated>
 *     I am rendered when not authenticated
 * <ng-template>
 */
@UntilDestroy()
@Directive({
    selector: '[isavAuthenticated]',
})
export class MatchAuthenticatedDirective implements OnInit, OnDestroy {
    /**
     * Whether to match for true on is authenticated or false
     * @default true
     */
    @Input('isavAuthenticated') match = true;

    /** Else template to render when value is not matched */
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('isavAuthenticatedElse') elseTemplate?: TemplateRef<any>;

    private _renderedView: EmbeddedViewRef<any> | null = null;

    constructor(
        private readonly _templateRef: TemplateRef<any>,
        private readonly _authService: AuthService,
        private readonly _viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit() {
        this._authService
            .authenticated$()
            .pipe(
                untilDestroyed(this),
                map((isAuthenticated) => this.match === isAuthenticated)
            )
            .subscribe((isAuthenticated) => {
                if (isAuthenticated) {
                    this.render();
                } else {
                    this.renderElse();
                }
            });
    }

    ngOnDestroy() {
        this.clearView();
    }

    render() {
        this.clearView();
        this._renderedView = this._viewContainerRef.createEmbeddedView(this._templateRef);
    }

    renderElse() {
        this.clearView();
        if (!this.elseTemplate) return;

        this._renderedView = this._viewContainerRef.createEmbeddedView(this.elseTemplate);
    }

    clearView() {
        this._renderedView?.destroy();
        this._renderedView = null;
    }
}
