<div
    class="image-display"
    (click)="showButtons = true"
    (isavClickedOutside)="showButtons = false"
    tabindex="0"
    aria-label="edit or remove image"
>
    <img [isavApiImg]="this.imageIri" />
    <button
        data-testid="btn-remove"
        isavBtn
        color="danger"
        [class.image-display-left-button]="editable"
        [class.image-display-center-button]="!editable"
        (click)="onImageRemove()"
        [class.is-shown]="showButtons"
        tabindex="0"
    >
        Remove
    </button>
    <button
        *ngIf="editable"
        data-testid="btn-edit"
        isavBtn
        color="warning"
        class="image-display-right-button"
        (click)="onImageEdit()"
        [class.is-shown]="showButtons"
        tabindex="0"
    >
        Edit
    </button>
</div>
