import { Injectable } from '@angular/core';
import { Maybe } from 'true-myth';
import jwt_decode from 'jwt-decode';

export interface TokenPayload {
    iat: number;
    exp: number;
    roles: string[];
    email: string;
    personIri: string;
}

@Injectable({ providedIn: 'root' })
export class TokenDecoder {
    decode(token: string): TokenPayload {
        return jwt_decode<TokenPayload>(token);
    }

    maybeDecode(token: string): Maybe<TokenPayload> {
        try {
            return Maybe.of(this.decode(token));
        } catch (e) {
            console.error(e);
            return Maybe.nothing();
        }
    }
}
