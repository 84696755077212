import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthCheckOnVisibilityChangeService {
    constructor(private readonly authService: AuthService, private readonly router: Router) {}

    register() {
        fromEvent(document, 'visibilitychange')
            .pipe(filter(() => document.visibilityState === 'visible'))
            .subscribe(() => this.checkForTokenValidity());
    }

    private checkForTokenValidity() {
        if (this.authService.isAuthenticated() && !this.authService.isTokenValid()) {
            this.authService.logout();
            this.router.navigate(['/sign-in'], { queryParams: { returnUrl: this.router.url } });
        }
    }
}
