import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UploadService } from '../../../core/api/upload.service';
import { DocumentDto } from '../../../core/dto/document';
import { NotificationService } from '../../../core/services/notification.service';
import { DialogManager } from '../../../shared/dialog/dialog.service';

class HttpFileUploadErrorResponse extends HttpErrorResponse {
    file: File;
    fileIndex: number;
}

@UntilDestroy()
@Component({
    selector: 'isav-document-uploader-dialog',
    templateUrl: './document-uploader-dialog.html',
    styleUrls: ['./document-uploader-dialog.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavDocumentUploaderDialog implements OnInit {
    files: File[];
    names: string[];
    progress: number[];
    uploading = false;

    constructor(
        private dialogManager: DialogManager<File[], DocumentDto[]>,
        private uploadService: UploadService,
        private notificationService: NotificationService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (!this.dialogManager.data || this.dialogManager.data.length < 1) {
            this.close([]);
            throw new Error('Opened IsavDocumentUploaderDialog without any files passed in!');
        }

        this.files = this.dialogManager.data;
        this.names = this.files.map((file) => file.name);
        this.progress = this.files.map(() => 0);
    }

    upload() {
        this.uploading = true;
        const uploads = this.files.map((file, index) => {
            const upload = this.uploadService.uploadDocument(file, this.names[index]);
            return upload.pipe(
                tap((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.progress[index] = Math.round((100 * event.loaded) / event.total!);
                    } else if (event.type === HttpEventType.Response) {
                        this.progress[index] = 100;
                    }

                    this.cdRef.markForCheck();
                }),
                catchError((err: HttpFileUploadErrorResponse) => {
                    err.file = file;
                    err.fileIndex = index;
                    return throwError(err);
                })
            );
        });

        forkJoin(uploads)
            .pipe(untilDestroyed(this))
            .subscribe(
                (responses: Array<HttpResponse<DocumentDto>>) => {
                    const documents: DocumentDto[] = responses.map((response) => response.body!);
                    this.close(documents);
                },
                (error: HttpFileUploadErrorResponse) => {
                    this.uploading = false;
                    this.cdRef.markForCheck();

                    this.notificationService.add({
                        title: `Failed to upload file!`,
                        message: this.errorNotificationMessage(error),
                        type: 'danger',
                        delay: 10000,
                    });
                }
            );
    }

    filesTrackBy(index: number, file: File): any {
        return file;
    }

    close(documents: DocumentDto[] = []): void {
        this.dialogManager.close(documents);
    }

    remove(index: number): void {
        if (this.files.length <= 1) throw new Error('Cannot remove last file from upload!');

        const indexFilter = (_, i) => i !== index;
        this.files = this.files.filter(indexFilter);
        this.names = this.names.filter(indexFilter);
        this.progress = this.progress.filter(indexFilter);
    }

    private errorNotificationMessage(error: HttpFileUploadErrorResponse): string {
        const fileName = this.names[error.fileIndex];
        switch (error.status) {
            case 413:
                return `File ${fileName} is too large.`;
            default:
                return `Unknown error occurred when uploading ${fileName}.`;
        }
    }
}
