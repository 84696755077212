import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogManager } from '../../../shared/dialog/dialog.service';

@Component({
    selector: 'isav-changes-warning-popup',
    templateUrl: './changes-warning-popup.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangesWarningPopup {
    constructor(private readonly dialogManager: DialogManager<undefined, boolean>) {}

    close(confirmed: boolean) {
        this.dialogManager.close(confirmed);
    }
}
