<isav-map-location-search-control
    (mapLocation)="zoomAndMark($event)"
></isav-map-location-search-control>

<div class="isav-map-popup" #overlayElement>
    <div class="isav-map-popup__content" *ngIf="location">
        <p class="mb-0 has-text-primary has-text-weight-bold">{{ location.name }}</p>
        <span class="help">{{ userFriendlyCoordinates }}</span>
    </div>
</div>
