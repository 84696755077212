import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { FieldTooltipDto } from '../dto/field-tooltip';
import { Iri } from '../dto/iri';
import { Observable } from 'rxjs';

@Injectable()
export class TooltipsResourceService extends BaseSearchService<FieldTooltipDto> {
    constructor(http: HttpClient) {
        super('field_tooltips', http);
    }

    patch(iri: Iri, data: Partial<FieldTooltipDto>): Observable<FieldTooltipDto> {
        const headers = new HttpHeaders().append('Content-Type', 'application/merge-patch+json');
        return this.http.patch<FieldTooltipDto>(iri, data, { headers });
    }
}
