import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MetadataService } from '../../core/services/metadata.service';
import { IsavValidators } from '../../utils/forms/validators';

@Component({
    selector: 'isav-password-recovery',
    templateUrl: './reset-password.html',
    styleUrls: ['./reset-password.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordPage implements OnInit {
    backendError: string = '';
    formGroup: UntypedFormGroup;
    loading = false;
    email: string;
    private token: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private metadataService: MetadataService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.metadataService.setTitle('Reset password');

        const { email, token } = this.route.snapshot.queryParams;
        this.email = email;
        this.token = token;

        this.formGroup = new UntypedFormGroup(
            {
                password: new UntypedFormControl('', [
                    IsavValidators.required('Type new password!'),
                ]),
                passwordRepeat: new UntypedFormControl('', [
                    IsavValidators.required('You need to repeat your password!'),
                ]),
            },
            { validators: [this.passwordMinLength, this.matchPassword] }
        );
    }

    changePassword() {
        if (!this.formGroup.valid) return;
        const { password } = this.formGroup.value;

        this.loading = true;
        this.http.post(`/api/person/reset-password`, { password, token: this.token }).subscribe(
            () => {
                this.loading = false;
                this.backendError = '';
                this.cdRef.markForCheck();
                this.router.navigate(['/sign-in']);
            },
            (err) => {
                console.error(err);
                this.backendError = err.error?.['hydra:description'] ?? '';
                this.loading = false;
                this.cdRef.markForCheck();
            }
        );
    }

    passwordMinLength = (control: AbstractControl): { passwordMinLength: string } | null => {
        const MIN_LENGTH = 8;
        const { password } = control.value;
        return password.length < MIN_LENGTH
            ? { passwordMinLength: 'Your new password needs to be at least 8 characters long!' }
            : null;
    };

    matchPassword = (control: AbstractControl): { matchPassword: string } | null => {
        const { password, passwordRepeat } = control.value;
        return password && password === passwordRepeat
            ? null
            : { matchPassword: 'Passwords do not match!' };
    };
}
