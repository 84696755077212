import { createAction, props } from '@ngrx/store';
import { Payload } from '../../utils/store';
import { ApiResourceDto } from '../dto/api-resource';
import { Iri } from '../dto/iri';

export const storeTaxonomy = createAction('taxonomies::store', props<Payload<ApiResourceDto>>());
export const storeTaxonomies = createAction(
    'taxonomies::store-many',
    props<{ taxonomies: ApiResourceDto[] }>()
);
export const patchTaxonomy = createAction(
    'taxonomies::patch',
    props<{
        iri: Iri;
        data: any;
    }>()
);
