import { Directive, OnDestroy, OnInit } from '@angular/core';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { IsavMap } from '../map';
import OLMap from 'ol/Map';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'isav-map-world-layer',
})
export class IsavMapOsmWorldLayer implements OnInit, OnDestroy {
    private readonly map: OLMap;
    private readonly layer = new TileLayer({
        source: new OSM(),
    });

    constructor(isavMap: IsavMap) {
        this.map = isavMap.map;
    }

    ngOnInit(): void {
        this.map.addLayer(this.layer);
    }

    ngOnDestroy(): void {
        this.map.removeLayer(this.layer);
        this.layer.dispose();
    }
}
