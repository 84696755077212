import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    NgZone,
    ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { relativeParent } from '../utilities/relative-parent';

@UntilDestroy()
@Component({
    selector: 'isav-loader-overlay',
    templateUrl: './loader-overlay.html',
    styleUrls: ['./loader-overlay.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavLoaderOverlay implements AfterViewInit {
    _parent?: HTMLElement | null;
    @ViewChild('loader', { static: true, read: ElementRef }) loader: ElementRef;

    constructor(private _ngZone: NgZone, private _elementRef: ElementRef) {}

    ngAfterViewInit() {
        this._ngZone.runOutsideAngular(() => {
            this._parent = relativeParent(this._elementRef.nativeElement);
            fromEvent(window, 'scroll', { passive: true })
                .pipe(untilDestroyed(this))
                .subscribe(this.reposition);
            this.reposition();
        });
    }

    private reposition = () => {
        if (!this._parent) return;

        const parentRect = this._parent.getBoundingClientRect();
        const parentVisibleHeight =
            window.innerHeight -
            Math.max(0, parentRect.top) -
            Math.max(0, window.innerHeight - parentRect.bottom);
        const parentInvisibleHeight = Math.min(0, parentRect.top) * -1;
        const maxTop = parentRect.height - 40;
        const top = Math.min(maxTop, parentVisibleHeight / 2 + parentInvisibleHeight);
        this.loader.nativeElement.style.top = top + 'px';
    };
}
