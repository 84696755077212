<div *ngIf="content" role="region" [attr.id]="id" [attr.aria-expanded]="false">
    <isav-smooth-height [trigger]="expanded">
        <p *ngIf="!expanded; else wholeText">
            {{content | isavFirstParagraph}}
            <a role="button" [attr.aria-controls]="id" (click)="expanded = true"> Read more </a>
        </p>
    </isav-smooth-height>
</div>
<ng-template #wholeText>
    <div class="content" [innerHTML]="safeContent"></div>
    <a role="button" [attr.aria-controls]="id" (click)="expanded = false"> Collapse </a>
</ng-template>
