import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SarSarReadDto } from '../dto/sar-sar-read';
import { BaseSearchService } from './base-search-service';

@Injectable()
export class SarsResourceService extends BaseSearchService<SarSarReadDto> {
    constructor(http: HttpClient) {
        super('sars', http);
    }
}
