<div class="modal is-active" (isavDialog)="close()">
    <div class="modal-background">
        <div class="has-text-right mt-5 mr-5">
            <button class="delete" aria-label="close" type="button" (click)="close()"></button>
        </div>
        <button
            *ngIf="current > 0 && images.length > 1"
            aria-label="Change image to the previous one"
            (click)="prev()"
            class="arrow arrow-prev"
        >
            <isav-icon icon="chevron-left" aria-hidden="true"></isav-icon>
        </button>
        <button
            *ngIf="current < images.length - 1 && images.length > 1"
            aria-label="Change image to the next one"
            (click)="next()"
            class="arrow arrow-next"
        >
            <isav-icon icon="chevron-right" aria-hidden="true"></isav-icon>
        </button>
    </div>
    <div class="modal-card">
        <img [src]="image" alt="" (load)="setLoaded(true)" [ngClass]="{loading: !imageLoaded}" />
        <isav-loader class="loader" *ngIf="!imageLoaded"></isav-loader>
    </div>
</div>
