import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MapCenterService {
    private readonly centerChanged$ = new Subject<[number, number]>();

    get centerChanged() {
        return this.centerChanged$.asObservable();
    }

    changeCenter(long: number, lat: number): void {
        this.centerChanged$.next([long, lat]);
    }
}
