import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    forwardRef,
    Injector,
    ChangeDetectorRef,
    Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IsavBaseControl } from '../base-control';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'isav-select-input',
    templateUrl: './select-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavSelectInput),
            multi: true,
        },
    ],
    host: {
        '[attr.id]': 'null',
    },
})
export class IsavSelectInput extends IsavBaseControl {
    @Input() placeholder = '';
    @Input() short: boolean;
    @Input() dropdownInBody: boolean;
    @Input() options: { value: any; text: string }[];

    value: any | any[] | null;
    private _multiple = false;

    constructor(_injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    @Input() set multiple(value: boolean | string) {
        this._multiple = coerceBooleanProperty(value);
        if (this.value === null && this._multiple) this.value = [];
    }

    get multiple(): boolean | string {
        return this._multiple;
    }

    writeValue(obj: any): void {
        this.value = obj;
        this._cdRef.markForCheck();
    }

    onChange(value: any) {
        this.value = value;
        this._onChange(value);
        this._cdRef.markForCheck();
    }

    onTouched() {
        this._onTouched();
    }
}
