<div class="columns marker">
    <div class="column is-3 has-text-centered">
        <isav-map *ngIf="marker.location" [initialZoom]="6">
            <isav-map-vector-layer>
                <isav-map-marker
                    [coordinate]="marker.location | isavMapCoordinate"
                    display="marker"
                    [center]="true"
                ></isav-map-marker>
            </isav-map-vector-layer>
        </isav-map>
    </div>
    <div class="column is-6">
        <p class="title is-5 mt-5">{{ marker.title }}</p>
        <p>{{ marker.startDate | isavDate }}-{{ marker.endDate | isavDate }}</p>
        <p><strong>Reference number:&nbsp;</strong>{{ marker.referenceNumber }}</p>
        <p data-testid="location"><strong>Location:&nbsp;</strong>{{ marker.location?.name }}</p>
        <p data-testid="coordinates">
            <strong>Coordinates:</strong>
            {{ marker.location | isavGeoCoordinates }}
        </p>
        <p>{{ marker.description }}</p>
    </div>
    <div class="column is-3 create-info">
        <p class="has-text-weight-bold has-text-centered">Created by</p>
        <p class="has-text-centered">
            <strong> {{ marker.author?.fullName }} </strong>
            {{ marker.createdAt | isavDate }}
        </p>
        <p class="is-flex mt-5">
            <a data-testid="delete-marker" class="has-text-centered mr-2" (click)="onDelete()"
                >Delete marker</a
            >
            <a
                data-testid="edit-marker"
                class="has-text-centered ml-2"
                [routerLink]="editLink()"
                [queryParams]="{ iri: marker['@id'] }"
            >
                Edit marker
            </a>
        </p>
    </div>
</div>
