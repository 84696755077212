import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { typeFromIri } from '../../core/type-from-iri';

@UntilDestroy()
@Component({
    selector: 'isav-content-view',
    templateUrl: './content-view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentView implements OnInit {
    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
            const iri = params.iri;
            if (!iri) return this.router.navigate(['/']);
            const type = typeFromIri(iri).replace(/_/g, '-');
            if (type === 'how-to-guides') {
                // There is no separate view page for how to guide
                return this.router.navigate(['how-to-guide'], { queryParams: { iri: iri } });
            }
            return this.router.navigate([`${type}/view`], { queryParams: { iri: iri } });
        });
    }
}
