import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Optional,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { prop } from 'ramda';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Iri } from '../../../core/dto/iri';
import {
    groupTaxonomyWithChildren,
    GroupedTaxonomyChildren,
} from '../../../core/taxonomies/group-taxonomy-with-children';
import { TaxonomiesService } from '../../../core/taxonomies/taxonomies.service';
import { TaxonomyTypes } from '../../../core/taxonomies/taxonomy-types';
import {
    applyTaxonomyRestriction,
    IsavRestrictTaxonomies,
} from '../restrict-taxonomies/restrict-taxonomies';

let UNIQUE_ID = 0;

export interface ApiTaxonomy {
    readonly '@id': string;
    readonly name?: string;
    readonly title?: string;
    readonly parent?: Iri | null;
    readonly children?: Iri[];
}

// eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
function noop() {}

@UntilDestroy()
@Component({
    selector: 'isav-taxonomy-select',
    templateUrl: './taxonomy-select.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavTaxonomySelect),
            multi: true,
        },
    ],
    host: {
        '[attr.id]': 'null',
    },
})
export class IsavTaxonomySelect implements ControlValueAccessor, OnInit, OnChanges {
    @Input() taxonomy: TaxonomyTypes;
    @Input() id = 'taxonomy-select-' + UNIQUE_ID++;
    @Input() placeholder = '';
    @Input() disableParents = false;
    @Input() dropdownInBody: boolean;
    @Input() short: boolean;

    value: null | Iri | Iri[];
    taxonomies: GroupedTaxonomyChildren<ApiTaxonomy>[] = [];
    private _multiple = false;
    private _onChange: any = noop;
    private _onTouched: any = noop;

    constructor(
        private taxonomiesService: TaxonomiesService,
        private cdRef: ChangeDetectorRef,
        @Optional() private restrictTaxonomies?: IsavRestrictTaxonomies
    ) {}

    @Input() set multiple(value: boolean | string) {
        this._multiple = coerceBooleanProperty(value);
        if (this.value === null && this._multiple) this.value = [];
    }

    get multiple(): boolean | string {
        return this._multiple;
    }

    get isTaxonomyTypeWithChildren(): boolean {
        return this.taxonomies.some((taxonomy) => !!taxonomy.children?.length);
    }

    ngOnInit() {
        this.reloadTaxonomies();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.taxonomy && !changes.taxonomy.isFirstChange()) {
            this.reloadTaxonomies();
        }
    }

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.cdRef.markForCheck();
    }

    onChange(value: any) {
        this.value = value;
        this._onChange(value);
        this.cdRef.markForCheck();
    }

    private reloadTaxonomies() {
        const taxonomies$ = this.taxonomiesService.getTaxonomiesByType(this.taxonomy) as Observable<
            ApiTaxonomy[]
        >;

        taxonomies$
            .pipe(
                applyTaxonomyRestriction(this.restrictTaxonomies, prop('@id')),
                map(groupTaxonomyWithChildren),
                untilDestroyed(this)
            )
            .subscribe((taxonomies) => {
                this.taxonomies = taxonomies;
                this.cdRef.markForCheck();
            });
    }

    onTouched() {
        this._onTouched();
    }

    taxonomyTrackBy(index: number, item: GroupedTaxonomyChildren<ApiTaxonomy>): string {
        return item['@id'];
    }
}
