import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedSearch } from './advanced-search';
import { AdvancedSearchFilters } from './advanced-search-filters';
import { AdvancedSearchListView } from './advanced-search-list-view';
import { AdvancedSearchMapView } from './advanced-search-map-view';
import { AdvancedSearchCardView } from './advanced-search-card-view';
import { AdvancedSearchTimelineView } from './advanced-search-timeline-view';
import { AdvancedSearchTitle } from './advanced-search-title';
import { SharedModule } from '../../shared/shared.module';
import { IsavCommonAuthModule } from '../../common-auth/common-auth.module';
import { MaxSizeToScreenDirective } from './max-size-to-screen.directive';

const EXPORTED_DECLARATIONS = [
    AdvancedSearch,
    AdvancedSearchFilters,
    AdvancedSearchTitle,
    AdvancedSearchListView,
    AdvancedSearchMapView,
    AdvancedSearchTimelineView,
    AdvancedSearchCardView,
];

const INNER_DECLARATIONS = [MaxSizeToScreenDirective];

@NgModule({
    declarations: [EXPORTED_DECLARATIONS, INNER_DECLARATIONS],
    exports: [EXPORTED_DECLARATIONS],
    imports: [CommonModule, SharedModule, PortalModule, IsavCommonAuthModule],
})
export class AdvancedSearchModule {}
