import { CanDisableCtor, mixinDisabled } from '../common-behaviours/disabled';
import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

/** @ignore */
class ItemBase {}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
const ItemMixinBase: typeof ItemBase & CanDisableCtor = mixinDisabled(ItemBase);

@Directive({
    selector: '[isavDropdownItem]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'dropdown-item',
        '[attr.role]': 'role',
        tabindex: '-1',
        '(click)': 'handleClick($event)',
        '(focus)': 'handleFocus()',
    },
})
export class IsavDropdownItem extends ItemMixinBase {
    @Input() role = 'menuitem';
    @Output() focused = new EventEmitter<void>();

    constructor(private _elementRef: ElementRef) {
        super();
    }

    focus() {
        this._elementRef.nativeElement?.focus();
    }

    handleClick(event: MouseEvent) {
        if (this.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    handleFocus() {
        this.focused.emit();
    }
}
