import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationStart, Router, NavigationEnd } from '@angular/router';
import { ViewportObserver } from '../../shared/viewport/viewport';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../core/auth/auth.service';
import { NotificationService } from '../../core/services/notification.service';

@UntilDestroy()
@Component({
    selector: 'isav-mobile-navigation',
    templateUrl: './mobile-navigation.html',
    styleUrls: ['./mobile-navigation.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavigation implements OnInit {
    open = false;
    onMobileView = false;
    url: string;

    constructor(
        private viewport: ViewportObserver,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
        public router: Router,
        private notificationService: NotificationService
    ) {}

    get visible() {
        return this.open && this.onMobileView;
    }

    ngOnInit() {
        this.onMobileView = this.viewport.isTabletOrLess();
        this.url = this.router.url;
        this.viewport
            .isTabletOrLess$()
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.onMobileView = value;
                this.cdRef.markForCheck();
            });

        this.router.events
            .pipe(
                filter(
                    (event) => event instanceof NavigationStart || event instanceof NavigationEnd
                ),
                untilDestroyed(this)
            )
            .subscribe((ev) => {
                if (ev instanceof NavigationStart) {
                    this.toggle(false);
                }
                if (ev instanceof NavigationEnd) {
                    this.url = ev.url;
                    this.cdRef.markForCheck();
                }
            });
    }

    toggle(isOpen: boolean) {
        this.open = isOpen;
        this.cdRef.markForCheck();
    }

    logout() {
        this.authService.logout();
        this.notificationService.add({
            title: 'Success',
            message: 'Successfully logged out',
            type: 'success',
            delay: 5000,
        });
    }

    isProjects() {
        if (this.url === '/' || this.url.includes('/projects')) return true;
        return false;
    }
}
