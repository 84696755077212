import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: 'img[isavLogo]',
})
export class IsavLogo {
    @HostBinding('attr.alt') @Input() alt = 'Isaaffik logo';

    @HostBinding('attr.src') readonly src = '/assets/isaaffik-logo.png';

    @HostBinding('attr.srcset') readonly srcset =
        '/assets/isaaffik-logo.png 1x, /assets/isaaffik-logo@2x.png 2x';

    @HostBinding('attr.aria-hidden') readonly ariaHidden = true;

    @HostBinding('class.isaaffik-logo') readonly isaaffikLogoClass = true;
}
