import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { MapLocationDto } from '../../core/dto/map-location';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Hydra, HydraProperties } from '../../core/dto/hydra';
import { map, pluck } from 'rxjs/operators';
import { prop, sortBy } from 'ramda';

@Injectable()
export class MapLocationSearchService implements OnDestroy {
    private readonly results$ = new ReplaySubject<MapLocationDto[]>(1);
    private readonly cancel$ = new Subject<void>();

    constructor(private readonly http: HttpClient) {}

    get results() {
        return this.results$.asObservable();
    }

    ngOnDestroy() {
        this.cancel$.next();
        this.cancel$.complete();
        this.results$.complete();
    }

    cancel() {
        this.cancel$.next();
    }

    search(searchText: string): void {
        this.cancel$.next();
        const params = new HttpParams()
            .append('name', searchText)
            .append('page', '1')
            .append('pagination', '1')
            .append('itemsPerPage', '20');
        const sortByName = sortBy(prop('name'));
        this.http
            .get<HydraProperties<MapLocationDto>>(`/api/map_locations`, { params })
            .pipe(map(Hydra.from), pluck('member'), map(sortByName))
            .subscribe((res) => this.results$.next(res));
    }
}
