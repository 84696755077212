import { format, parse } from 'date-fns';

/**
 * Formats date in format YYYY-MM-DD
 * @param date - date to format
 */
export function formatStandardDate(date: Date): string {
    return format(date, 'yyyy-MM-dd');
}

export function formatStandardDateTime(date: Date): string {
    return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
}

/**
 * Formats date in format DD/MM/YYYY
 * @param date - date to format
 */
export function formatIsavDate(date: Date): string {
    return format(date, 'dd/MM/yyyy');
}

/**
 * Formats date in format DD/MM/YYYY HH:MM:SS
 * @param date - date to format
 */
export function formatIsavDateTime(date: Date): string {
    return format(date, 'dd/MM/yyyy HH:mm:ss');
}

/**
 * returns current date formatted as YYYY-MM-DD
 * @return {string}
 */
export function getToday() {
    return formatStandardDate(new Date());
}

export function parseIsavDate(date: string): Date {
    return parse(date, 'dd/MM/yyyy', new Date());
}

export function parseStandardDate(date: string): Date {
    return parse(date, 'yyyy-MM-dd', new Date());
}
