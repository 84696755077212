import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Iri } from '../../../core/dto/iri';
import { MarkerColor } from '../../../core/dto/marker-color-enum';
import { TypeDto } from '../../../core/dto/type';
import {
    GroupedTaxonomyChildren,
    groupTaxonomyWithChildren,
} from '../../../core/taxonomies/group-taxonomy-with-children';
import { TaxonomiesService } from '../../../core/taxonomies/taxonomies.service';
import { IsavOptionSelectedChange } from '../../../shared/select/option';
import { uniq } from 'ramda';

@Component({
    selector: 'isav-type-taxonomy-select',
    templateUrl: './type-taxonomy-select.html',
    styleUrls: ['./type-taxonomy-select.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[attr.id]': 'null',
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavTypeTaxonomySelect),
            multi: true,
        },
    ],
})
export class IsavTypeTaxonomySelect implements OnInit, ControlValueAccessor {
    @Input() id: string;
    @Input() multiple: boolean;
    @Input() dropdownInBody: boolean;
    @Input() placeholder: string;

    value: Iri[];
    disabled: boolean;
    types$: Observable<GroupedTaxonomyChildren<TypeDto>[]>;

    onChange: (value: Iri[]) => void = noop;
    onTouched: () => void = noop;

    constructor(private taxonomiesService: TaxonomiesService, private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.types$ = this.taxonomiesService
            .getTaxonomiesByType('Type')
            .pipe(map(groupTaxonomyWithChildren));
    }

    iriTrackBy(index: number, item: TypeDto): Iri {
        return item['@id'];
    }

    update(value: Iri[]): void {
        this.value = value;
        this.onChange(value);
    }

    writeValue(obj: any): void {
        this.value = [];
        if (Array.isArray(obj) && obj.every((iri) => typeof iri === 'string')) {
            this.value = obj;
        }

        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cdRef.markForCheck();
    }

    color(marker: number): string {
        return MarkerColor[marker || '1'].toLowerCase();
    }

    onParentSelect(type: GroupedTaxonomyChildren<TypeDto>, event: IsavOptionSelectedChange): void {
        if (!this.multiple) return; // only apply on multiple

        if (event.selected) {
            this.update(
                uniq([...this.value, event.origin.value, ...type.children!.map((t) => t['@id'])])
            );
        } else {
            const childrenIds = new Set(type.children!.map((t) => t['@id']));
            this.update(this.value.filter((id) => !childrenIds.has(id)));
        }
    }
}
