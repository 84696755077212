<div class="modal is-active" (isavDialog)="close()">
    <div class="modal-background"></div>
    <div *ngIf="!uploading else uploadingTemplate" class="modal-card">
        <div class="modal-card-head">
            <p class="modal-card-title">Upload documents</p>
            <button class="delete" aria-label="close" type="button" (click)="close()"></button>
        </div>
        <div class="modal-card-body">
            <div
                *ngFor="let file of files; trackBy: filesTrackBy; let index = index"
                class="columns is-mobile mb-0"
            >
                <div class="column is-1 document-icon">
                    <isav-file-icon [mimeType]="file.type"></isav-file-icon>
                </div>
                <div class="column is-10 document-name-input">
                    <input
                        aria-label="Document name"
                        type="text"
                        class="input is-fullwidth"
                        [value]="names[index]"
                        (input)="names[index] = $event.target.value"
                    />
                </div>
                <div class="column is-1 delete-button">
                    <button
                        isavBtn
                        color="danger"
                        (click)="remove(index)"
                        [disabled]="files.length < 2"
                    >
                        <isav-icon icon="trash"></isav-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-card-foot">
            <button isavBtn color="primary" [outlined]="true" (click)="close()">Cancel</button>
            <button isavBtn color="primary" (click)="upload()" data-testid="upload-btn">
                Upload
            </button>
        </div>
    </div>
</div>

<ng-template #uploadingTemplate>
    <div class="modal-card">
        <div class="modal-card-head">
            <p class="modal-card-title">Upload documents</p>
            <button class="delete" aria-label="close" type="button" (click)="close()"></button>
        </div>
        <div class="modal-card-body">
            <div
                *ngFor="let file of files; trackBy: filesTrackBy; let index = index"
                class="columns is-mobile mb-0"
            >
                <div class="column is-1 document-icon">
                    <isav-file-icon [mimeType]="file.type"></isav-file-icon>
                </div>
                <div class="column is-6 is-11-mobile uploading-presentation">
                    <p>{{ names[index] }}</p>
                </div>
                <div class="column is-5 is-12-mobile uploading-presentation">
                    <progress class="progress is-primary" [value]="progress[index]" [max]="100">
                        {{ progress[index] }}%
                    </progress>
                </div>
            </div>
        </div>
        <div class="modal-card-foot">
            <button isavBtn color="primary" [outlined]="true" (click)="close()">Cancel</button>
        </div>
    </div>
</ng-template>
