import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Location } from '@angular/common';

/**
 * This directive matches for active url segment and adds class if it matches
 */
@Directive({
    selector: '[isavActiveUrlSegment]',
})
export class IsavActiveUrlSegment implements OnInit, OnChanges, OnDestroy {
    @Input('isavActiveUrlSegment') activeUrlSegment = '';
    @Input() activeClass = 'is-active';

    private routeSub = Subscription.EMPTY;

    constructor(
        private router: Router,
        private location: Location,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        this.routeSub = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.location.path()),
                startWith(this.location.path()),
                map((path) => path.split('?')[0]), // we do not want query params
                map((pathname) => pathname.split('/').indexOf(this.activeUrlSegment) !== -1)
            )
            .subscribe((match) => {
                const classList = this.elementRef.nativeElement.classList;
                if (match) {
                    classList.add(this.activeClass);
                } else {
                    classList.remove(this.activeClass);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.activeClass) {
            const classList = this.elementRef.nativeElement.classList;
            classList.replace(changes.activeClass.previousValue, changes.activeClass.currentValue);
        }
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
