import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { NewsDto } from '../dto/news';

@Injectable()
export class NewsResourceService extends BaseSearchService<NewsDto> {
    constructor(http: HttpClient) {
        super('news', http);
    }
}
