<picture>
    <source
        [srcset]="'/assets/slideshow/' + image.name + '.jpg 1x, /assets/slideshow/' + image.name + '@2x.jpg 2x'"
        type="image/jpeg"
    />
    <source
        [srcset]="'/assets/slideshow/' + image.name + '.webp 1x, /assets/slideshow/' + image.name + '@2x.webp 2x'"
        type="image/webp"
    />
    <img class="is-covering" [src]="'/assets/slideshow/' + image.name + '.jpg'" alt="" />
</picture>
<div
    class="image-overlay"
    [class.placement-top]="image.placement === 'top'"
    [class.placement-bottom]="image.placement === 'bottom'"
>
    <div class="image-slogan">
        <p class="slogan-title">{{ image.sloganTitle }}</p>
        <p>{{ image.sloganText }}</p>
    </div>
</div>
