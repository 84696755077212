<a
    isavBtn
    color="primary"
    [outlined]="true"
    routerLink="../view"
    queryParamsHandling="preserve"
    data-testid="go-to-view"
    [disabled]="disabled"
>
    <ng-content></ng-content>
</a>
