<ng-container *ngIf="state">
    <div class="advanced-search-filters" [class.is-expanded]="expanded">
        <div class="filters-toggle">
            <button
                class="toggle-button"
                [attr.aria-label]="expanded ? 'Collapse search filters' : 'Expand search filters'"
                aria-controls="advanced-search-filters"
                (click)="toggle()"
            >
                <isav-icon
                    [icon]="expanded ? 'angle-double-left' : 'angle-double-right'"
                ></isav-icon>
            </button>
        </div>
        <div
            class="filters-content"
            id="advanced-search-filters"
            aria-label="Search filters"
            [attr.aria-expanded]="expanded.toString()"
            isavMaxSizeToScreen
        >
            <ng-container *ngIf="expanded; else searchIconTemplate">
                <ng-content select="isav-advanced-search-filters"></ng-content>
            </ng-container>
        </div>
    </div>

    <ng-template #searchIconTemplate>
        <span class="fas fa-search fa-2x" aria-hidden="true"></span>
    </ng-template>

    <div
        class="advanced-search__results"
        [class.is-expanded]="expanded"
        [class.is-collapsed]="!expanded"
    >
        <h1 class="title is-1">
            <ng-content select="isav-advanced-search-title"></ng-content>
        </h1>

        <div class="advanced-search__results__header level mb-4">
            <div class="level-left">
                <h2 class="title is-5 mb-0" data-testid="results">
                    Results
                    <span class="is-small advanced-search__results__count">
                        ({{ indexesShown }} out of {{state.results.totalItems}})
                    </span>
                </h2>
            </div>

            <div class="level-right">
                <div class="buttons has-addons" *ngIf="!hasOnlyListView">
                    <button
                        isavBtn
                        [color]="viewButtonColor(1)"
                        (click)="setViewMode(1)"
                        data-testid="list-view"
                    >
                        List
                    </button>
                    <button
                        *ngIf="hasCardView"
                        isavBtn
                        data-testid="card-view"
                        [color]="viewButtonColor(8)"
                        (click)="setViewMode(8)"
                    >
                        Card
                    </button>
                    <button
                        *ngIf="hasMapView"
                        isavBtn
                        [color]="viewButtonColor(2)"
                        (click)="setViewMode(2)"
                        data-testid="map-view"
                    >
                        Map
                    </button>
                    <button
                        *ngIf="hasTimelineView"
                        isavBtn
                        [color]="viewButtonColor(4)"
                        (click)="setViewMode(4)"
                        data-testid="timeline-view"
                    >
                        Timeline
                    </button>
                </div>

                <ng-container *isavAuthenticated="true">
                    <ng-container [ngSwitch]="csvMode">
                        <div *ngSwitchCase="'Admin'" class="buttons ml-2">
                            <ng-container *isavUserHasRole="['ROLE_ADMIN']">
                                <a isavBtn color="primary" (click)="onCsvClick()">CSV</a>
                            </ng-container>
                        </div>
                        <div *ngSwitchCase="'Users'" class="buttons ml-2">
                            <a isavBtn color="primary" (click)="onCsvClick()">CSV</a>
                        </div>
                        <div *ngSwitchDefault></div>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <div class="advanced-search__results__content">
            <div class="table-container" [style.overflow]="viewMode === 8 ? 'hidden' : 'auto'">
                <ng-template [cdkPortalOutlet]="renderedPortal"></ng-template>
            </div>
            <isav-loader-overlay *ngIf="state.loading"></isav-loader-overlay>
        </div>

        <isav-pagination
            *ngIf="state.results.totalItems > 0 && this.isNotOnMapView"
            [total]="state.results.totalItems"
            [queryPagination]="pagination"
            [perPage]="shownPerPage"
            [page]="page"
            [showAllEnable]="isOnListView ? true : false"
            (pageChange)="updatePage($event)"
            (all)="showAll()"
            (pages)="showPages()"
            class="mt-5"
        ></isav-pagination>
    </div>
</ng-container>
