import { Directive } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IsavFieldAccessor } from './field-accessor';

@Directive({
    selector: '[isavFieldIndex]',
})
export class IsavFieldIndexDirective {
    private readonly _fields$ = new BehaviorSubject<IsavFieldAccessor[]>([]);

    get fields$(): Observable<IsavFieldAccessor[]> {
        return this._fields$.asObservable();
    }

    registerField(fieldAccessor: IsavFieldAccessor) {
        this._fields$.next(this._fields$.getValue().concat([fieldAccessor]));
    }

    unregisterField(fieldAccessor: IsavFieldAccessor) {
        this._fields$.next(this._fields$.getValue().filter((f) => f !== fieldAccessor));
    }
}
