<div class="buttons has-addons is-flat">
    <a isavBtn color="primary" routerLink="/projects" [class.is-link]="isProjects()">Projects</a>
    <a isavBtn color="primary" routerLink="/infrastructures" routerLinkActive="is-link">
        Infrastructures
    </a>
    <a isavBtn color="primary" routerLink="/logistics" routerLinkActive="is-link">Logistics</a>
    <a isavBtn color="primary" routerLink="/datalinks" routerLinkActive="is-link">Data-Links</a>
    <a isavBtn color="primary" routerLink="/people" routerLinkActive="is-link">People</a>
    <a
        *isavUserHasRole="['ROLE_SAR', 'ROLE_ADMIN']"
        isavBtn
        color="primary"
        routerLink="/sar"
        [class.is-link]="isSar()"
        [queryParams]="{ '__view': '3' }"
        >SAR</a
    >
</div>
