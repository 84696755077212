<ng-container *isavAuthenticated="true">
    <ng-container *ngIf="domainEventJournal$ | async; let list">
        <div class="mt-5 mb-5" *ngIf="list.length > 0">
            <h2 class="title is-6">Last updated</h2>
            <div class="domain-event-journal-list mb-2">
                <ng-container *ngFor="let event of list; let i = index; let even = even">
                    <isav-domain-event-journal
                        *ngIf="i < shownUpdates"
                        [event]="event"
                        [class.has-background-blueish]="even"
                        class="is-block"
                    >
                    </isav-domain-event-journal>
                </ng-container>
            </div>
            <p class="has-text-right" *ngIf="showAll">
                <a *ngIf="list.length > shownUpdates" (click)="shownUpdates = list.length"
                    >Show all updates ({{ list.length }})</a
                >
                <a *ngIf="shownUpdates > 1" (click)="shownUpdates = 1">Hide</a>
            </p>
        </div>
    </ng-container>
</ng-container>
