import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

/**
 * Global dialog focus service that will trap focus inside the dialog when it is created and trap
 * it only in topmost created dialog, to ensure that multiple dialogs can be open at the same time.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class DialogFocusService {
    private dialogStack: Array<HTMLElement> = [];
    private previouslyFocused = new Map<HTMLElement, Element>();
    private lastFocusedElement: null | Element = null;
    private disableListener = false;
    private readonly preNode: HTMLDivElement;
    private readonly postNode: HTMLDivElement;

    constructor(@Inject(DOCUMENT) private document: Document) {
        this.preNode = document.createElement('div');
        this.postNode = document.createElement('div');
        this.preNode.tabIndex = 0;
        this.postNode.tabIndex = 0;
        this.preNode.classList.add('isav-dialog-trap-focus-node', 'is-pre');
        this.postNode.classList.add('isav-dialog-trap-focus-node', 'is-post');

        fromEvent(document, 'focus', { capture: true })
            .pipe(
                filter(() => this.dialogStack.length > 0),
                filter(() => !this.disableListener),
                untilDestroyed(this)
            )
            .subscribe(() => this.trapFocus());
    }

    /**
     * Traps focus inside an element and pushes it on top of the stack so focus will be trapped
     * inside the element
     *
     * @param element - Element in which we should trap focus
     */
    trap(element: HTMLElement): void {
        this.dialogStack.push(element);

        if (this.document.activeElement) {
            this.previouslyFocused.set(element, this.document.activeElement);
        }

        this.focusFirst(element);
        this.lastFocusedElement = this.document.activeElement;

        this.updateNodes();
        this.updateBody();
    }

    /**
     * Releases element from trapping focus and if it was the top most open dialog restores focus to
     * previously focused element
     * @param element
     */
    release(element: HTMLElement): void {
        const index = this.dialogStack.indexOf(element);
        const isLast = index === this.dialogStack.length - 1;

        if (index !== -1) {
            const el = this.dialogStack.splice(index, 1)[0];
            if (this.previouslyFocused.has(el)) {
                const previous = this.previouslyFocused.get(el);
                this.previouslyFocused.delete(el);
                if (isLast) this.tryFocus(previous);
            }
        }

        this.updateNodes();
        this.updateBody();
    }

    private trapFocus() {
        const trappedElement = this.dialogStack[this.dialogStack.length - 1];
        if (!trappedElement.contains(this.document.activeElement)) {
            this.focusFirst(trappedElement);
            if (this.document.activeElement === this.lastFocusedElement) {
                this.focusLast(trappedElement);
            }
        }

        this.lastFocusedElement = this.document.activeElement;
    }

    private focusFirst(node: any): boolean {
        this.tryFocus(node);
        if (this.document.activeElement === node) return true;

        const childNodes = Array.from(node.childNodes);
        for (const cn of childNodes) {
            const res = this.focusFirst(cn);
            if (res) return res;
        }

        return false;
    }

    private focusLast(node: any) {
        this.tryFocus(node);
        if (this.document.activeElement === node) return true;

        const childNodes = Array.from(node.childNodes).reverse();
        for (const cn of childNodes) {
            const res = this.focusLast(cn);
            if (res) return res;
        }

        return false;
    }

    private tryFocus(node: any) {
        this.disableListener = true;
        try {
            node.focus();
        } catch (e) {
            // ignore error as all we try to do is focus the element
        }
        this.disableListener = false;
    }

    /**
     * Updates class on body element to prevent scrolling of content when dialog is open.
     * It adds and removes has-dialog class from body and html element.
     *
     * We need to have overflow: hidden on html element to hide scrollbar
     * @private
     */
    private updateBody() {
        if (this.dialogStack.length === 0) {
            this.document.documentElement.classList.remove('has-dialog');
        } else {
            this.document.documentElement.classList.add('has-dialog');
        }
    }

    private updateNodes() {
        if (this.dialogStack.length === 0) {
            this.preNode.parentNode?.removeChild(this.preNode);
            this.postNode.parentNode?.removeChild(this.postNode);
        } else {
            const el: any = this.dialogStack[this.dialogStack.length - 1];
            el.parentNode?.insertBefore(this.preNode, el);
            el.parentNode?.insertBefore(this.postNode, el.nextSibling || null);
        }
    }
}
