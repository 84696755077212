import { HasElementRef } from '../common-behaviours/has-element-ref';
import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    Input,
    OnDestroy,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { CanDisableCtor, mixinDisabled } from '../common-behaviours/disabled';
import { IsavDropdown } from './dropdown';
import { Subscription } from 'rxjs';

/** @ignore */
class TriggerBase implements HasElementRef {
    constructor(public _elementRef: ElementRef) {}
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
const TriggerMixinBase: typeof TriggerBase & CanDisableCtor = mixinDisabled(TriggerBase);

@Directive({
    selector: '[isavDropdownTriggerFor]',
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['disabled'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'dropdown',
        '[class.is-active]': '!!_renderedDropdown',
        '[class.is-right]': 'isRight',
        '[class.is-up]': 'isUp',
        'aria-haspopup': 'true',
        '[attr.aria-expanded]': '!!_renderedDropdown',
        '[attr.aria-controls]': 'dropdown?.id || null',
        '(click)': 'handleClick($event)',
    },
})
export class IsavDropdownTrigger extends TriggerMixinBase implements OnDestroy {
    @Input() isUp = false;
    @Input() isRight = false;

    _renderedDropdown: EmbeddedViewRef<any> | null = null;

    private _closedSub = Subscription.EMPTY;
    private _openedSub = Subscription.EMPTY;

    constructor(
        _elementRef: ElementRef,
        private _vcRef: ViewContainerRef,
        private _renderer2: Renderer2,
        private _cdRef: ChangeDetectorRef
    ) {
        super(_elementRef);
    }

    @Input('isavDropdownTriggerFor')
    get dropdown(): IsavDropdown {
        return this._dropdown;
    }

    set dropdown(dropdown: IsavDropdown) {
        if (this._dropdown === dropdown) return;

        this._closedSub.unsubscribe();
        this._openedSub.unsubscribe();
        this.handleClosing(true);

        this._dropdown = dropdown;
        this._openedSub = this.dropdown.opened.subscribe(this.handleOpening);
        this._closedSub = this.dropdown.closed.subscribe(this.handleClosing);
    }

    private _dropdown: IsavDropdown;

    ngOnDestroy() {
        this._openedSub.unsubscribe();
        this._closedSub.unsubscribe();
    }

    handleClick(event: MouseEvent) {
        if (this.disabled) return;

        if (this._renderedDropdown) {
            this.dropdown.closed.emit(true);
        } else {
            this.dropdown.opened.emit();
        }
    }

    handleOpening = () => {
        if (this._renderedDropdown) return;

        this._renderedDropdown = this._vcRef.createEmbeddedView(this.dropdown.template);
        this._renderedDropdown.rootNodes.forEach((node) => {
            this._renderer2.appendChild(this._elementRef.nativeElement, node);
        });

        this._cdRef.markForCheck();
    };

    handleClosing = (restoreFocus?: boolean) => {
        if (!this._renderedDropdown) return;

        this._vcRef.remove(this._vcRef.indexOf(this._renderedDropdown));
        this._renderedDropdown = null;
        if (restoreFocus) this._elementRef.nativeElement?.focus();

        this._cdRef.markForCheck();
    };
}
