import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FieldTooltipDto } from '../dto/field-tooltip';
import { TooltipsResourceService } from './tooltips-resource-service';
import { pluck, map, tap } from 'rxjs/operators';
import { Iri } from '../dto/iri';

@Injectable()
export class TooltipsService {
    private store: BehaviorSubject<Array<FieldTooltipDto>> = new BehaviorSubject([]);
    private loaded: boolean = false;

    constructor(private tooltipsResourceService: TooltipsResourceService) {}

    getAll(): Observable<FieldTooltipDto[]> {
        if (!this.loaded) this.load();
        return this.store;
    }

    getValue(key: string): Observable<string> {
        if (!this.loaded) this.load();
        return this.store.pipe(
            map((tooltips) => tooltips.find((x) => x.tooltipKey === key)?.tooltipValue ?? '')
        );
    }

    updateValue(iri: Iri, tooltip: Partial<FieldTooltipDto>): Observable<FieldTooltipDto> {
        return this.tooltipsResourceService.patch(iri, tooltip).pipe(
            tap(() => {
                const prev = this.store.getValue();
                const newArray = prev.filter((t) => t['@id'] !== iri);
                const updated = prev.find((t) => t['@id'] === iri);
                newArray.push({ ...updated, ...tooltip } as FieldTooltipDto);
                this.store.next(newArray);
            })
        );
    }

    load() {
        this.loaded = true;
        this.tooltipsResourceService
            .getAll()
            .pipe(pluck('member'))
            .subscribe((res) => {
                this.store.next(res);
            });
    }
}
