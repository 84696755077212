import { environment } from '../../environments/environment';

export function dasherize(str: string): string {
    return str.toLowerCase().replace(/\s+/g, '-');
}

export function lastTwo(str: string): string {
    if (str.length < 2) return str;
    return str.substring(str.length - 2);
}

export function isVowel(str: string): boolean {
    str = str.toLowerCase();
    return ['a', 'e', 'i', 'o', 'u', 'y'].includes(str);
}

export function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function separateWithSpaces(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function uncapitalize(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

export function camelCaseToKebabCase(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

/**
 * Checks whether a passed value is a string and otherwise raises an error in console to warn
 * developer
 * @param value - value to check if it is a string
 * @return {boolean} - whether the value is a string or not
 */
export function onlyStrings(value: any): boolean {
    if (typeof value === 'string') return true;

    // throw error in dev so there is more impact
    if (!environment.production) {
        throw new Error(
            `'${JSON.stringify(
                value
            )}' passed to TaxonomiesNames as Iri. Check your types with api response!`
        );
    }

    console.error(
        `'${JSON.stringify(
            value
        )}' passed to TaxonomiesNames as Iri. Check your types with api response!`
    );

    return false;
}
