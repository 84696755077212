import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IsavBaseControl } from '../base-control';

@Component({
    selector: 'isav-empty-input',
    templateUrl: './empty-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IsavEmptyInput), multi: true },
    ],
})
export class IsavEmptyInput extends IsavBaseControl {
    value: any;

    constructor(injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(injector);
    }

    writeValue(obj: any): void {
        this.value = obj;
        this._cdRef.markForCheck();
    }
}
