import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureKey, taxonomiesReducer } from './taxonomies.store';
import { TaxonomiesService } from './taxonomies.service';
import { throwIfDefined } from '../module-import-guard';

@NgModule({
    imports: [StoreModule.forFeature(featureKey, taxonomiesReducer)],
    providers: [TaxonomiesService],
})
export class TaxonomiesModule {
    constructor(@SkipSelf() @Optional() taxonomiesModule: TaxonomiesModule) {
        throwIfDefined(taxonomiesModule, 'TaxonomiesModule');
    }
}
