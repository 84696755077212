import { Pipe, PipeTransform } from '@angular/core';
import { GeoLocationDto } from '../../core/dto/geo-location';
import { GeoLocationReadCollectionDto } from '../../core/dto/geo-location-read-collection';
import { IsavGeoCoordinatesPipe } from './geo-coordinates-pipe';

@Pipe({
    name: 'isavGeoLocation',
    pure: true,
})
export class IsavGeoLocationPipe extends IsavGeoCoordinatesPipe implements PipeTransform {
    transform(value: Partial<GeoLocationDto | GeoLocationReadCollectionDto>): string {
        if (value === null || value === undefined) {
            return '';
        }

        const { name } = value;
        if (name && typeof name === 'string') return name;

        return super.transform(value);
    }
}
