<div class="field is-horizontal mb-4 mt-3">
    <div class="field-label field-label-tooltip">
        <span data-testid="radio-field-label" class="has-text-primary has-text-weight-bold">
            {{ label }}<span class="has-text-danger" *ngIf="mandatory">&nbsp;*</span>
        </span>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="field">
            <div class="control" role="radiogroup" [attr.aria-label]="label">
                <label class="radio">
                    <input
                        type="radio"
                        data-testid="radio-true"
                        (change)="onChange(true)"
                        [checked]="value === true"
                    />
                    {{ trueLabel }}
                </label>
                <label class="radio">
                    <input
                        type="radio"
                        data-testid="radio-false"
                        (change)="onChange(false)"
                        [checked]="value === false"
                    />
                    {{ falseLabel }}
                </label>
            </div>
        </div>
    </div>
</div>
