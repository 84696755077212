import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { compose, equals, filter, prop } from 'ramda';
import { pluck, switchMap, startWith } from 'rxjs/operators';
import { PagesResourceService } from '../../core/api/pages-resource.service';
import { LocationType, PageDto } from '../../core/dto/page';
import { AuthService } from '../../core/auth/auth.service';
import { merge } from 'rxjs';

@Component({
    selector: 'isav-footer',
    templateUrl: './footer.html',
    styleUrls: ['./footer.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavFooter implements OnInit {
    about: PageDto[] = [];
    quickGuides: PageDto[] = [];
    shortcuts: PageDto[] = [];

    constructor(
        private pagesService: PagesResourceService,
        private authService: AuthService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const auth = this.authService.authenticated$();
        const pages = this.pagesService.notify();

        merge(auth, pages)
            .pipe(
                startWith(null),
                switchMap(() => {
                    return this.pagesService
                        .getAll({ pagination: '0', 'order[weight]': 'asc' })
                        .pipe(pluck('member'));
                })
            )
            .subscribe((data) => {
                const filterPages = (type: LocationType) =>
                    filter(compose(equals(type), prop('locationType')), data);
                this.about = filterPages(LocationType.ABOUT);
                this.quickGuides = filterPages(LocationType.QUICK_GUIDES);
                this.shortcuts = filterPages(LocationType.SHORTCUTS);
                this.cdRef.markForCheck();
            });
    }
}
