import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'isav-unpublished-warning',
    templateUrl: './unpublished-warning.html',
    styleUrls: ['./unpublished-warning.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnpublishedWarning {
    @Input() contentName: string;
    @Input() published: boolean;
    @Input() myContentPath: string = '';
}
