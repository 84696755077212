import {
    Directive,
    EmbeddedViewRef,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[isavMatchUrl]',
})
export class MatchUrlDirective implements OnInit {
    @Input('isavMatchUrl') segmentToMatch?: string;

    private _renderedView: EmbeddedViewRef<any> | null = null;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.url
            .pipe(
                untilDestroyed(this),
                map((segments) => this.matches(segments)),
                distinctUntilChanged()
            )
            .subscribe((show) => {
                if (show) {
                    this.show();
                } else {
                    this.hide();
                }
            });
    }

    matches(urlSegment: UrlSegment[]): boolean {
        return Boolean(
            this.segmentToMatch &&
                urlSegment.some((segment) => segment.path === this.segmentToMatch)
        );
    }

    show() {
        if (this._renderedView) return;
        this._renderedView = this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

    hide() {
        const index = this._renderedView ? this.viewContainerRef.indexOf(this._renderedView) : -1;
        if (index !== -1) this.viewContainerRef.remove(index);
        this._renderedView = null;
    }
}
