import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * List of icons and regex that match mime type for this specific icon
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 *
 * @type {[RegExp, string][]}
 */
const MIME_TYPE_ICONS: Array<[RegExp, string]> = [
    [/pdf/, 'file-pdf'],
    [/image/, 'file-image'],
    [/csv/, 'file-csv'],
    [/vnd\.ms-excel|vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet/, 'file-excel'],
    [/msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document/, 'file-word'],
    [
        /vnd\.ms-powerpoint|vnd\.openxmlformats-officedocument\.presentationml\.presentation/,
        'file-powerpoint',
    ],
    [/audio/, 'file-audio'],
    [/g?zip|x-7z-compressed/, 'file-archive'],
    [/video/, 'file-video'],
];

@Component({
    selector: 'isav-file-icon',
    templateUrl: './file-icon.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavFileIcon {
    @Input() mimeType?: string;

    get icon(): string {
        return MIME_TYPE_ICONS.find(([regex]) => regex.test(this.mimeType ?? ''))?.[1] ?? 'file';
    }
}
