import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { MarkersResourceService } from '../../../core/api/markers-resource.service';
import { DialogManager } from '../../../shared/dialog/dialog.service';
import { Coordinate } from 'ol/coordinate';
import { GeoLocationDto } from '../../../core/dto/geo-location';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NotificationService } from '../../../core/services/notification.service';
import { IsavValidators } from '../../../utils/forms/validators';

@Component({
    selector: 'isav-map-markers-dialog',
    templateUrl: './map-markers-dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavMapMarkersDialog implements OnInit {
    coordinate: Coordinate = [];
    loading: boolean = false;

    form: UntypedFormGroup = new UntypedFormGroup(
        {
            title: new UntypedFormControl(null),
            description: new UntypedFormControl(null),
            startDate: new UntypedFormControl(null),
            endDate: new UntypedFormControl(null),
        },
        {
            validators: IsavValidators.dates(
                'startDate',
                'endDate',
                'Start date cannot be after the end date!'
            ),
        }
    );

    constructor(
        private dialogManager: DialogManager<Coordinate, boolean>,
        private markersService: MarkersResourceService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        if (this.dialogManager.data) {
            this.coordinate = this.dialogManager.data;
        } else {
            this.close();
        }
    }

    create() {
        if (this.coordinate.length < 2) return;
        this.loading = true;
        const [longitude, latitude] = this.coordinate;
        this.markersService
            .create({ ...this.form.value, location: { longitude, latitude } as GeoLocationDto })
            .subscribe(() => {
                this.loading = false;
                this.notificationService.add({
                    title: 'Success',
                    message: 'Successfully created marker',
                    type: 'success',
                    delay: 5000,
                });
                this.close(true);
            });
    }

    close(created: boolean = false): void {
        this.dialogManager.close(created);
    }
}
