<div class="dropdown" [class.is-active]="isOpened" (isavClickedOutside)="close()" #dropdown>
    <div class="dropdown-trigger">
        <input
            type="text"
            class="input"
            role="combobox"
            aria-autocomplete="none"
            aria-haspopup="true"
            [placeholder]="placeholder || label"
            [attr.aria-owns]="id + '-options'"
            [attr.aria-expanded]="isOpened"
            [attr.aria-activedescendant]="activeDescendant"
            [attr.aria-label]="label || null"
            [attr.aria-labelledby]="labelledby"
            [value]="searchString"
            (focus)="open()"
            (keyup.enter)="select(activeOption)"
            (keyup.arrowUp)="previous()"
            (keyup.arrowDown)="next()"
            (input)="update($event.target.value)"
        />
    </div>
    <div class="dropdown-menu">
        <div
            class="dropdown-content"
            role="listbox"
            [id]="id + '-options'"
            [attr.aria-label]="label"
            #listbox
        >
            <a
                *ngFor="let option of options; let idx = index"
                role="option"
                class="dropdown-item"
                [class.is-activedescendant]="activeOption === idx"
                [id]="optionId(idx)"
                [attr.aria-selected]="activeOption === idx || null"
                (click)="select(idx); close()"
            >
                <ng-container
                    *ngTemplateOutlet="autocompleteOption?.templateRef; context: { $implicit: option }"
                >
                </ng-container>
            </a>
        </div>
    </div>
</div>
