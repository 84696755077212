import { featureKey, TaxonomiesState } from './taxonomies.store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Iri } from '../dto/iri';

export const selectTaxonomies = createFeatureSelector<TaxonomiesState<any>>(featureKey);
export const selectTaxonomy = createSelector(
    selectTaxonomies,
    (state: TaxonomiesState<any>, iri: string) => {
        return state[iri];
    }
);
export const selectTaxonomyByType = createSelector(
    selectTaxonomies,
    (state: TaxonomiesState<any>, type: string): any[] => {
        return Object.values(state).filter((t) => t['@type'] === type);
    }
);
export const selectTaxonomiesByIri = createSelector(
    selectTaxonomies,
    (state: TaxonomiesState<any>, iris: Iri[]) => iris.map((iri) => state[iri])
);
