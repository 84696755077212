import { Injectable } from '@angular/core';
import { PersistentTokenStorage } from './persistent-token-storage';

@Injectable()
export class LocalStoragePersistentTokenStorage extends PersistentTokenStorage {
    private static TOKEN_KEY = 'jwt_token';
    private static REFRESH_TOKEN_KEY = 'jwt_refresh_token';

    getToken(): string | null {
        return localStorage.getItem(LocalStoragePersistentTokenStorage.TOKEN_KEY) || null;
    }

    setToken(token: string): void {
        localStorage.setItem(LocalStoragePersistentTokenStorage.TOKEN_KEY, token);
    }

    getRefreshToken(): string | null {
        return localStorage.getItem(LocalStoragePersistentTokenStorage.REFRESH_TOKEN_KEY) || null;
    }

    setRefreshToken(refreshToken?: string) {
        if (refreshToken) {
            localStorage.setItem(
                LocalStoragePersistentTokenStorage.REFRESH_TOKEN_KEY,
                refreshToken
            );
        }
    }

    clear() {
        localStorage.removeItem(LocalStoragePersistentTokenStorage.TOKEN_KEY);
        localStorage.removeItem(LocalStoragePersistentTokenStorage.REFRESH_TOKEN_KEY);
    }
}
