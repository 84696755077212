import { Directive, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { IsavMap } from '../map';
import { IsavMapVectorSource } from './vector-source';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'isav-map-vector-layer',
    providers: [
        { provide: IsavMapVectorSource, useExisting: forwardRef(() => IsavMapVectorLayer) },
    ],
})
export class IsavMapVectorLayer extends IsavMapVectorSource implements OnInit, OnDestroy {
    private _features = new VectorSource({
        wrapX: false,
    });

    private _layer = new VectorLayer({
        source: this._features,
        zIndex: 100,
    });

    constructor(private map: IsavMap) {
        super();
    }

    ngOnInit(): void {
        this.map.map.addLayer(this._layer);
    }

    ngOnDestroy(): void {
        this.map.map.removeLayer(this._layer);
    }

    getVectorSource(): VectorSource {
        return this._features;
    }

    center(coordinate: Coordinate): void {
        this.map.view.setCenter(coordinate);
    }

    fromSourceCoordinate(coordinate: Coordinate): Coordinate {
        return this.map.fromMapCoordinate(coordinate);
    }

    toSourceCoordinate(coordinate: Coordinate): Coordinate {
        return this.map.toMapCoordinate(coordinate);
    }
}
