<div
    *ngFor="let position of positions"
    isavMapNativeControl
    class="isav-map-controls-container"
    [addClasses]="false"
    [ngClass]="positionClass[position]"
>
    <div
        *ngFor="let control of controls$ | async | filterWithPosition : position | sortControls"
        class="isav-map-control-wrapper ol-control ol-unselectable"
    >
        <ng-template [cdkPortalOutlet]="control.getPortal()"></ng-template>
    </div>
</div>
