import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    OnDestroy,
    Output,
} from '@angular/core';
import { DialogFocusService } from './dialog-focus.service';

@Directive({
    selector: '[isavDialog]',
})
export class IsavDialog implements AfterViewInit, OnDestroy {
    @HostBinding('attr.role') readonly role = 'dialog';

    // eslint-disable-next-line @angular-eslint/no-output-rename
    @Output('isavDialog') closeDialog = new EventEmitter<KeyboardEvent>();

    constructor(public elementRef: ElementRef, private dialogFocusService: DialogFocusService) {}

    ngAfterViewInit() {
        this.dialogFocusService.trap(this.elementRef.nativeElement);
    }

    ngOnDestroy() {
        this.dialogFocusService.release(this.elementRef.nativeElement);
    }

    @HostListener('document:keydown', ['$event'])
    onDocumentKeydown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.closeDialog.emit(event);
        }
    }
}
