<isav-caption>
    <ng-content select="[isavItemCaption]"></ng-content>
</isav-caption>
<div class="item-view mt-5 columns">
    <div class="column is-4">
        <figure class="image is-4by3">
            <ng-content select="[isavItemImage]"></ng-content>
        </figure>
    </div>
    <div class="column is-8"><ng-content></ng-content></div>
</div>
