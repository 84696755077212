import {
    Component,
    ChangeDetectionStrategy,
    forwardRef,
    Injector,
    ChangeDetectorRef,
} from '@angular/core';
import { IsavBaseControl } from '../base-control';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { StringDateTime } from '../../../core/dto/string-date-time';
import { formatStandardDate } from '../../../utils/date';

interface StartEndDate {
    startDate: StringDateTime;
    endDate: StringDateTime;
}

@Component({
    selector: 'isav-start-end-date-input',
    templateUrl: './start-end-date-input.html',
    styleUrls: ['./start-end-date-input.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavStartEndDateInput),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => IsavStartEndDateInput),
            multi: true,
        },
    ],
})
export class IsavStartEndDateInput extends IsavBaseControl {
    value: StartEndDate = { startDate: '', endDate: '' };

    constructor(injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(injector);
    }

    onChange(property: 'startDate' | 'endDate', value: string) {
        this.value[property] = value;
        this._onChange(this.value);
        this._cdRef.markForCheck();
    }

    writeValue(obj: any): void {
        // Make sure obj shape is at least StartEndDate
        if (obj && typeof obj.startDate === 'string' && typeof obj.endDate === 'string') {
            this.value.startDate = formatStandardDate(new Date(obj.startDate));
            this.value.endDate = formatStandardDate(new Date(obj.endDate));
        } else {
            // if we go invalid object or null we clear it
            this.value.startDate = '';
            this.value.endDate = '';
        }
        this._cdRef.markForCheck();
    }

    onFocus() {
        this._onTouched();
    }

    validate() {
        if (this.value.startDate > this.value.endDate) {
            return { dates: 'Start date cannot be after the end date!' };
        }
        return null;
    }
}
