import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContentService } from '../../../core/api/content.service';
import { NotificationService } from '../../../core/services/notification.service';
import { DialogManager, DialogService } from '../../../shared/dialog/dialog.service';
import { IsavBaseControl } from '../base-control';
import {
    ContentEditorData,
    IsavContentEditorDialog,
} from '../content-editor-dialog/content-editor-dialog';
import { ContentEditorDialogService } from '../content-editor-dialog/content-editor-dialog.service';

let UNIQUE_ID = 0;

@UntilDestroy()
@Component({
    selector: 'isav-content-editor',
    templateUrl: './content-editor.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavContentEditor),
            multi: true,
        },
    ],
    host: {
        '[attr.id]': 'null',
    },
})
export class IsavContentEditor extends IsavBaseControl {
    @Input() id = 'isav-content-editor--' + UNIQUE_ID++;
    @Input() simple;
    @Input() iri;
    @Input() property;

    value: string;
    dialogManager: DialogManager<ContentEditorData, string> | null;

    constructor(
        injector: Injector,
        private cdRef: ChangeDetectorRef,
        private dialogService: DialogService,
        private contentEditorDialogService: ContentEditorDialogService,
        private contentService: ContentService,
        private notificationService: NotificationService
    ) {
        super(injector);
    }

    onFocus(): void {
        this._onTouched();
    }

    onChange(value: string): void {
        this.value = value;
        this._onChange(value);
    }

    writeValue(value: any): void {
        this.value = value;
        this.cdRef.markForCheck();
    }

    open() {
        this.dialogManager = this.dialogService.spawnComponent<
            string,
            ContentEditorData,
            IsavContentEditorDialog
        >(IsavContentEditorDialog, {
            value: this.value,
            title: this.label,
        });
        this.dialogManager.closed$.pipe(untilDestroyed(this)).subscribe((value) => {
            if (value) {
                this.onChange(value);
                this.contentEditorDialogService.next(value);
                if (this.iri && this.property) {
                    this.contentService
                        .patch(this.iri, { [this.property]: value })
                        .subscribe(() => {
                            this.notificationService.add({
                                title: 'Success',
                                message: 'Successfully updated content',
                                type: 'success',
                                delay: 5000,
                            });
                        });
                }
            }
        });
    }
}
