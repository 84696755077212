<h2 class="title is-3">Partners</h2>

<div class="is-flex is-wrapping is-justify-content-center">
    <div class="partner-img">
        <a href="http://www.arctic.au.dk/" aria-label="Visit Arctic AU partner page">
            <img src="/assets/partners/arctic-au.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://arctichub.gl/" aria-label="Visit Arctic Hub partner page">
            <img src="/assets/partners/arctic-hub.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="http://www.armap.org/" aria-label="Visit Armap partner page">
            <img src="/assets/partners/armap.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://www.awi.de/" aria-label="Visit Alfred Wegener Institute page">
            <img src="/assets/partners/awi.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://www.forsvaret.dk/" aria-label="Visit Arctic Command partner page">
            <img src="/assets/partners/arctic-command.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="http://umanitoba.ca/ceos/" aria-label="Visit CEOS partner page">
            <img src="/assets/partners/ceos.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="http://www.dmi.dk/" aria-label="Visit DMI partner page">
            <img src="/assets/partners/dmi.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="http://dtu.dk/english" aria-label="Visit DTU partner page">
            <img src="/assets/partners/dtu.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="http://www.geus.dk/UK/Pages/default.aspx" aria-label="Visit GEUS partner page">
            <img src="/assets/partners/geus.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://natur.gl/" aria-label="Visit GINR partner page">
            <img src="/assets/partners/ginr.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://uk.uni.gl/" aria-label="Visit UK-UNI partner page">
            <img src="/assets/partners/uk-uni.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a
            href="https://www.ku.dk/english"
            aria-label="Visit University of Copenhagen partner page"
        >
            <img src="/assets/partners/ku.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a
            href="https://naalakkersuisut.gl//kl-GL"
            aria-label="Visit Naalakkersuisut - Government of Greenland partner page"
        >
            <img src="/assets/partners/naalakkersuisut.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="http://en.natmus.dk/" aria-label="Visit Natmus partner page">
            <img src="/assets/partners/natmus.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://www.nsf.gov/" aria-label="Visit NSF partner page">
            <img src="/assets/partners/nsf.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://www.sdu.dk/en/sif" aria-label="Visit SDU partner page">
            <img src="/assets/partners/sdu-sif.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://ufm.dk/" aria-label="Visit UFM partner page">
            <img src="/assets/partners/ufm.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://uit.no/startsida" aria-label="Visit UIT partner page">
            <img src="/assets/partners/uit.jpg" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://uit.no/startsida" aria-label="Visit SIOS partner page">
            <img src="/assets/partners/sios.png" alt="" />
        </a>
    </div>
    <div class="partner-img">
        <a href="https://isaaffik.org/" aria-label="Visit ISAAFFIK homepage">
            <img src="/assets/partners/isaaffik.jpg" alt="" />
        </a>
    </div>
</div>
