import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { INotif, NotificationService } from '../../core/services/notification.service';

@Component({
    selector: 'isav-notifications-container',
    templateUrl: './notifications-container.html',
    styleUrls: ['./notifications-container.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class NotificationsContainer implements OnInit {
    notifications$: Observable<INotif[]>;

    constructor(private notificationService: NotificationService) {}

    ngOnInit() {
        this.notifications$ = this.notificationService.get();
    }

    remove(notification: INotif) {
        this.notificationService.remove(notification);
    }
}
