import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, timer } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TokenService } from '../api/token.service';
import { signIn, signOut } from './auth.store';
import { TokenDecoder } from './token-decoder';

@Injectable()
export class RefreshTokenEffects {
    refreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signIn),
            switchMap(({ token, refresh_token }) =>
                timer(this.getTimeTillRefresh(token)).pipe(
                    takeUntil(this.actions$.pipe(ofType(signOut), take(1))),
                    switchMap(() => {
                        if (!refresh_token) return of(signOut);
                        return this.tokenService.postAuthTokenRefresh({ refresh_token }).pipe(
                            map(signIn),
                            catchError(() => of(signOut))
                        );
                    })
                )
            )
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly tokenService: TokenService,
        private readonly tokenDecoder: TokenDecoder
    ) {}

    /**
     * Due date is one minute before the token expires or 0 since we want it to fire immediately
     * if token will expire in less then one minute from now
     * @param token
     * @private
     */
    private getTimeTillRefresh(token: string): number {
        const payload = this.tokenDecoder.decode(token);
        const now = Math.floor(Date.now() / 1000);
        return Math.max((payload.exp - now - 60) * 1000, 0);
    }
}
