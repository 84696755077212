<ng-container *ngIf="notes$ | async; let notes">
    <div class="mb-5" *ngIf="notes.length>0">
        <ng-container *ngFor="let note of notes; let i = index">
            <isav-note *ngIf="i < shownNotes" [note]="note"></isav-note>
        </ng-container>
        <p class="has-text-right">
            <a
                data-testid="show-notes"
                (click)="show(notes.length)"
                *ngIf="notes.length > shownNotes"
                >Show all notes ({{notes.length}})</a
            >
            <a data-testid="hide-notes" (click)="hide()" *ngIf="shownNotes > 2">Hide notes</a>
        </p>
    </div>
</ng-container>
