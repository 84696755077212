import { TemplatePortal } from '@angular/cdk/portal';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchState } from '../../core/search/search-state';
import { SearchService } from '../../core/search/search.service';
import { FileService } from '../../core/services/file.service';
import { AdvancedSearchCardView } from './advanced-search-card-view';
import { AdvancedSearchListView } from './advanced-search-list-view';
import { AdvancedSearchMapView } from './advanced-search-map-view';
import { AdvancedSearchTimelineView } from './advanced-search-timeline-view';

/**
 * Used for bitwise operations to determine which view to show and which are allowed
 */
export enum ViewMode {
    // Card Timeline Map List
    // (8) (4) (2) (1)
    List = 1, // 0001
    Map = 2, // 0010
    Timeline = 4, // 0100
    Card = 8, // 1000
    NoCard = 7, // 0111 - Timeline and Map and List
    NoTimeline = 11, // 1011 - Card and Map and List
    NoMap = 13, // 1101 - Card and Timeline and List
    All = 15, // 1111
}

export enum CsvMode {
    None = 'None',
    Users = 'Users',
    Admin = 'Admin',
}

@Component({
    selector: 'isav-advanced-search',
    templateUrl: './advanced-search.html',
    styleUrls: ['./advanced-search.sass'],
    host: {
        class: 'page-container-vertical',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearch<T> implements OnInit, AfterContentInit {
    @Input() state: SearchState<T>;
    @Input() csvMode: CsvMode = CsvMode.Users;

    /**
     * View modes that should be available in this advanced search
     * For convenience usage in the template you can specify either a number, one from
     * {ViewMode} enum or a string name from the enum (List, Map, Timeline or All)
     *
     * List mode is always available and by default view is set to contain only this
     * @return {string | number}
     */
    @Input() get viewModes(): string | number {
        return this._viewModes;
    }

    set viewModes(viewModes: string | number) {
        if (typeof viewModes === 'string') {
            this._viewModes = ViewMode[viewModes];
        } else {
            this._viewModes = viewModes;
        }

        if (!this._viewModes) {
            this._viewModes = ViewMode.List;
        }
    }

    @ContentChild(AdvancedSearchListView) listView?: AdvancedSearchListView;
    @ContentChild(AdvancedSearchMapView) mapView?: AdvancedSearchMapView;
    @ContentChild(AdvancedSearchTimelineView) timelineView?: AdvancedSearchTimelineView;
    @ContentChild(AdvancedSearchCardView) cardView?: AdvancedSearchCardView;

    viewMode: ViewMode;
    expanded: boolean = true;
    renderedPortal: TemplatePortal;
    private _viewModes: number = ViewMode.List;

    constructor(
        private searchService: SearchService<T>,
        private router: Router,
        private route: ActivatedRoute,
        private fileService: FileService,
        private viewContainerRef: ViewContainerRef
    ) {}

    get pagination(): string {
        let pagination = this.state.query?.pagination;
        pagination = Array.isArray(pagination) ? pagination[0] : pagination;
        return pagination || '1';
    }

    get shownPerPage(): number {
        if (this.pagination === '0') return this.state.results.totalItems;
        return this.viewMode === ViewMode.Timeline ? 50 : this.viewMode === ViewMode.Card ? 16 : 20;
    }

    get page(): number {
        return Number(this.state.query.page || '1');
    }

    get indexesShown(): string {
        if (this.state.results.member.length === 0) return '0';

        const from = (this.page - 1) * this.shownPerPage + 1;
        const till = from + this.state.results.member.length - 1;

        return `${from}-${till}`;
    }

    get isOnListView(): boolean {
        return this.viewMode === ViewMode.List;
    }

    get isNotOnMapView(): boolean {
        return this.viewMode !== ViewMode.Map;
    }

    get hasMapView(): boolean {
        // eslint-disable-next-line no-bitwise
        return (this._viewModes & ViewMode.Map) === ViewMode.Map;
    }

    get hasTimelineView(): boolean {
        // eslint-disable-next-line no-bitwise
        return (this._viewModes & ViewMode.Timeline) === ViewMode.Timeline;
    }

    get hasCardView() {
        // eslint-disable-next-line no-bitwise
        return (this._viewModes & ViewMode.Card) === ViewMode.Card;
    }

    get hasOnlyListView(): boolean {
        return this._viewModes === ViewMode.List;
    }

    ngOnInit() {
        this.update({
            page: this.route.snapshot.queryParams.page || '1',
            pagination: '1',
        });
    }

    ngAfterContentInit(): void {
        if (!this.listView) throw new Error('Missing list view!');

        const viewMode = Number(this.route.snapshot.queryParams.__view || ViewMode.List);
        this.setViewMode(viewMode);
    }

    updatePage(page: number) {
        this.update({ page: page.toString(10), pagination: '1' });
    }

    showAll() {
        this.update({ page: '1', pagination: '0' });
    }

    showPages() {
        this.update({ page: '1', pagination: '1' });
    }

    update(query) {
        this.searchService.search(query);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: query,
            queryParamsHandling: 'merge',
            preserveFragment: true,
        });
    }

    onCsvClick() {
        this.searchService.csv(this.state.query).subscribe((res) => {
            let fileName;
            if (this.state.query.searchIn) {
                fileName =
                    this.state.query.searchIn === 'fieldworks' ? 'fieldworks.csv' : 'projects.csv';
            } else {
                fileName = this.searchService.getNamespace() + '.csv';
            }
            this.fileService.saveCsv(res, fileName);
        });
    }

    toggle(): void {
        this.expanded = !this.expanded;
    }

    setViewMode(viewMode: number) {
        if (this.viewMode === viewMode) return;
        if (!this.isViewModeAllowed(viewMode)) return;

        let template: TemplateRef<any> | undefined;
        switch (viewMode) {
            case ViewMode.Timeline:
                template = this.timelineView?.templateRef;
                break;
            case ViewMode.Map:
                template = this.mapView?.templateRef;
                break;
            case ViewMode.Card:
                template = this.cardView?.templateRef;
                break;
            case ViewMode.List:
            default:
                template = this.listView?.templateRef;
                break;
        }

        if (!template) return;

        this.viewMode = viewMode;
        this.renderedPortal = new TemplatePortal<any>(template, this.viewContainerRef);

        if (this.viewMode === ViewMode.List) {
            this.update({
                page: '1',
                pagination: '1',
                itemsPerPage: '20',
            });
        } else if (this.viewMode === ViewMode.Timeline) {
            this.update({
                page: '1',
                pagination: '1',
                itemsPerPage: '50',
            });
        } else if (this.viewMode === ViewMode.Card) {
            this.update({
                page: '1',
                pagination: '1',
                itemsPerPage: '16',
            });
        } else {
            this.update({
                page: null,
                pagination: '0',
            });
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: {
                page: '1',
                __view: this.viewMode,
            },
            preserveFragment: true,
        });
    }

    private isViewModeAllowed(viewMode: number): Boolean {
        // eslint-disable-next-line no-bitwise
        return (this._viewModes & viewMode) === viewMode;
    }

    viewButtonColor(number: number): 'primary' | 'link' {
        return this.viewMode === number ? 'link' : 'primary';
    }
}
