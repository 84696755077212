<div class="modal is-active" (isavDialog)="close(false)">
    <div class="modal-background"></div>
    <div class="modal-card">
        <div class="modal-card-head">
            <p class="modal-card-title has-text-danger">Unsaved changes!</p>
            <button
                class="delete"
                type="button"
                aria-label="Close warning and go back to changes!"
                (click)="close(false)"
            ></button>
        </div>
        <div class="modal-card-body">
            You are navigating away and you have unsaved changes, are you sure you want to leave
            current page and lose all your changes?
        </div>
        <div class="modal-card-foot">
            <button isavBtn color="danger" type="button" (click)="close(true)">
                Yes, discard changes
            </button>
            <button isavBtn color="secondary" type="button" (click)="close(false)">
                No, cancel navigation
            </button>
        </div>
    </div>
</div>
