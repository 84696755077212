export function relativeParent(node: HTMLElement) {
    let parent = node.parentElement;
    while (parent && parent !== document.body) {
        const parentStyles = window.getComputedStyle(parent);
        if (
            parentStyles.position === 'relative' ||
            parentStyles.position === 'absolute' ||
            parentStyles.position === 'fixed'
        ) {
            return parent;
        }

        parent = parent.parentElement;
    }
    return parent;
}
