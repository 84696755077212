import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Iri } from '../../core/dto/iri';
import { ContentService } from '../../core/api/content.service';
import { Observable } from 'rxjs';
import { SarDto } from '../../core/dto/sar';

@Component({
    selector: 'isav-sar-assets',
    templateUrl: './sar-assets.html',
    styleUrls: ['./sar-assets.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavSarAssets implements OnChanges {
    @Input() iri: Iri;

    sar$: Observable<SarDto>;

    constructor(private contentService: ContentService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.iri) {
            this.sar$ = this.contentService.getByIri<SarDto>(this.iri);
        }
    }
}
