import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
    selector: '[isavGoBack]',
})
export class IsavGoBackDirective {
    constructor(private location: Location) {}

    @HostListener('click') back() {
        this.location.back();
    }
}
