import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { DialogManager } from '../../../shared/dialog/dialog.service';
import { Iri } from '../../../core/dto/iri';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '../../../core/services/notification.service';
import { ContentService } from '../../../core/api/content.service';
import { ImageDto } from '../../../core/dto/image';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
    selector: 'isav-image-popup',
    templateUrl: './image-popup.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavImagePopup implements OnInit, OnDestroy {
    form: UntypedFormGroup = new UntypedFormGroup({
        caption: new UntypedFormControl(null),
        copyright: new UntypedFormControl(null),
    });

    imageIri: Iri | null = null;
    loading: boolean = false;
    cancel$ = new Subject<Iri | null>();
    maxImageSize = environment.maxImageSize;
    userIri: Iri;
    authorIri: Iri | null = null;

    constructor(
        private dialogManager: DialogManager<Iri | null, Iri | null>,
        private contentService: ContentService,
        private notificationService: NotificationService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.dialogManager.data) {
            this.imageIri = this.dialogManager.data;
            this.loading = true;
            this.contentService
                .getByIri<ImageDto>(this.imageIri)
                .pipe(takeUntil(this.cancel$))
                .subscribe((image) => {
                    this.form.patchValue(image);
                    this.authorIri = image.author;
                    this.loading = false;
                    if (this.authorIri !== this.userIri) {
                        this.form.disable();
                    }
                    this.cdr.markForCheck();
                });
        }

        this.authService
            .userIri$()
            .pipe(takeUntil(this.cancel$))
            .subscribe((iri) => {
                this.userIri = iri;
                this.cdr.markForCheck();
            });

        this.cancel$.subscribe((iri) => {
            this.dialogManager.close(iri);
        });
    }

    ngOnDestroy() {
        this.cancel$.complete();
    }

    close(): void {
        this.cancel$.next(null);
    }

    save(): void {
        this.loading = true;
        this.contentService
            .patch(this.imageIri!, this.form.value)
            .pipe(takeUntil(this.cancel$))
            .subscribe(
                () => {
                    this.loading = false;
                    this.cancel$.next(this.imageIri);
                },
                (error) => {
                    this.loading = false;
                    this.notificationService.error(
                        error.error?.['hydra:description'] ??
                            error.error?.message ??
                            'Unknown error occurred!'
                    );
                }
            );
    }

    onChange(iri: Iri) {
        this.imageIri = iri;
        this.authorIri = this.userIri;
        this.form.enable();
        this.cdr.markForCheck();
    }
}
