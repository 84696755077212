import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Nullable } from '../../utils/types/nullable';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // run this interceptor only for /api
        if (!req.url.startsWith('/api')) return next.handle(req);

        return this.authService.token$().pipe(
            take(1),
            switchMap((token) => {
                const newReq = this.addAuthorizationHeader(token, req);
                return next.handle(newReq);
            })
        );
    }

    private addAuthorizationHeader(token: Nullable<string>, req: HttpRequest<any>) {
        if (token) return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        return req;
    }
}
