<div class="note-form mb-5 mt-5">
    <form [formGroup]="form">
        <div class="field">
            <label [for]="labelId">Title</label>
            <isav-tooltip-display tooltipKey="Note:title"></isav-tooltip-display>
            <div class="control">
                <input
                    [id]="labelId"
                    formControlName="title"
                    class="input"
                    type="text"
                    placeholder="Title..."
                />
            </div>
        </div>
        <div class="field">
            <label [for]="areaId">Description</label>
            <isav-tooltip-display tooltipKey="Note:description"></isav-tooltip-display>
            <div class="control">
                <textarea
                    [id]="areaId"
                    formControlName="description"
                    class="textarea"
                    placeholder="Description..."
                ></textarea>
            </div>
        </div>
        <div class="field">
            <div
                class="control"
                role="radiogroup"
                aria-label="Whether note is a personal note (only you can see it) or it is shared with others"
            >
                <div>
                    <span>Personal or shared</span>
                    <isav-tooltip-display tooltipKey="Note:personal"></isav-tooltip-display>
                </div>
                <label class="radio">
                    <input
                        data-testid="radio-personal"
                        type="radio"
                        formControlName="personal"
                        [value]="true"
                    />
                    Personal note
                </label>
                <label class="radio">
                    <input
                        data-testid="radio-shared"
                        type="radio"
                        formControlName="personal"
                        [value]="false"
                    />
                    Shared with owners
                </label>
            </div>
        </div>
        <div class="buttons is-right">
            <ng-content></ng-content>
        </div>
    </form>
</div>
