import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangesWarningPopup } from './changes-warning-popup/changes-warning-popup';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    declarations: [ChangesWarningPopup],
    imports: [CommonModule, SharedModule],
})
export class ChangesWarningModule {}
