<isav-simple-search-container>
    <ng-container isavSimpleSearchContent>
        <h1 class="title is-1 has-text-centered">Search {{ form.get('searchIn')?.value }}</h1>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" isavSimpleSearchForm>
            <div class="field">
                <div class="control">
                    <input
                        type="text"
                        class="input"
                        formControlName="extractedText"
                        placeholder="Type text to search for {{ form.get('searchIn')?.value }}"
                        [attr.aria-label]="'Type text to search for ' + form.get('searchIn')?.value"
                    />
                </div>
            </div>
            <div
                class="field is-grouped"
                role="radiogroup"
                aria-label="Search either for field works or projects"
            >
                <div class="control">
                    <label class="radio">
                        <input
                            type="radio"
                            class="radio"
                            formControlName="searchIn"
                            value="projects"
                        />
                        Search for all projects
                    </label>
                </div>
                <div class="control">
                    <label class="radio">
                        <input
                            type="radio"
                            class="radio"
                            formControlName="searchIn"
                            value="fieldworks"
                        />
                        Search for all fieldworks
                    </label>
                </div>
            </div>
            <div
                class="field is-grouped"
                role="radiogroup"
                aria-label="Search either for field works or projects"
            >
                <div class="control">
                    <label class="radio">
                        <input
                            type="radio"
                            class="radio"
                            formControlName="searchIn"
                            value="active-projects"
                        />
                        Search active projects
                    </label>
                </div>
                <div class="control">
                    <label class="radio">
                        <input
                            type="radio"
                            class="radio"
                            formControlName="searchIn"
                            value="active-fieldworks"
                        />
                        Search active fieldworks
                    </label>
                </div>
            </div>
            <div class="field is-grouped is-grouped-centered">
                <div class="control">
                    <button type="submit" isavBtn color="success" isavSimpleSearchSubmit>
                        Search
                    </button>
                </div>
            </div>
            <div class="help">
                <p class="help mt-4 has-text-right">
                    <a [routerLink]="['/projects/search']"> Advanced search </a>
                </p>
            </div>
        </form>
    </ng-container>
</isav-simple-search-container>

<div class="page-container page-container-vertical">
    <h2 class="title is-2">Fieldworks</h2>
    <h3 class="title is-6">Latest fieldworks</h3>
    <div class="columns is-multiline">
        <isav-landing-card
            *ngFor="let fieldwork of newestFieldworks$ | async"
            [image]="fieldwork.fieldworkCoverPhoto || fieldwork.project.projectCoverPhoto"
        >
            <ng-container
                isavLandingCardCaption
                *ngTemplateOutlet="caption; context: { $implicit: fieldwork }"
            ></ng-container>
            <ng-container
                isavLandingCardHeading
                *ngTemplateOutlet="fieldworkTitle; context: { $implicit: fieldwork }"
            ></ng-container>
            <ng-container
                isavLandingCardFooter
                *ngTemplateOutlet="footer; context: { $implicit: fieldwork }"
            ></ng-container>
        </isav-landing-card>
    </div>

    <h3 class="title is-6">Upcoming fieldworks</h3>
    <div class="columns is-multiline">
        <isav-landing-card
            *ngFor="let fieldwork of upcomingFieldworks$ | async"
            [image]="fieldwork.fieldworkCoverPhoto || fieldwork.project.projectCoverPhoto"
        >
            <ng-container
                isavLandingCardCaption
                *ngTemplateOutlet="caption; context: { $implicit: fieldwork }"
            ></ng-container>
            <ng-container
                isavLandingCardHeading
                *ngTemplateOutlet="fieldworkTitle; context: { $implicit: fieldwork }"
            ></ng-container>
            <ng-container
                isavLandingCardFooter
                *ngTemplateOutlet="footer; context: { $implicit: fieldwork }"
            ></ng-container>
        </isav-landing-card>
    </div>
    <div class="has-text-right">
        <a
            data-testid="link-fieldworks"
            [routerLink]="['/projects/search']"
            [queryParams]="{ searchIn: 'fieldworks', page: '1', __view: '8' }"
            class="mr-4"
        >
            View all fieldworks
        </a>
    </div>
    <h2 class="title is-2">Projects</h2>
    <h3 class="title is-6">Latest projects</h3>
    <div class="columns is-multiline">
        <isav-landing-card
            *ngFor="let project of newestProjects$ | async"
            [image]="project.projectCoverPhoto"
        >
            <ng-container
                isavLandingCardCaption
                *ngTemplateOutlet="caption; context: { $implicit: project }"
            ></ng-container>
            <ng-container
                isavLandingCardHeading
                *ngTemplateOutlet="projectTitle; context: { $implicit: project }"
            ></ng-container>
            <ng-container
                isavLandingCardFooter
                *ngTemplateOutlet="footer; context: { $implicit: project }"
            ></ng-container>
        </isav-landing-card>
    </div>

    <h3 class="title is-6">Upcoming projects</h3>
    <div class="columns is-multiline">
        <isav-landing-card
            *ngFor="let project of upcomingProjects$ | async"
            [image]="project.projectCoverPhoto"
        >
            <ng-container
                isavLandingCardCaption
                *ngTemplateOutlet="caption; context: { $implicit: project }"
            ></ng-container>
            <ng-container
                isavLandingCardHeading
                *ngTemplateOutlet="projectTitle; context: { $implicit: project }"
            ></ng-container>
            <ng-container
                isavLandingCardFooter
                *ngTemplateOutlet="footer; context: { $implicit: project }"
            ></ng-container>
        </isav-landing-card>
    </div>
    <div class="has-text-right">
        <a
            data-testid="link-projects"
            [routerLink]="['/projects/search']"
            [queryParams]="{ searchIn: 'projects', page: '1', __view: '8' }"
            class="mr-4"
        >
            View all projects
        </a>
    </div>
</div>

<ng-template #caption let-content>
    <b>{{ (content.projectStart || content.start) | isavDate }}</b>
    <span>&nbsp;-&nbsp;</span>
    <b>{{ (content.projectEnd || content.end) | isavDate }}</b>
</ng-template>

<ng-template #projectTitle let-project>
    <a
        [routerLink]="[ '/projects/view', project.title ]"
        [queryParams]="{ iri: project['@id'] }"
        isavLandingCardHeading
    >
        {{project.title}}
    </a>
</ng-template>

<ng-template #fieldworkTitle let-fieldwork>
    <a
        [routerLink]="[ '/projects/view', fieldwork.project.title ]"
        [queryParams]="{ iri: fieldwork.project['@id'] }"
        isavLandingCardHeading
    >
        {{ fieldwork.title || fieldwork.project.title }}
    </a>
</ng-template>

<ng-template #footer let-content>
    <div class="columns">
        <div class="column">
            <b>Organisation:&nbsp;</b><br />
            <isav-taxonomies-names
                property="title"
                [iris]="[content.organisation || content.project?.organisation]"
            >
            </isav-taxonomies-names>
        </div>
        <div class="column">
            <b>Topics:&nbsp;</b><br />
            <isav-taxonomies-names
                [limit]="true"
                [iris]="content.topics || content.project?.topics"
            ></isav-taxonomies-names>
        </div>
    </div>
</ng-template>
