import { addIsPrefix, safeAddClass, safeRemoveClass } from './safe-classlist';
import { HasElementRef } from './has-element-ref';
import { Constructor } from '../../utils/types/constructor';

export const ALLOWED_SIZES = ['', 'small', 'normal', 'large'];

export interface HasSize {
    size: string;
}

export type HasSizeCtor = Constructor<HasSize>;

export function mixinSize<T extends Constructor<HasElementRef>>(base: T): T & HasSizeCtor {
    return class extends base implements HasSize {
        private _size = '';

        get size(): string {
            return this._size;
        }

        set size(size: string) {
            if (ALLOWED_SIZES.includes(size)) {
                if (this._size) safeRemoveClass(this._elementRef, addIsPrefix(this._size));
                this._size = size;
                if (this._size) safeAddClass(this._elementRef, addIsPrefix(this._size));
            }
        }
    };
}
