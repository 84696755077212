import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { IsavMapControlsService } from './controls.service';

export interface IMapControl {
    getOrder(): number;
    getPosition(): IsavMapControlPosition;
    getPortal(): Portal<any>;
}

export type IsavMapControlPosition = 'top left' | 'top right' | 'bottom left' | 'bottom right';

@Directive({
    selector: '[isavMapControl]',
})
export class IsavMapControl implements OnInit, OnDestroy, IMapControl {
    @Input('isavMapControl') position: IsavMapControlPosition;
    @Input('isavMapControlOrder') order: number = Infinity;
    private readonly portal: TemplatePortal;

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly vcRef: ViewContainerRef,
        private readonly controlsService: IsavMapControlsService
    ) {
        this.portal = new TemplatePortal(this.templateRef, this.vcRef);
    }

    ngOnInit() {
        this.controlsService.register(this);
    }

    ngOnDestroy() {
        this.controlsService.unregister(this);
    }

    getPortal(): Portal<any> {
        return this.portal;
    }

    getPosition(): IsavMapControlPosition {
        return this.position;
    }

    getOrder(): number {
        return this.order;
    }
}
