import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfDefined } from '../module-import-guard';
import { ContentService } from './content.service';
import { InfrastructuresResourceService } from './infrastructures-resource.service';
import { IriResolver } from './iri.resolver';
import { TokenService } from './token.service';
import { PeopleResourceService } from './people-resource.service';
import { DataLinksResourceService } from './data-links-resource.service';
import { ProjectsResourceService } from './projects-resource.service';
import { LogisticsResourceService } from './logistics-resource.service';
import { NotesResourceService } from './notes-resource.service';
import { UploadService } from './upload.service';
import { EducationAndTrainingsResourceService } from './education-and-trainings-resource.service';
import { SarsResourceService } from './sars-resource.service';
import { MarkersResourceService } from './markers-resource.service';
import { PersonService } from './person.service';
import { DomainEventJournalsService } from './domain-event-journals.service';
import { SiosMappingsResourceService } from './sios-mappings-resource.service';
import { ArmapMappingsResourceService } from './armap-mappings-resource.service';
import { EventsResourceService } from './events-resource.service';
import { TooltipsResourceService } from './tooltips-resource-service';
import { TooltipsService } from './tooltips-service';
import { HowToGuidesResourceService } from './how-to-guides-resource.service';
import { NewsResourceService } from './news-resource.service';
import { PagesResourceService } from './pages-resource.service';
import { NewsSyncImagesResourceService } from './news-sync-images-resource.service';
import { MailsService } from './mails.service';
import { ContentCreateAccessService } from './content-create-access.service';
import { ImageService } from './image.service';
import { AutoMatchesResourceService } from './auto-matches-resource.service';
import { ArmapImagesResourceService } from './armap-images-resource.service';

@NgModule({
    providers: [
        ContentService,
        InfrastructuresResourceService,
        TokenService,
        IriResolver,
        PeopleResourceService,
        PersonService,
        DataLinksResourceService,
        ProjectsResourceService,
        MarkersResourceService,
        LogisticsResourceService,
        NotesResourceService,
        UploadService,
        EducationAndTrainingsResourceService,
        EventsResourceService,
        SarsResourceService,
        DomainEventJournalsService,
        SiosMappingsResourceService,
        ArmapMappingsResourceService,
        TooltipsResourceService,
        TooltipsService,
        HowToGuidesResourceService,
        NewsResourceService,
        PagesResourceService,
        NewsSyncImagesResourceService,
        MailsService,
        ContentCreateAccessService,
        ImageService,
        AutoMatchesResourceService,
        ArmapImagesResourceService,
    ],
})
export class ApiModule {
    constructor(@SkipSelf() @Optional() apiModule: ApiModule) {
        throwIfDefined(apiModule, 'ApiModule');
    }
}
