import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewportObserver } from '../../shared/viewport/viewport';
import { delay, startWith } from 'rxjs/operators';

@UntilDestroy()
@Directive({
    selector: '[isavMaxSizeToScreen]',
})
export class MaxSizeToScreenDirective implements AfterViewInit {
    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly viewportObserver: ViewportObserver
    ) {}

    ngAfterViewInit() {
        const initialDelayed = of({}).pipe(delay(250));
        merge(fromEvent(document, 'scroll'), fromEvent(window, 'resize'), initialDelayed)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.viewportObserver.isTablet()) {
                    const rect = this.elementRef.nativeElement.getBoundingClientRect();
                    const height = Math.min(
                        window.innerHeight - rect.top,
                        window.innerHeight - 24.5
                    );
                    this.elementRef.nativeElement.style.maxHeight = height + 'px';
                } else {
                    this.elementRef.nativeElement.style.maxHeight = 'none';
                }
            });
    }
}
