import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    NgZone,
    AfterViewInit,
    ElementRef,
} from '@angular/core';
import { DataItem, VisTimelineService } from 'ngx-vis';
import { ProjectDto } from '../../core/dto/project';
import { typeFromIri } from '../../core/type-from-iri';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';
import { formatStandardDate } from '../../utils/date';
import { compose, head, map, max, min, prop, reduce } from 'ramda';

let UNIQUE_ID = 0;

type DateType = Date | string | number;

interface TimelineOptions {
    start?: string;
    end?: string;
    clickToUse: boolean;
}

interface ContentDto {
    '@id': string;
    '@type': string;
    title?: string;
    project?: ProjectDto;
    fullNameOfDataSet?: string;
    start?: string;
    startDate?: string;
    projectStart?: string;
    end?: string;
    endDate?: string;
    projectEnd?: string;
}

type IsavDataItem = DataItem & Required<Pick<DataItem, 'end'>>;

@UntilDestroy()
@Component({
    selector: 'isav-timeline',
    templateUrl: './timeline.html',
    styleUrls: ['./timeline.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavTimeline implements OnChanges, AfterViewInit {
    @Input() data: ContentDto[];
    @Input() sarFilter: number = 0;
    filteredData: ContentDto[];
    filteredCounter: number = 0;

    id: string = 'timeline-' + UNIQUE_ID++;
    visTimelineItems: IsavDataItem[] = [];
    options: TimelineOptions = {
        clickToUse: true,
    };

    MAX_RESULTS = 50;

    public constructor(
        private _ngZone: NgZone,
        private visTimelineService: VisTimelineService,
        private router: Router,
        private elementRef: ElementRef
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.filteredData = this.data.filter((e) => this.isDatePeriodValid(e));
            this.filteredCounter = this.data.length - this.filteredData.length;
            this.visTimelineItems = this.filteredData
                .slice(0, this.MAX_RESULTS)
                .map((elem, index) => {
                    return {
                        id: index,
                        content: this.getContent(elem),
                        start: this.getStartDate(elem),
                        end: this.getEndDate(elem),
                    };
                });
            this.setZoomToBoundaryDates();
        }
    }

    ngAfterViewInit() {
        this._ngZone.runOutsideAngular(() => {
            fromEvent(window, 'scroll', { passive: true })
                .pipe(startWith(0), untilDestroyed(this))
                .subscribe(() => {
                    this.setTimelineBottomPosition();
                });
        });
    }

    timelineInitialized() {
        this.visTimelineService.on(this.id, 'select');
        this.visTimelineService.select.subscribe((eventData) => {
            const target = eventData[1].event.target;
            if (target.tagName === 'A') {
                const routerLink = target.getAttribute('data-router-link');
                if (routerLink) {
                    this._ngZone.run(() => {
                        this.router.navigateByUrl(routerLink);
                    });
                }
            }
        });
        // This event is fired after redraw, which changes position of the bottom part
        // we need to set position again after that
        this.visTimelineService.on(this.id, 'changed');
        this._ngZone.runOutsideAngular(() => {
            this.visTimelineService.changed.subscribe(() => {
                this.setTimelineBottomPosition();
            });
        });
    }

    setZoomToBoundaryDates() {
        if (this.visTimelineItems.length > 0) {
            const toTime = (d: DateType) => new Date(d).getTime();
            const toDate = (d: number) => formatStandardDate(new Date(d));

            // same as reduce but use list head as first value
            const reduceHead =
                <T>(fn: (acc: T, el: T) => T): ((list: T[]) => T) =>
                (list) =>
                    reduce(fn, head(list) as T, list);

            /**
             * finds the date that interests us, from list first takes a property, then reduces those with a given comparator
             * to return the formated date at the end
             */
            const pickDate = <K extends string, T extends Record<K, DateType>>(
                list: T[],
                property: K,
                compare: (a: number, b: number) => number
            ): unknown => {
                return compose<T[], DateType[], number[], number, string>(
                    toDate,
                    reduceHead(compare),
                    map(toTime),
                    // @ts-ignore
                    map(prop(property))
                )(list as any);
            };

            const start = pickDate(this.visTimelineItems, 'start', min) as string;
            const end = pickDate(this.visTimelineItems, 'end', max) as string;

            this.zoom(start, end);
        }
    }

    getName(content: ContentDto): string {
        if (content.title) return content.title;
        if (content.fullNameOfDataSet) return content.fullNameOfDataSet;
        if (content.project?.title) return content.project?.title;
        return '';
    }

    getContent(elem: ContentDto): string {
        if (elem['@type'] === 'Fieldwork') {
            return `<a href="/${typeFromIri(elem.project!['@id'])}/view?iri=${
                elem.project!['@id']
            }" onclick="event.preventDefault()" data-router-link="/${typeFromIri(
                elem.project!['@id']
            )}/view?iri=${elem.project!['@id']}">${this.getName(elem)}</a>`;
        }
        return `<a href="/${typeFromIri(elem['@id'])}/view?iri=${
            elem['@id']
        }" onclick="event.preventDefault()" data-router-link="/${typeFromIri(
            elem['@id']
        )}/view?iri=${elem['@id']}">${this.getName(elem)}</a>`;
    }

    getStartDate(content: ContentDto): string {
        if (content.startDate) return content.startDate.slice(0, 10);
        if (content.start) return content.start.slice(0, 10);
        if (content.projectStart) return content.projectStart.slice(0, 10);
        return '';
    }

    getEndDate(content: ContentDto): string {
        if (content.endDate) return content.endDate.slice(0, 10);
        if (content.end) return content.end.slice(0, 10);
        if (content.projectEnd) return content.projectEnd.slice(0, 10);
        return '';
    }

    zoom(start: string, end: string) {
        this.options = {
            start: start,
            end: end,
            clickToUse: true,
        };
    }

    isDatePeriodValid(content: ContentDto) {
        // filter out content which begins and ends at the same day
        if (this.getStartDate(content) === this.getEndDate(content)) return false;
        return this.getStartDate(content) && this.getEndDate(content);
    }

    setTimelineBottomPosition() {
        const timeline = this.elementRef.nativeElement.querySelector(
            'div.vis-timeline'
        ) as HTMLElement;
        const bottom = this.elementRef.nativeElement.querySelector(
            'div.vis-panel.vis-bottom'
        ) as HTMLElement;

        if (timeline && bottom) {
            // _ _ _ _ _ _ _ _ _
            // |   y|          |
            // | _ _| ______   |
            // | x   |______|  |
            // |_ _ _ _ _ _ _ _|
            //

            // we need to set bottom to fixed position when there is timeline below
            // visible part of the window

            // y changes during scroll, will be below 0 when element is above
            // visible part of the window

            if (
                timeline.getBoundingClientRect().y + timeline.getBoundingClientRect().height >
                window.innerHeight
            ) {
                bottom.style.position = 'fixed';
                bottom.style.bottom = '0px';
                bottom.style.top = 'auto';
                bottom.style.left = `${timeline.getBoundingClientRect().x}px`;
                bottom.style.width = `${timeline.getBoundingClientRect().width}px`;
            } else {
                bottom.style.position = 'absolute';
                bottom.style.left = '0px';
                bottom.style.bottom = '0px';
                bottom.style.top = 'auto';
            }
        }
    }
}
