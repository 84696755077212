import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'isav-status-toggle',
    templateUrl: './status-toggle.html',
    styleUrls: ['./status-toggle.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavStatusToggle {
    @Input() enabled?: boolean;
    @Input() loading?: boolean;
    @Output() enabledChange = new EventEmitter<boolean>();

    get isDefined(): boolean {
        return typeof this.enabled === 'boolean';
    }

    handleDisable() {
        this.enabledChange.emit(false);
    }

    handleEnable() {
        this.enabledChange.emit(true);
    }
}
