import {
    Component,
    ChangeDetectionStrategy,
    Input,
    ViewEncapsulation,
    forwardRef,
    OnChanges,
    ChangeDetectorRef,
    SimpleChanges,
    OnInit,
    Optional,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Iri } from '../../../core/dto/iri';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TaxonomiesService } from '../../../core/taxonomies/taxonomies.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { map, switchMap } from 'rxjs/operators';
import { OrganisationDto } from '../../../core/dto/organisation';
import { BehaviorSubject, noop, identity } from 'rxjs';
import {
    applyTaxonomyRestriction,
    IsavRestrictTaxonomies,
} from '../restrict-taxonomies/restrict-taxonomies';
import { DepartmentDto } from '../../../core/dto/department';
import { sortByTitle } from '../../../core/taxonomies/taxonomies.utils';

let UNIQUE_ID = 0;

@UntilDestroy()
@Component({
    selector: 'isav-department-select',
    templateUrl: './department-select.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavDepartmentSelect),
            multi: true,
        },
    ],
    host: {
        '[attr.id]': 'null',
    },
})
export class IsavDepartmentSelect implements ControlValueAccessor, OnChanges, OnInit {
    @Input() organisationIri: Iri;
    @Input() id = 'department-select-' + UNIQUE_ID++;
    @Input() placeholder = '';
    @Input() dropdownInBody: boolean;
    @Input() short: boolean;

    organisationIri$ = new BehaviorSubject<Iri | null>(null);
    departments: DepartmentDto[] = [];
    value: null | Iri | Iri[];

    private _multiple = false;
    private _onChange: any = noop;
    private _onTouched: any = noop;

    constructor(
        private taxonomiesService: TaxonomiesService,
        private cdRef: ChangeDetectorRef,
        @Optional() private restrictTaxonomies?: IsavRestrictTaxonomies
    ) {}

    @Input() set multiple(value: boolean | string) {
        this._multiple = coerceBooleanProperty(value);
        if (this.value === null && this._multiple) this.value = [];
    }

    get multiple(): boolean | string {
        return this._multiple;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.organisationIri) {
            this.organisationIri$.next(this.organisationIri);
        }
    }

    ngOnInit() {
        this.organisationIri$
            .pipe(
                switchMap((iri: string) => this.taxonomiesService.resolve<OrganisationDto>(iri)),
                map(this.getDepartmentsIris),
                applyTaxonomyRestriction(this.restrictTaxonomies, identity),
                switchMap((iris: Iri[]) => this.taxonomiesService.resolveMany<DepartmentDto>(iris)),
                map((res) => sortByTitle(res)),
                untilDestroyed(this)
            )
            .subscribe((departments) => {
                this.departments = departments;
            });
    }

    onChange(value: any) {
        this.value = value;
        this._onChange(value);
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.cdRef.markForCheck();
    }

    onTouched() {
        this._onTouched();
    }

    iriTrackBy(index: number, department: DepartmentDto): string {
        return department['@id'];
    }

    private getDepartmentsIris(organisation: OrganisationDto): Iri[] {
        return (organisation?.departments ?? []) as Iri[];
    }
}
