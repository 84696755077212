import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { InfrastructureDto } from '../dto/infrastructure';

@Injectable()
export class InfrastructuresResourceService extends BaseSearchService<InfrastructureDto> {
    constructor(http: HttpClient) {
        super('infrastructures', http);
    }
}
