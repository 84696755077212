import { ObserversModule } from '@angular/cdk/observers';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { IsavLoader } from './loader/loader';
import { IsavSmoothHeight } from './smooth-height/smooth-height';
import { UtilitiesModule } from './utilities/utilities.module';
import { IsavTableModule } from './table/table.module';
import { IsavCardModule } from './card/card.module';
import { PortalModule } from '@angular/cdk/portal';

import { IsavAnchorButton, IsavButton } from './button/button';
import { InfoBoxComponent } from './info-box/info-box';
import { TeaserComponent } from './teaser/teaser';
import { IsavCaption } from './caption/caption';
import { IsavDialog } from './dialog/dialog';
import { DialogService } from './dialog/dialog.service';
import { IsavIcon } from './icon/icon';
import { IsavDropdown } from './dropdown/dropdown';
import { IsavDropdownItem } from './dropdown/dropdown-item';
import { IsavDropdownTrigger } from './dropdown/dropdown-trigger';
import { IsavLogo } from './logo/logo';
import { IsavPagination } from './pagination/pagination';
import { IsavActionTitle } from './action-title/action-title';
import { IsavFieldIndex } from './field-index/field-index';
import { IsavFieldIndexDirective } from './field-index/field-index.directive';
import { IsavHelpTooltip } from './help-tooltip/help-tooltip';
import { IsavField } from './field-index/field';
import { IsavLoaderOverlay } from './loader-overlay/loader-overlay';
import { IsavNotification } from './notification/notification';
import { IsavUserBadge } from './user-badge/user-badge';
import { IsavAutocomplete } from './autocomplete/autocomplete';
import { IsavAutocompleteOption } from './autocomplete/autocomplete-option';
import { IsavTabs } from './tabs/tabs';
import { IsavTab } from './tabs/tab/tab';
import { IsavTabTitleDirective } from './tabs/tab/tab-title.directive';
import { IsavSelect } from './select/select';
import { IsavOption } from './select/option';
import { IsavOptgroup } from './select/optgroup';
import { IsavFileDropzone } from './file-dropzone/file-dropzone';
import { IsavFileIcon } from './file-icon/file-icon';
import { IsavPopover } from './popover/popover';
import { IsavTabContentDirective } from './tabs/tab/tab-content.directive';
import { IsavImageLightbox } from './image-lightbox/image-lightbox';
import { IsavImageLightboxDirective } from './image-lightbox/image-lightbox.directive';
import { ImageGalleryModal } from './image-gallery-modal/image-gallery-modal';
import { ImageCarousel } from './image-carousel/image-carousel';

/**
 * Elements that are declared and exported by shared module
 */
const SHARED_ELEMENTS = [
    IsavButton,
    IsavAnchorButton,
    InfoBoxComponent,
    TeaserComponent,
    IsavCaption,
    IsavDialog,
    IsavIcon,
    IsavDropdown,
    IsavDropdownItem,
    IsavDropdownTrigger,
    IsavLogo,
    IsavPagination,
    IsavActionTitle,
    IsavHelpTooltip,
    IsavField,
    IsavFieldIndex,
    IsavFieldIndexDirective,
    IsavLoaderOverlay,
    IsavNotification,
    IsavUserBadge,
    IsavAutocomplete,
    IsavAutocompleteOption,
    IsavTab,
    IsavTabs,
    IsavTabTitleDirective,
    IsavTabContentDirective,
    IsavSelect,
    IsavOption,
    IsavOptgroup,
    IsavFileDropzone,
    IsavFileIcon,
    IsavLoader,
    IsavSmoothHeight,
    IsavPopover,
    IsavImageLightbox,
    IsavImageLightboxDirective,
    ImageGalleryModal,
    ImageCarousel,
];

const SHARED_MODULES = [UtilitiesModule, IsavCardModule, IsavTableModule];

/**
 * Shared module containing of reusable UI components. Components of this module should not
 * depend on any other module inside the application, that involves services too.
 *
 * Utilities module is a module that groups all utilities inside shared components for easier
 * testing (just include UtilitiesModule) to test components in isolation as those sometimes are
 * integral parts of the components itself.
 */
@NgModule({
    imports: [
        CommonModule,
        LayoutModule,
        RouterModule,
        PortalModule,
        ObserversModule,
        SHARED_MODULES,
    ],
    declarations: [SHARED_ELEMENTS],
    exports: [SHARED_ELEMENTS, SHARED_MODULES],
    providers: [DialogService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
