import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IsavBaseControl } from '../base-control';

@Component({
    selector: 'isav-textarea-input',
    templateUrl: './textarea-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavTextareaInput),
            multi: true,
        },
    ],
})
export class IsavTextareaInput extends IsavBaseControl {
    value: string = '';
    placeholder: string = '';

    constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
        super(injector);
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.cdRef.markForCheck();
    }

    onChange(value: string): void {
        this.value = value;
        this._onChange(value);
    }
}
