<div
    (isavDialog)="close()"
    class="ie-warning-message message is-warning"
    aria-modal="true"
    aria-labelledby="ie-warning-header"
    aria-describedby="ie-warning-body"
>
    <div class="message-header">
        <p id="ie-warning-header">Warning on using Internet Explorer</p>
        <button class="delete" aria-label="Close popup" (click)="close()"></button>
    </div>
    <div id="ie-warning-body" class="message-body">
        <p class="mb-2">
            We are very sorry but we are not supporting this outdated browser, you might not be able
            to use all of the features on this website. We advise you to switch to any other modern
            browser which supports latest web features.
        </p>
        <p class="help">
            You can use Microsoft Edge, Chrome, Firefox, Safari or any other that supports newer
            features.
        </p>
    </div>
</div>
