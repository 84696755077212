import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isavEventName',
})
export class IsavEventNamePipe implements PipeTransform {
    transform(value: any): string {
        if (/UnPublished/.test(value)) return 'unpublished by';
        if (/Published/.test(value)) return 'published by';
        if (/Created/.test(value)) return 'created by';
        if (/Revoked/.test(value)) return 'ownership revoked from';
        if (/Shared/.test(value)) return 'ownership shared with';
        if (/Deactivated/.test(value)) return 'deactivated by';
        if (/Activated/.test(value)) return 'activated by';
        if (/Registered/.test(value)) return 'registered';
        if (/NewArmapProjectImported/.test(value)) return 'armap project imported';
        if (/ContentImported/.test(value)) return 'content imported';
        if (/Synced/.test(value)) return 'news synced';
        return 'updated by';
    }
}
