import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ContentCreateAccessDto } from '../../core/dto/content-create-access';
import { ContentCreateAccessService } from '../../core/api/content-create-access.service';

export class HeaderAction {
    constructor(
        public readonly text: string,
        public readonly link: string[],
        public readonly segment: string,
        public readonly contentName: string
    ) {}
}

@Component({
    selector: 'isav-header-actions',
    templateUrl: './header-actions.html',
    styleUrls: ['./header-actions.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderActions implements OnInit {
    allowedActions$: Observable<HeaderAction[]>;
    promotedAction$: Observable<HeaderAction>;
    actions$: Observable<HeaderAction[]>;

    private readonly actions: HeaderAction[] = [
        new HeaderAction('Create project', ['/projects', 'create'], 'projects', 'Project'),
        new HeaderAction(
            'Create infrastructure',
            ['/infrastructures', 'create'],
            'infrastructures',
            'Infrastructure'
        ),
        new HeaderAction('Create logistics', ['/logistics', 'create'], 'logistics', 'Logistic'),
        new HeaderAction('Create data link', ['/datalinks', 'create'], 'datalinks', 'Datalink'),
        new HeaderAction(
            'Create education or training',
            ['/education-and-trainings', 'create'],
            'education-and-trainings',
            'EducationAndTraining'
        ),
        new HeaderAction('Create marker', ['/markers', 'create'], 'markers', 'Marker'),
        new HeaderAction('Create event', ['/events', 'create'], 'events', 'Event'),
        new HeaderAction('Create guide', ['/how-to-guide', 'create'], 'how-to-guide', 'HowToGuide'),
        new HeaderAction('Create news', ['/news', 'create'], 'news', 'News'),
        new HeaderAction('Create page', ['/my-isaaffik/my-pages'], 'my-isaaffik', 'Page'),
    ];

    constructor(
        private router: Router,
        private location: Location,
        private contentCreateAccessService: ContentCreateAccessService
    ) {}

    ngOnInit() {
        this.allowedActions$ = this.contentCreateAccessService
            .getAll()
            .pipe(map((list) => this.filterActions(list)));

        const promoted$: Observable<HeaderAction> = this.router.events.pipe(
            filter((evt) => evt instanceof NavigationEnd),
            map(() => this.getLocation()),
            startWith(this.getLocation()),
            map((segments: string[]) => this.findPromotedAction(segments))
        );

        this.promotedAction$ = combineLatest([promoted$, this.allowedActions$]).pipe(
            map(([promoted, allowed]) => {
                return allowed.find((a) => a?.contentName === promoted?.contentName) || allowed[0];
            })
        );

        this.actions$ = combineLatest([this.promotedAction$, this.allowedActions$]).pipe(
            map(([promoted, allowed]) => {
                return allowed.filter((a) => a?.contentName !== promoted?.contentName);
            })
        );
    }

    filterActions(accessList: ContentCreateAccessDto[]): HeaderAction[] {
        return this.actions.filter((action) => this.hasAccess(action, accessList));
    }

    hasAccess(action: HeaderAction, accessList: ContentCreateAccessDto[]) {
        return accessList.find((a) => a.contentName === action.contentName)?.access || false;
    }

    private getLocation() {
        return this.location.path(false).replace(/\?.*$/, '').split('/');
    }

    private findPromotedAction(segments: string[]): HeaderAction {
        return (
            this.actions.find((action) => segments.some((segment) => action.segment === segment)) ||
            this.actions[0]
        );
    }
}
