import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Inject,
    Injectable,
    Injector,
    Renderer2,
    RendererFactory2,
    TemplateRef,
    Type,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';

export type CloseFn = () => void;

export class DialogManager<D = any, R = never> {
    private readonly _closed$ = new Subject<R | undefined>();

    constructor(private readonly _data?: D) {}

    get data(): D | undefined {
        return this._data;
    }

    get closed$() {
        return this._closed$.asObservable();
    }

    close(returnData?: R) {
        this._closed$.next(returnData);
        this._closed$.complete();
    }
}

@Injectable()
export class DialogService {
    private static renderer2: Renderer2;

    constructor(
        private appRef: ApplicationRef,
        private injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private componentFactoryResolver: ComponentFactoryResolver,
        rendererFactory2: RendererFactory2
    ) {
        if (!DialogService.renderer2)
            DialogService.renderer2 = rendererFactory2.createRenderer(document.body, null);
    }

    spawnTemplate<D>(templateRef: TemplateRef<any>, data?: D): CloseFn {
        let embeddedView: EmbeddedViewRef<any>;

        const closeFn: CloseFn = () => {
            embeddedView.destroy();
        };

        embeddedView = templateRef.createEmbeddedView({ $implicit: data, close: closeFn });

        this.appRef.attachView(embeddedView);
        embeddedView.rootNodes.forEach((rootNode) =>
            DialogService.renderer2.appendChild(this.document.body, rootNode)
        );

        return closeFn;
    }

    spawnComponent<R, D, C>(component: Type<C>, data?: D): DialogManager<D, R> {
        const dialogManager = new DialogManager<D, R>(data);
        const factory = this.componentFactoryResolver.resolveComponentFactory(component);

        let componentRef: ComponentRef<C>;

        const injector = Injector.create({
            providers: [{ provide: DialogManager, useValue: dialogManager }],
            parent: this.injector,
        });

        componentRef = factory.create(injector);

        this.appRef.attachView(componentRef.hostView);

        (componentRef.hostView as EmbeddedViewRef<any>).rootNodes.forEach((rootNode) => {
            DialogService.renderer2.appendChild(this.document.body, rootNode);
        });

        dialogManager.closed$.subscribe(() => {
            componentRef.destroy();
        });

        return dialogManager;
    }
}
