import { Directive, Input, OnInit, HostBinding } from '@angular/core';
import { Iri } from '../../core/dto/iri';
import { ImageService } from '../../core/api/image.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[isavAltImageCaption]',
})
export class IsavAltImageCaption implements OnInit {
    @Input('isavAltImageCaption') iri: Iri;
    @HostBinding('attr.alt') alt = '';

    constructor(private imageService: ImageService) {}

    ngOnInit() {
        if (this.iri !== null) {
            this.imageService
                .getImage$(this.iri)
                .pipe(untilDestroyed(this))
                .subscribe((image) => {
                    this.alt = image.caption ?? '';
                });
        }
    }
}
