import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { SanitizerService } from '../../core/sanitizer/sanitizer.service';

@Component({
    selector: 'isav-content-editor-display',
    templateUrl: './content-editor-display.html',
    styleUrls: ['./content-editor-display.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavContentEditorDisplay implements OnChanges {
    @Input() content?: string = '';
    safeContent: SafeHtml | string = '';

    constructor(private sanitizer: SanitizerService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.content) {
            this.safeContent = this.sanitizer.sanitize(this.content ?? '');
        }
    }
}
