import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { authReducer, featureKey } from './auth.store';
import { PersistentTokenStorage } from './persistent-token-storage';
import { LocalStoragePersistentTokenStorage } from './local-storage-persistent-token-storage';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AuthenticatedGuard } from './authenticated.guard';
import { AuthService } from './auth.service';
import { NotAuthenticatedGuard } from './not-authenticated.guard';
import { IriWithSearchFallbackGuard } from './iri-with-search-fallback.guard';
import { ContentCreateGuard } from './content-create.guard';
import { ContentEditGuard } from './content-edit.guard';
import { throwIfDefined } from '../module-import-guard';
import { RoleGuard } from './role.guard';
import { AdminGuard } from './admin.guard';
import { EffectsModule } from '@ngrx/effects';
import { RefreshTokenEffects } from './refresh-token.effects';
import { StoreTokenEffects } from './store-token.effects';
import { AuthCheckOnVisibilityChangeService } from './auth-check-on-visibility-change.service';

/**
 * Internal module that provides guards and auth services
 * Should not be imported outside CoreModule
 */
@NgModule({
    imports: [
        StoreModule.forFeature(featureKey, authReducer),
        EffectsModule.forFeature([RefreshTokenEffects, StoreTokenEffects]),
    ],
    providers: [
        { provide: PersistentTokenStorage, useClass: LocalStoragePersistentTokenStorage },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        AuthService,
        AuthenticatedGuard,
        NotAuthenticatedGuard,
        IriWithSearchFallbackGuard,
        ContentCreateGuard,
        ContentEditGuard,
        RoleGuard,
        AdminGuard,
    ],
})
export class AuthModule {
    constructor(
        @SkipSelf() @Optional() authModule: AuthModule,
        authCheckOnVisibilityChange: AuthCheckOnVisibilityChangeService
    ) {
        throwIfDefined(authModule, 'AuthModule');
        authCheckOnVisibilityChange.register();
    }
}
