import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Hydra, HydraProperties } from '../dto/hydra';
import { SearchResource } from '../search/search-resource';

export class BaseSearchService<C> implements SearchResource<C> {
    constructor(private resourceName: string, protected http: HttpClient) {}

    getAll(
        queryParams: { [key: string]: string | string[] } = {},
        isSelf?: boolean
    ): Observable<Hydra<C>> {
        const params = new HttpParams({ fromObject: queryParams });
        const url = this.resourceUrl(isSelf);

        return this.http
            .get<HydraProperties<C>>(url, {
                params,
            })
            .pipe(map(Hydra.from));
    }

    getAllAsCsv(
        queryParams: { [key: string]: string | string[] } = {},
        isSelf?: boolean
    ): Observable<string> {
        const params = new HttpParams({ fromObject: queryParams });
        const url = this.resourceUrl(isSelf);
        const headers = new HttpHeaders({
            accept: 'text/csv',
        });

        return this.http.get(url, { headers, params, responseType: 'text' });
    }

    protected resourceUrl(isSelf: boolean = false) {
        return isSelf ? `/api/self/${this.resourceName}` : `/api/${this.resourceName}`;
    }
}
