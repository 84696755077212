import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, Host, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { IsavMapMarker } from '../features/marker';

@Directive({
    selector: '[isavMapOverlayContent]',
})
export class IsavMapOverlayContent {
    @Input('isavMapOverlayContent') uniqueIdentifier: any;

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        @Host() marker: IsavMapMarker
    ) {
        marker.addPopup(this);
    }

    getUniqueIdentifier(): any {
        return this.uniqueIdentifier;
    }

    getPortal(): TemplatePortal {
        return new TemplatePortal<any>(this._templateRef, this._viewContainerRef);
    }
}
