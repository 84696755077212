import { Directive, HostBinding, Input } from '@angular/core';
import { Iri } from '../../core/dto/iri';

@Directive({
    selector: 'img[isavApiImg]',
})
export class IsavApiImg {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('isavApiImg') isavApiImg?: Iri;
    @Input('useFallbackImg') useFallbackImg: boolean = false;
    @Input('alt') @HostBinding('attr.alt') altText = '';

    @HostBinding('attr.src') get src() {
        if (this.isavApiImg) return `${this.isavApiImg}/download`;
        if (this.useFallbackImg) return `/assets/not-found-image.png`;
        return null;
    }
}
