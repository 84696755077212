import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
    constructor(private notificationService: NotificationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap({
                error: ({ error }: HttpErrorResponse) => {
                    const title = error['hydra:title'];
                    const message = error['hydra:description'];
                    if (req.url === '/api/self/content/access-edit-lookup') return;
                    if (title && message) {
                        this.notificationService.add({
                            title,
                            message,
                            delay: 10000,
                            type: 'danger',
                        });
                    }
                },
            })
        );
    }
}
