import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ViewportObserver {
    private readonly mobileQuery = `(max-width: 767px)`;
    private readonly tabletQuery = `(min-width: 768px)`;
    private readonly tabletOrLessQuery = `(max-width: 1023px)`;
    private readonly desktopQuery = `(min-width: 1024px)`;

    constructor(private breakpointObserver: BreakpointObserver) {}

    isMobile(): boolean {
        return this.isMatched(this.mobileQuery);
    }

    isMobile$(): Observable<boolean> {
        return this.isMatched$(this.mobileQuery);
    }

    isTablet(): boolean {
        return this.isMatched(this.tabletQuery);
    }

    isTablet$(): Observable<boolean> {
        return this.isMatched$(this.tabletQuery);
    }

    isTabletOrLess() {
        return this.isMatched(this.tabletOrLessQuery);
    }

    isTabletOrLess$(): Observable<boolean> {
        return this.isMatched$(this.tabletOrLessQuery);
    }

    isDesktop(): boolean {
        return this.isMatched(this.desktopQuery);
    }

    isDesktop$(): Observable<boolean> {
        return this.isMatched$(this.desktopQuery);
    }

    private isMatched(query: string): boolean {
        return this.breakpointObserver.isMatched(query);
    }

    private isMatched$(query: string): Observable<boolean> {
        return this.breakpointObserver.observe(query).pipe(
            map((state) => state.matches),
            startWith(this.isDesktop())
        );
    }
}
