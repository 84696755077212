<isav-select
    [id]="id"
    [placeholder]="placeholder"
    [value]="value"
    (valueChange)="onChange($event)"
    [multiple]="multiple"
    (touched)="onTouched()"
    [dropdownInBody]="dropdownInBody"
    [short]="short"
    [readonly]="departments.length === 0"
>
    <isav-option
        *ngFor="let department of departments; trackBy: iriTrackBy"
        [value]="department['@id']"
    >
        {{ department.title }}
    </isav-option>
</isav-select>
<p class="help" *ngIf="!organisationIri">Select organisation to be able to select department</p>
