import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { NewsSyncImagesDto } from '../dto/news-sync-images';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NewsSyncImagesResourceService extends BaseSearchService<NewsSyncImagesDto> {
    private newImage = new Subject();

    constructor(http: HttpClient) {
        super('news_sync_images', http);
    }

    create(newsSyncImage: Partial<NewsSyncImagesDto>): Observable<NewsSyncImagesDto> {
        return this.http.post<NewsSyncImagesDto>('/api/news_sync_images', newsSyncImage);
    }

    update(newsSyncImage: Partial<NewsSyncImagesDto>, id: string) {
        const headers = new HttpHeaders().append('Content-Type', 'application/merge-patch+json');
        return this.http.patch('/api/news_sync_images/' + id, newsSyncImage, { headers });
    }

    next() {
        this.newImage.next();
    }

    notify() {
        return this.newImage;
    }
}
