import { createSelector } from '@ngrx/store';
import { GlobalSearchState } from './search.store';

export const selectGlobalSearch = (state: any): GlobalSearchState => state.search;
export const selectSearch = createSelector(
    selectGlobalSearch,
    (state: GlobalSearchState, namespace) => {
        return state[namespace];
    }
);
