<div class="user-badge">
    <figure class="image is-24x24 user-badge-element">
        <img class="is-rounded user-image" [isavApiImg]="imageUrl" />
    </figure>
    <span class="user-badge-element user-badge-content">
        <ng-content></ng-content>
    </span>
    <a
        data-testid="user-badge-remove"
        aria-label="remove"
        class="delete user-badge-element"
        (click)="onRemove()"
    ></a>
</div>
