import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MetadataService } from '../../core/services/metadata.service';
import { IsavValidators } from '../../utils/forms/validators';

const INVALID_EMAIL_MSG = 'This needs to be a valid e-mail address!';

@Component({
    selector: 'isav-forgot-password',
    templateUrl: './reset-password-request.html',
    styleUrls: ['./reset-password-request.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordRequestPage implements OnInit {
    formGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', [
            IsavValidators.required(INVALID_EMAIL_MSG),
            IsavValidators.email(INVALID_EMAIL_MSG),
        ]),
    });
    backendError: string = '';
    loading = false;
    success = false;

    constructor(
        private http: HttpClient,
        private metadataService: MetadataService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.metadataService.setTitle('Request reset password');
        this.metadataService.setDescription(
            'Request your Isaaffik account password reset in few simple steps.'
        );
    }

    onSubmit(): void {
        if (!this.formGroup.valid) return;

        this.loading = true;
        this.http.post(`/api/person/reset-password-request`, this.formGroup.value).subscribe(
            () => {
                this.backendError = '';
                this.loading = false;
                this.success = true;
                this.cdRef.markForCheck();
            },
            (err) => {
                console.error(err);
                this.loading = false;
                this.backendError = err.error?.['hydra:description'] ?? '';
                this.cdRef.markForCheck();
            }
        );
    }
}
