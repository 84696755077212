import { Pipe, PipeTransform } from '@angular/core';
import { formatIsavDate } from '../../utils/date';

@Pipe({
    name: 'isavDate',
    pure: true,
})
export class IsavDatePipe implements PipeTransform {
    transform(value: any, ...args): any {
        // new Date(null) -> 01/01/1970
        if (value === null) return '';
        const date = new Date(value);
        if (Number.isNaN(date.getTime())) return '';
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return formatIsavDate(new Date(date.getTime() - userTimezoneOffset));
    }
}
