import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[isavEscapeClicked]',
})
export class EscapeClickedDirective {
    @Output('isavEscapeClicked') escapeClicked = new EventEmitter<KeyboardEvent>();

    @HostListener('document:keydown', ['$event'])
    handleDocumentKeydown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.escapeClicked.emit(event);
        }
    }
}
