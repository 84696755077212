import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SarDto } from '../../core/dto/sar';

@Component({
    selector: 'isav-sar-display',
    templateUrl: './sar-display.html',
    styleUrls: ['./sar-display.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavSarDisplay {
    @Input() sar: SarDto;
    @Input() noAssetInfo: string | null = null;
}
