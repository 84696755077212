import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    OnInit,
    OnDestroy,
    AfterViewInit,
} from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ViewportObserver } from '../../../shared/viewport/viewport';

@UntilDestroy()
@Component({
    selector: 'isav-landing-page-carousel',
    templateUrl: './landing-page-carousel.html',
    styleUrls: ['./landing-page-carousel.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavLandingPageCarousel implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('images') images: ElementRef<HTMLDivElement>;

    imagesArray = [
        {
            name: 'overview',
            sloganTitle: 'GET AN OVERVIEW',
            sloganText:
                'Search for projects, fieldwork, infrastructure, education, and much more. View search results on either list, map, or timeline.',
            placement: 'bottom',
        },
        {
            name: 'collaborate',
            sloganTitle: 'COLLABORATE – EXPAND YOUR NETWORK',
            sloganText:
                'Find other Arctic professionals that share your interests to collaborate with.',
            placement: 'bottom',
        },
        {
            name: 'share',
            sloganTitle: 'SHARE LOGISTICS',
            sloganText:
                'Planning an expedition to remote locations in the Arctic? Post your own travel idea to find collaborators to share expenses and cut costs.',
            placement: 'top',
        },
        {
            name: 'ship',
            sloganTitle: 'APPLY FOR SUPPORT',
            sloganText:
                'Need logistic support or support for scientific activities? Apply Joint Arctic Command.',
            placement: 'top',
        },
        {
            name: 'create',
            sloganTitle: 'CREATE YOUR OWN CONTENT',
            sloganText:
                'Join ISAAFFIK now and start adding your own content: Projects, fieldworks, logistics, data-links, news, and much more.',
            placement: 'bottom',
        },
    ];

    numbers: number[];
    moveWidth: number;
    currentImage = 0;
    interval$: Subscription;

    constructor(private viewport: ViewportObserver) {}

    ngOnInit() {
        this.numbers = Array.from(Array(this.imagesArray.length).keys());
        this.interval$ = interval(10000).subscribe(() => {
            this.changeImage();
        });
        this.viewport
            .isDesktop$()
            .pipe(untilDestroyed(this))
            .subscribe((desktop) => {
                this.moveWidth = desktop ? -450 : -350;
            });
    }

    ngAfterViewInit() {
        this.images.nativeElement.addEventListener('transitionend', () => {
            if (this.currentImage === this.imagesArray.length) {
                this.images.nativeElement.style.transition = `none`;
                this.currentImage = 0;
                this.images.nativeElement.style.transform = `translateX(0px)`;
            }
        });
    }

    ngOnDestroy() {
        this.interval$.unsubscribe();
    }

    changeImage() {
        if (this.currentImage < this.imagesArray.length) {
            this.currentImage++;
            this.images.nativeElement.style.transition = `transform 1s ease-out`;
            this.images.nativeElement.style.transform = `translateX(${
                this.currentImage * this.moveWidth
            }px)`;
        }
    }
}
