import { AfterContentInit, Directive, HostBinding, Input, OnDestroy, Self } from '@angular/core';
import { IsavFieldIndexDirective } from './field-index.directive';
import { AbstractControl, NgControl } from '@angular/forms';
import { IsavFieldAccessor } from './field-accessor';

let UNIQUE_ID = 0;

@Directive({
    selector: '[isavField]',
})
export class IsavField implements IsavFieldAccessor, AfterContentInit, OnDestroy {
    @Input() @HostBinding('attr.id') id = 'isav-field-' + UNIQUE_ID++;
    @Input('mandatory') isMandatory = false;
    @Input('isavField') differentName: string;
    @Input('name') controlName: string;

    constructor(private fieldIndex: IsavFieldIndexDirective, @Self() public ngControl: NgControl) {}

    get control(): AbstractControl | undefined {
        return this.ngControl.control || undefined;
    }

    get name(): string {
        return this.differentName || this.controlName;
    }

    ngAfterContentInit() {
        this.fieldIndex.registerField(this);
    }

    ngOnDestroy() {
        this.fieldIndex.unregisterField(this);
    }
}
