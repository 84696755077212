<div class="modal is-active" (isavDialog)="close()">
    <div class="modal-background"></div>
    <div class="modal-card is-relative">
        <div class="modal-card-head">
            <p class="modal-card-title">
                <span>Images upload</span>
            </p>
            <button class="delete" aria-label="close" type="button" (click)="close()"></button>
        </div>

        <div class="modal-card-body" [ngSwitch]="status">
            <ng-container *ngSwitchCase="'NO_IMAGES'">
                <ng-container *ngTemplateOutlet="noImagesTemp"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'IMAGES'">
                <ng-container *ngTemplateOutlet="imagesTemp"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'UPLOADING_IMAGES'">
                <ng-container *ngTemplateOutlet="uploadingTemp"></ng-container>
            </ng-container>
        </div>

        <div class="modal-card-foot">
            <button isavBtn color="primary" (click)="upload()">Upload</button>
            <button data-testid="btn-close" isavBtn (click)="close()">Close</button>
        </div>

        <isav-loader-overlay *ngIf="loading"></isav-loader-overlay>
    </div>
</div>

<ng-template #imagesTemp>
    <div
        *ngFor="let image of images; trackBy: imagesTrackBy; let index = index"
        class="columns is-mobile mb-0"
    >
        <div class="column is-1 image-icon">
            <isav-file-icon [mimeType]="image.type"></isav-file-icon>
        </div>
        <div class="column is-10 image-name">
            <span>{{ image.name }}</span>
        </div>
        <div class="column is-1 delete-button">
            <button isavBtn color="danger" (click)="remove(index)" [disabled]="images.length < 2">
                <isav-icon icon="trash"></isav-icon>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #noImagesTemp>
    <div class="no-images">
        <div class="uploader-zone dropzone" (isavFileDropped)="onFileDropped($event)">
            <div class="uploader-content">
                <div>Drag and drop images here</div>
                <div>or</div>
                <div class="file is-centered is-primary">
                    <label class="file-label">
                        <input
                            [multiple]="true"
                            class="file-input"
                            type="file"
                            (change)="onFileSelected($event)"
                        />
                        <span class="file-cta">
                            <span class="file-icon">
                                <i class="fas fa-upload"></i>
                            </span>
                            <span class="file-label"> Choose an images </span>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #uploadingTemp>
    <div
        *ngFor="let image of images; trackBy: imagesTrackBy; let index = index"
        class="columns is-mobile mb-0"
    >
        <div class="column is-1 image-icon">
            <isav-file-icon [mimeType]="image.type"></isav-file-icon>
        </div>
        <div class="column is-6 is-11-mobile image-name">
            <span>{{ names[index] }}</span>
        </div>
        <div class="column is-5 is-12-mobile progress-container">
            <progress class="progress is-primary" [value]="progress[index]" [max]="100">
                {{ progress[index] }}%
            </progress>
        </div>
    </div>
</ng-template>
