import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TokenDto } from '../dto/token';

@Injectable()
export class TokenService {
    constructor(private http: HttpClient) {}

    postAuthToken(payload: { email: string; password: string }): Observable<TokenDto> {
        return this.http.post<TokenDto>(`/api/auth/token`, payload);
    }

    postAuthTokenRefresh(payload: { refresh_token: string }): Observable<TokenDto> {
        return this.http.post<TokenDto>(`/api/auth/token/refresh`, payload);
    }
}
