import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { throwIfDefined } from './module-import-guard';
import { searchReducer } from './search/search.store';
import { SearchEffects } from './search/search.effects';
import { TaxonomiesModule } from './taxonomies/taxonomies.module';
import { coreImports } from '../../environments/environment';
import { MetadataService } from './services/metadata.service';
import { AuthModule } from './auth/auth.module';
import { NotificationService } from './services/notification.service';
import { ApiModule } from './api/api.module';
import { NotificationInterceptor } from './services/notification.interceptor';
import { FileService } from './services/file.service';
import { BrowserDetectionService } from './services/browser-detection.service';
import { SanitizerModule } from './sanitizer/sanitizer.module';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot({
            router: routerReducer,
            search: searchReducer,
        }),
        EffectsModule.forRoot([SearchEffects]),
        StoreRouterConnectingModule.forRoot(),
        ApiModule,
        AuthModule,
        TaxonomiesModule,
        SanitizerModule,
        coreImports,
    ],
    providers: [
        MetadataService,
        NotificationService,
        FileService,
        { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true },
        BrowserDetectionService,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() coreModule: CoreModule) {
        throwIfDefined(coreModule, 'CoreModule');
    }
}
