<p>
    &raquo;&nbsp;
    <ng-container *ngIf="page.externalLink?.url; else internal">
        <a [href]="page.externalLink?.url" target="_blank">{{ page.title }}</a>
    </ng-container>
    <ng-template #internal>
        <a data-cy="page-link" [routerLink]="['/pages/view']" [queryParams]="{ iri: page['@id'] }"
            >{{ page.title }}</a
        >
    </ng-template>
</p>
