<div isavFieldIndex (focusin)="focusin()" class="is-relative">
    <div class="field is-horizontal mb-2">
        <div class="field-label field-label-tooltip">
            <span class="has-text-weight-bold has-text-primary">{{label}}</span>
            <span *ngIf="mandatory" class="has-text-danger"> *</span>
            <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
        </div>

        <div class="field-body">
            <div class="field">
                <div class="control">
                    <button
                        [id]="id"
                        isavBtn
                        color="success"
                        size="small"
                        type="button"
                        (click)="addControl()"
                        data-testid="addbtn"
                    >
                        <isav-icon icon="plus"></isav-icon>
                        <span>{{ addLabel }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div
        *ngFor="let control of controls.controls; trackBy: trackBy; let even = even; let index = index"
        class="many-input-container"
        [class.has-background-primary-light]="even"
    >
        <ng-container
            *ngTemplateOutlet="
                manyInputControl?.template;
                context: { $implicit: control, control: control, index: index, position: index + 1 }
            "
        >
        </ng-container>

        <div class="field is-horizontal">
            <div class="field-label field-label-tooltip"></div>
            <div class="field-body">
                <button
                    isavBtn
                    color="danger"
                    type="button"
                    size="small"
                    class="mt-2"
                    (click)="removeControl(index)"
                    data-testid="removebtn"
                >
                    <isav-icon icon="trash"></isav-icon>
                    <span>{{ removeLabel }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
