import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[type=date]',
})
export class IsavDateInputDirective implements OnChanges {
    @HostBinding('placeholder')
    readonly DATE_FORMAT_PLACEHOLDER = 'YYYY-MM-DD';

    @Input() placeholder: string = '';
    private warned = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.placeholder && changes.placeholder.currentValue && !this.warned) {
            console.warn(
                'input[type=date] should not have placeholder set to any value other then the accepted format. Please remove placeholder as it is automatically set!'
            );
            this.warned = true;
        }
    }
}
