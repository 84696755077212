import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { IsavMap } from '../map';
import { IsavMapVectorSource } from './vector-source';

@UntilDestroy()
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'isav-map-area',
})
export class IsavMapArea implements OnChanges, OnInit, OnDestroy {
    @Input() fit = false;
    @Input() coordinate: Coordinate[] | null; // WGS84 coordinates

    private readonly _feature: Feature<Polygon>;

    constructor(private vectorSource: IsavMapVectorSource, private isavMap: IsavMap) {
        this._feature = new Feature();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.coordinate && !changes.coordinate.isFirstChange()) {
            this.updateCoordinates();
        }
    }

    ngOnInit() {
        this.updateCoordinates();

        if (this.fit && this.coordinate) {
            this.isavMap.view.fit(this._feature.getGeometry());
        }

        this.isavMap.viewChanged
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateCoordinates());
    }

    ngOnDestroy() {
        if (this._feature.getGeometry()) {
            this.vectorSource.getVectorSource().removeFeature(this._feature);
        }
        this._feature.dispose();
    }

    private updateCoordinates() {
        const polygon: Polygon | undefined = this._feature.getGeometry();
        const coordinate = this.coordinate?.map((c) => this.isavMap.toMapCoordinate(c)) ?? [];

        if (coordinate && coordinate.length) {
            if (!polygon) {
                this._feature.setGeometry(new Polygon([coordinate]));
                this.vectorSource.getVectorSource().addFeature(this._feature);
            } else {
                polygon.setCoordinates([coordinate]);
            }
        } else {
            if (polygon) {
                this.vectorSource.getVectorSource().removeFeature(this._feature);
            }
            this._feature.setGeometry(undefined);
        }
    }
}
