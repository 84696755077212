import { Directive, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { IsavMap } from '../map';
import { IsavMapVectorSource } from './vector-source';

const styleCache = {};

function clusterStyle(feature: FeatureLike): Style {
    const features = feature.get('features');
    const size = features.length;

    if (size === 1) {
        return features[0].getStyle();
    }

    let style = styleCache[size];
    if (!style) {
        style = new Style({
            image: new Circle({
                radius: 10,
                stroke: new Stroke({
                    color: '#fff',
                }),
                fill: new Fill({
                    color: '#808080',
                }),
            }),
            text: new Text({
                text: size.toString(),
                fill: new Fill({
                    color: '#fff',
                }),
            }),
        });
        styleCache[size] = style;
    }
    return style;
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'isav-map-clustered-vector-layer',
    providers: [
        {
            provide: IsavMapVectorSource,
            useExisting: forwardRef(() => IsavMapClusteredVectorLayer),
        },
    ],
})
export class IsavMapClusteredVectorLayer extends IsavMapVectorSource implements OnInit, OnDestroy {
    private _features = new VectorSource({
        wrapX: false,
    });
    private _layer: VectorLayer = new VectorLayer({
        source: new Cluster({
            distance: 30,
            source: this._features,
        }),
        style: clusterStyle,
        zIndex: 100,
    });

    constructor(private map: IsavMap) {
        super();
    }

    ngOnInit(): void {
        this.map.map.addLayer(this._layer);
    }

    ngOnDestroy() {
        this.map.map.removeLayer(this._layer);
    }

    getVectorSource(): VectorSource {
        return this._features;
    }

    center(coordinate: Coordinate): void {
        this.map.view.setCenter(coordinate);
    }

    fromSourceCoordinate(coordinate: Coordinate): Coordinate {
        return this.map.fromMapCoordinate(coordinate);
    }

    toSourceCoordinate(coordinate: Coordinate): Coordinate {
        return this.map.toMapCoordinate(coordinate);
    }
}
