import { Pipe, PipeTransform } from '@angular/core';
import { GeoLocationDto } from '../../core/dto/geo-location';
import { GeoLocationReadCollectionDto } from '../../core/dto/geo-location-read-collection';
import { environment } from '../../../environments/environment';

@Pipe({
    name: 'isavGeoCoordinates',
    pure: true,
})
export class IsavGeoCoordinatesPipe implements PipeTransform {
    transform(value: Partial<GeoLocationDto | GeoLocationReadCollectionDto>): string {
        if (value === null || value === undefined) {
            return '';
        }

        const { longitude, latitude } = value;

        if (typeof longitude !== 'number' || typeof latitude !== 'number') {
            if (!environment.production) {
                const pipeName = this.constructor.name ?? 'IsavGeoCoordinatesPipe';
                throw new Error(`${pipeName} received strange data "${JSON.stringify(value)}"`);
            }
            return '';
        }

        return `${latitude.toFixed(5)} N, ${longitude.toFixed(5)} W`;
    }
}
