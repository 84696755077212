import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ContentService } from './content.service';
import { ContentCreateAccessDto } from '../dto/content-create-access';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class ContentCreateAccessService {
    private readonly createAccessList$: Observable<Array<ContentCreateAccessDto>>;

    constructor(private contentService: ContentService, private authService: AuthService) {
        const loadAccessList = this.contentService.getCreateAccessList();
        this.createAccessList$ = this.authService
            .authenticated$()
            .pipe(
                switchMap((isAuthenticated) =>
                    isAuthenticated ? loadAccessList.pipe(shareReplay(1)) : of([])
                )
            );
    }

    getAll(): Observable<ContentCreateAccessDto[]> {
        return this.createAccessList$;
    }

    getAccess(name: string): Observable<boolean> {
        return this.createAccessList$.pipe(
            map((contents) => contents.find((x) => x.contentName === name)?.access ?? false)
        );
    }
}
