import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentDto } from '../dto/document';
import { ImageDto } from '../dto/image';

@Injectable()
export class UploadService {
    constructor(private http: HttpClient) {}

    uploadImage(file: File): Observable<HttpEvent<ImageDto>> {
        const formData = new FormData();
        formData.append('file', file, file.name);

        const req = new HttpRequest('POST', `/api/images`, formData, {
            reportProgress: true,
        });

        return this.http.request(req);
    }

    uploadDocument(file: File, name: string | null = null): Observable<HttpEvent<DocumentDto>> {
        const formData = new FormData();
        formData.append('file', file, name ?? file.name);

        const req = new HttpRequest('POST', '/api/documents', formData, {
            reportProgress: true,
        });

        return this.http.request(req);
    }
}
