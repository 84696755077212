<div class="modal is-active" (isavDialog)="close()">
    <div class="modal-background"></div>
    <div class="modal-card">
        <div class="modal-card-head">
            <p class="modal-card-title">
                <span>Confirm deletion</span>
            </p>
            <button class="delete" aria-label="close" type="button" (click)="close()"></button>
        </div>

        <div class="modal-card-body">
            <span *ngIf="!wipe"> Are you sure you want to permanently remove {{ name }} ? </span>
            <span *ngIf="wipe">
                Are you sure you want to permanently wipe {{ name }}'s data ?
            </span>
        </div>

        <div class="modal-card-foot">
            <button data-testid="btn-delete" isavBtn color="danger" (click)="close(true)">
                Delete
            </button>
            <button data-testid="btn-close" isavBtn (click)="close()">Close</button>
        </div>
    </div>
</div>
