import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Iri } from '../../../core/dto/iri';
import { DomainEventJournalsService } from '../../../core/api/domain-event-journals.service';
import { Observable } from 'rxjs';
import { DomainEventJournalDto } from '../../../core/dto/domain-event-journal';
import { map } from 'rxjs/operators';

@Component({
    selector: 'isav-domain-event-journal-list',
    templateUrl: './domain-event-journal-list.html',
    styleUrls: ['./domain-event-journal-list.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavDomainEventJournalList implements OnChanges {
    @Input() iri: Iri;
    @Input() showAll: boolean = true;
    @Input() eventName: string;

    shownUpdates: number = 1;
    domainEventJournal$: Observable<DomainEventJournalDto[]>;

    constructor(private domainEventJournalsService: DomainEventJournalsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.iri && this.iri) {
            const uuid = this.getUuidFromIri(this.iri);
            this.domainEventJournal$ = this.domainEventJournalsService
                .getAll({
                    entityId: uuid,
                    'order[recordedAt]': 'desc',
                })
                .pipe(
                    map((eventJournal) =>
                        this.eventName
                            ? eventJournal.filter((event) => event.eventName === this.eventName)
                            : eventJournal
                    )
                );
        }
    }

    /**
     * Retrieves a uuid part from iri. Each iri will be in format /api/:type/:uuid and we take
     * advantage of that to get uuid.
     */
    getUuidFromIri(iri: Iri): string {
        return iri.split('/')[3];
    }
}
