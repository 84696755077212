import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Iri } from '../../core/dto/iri';
import { ImageDto } from '../../core/dto/image';
import { Observable, EMPTY } from 'rxjs';
import { ImageService } from '../../core/api/image.service';

@Component({
    selector: 'isav-image-caption',
    templateUrl: './image-caption.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavImageCaption implements OnChanges {
    @Input() iri: Iri;
    image$: Observable<ImageDto>;

    constructor(private imageService: ImageService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.iri) {
            if (this.iri !== null) {
                this.image$ = this.imageService.getImage$(this.iri);
            } else {
                this.image$ = EMPTY;
            }
        }
    }
}
