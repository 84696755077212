<div
    *ngIf="canCollapse && isCollapsed"
    [innerHTML]="safeExcerpt"
    aria-hidden="true"
    class="is-hidden-print"
></div>
<div
    [innerHTML]="safeContent"
    [attr.id]="id"
    class="content"
    [class.is-hidden-not-print]="canCollapse && isCollapsed"
></div>
<button
    *ngIf="canCollapse"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-controls]="id"
    [attr.aria-expanded]="(!isCollapsed).toString()"
    [attr.aria-label]="buttonLabel"
    class="link-button is-hidden-print"
>
    Read {{ isCollapsed ? 'more' : 'less' }}
</button>
