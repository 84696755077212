import {
    ChangeDetectorRef,
    Directive,
    EmbeddedViewRef,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ContentService } from '../../core/api/content.service';
import { AuthService } from '../../core/auth/auth.service';
import { Iri } from '../../core/dto/iri';
import { switchTruthyOrFalse } from '../../utils/rxjs/switch-truthy-or-false';

@Directive({
    selector: '[isavHasEditAccess]',
})
export class IsavHasEditAccess implements OnInit, OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('isavHasEditAccessElse') elseTemplate?: TemplateRef<any>;

    private _iri$ = new ReplaySubject<Iri>(1);
    private _renderedTemplate?: EmbeddedViewRef<any>;

    constructor(
        private _authService: AuthService,
        private _templateRef: TemplateRef<any>,
        private _vc: ViewContainerRef,
        private _contentService: ContentService,
        private _cdRef: ChangeDetectorRef
    ) {}

    @Input('isavHasEditAccess')
    set iri(iri: string) {
        this._iri$.next(iri);
    }

    ngOnInit() {
        this._authService
            .authenticated$()
            .pipe(
                switchTruthyOrFalse(() => this._iri$.asObservable()),
                switchTruthyOrFalse((iri) => this._contentService.getEditAccess(iri)),
                distinctUntilChanged()
            )
            .subscribe((hasAccess) => {
                this.remove();
                this.render(hasAccess ? this._templateRef : this.elseTemplate);
                this._cdRef.markForCheck();
            });
    }

    ngOnDestroy() {
        this._iri$.complete();
    }

    private render(template?: TemplateRef<any>) {
        if (!template) return;
        this._renderedTemplate = this._vc.createEmbeddedView(template);
    }

    private remove() {
        if (!this._renderedTemplate) return;

        const index = this._vc.indexOf(this._renderedTemplate);
        this._vc.remove(index);
        this._renderedTemplate = undefined;
    }
}
