<span class="sr-only" [id]="labelId">{{placeholder}}:</span>
<button
    class="button isav-select-button"
    aria-haspopup="listbox"
    type="button"
    [id]="id"
    [attr.aria-labelledby]="labelledBy"
    [attr.aria-owns]="listboxId"
    [attr.aria-expanded]="open || null"
    (click)="toggleDropdown()"
    (keydown.arrowDown)="openDropdown()"
    (keydown.arrowUp)="openDropdown(true)"
    [class.is-disabled]="readonly"
    #buttonEl
>
    <span class="isav-select-button-label-container" data-testid="placeholder">
        <span *ngIf="model.isEmpty()" aria-hidden="true" class="isav-select-button-label">
            {{placeholder}}
        </span>
        <span *ngIf="!model.isEmpty()" [id]="textId" class="isav-select-button-label">
            {{viewText}}
        </span>
    </span>
    <span class="icon is-small isav-select-icon" aria-hidden="true">
        <i class="fas fa-chevron-down"></i>
    </span>
</button>

<div
    role="listbox"
    tabindex="-1"
    class="dropdown-content isav-select-listbox"
    [class.is-short]="short"
    [id]="listboxId"
    [style.display]="open ? 'block' : 'none'"
    [class.is-shown]="open"
    [attr.aria-labelledby]="labelId"
    [attr.aria-multiselectable]="multiple || null"
    [attr.aria-activedescendant]="activeDescendantManager.activeItem?.id || null"
    [attr.aria-readonly]="readonly"
    (keydown.escape)="closeDropdown()"
    (keydown.enter)="selectActivated()"
    (keydown)="handleKeydown($event)"
    (isavClickedOutside)="handleOutsideClick($event)"
    #listboxEl
>
    <ng-content></ng-content>
</div>
