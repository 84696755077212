import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomainEventJournalDto } from '../dto/domain-event-journal';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { Hydra, HydraProperties } from '../dto/hydra';

@Injectable()
export class DomainEventJournalsService {
    constructor(private http: HttpClient) {}

    getAll(
        queryParams: { [key: string]: string | string[] } = {}
    ): Observable<DomainEventJournalDto[]> {
        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<HydraProperties<DomainEventJournalDto>>('/api/domain_event_journals', { params })
            .pipe(map(Hydra.from), pluck('member'));
    }
}
