import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';

@Injectable()
export class ResetPasswordGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const hasToken = route.queryParamMap.has('token');
        const hasEmail = route.queryParamMap.has('email');
        return (hasToken && hasEmail) || this.router.createUrlTree(['/']);
    }
}
