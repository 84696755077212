import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FieldworkProjectReadFieldworkReadSarReadDto } from '../../core/dto/fieldwork-project-read-fieldwork-read-sar-read';

@Component({
    selector: 'isav-project-fieldworks',
    templateUrl: './project-fieldworks.html',
    styleUrls: ['./project-fieldworks.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavProjectFieldworks {
    @Input() fieldworks: FieldworkProjectReadFieldworkReadSarReadDto[];
    @Input() fieldworkIncludeOutsideSettlements: boolean;
    shownFieldworks = 2;

    showAll(number: number) {
        this.shownFieldworks = number;
    }

    hide() {
        this.shownFieldworks = 2;
    }
}
