<ng-container *ngFor="let skill of sar.skills">
    <div data-testid="sar-skills" class="columns sar-view">
        <div class="column is-1">
            <span>{{skill.numberOfParticipants}}</span>
        </div>
        <div class="column is-5">
            <isav-taxonomies-names [iris]="[skill.sarSkill]"></isav-taxonomies-names>
        </div>
        <div class="column is-6">
            <span>{{skill.description}}</span>
        </div>
    </div>
</ng-container>
<ng-container *ngFor="let equipment of sar.equipments">
    <div data-testid="sar-equipments" class="columns sar-view">
        <div class="column is-1">
            <span>{{equipment.numberOfParticipants}}</span>
        </div>
        <div class="column is-5">
            <isav-taxonomies-names [iris]="[equipment.sarEquipment]"></isav-taxonomies-names>
        </div>
        <div class="column is-6">
            <span>{{equipment.description}}</span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="noAssetInfo && sar.equipments.length === 0 && sar.skills.length === 0">
    {{ noAssetInfo }}
</ng-container>
