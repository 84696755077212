import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { EducationAndTrainingDto } from '../dto/education-and-training';

@Injectable()
export class EducationAndTrainingsResourceService extends BaseSearchService<EducationAndTrainingDto> {
    constructor(http: HttpClient) {
        super('education_and_trainings', http);
    }
}
