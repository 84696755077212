import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmailDto } from '../dto/email';

@Injectable()
export class MailsService {
    constructor(private http: HttpClient) {}

    sendEmails(email: EmailDto) {
        return this.http.post('/api/mails', email);
    }
}
