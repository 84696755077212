import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NoteDto } from '../dto/note';
import { Observable, Subject } from 'rxjs';
import { map, pluck, filter } from 'rxjs/operators';
import { Hydra, HydraProperties } from '../dto/hydra';
import { Iri } from '../dto/iri';
import { BaseSearchService } from './base-search-service';

interface INoteService {
    updated(contentIri: Iri): void;
    notify(contentIri: Iri): Observable<Iri>;
}

@Injectable()
export class NotesResourceService extends BaseSearchService<NoteDto> implements INoteService {
    private updatedContent$ = new Subject<Iri>();

    constructor(http: HttpClient) {
        super('notes', http);
    }

    getAllSelf(queryParams: { [key: string]: string | string[] } = {}): Observable<NoteDto[]> {
        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<HydraProperties<NoteDto>>('/api/self/notes', { params })
            .pipe(map(Hydra.from), pluck('member'));
    }

    create(note: Partial<NoteDto>): Observable<NoteDto> {
        return this.http.post<NoteDto>(`/api/notes`, note);
    }

    updated(contentIri: Iri) {
        this.updatedContent$.next(contentIri);
    }

    notify(contentIri: Iri): Observable<Iri> {
        return this.updatedContent$.pipe(filter((note) => contentIri === note));
    }
}
