import { ChangeDetectionStrategy, Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'isav-go-to-view',
    templateUrl: './go-to-view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavGoToView {
    @Input() disabled: boolean = false;
}
