import {
    Directive,
    EmbeddedViewRef,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[isavUserHasRole]',
})
export class IsavUserHasRoleDirective implements OnChanges, OnDestroy {
    @Input('isavUserHasRole') match: string[] = [];

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('isavUserHasRoleElse') elseTemplate?: TemplateRef<any>;

    private _renderedView: EmbeddedViewRef<any> | null = null;

    constructor(
        private readonly _templateRef: TemplateRef<any>,
        private readonly _authService: AuthService,
        private readonly _viewContainerRef: ViewContainerRef
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.match) {
            this._authService
                .roles$()
                .pipe(untilDestroyed(this))
                .subscribe((roles) => {
                    if (this.hasRole(roles)) {
                        this.render();
                    } else {
                        this.renderElse();
                    }
                });
        }
    }

    ngOnDestroy() {
        this.clearView();
    }

    render() {
        this.clearView();
        this._renderedView = this._viewContainerRef.createEmbeddedView(this._templateRef);
    }

    renderElse() {
        this.clearView();
        if (!this.elseTemplate) return;

        this._renderedView = this._viewContainerRef.createEmbeddedView(this.elseTemplate);
    }

    clearView() {
        this._renderedView?.destroy();
        this._renderedView = null;
    }

    hasRole(roles: string[]): boolean {
        return roles.some((role) => this.match.includes(role));
    }
}
