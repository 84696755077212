import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { NotesResourceService } from '../../core/api/notes-resource.service';
import { NoteDto } from '../../core/dto/note';
import { Observable } from 'rxjs';
import { Iri } from '../../core/dto/iri';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
    selector: 'isav-notes',
    templateUrl: './notes.html',
    styleUrls: ['./notes.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavNotes implements OnInit {
    @Input() iri: Iri;

    shownNotes: number = 2;

    notes$: Observable<NoteDto[]>;

    constructor(private notesService: NotesResourceService, private _cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.notes$ = this.notesService.getAllSelf({
            'content.id': this.iri,
            'order[createdAt]': 'desc',
        });
        this.notesService
            .notify(this.iri)
            .pipe(untilDestroyed(this))
            .subscribe((iri) => {
                this.notes$ = this.notesService.getAllSelf({
                    'content.id': iri,
                    'order[createdAt]': 'desc',
                });
                this._cdr.markForCheck();
            });
    }

    show(length: number) {
        this.shownNotes = length;
    }

    hide() {
        this.shownNotes = 2;
    }
}
