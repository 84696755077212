<div
    *ngIf="results.length && isOpen"
    class="results"
    [id]="getUniqueId('popup')"
    role="listbox"
    #resultListElement
>
    <button
        *ngFor="let result of results; let index = index"
        [id]="getUniqueOptionId(selected)"
        class="result"
        [class.is-selected]="selected === index"
        role="option"
        [attr.aria-selected]="(selected === index) ? 'true' : 'false'"
        (mouseenter)="selected = index"
        (click)="select(result)"
    >
        <strong>{{ result.name }}</strong>
        ({{ result.latitude.toFixed(3) }},&nbsp;{{ result.longitude.toFixed(3) }})
    </button>
</div>
<div
    class="ol-control ol-unselectable search-container"
    role="combobox"
    aria-haspopup="listbox"
    [attr.aria-owns]="getUniqueId('popup')"
    [attr.aria-expanded]="isOpen ? 'true' : 'false'"
>
    <input
        type="search"
        class="search-input"
        [formControl]="control"
        [class.is-expanded]="expanded"
        (keydown.enter)="select(results[selected])"
        (keydown.arrowUp)="handleArrowKeydown($event, -1)"
        (keydown.arrowDown)="handleArrowKeydown($event, 1)"
        aria-autocomplete="list"
        [attr.aria-controls]="getUniqueId('popup')"
        [attr.aria-activedescendant]="getUniqueOptionId(selected)"
        aria-multiselectable="false"
        (focus)="isOpen = true"
        #inputElement
    />
    <button type="button" class="search-btn" (click)="search()" *ngIf="expanded">
        <isav-icon *ngIf="!isLoading" icon="search"></isav-icon>
        <isav-loader *ngIf="isLoading"></isav-loader>
    </button>
    <button type="button" class="toggle-btn" (click)="toggle()">
        <isav-icon [icon]="expanded ? 'times' : 'search'"></isav-icon>
    </button>
    <isav-tooltip-display
        *ngIf="!expanded"
        [map]="true"
        [tooltipKey]="'Map:search'"
    ></isav-tooltip-display>
</div>
