import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { IsavMap } from '../map';

@Directive({
    selector: '[isavMapContextOption]',
})
export class IsavMapContextOption implements OnDestroy {
    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private isavMap: IsavMap
    ) {
        this.isavMap.addContextMenuOption(this);
    }

    getPortal(): TemplatePortal {
        return new TemplatePortal<any>(this._templateRef, this._viewContainerRef);
    }

    ngOnDestroy() {
        this.isavMap.removeContextMenuOption(this);
    }
}
