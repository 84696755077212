import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SiosMappingDto } from '../dto/sios-mappings';
import { BaseSearchService } from './base-search-service';
import { uncapitalize, camelCaseToKebabCase } from '../../utils/strings';

@Injectable()
export class SiosMappingsResourceService extends BaseSearchService<SiosMappingDto> {
    constructor(http: HttpClient) {
        super('sios_mappings', http);
    }

    map(taxonomyClass: string, siosMapping: string, taxonomy: string) {
        const property = uncapitalize(taxonomyClass);
        const body = { siosMapping: siosMapping, [property]: taxonomy };
        const url = camelCaseToKebabCase(taxonomyClass);
        return this.http.post(`/api/sios-mapping/map-${url}`, body);
    }
}
