import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, pluck, take } from 'rxjs/operators';
import { Hydra } from '../dto/hydra';
import { selectTaxonomyByType } from '../taxonomies/taxonomies.selectors';
import { TaxonomyTypesMap } from '../taxonomies/taxonomy-types';
import {
    SEARCH_RESOURCE,
    SEARCH_RESOURCE_NAMESPACE,
    SEARCH_RESOURCE_USE_SELF,
    SearchResource,
} from './search-resource';
import { SearchParams, SearchState } from './search-state';
import { beginSearch, searchSort, updateQuery } from './search.actions';
import { selectSearch } from './search.selectors';

@Injectable()
export class SearchService<T = {}> {
    constructor(
        private store: Store,
        @Inject(SEARCH_RESOURCE_NAMESPACE) private namespace: string,
        @Inject(SEARCH_RESOURCE) private resource: SearchResource<T>,
        @Inject(SEARCH_RESOURCE_USE_SELF) private isSelf: boolean
    ) {}

    list(limitResults: number = 12): Observable<T[]> {
        return this.resource.getAll({ page: '1' }, this.isSelf).pipe(
            map(Hydra.from),
            map((result) => result.member.slice(0, limitResults))
        );
    }

    csv(query: SearchParams): Observable<string> {
        const params = {
            ...query,
            pagination: '0', //enforce returning all results - we want them all in csv
        };

        delete params['page'];
        delete params['itemsPerPage'];

        return this.resource.getAllAsCsv(params, this.isSelf);
    }

    search(query: SearchParams): void {
        this.store.dispatch(beginSearch({ namespace: this.namespace, query }));
    }

    searchState(): Observable<SearchState<T>> {
        return this.store.select(selectSearch, this.namespace);
    }

    getTaxonomiesByType<K extends keyof TaxonomyTypesMap, R = TaxonomyTypesMap[K]>(
        type: K
    ): Observable<R[]> {
        return this.store.select(selectTaxonomyByType, type);
    }

    updateQuery(query: SearchParams) {
        this.store.dispatch(updateQuery({ namespace: this.namespace, query }));
    }

    updateQueryWithSearch(query: SearchParams): void {
        this.store
            .select(selectSearch, this.namespace)
            .pipe(take(1))
            .subscribe((state) => {
                this.store.dispatch(
                    beginSearch({ namespace: this.namespace, query: { ...state.query, ...query } })
                );
            });
    }

    sort(prop: string, direction: string) {
        const orderProp = `order[${prop}]`;
        this.store.dispatch(
            searchSort({ namespace: this.namespace, query: { [orderProp]: direction } })
        );
    }

    getAll(query: SearchParams): Observable<T[]> {
        return this.resource.getAll(this.clearQuery(query)).pipe(map(Hydra.from), pluck('member'));
    }

    getNamespace(): string {
        return this.namespace;
    }

    /**
     * Removes null, undefined and empty string values and keys from query object
     * @param {SearchParams} query
     * @return {SearchParams}
     * @private
     */
    private clearQuery(query: SearchParams): SearchParams {
        const isValid = (value) => value === false || value;
        return Object.keys(query)
            .filter((key) => isValid(query[key]))
            .reduce((acc, key) => {
                acc[key] = query[key];
                return acc;
            }, {});
    }
}
