import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IsavMapModule } from '../map/map.module';
import { SharedModule } from '../shared/shared.module';
import { AdvancedSearchModule } from './advanced-search/advanced-search.module';
import { IsavCommonAuthModule } from '../common-auth/common-auth.module';
import { IsavSortHeaderCell } from './advanced-search/sort-header-cell';
import { CKEditor5WrapperModule } from './ckeditor5-wrapper/ckeditor5-wrapper.module';
import { IsavContactInformation } from './contact-information/contact-information';
import { IsavContentEditorDisplay } from './content-editor-display/content-editor-display';
import { IsavContentOwnership } from './content-ownership/content-ownership';
import { IsavRestrictTaxonomies } from './forms/restrict-taxonomies/restrict-taxonomies';
import { IsavRouteInput } from './forms/route-input/route-input';
import { IsavTypeTaxonomySelect } from './forms/type-taxonomy-select/type-taxonomy-select';
import { IsavTitleFallback } from './title-fallback/title-fallback';
import {
    ContentTypeAside,
    ContentTypeDetails,
    ContentTypeView,
} from './content-type-view/content-type-view';
import { IsavEditLink } from './edit-link/edit-link';
import { IsavExpandableFirstParagraph } from './expandable-first-paragraph/expandable-first-paragraph';
import { IsavExternalLink } from './external-link/external-link';
import { IsavFirstParagraphPipe } from './first-paragraph-pipe/first-paragraph.pipe';
import { IsavContentEditor } from './forms/content-editor/content-editor';
import { IsavCoordinatesInput } from './forms/coordinates-input/coordinates-input';
import { IsavDateInput } from './forms/date-input/date-input';
import { IsavDocumentInput } from './forms/document-input/document-input';
import { IsavDocumentUploaderDialog } from './forms/document-uploader-dialog/document-uploader-dialog';
import { IsavDocumentUploader } from './forms/document-uploader/document-uploader';
import { IsavErrorClass } from './forms/error-class/error-class';
import { IsavErrorDisplay } from './forms/error-display/error-display';
import { IsavFileDisplay } from './forms/file-display/file-display';
import { IsavImageUploader } from './forms/image-uploader/image-uploader';
import { IsavLinkInput } from './forms/link-input/link-input';
import { IsavManyInput, IsavManyInputControl } from './forms/many-input/many-input';
import { IsavPasswordInput } from './forms/password-input/password-input';
import { IsavPersonSelector } from './forms/person-selector/person-selector';
import { IsavRadioInput } from './forms/radio-input/radio-input';
import { IsavTaxonomySelectInput } from './forms/taxonomy-select-input/taxonomy-select-input';
import { IsavTaxonomySelect } from './forms/taxonomy-select/taxonomy-select';
import { IsavDepartmentSelect } from './forms/department-select/department-select';
import { IsavDepartmentInput } from './forms/department-input/department-input';
import { IsavTextInput } from './forms/text-input/text-input';
import { IsavEmptyInput } from './forms/empty-input/empty-input';
import { IsavTextareaInput } from './forms/textarea-input/textarea-input';
import { IsavGeoLocationPipe } from './geo-location-pipe/geo-location-pipe';
import { IsavGeoCoordinatesPipe } from './geo-location-pipe/geo-coordinates-pipe';
import { IsavGoBackDirective } from './go-back/go-back';
import { IsavGoToView } from './go-to-view/go-to-view';
import { IsavHasEditAccess } from './has-edit-access/has-edit-access';
import { IsavLandingCard, IsavLandingCardContentDirective } from './landing-card/landing-card';
import { MiniContentSearch } from './mini-content-search/mini-content-search';
import { IsavAddNote } from './notes/add-note/add-note';
import { IsavNoteForm } from './notes/note-form/note-form';
import { IsavNote } from './notes/note/note';
import { IsavNotes } from './notes/notes';
import { IsavDomainEventJournalList } from './domain-event-journal/domain-event-journal-list/domain-event-journal-list';
import { IsavDomainEventJournal } from './domain-event-journal/domain-event-journal/domain-event-journal';
import { IsavPageContent } from './page-content/page-content';
import { IsavProjectFieldwork } from './project-fieldwork/project-fieldwork';
import { IsavProjectFieldworks } from './project-fieldworks/project-fieldworks';
import { IsavSarAssets } from './sar-assets/sar-assets';
import { IsavSarDisplay } from './sar-display/sar-display';
import { SimpleSearchContainerModule } from './simple-search-container/simple-search-container.module';
import { TaxonomiesNames } from './taxonomies-names/taxonomies-names';
import { IsavStartEndDateInput } from './forms/start-end-date-input/start-end-date-input';
import { IsavMarker } from './marker/marker';
import { IsavSelectInput } from './forms/select-input/select-input';
import { IsavDateTimePipe } from './domain-event-journal/date-time.pipe';
import { IsavEventNamePipe } from './domain-event-journal/event-name.pipe';
import { IsavLatestContent } from './latest-content/latest-content/latest-content';
import { IsavListItemView } from './latest-content/list-item-view/list-item-view';
import { IsavFooterLink } from './footer-link/footer-link';
import { IsavHintCard } from './latest-content/hint-card/hint-card';
import { IsavViewLink } from './view-link/view-link';
import { IsavTimeline } from './timeline/timeline';
import { VisModule } from 'ngx-vis';
import { IsavFilesLink } from './files-link/files-link';
import { IsavCoordinateAreaInput } from './forms/coordinate-area-input/coordinate-area-input';
import { IsavMapCoordinate } from './map/map-coordinate/map-coordinate';
import { IsavTaxonomyCheckboxList } from './forms/taxonomy-checkbox-list/taxonomy-checkbox-list';
import { IsavTaxonomyCheckboxListInput } from './forms/taxonomy-checkbox-list-input/taxonomy-checkbox-list-input';
import { IsavEditSarDialog } from './forms/edit-sar/edit-sar-dialog';
import { IsavEditSar } from './forms/edit-sar/edit-sar';
import { IsavDeleteContentModal } from './delete-content-modal/delete-content-modal';
import { DeleteConfirmationService } from './delete-content-modal/delete-confirmation.service';
import { IsavMapLocationSearchControl } from './map-location/map-location-search-control';
import { IsavMapLocationSearchHighlight } from './map-location/map-location-search-highlight';
import { IsavMapMarkersDisplay } from './map/map-markers-display/map-markers-display';
import { IsavMapMarkersCreation } from './map/map-markers-creation/map-markers-creation';
import { IsavMapMarkersDialog } from './map/map-markers-dialog/map-markers-dialog';
import { IsavImagePopup } from './forms/image-popup/image-popup';
import { IsavImageSimplePopup } from './forms/image-simple-popup/image-simple-popup';
import { IsavImageForm } from './forms/image-form/image-form';
import { IsavImageBatchUploadPopup } from './forms/image-batch-upload-popup/image-batch-upload-popup';
import { IsavImageDisplay } from './forms/image-display/image-display';
import { IsavImageCaption } from './image-caption/image-caption';
import { TooltipsSharedModule } from '../tooltips/tooltips-shared.module';
import { IsavDescriptionDisplay } from './description-display/description-display';
import { IsavTaxonomiesExternalLink } from './taxonomies-external-link/taxonomies-external-link';
import { IsavDateInputDirective } from './forms/date-input/date-input.directive';
import { UnpublishedWarning } from './unpublished-warning/unpublished-warning';
import { ScreenSizeSplitPipe } from './forms/taxonomy-checkbox-list/screen-size-split.pipe';
import { IsavDateTimeInput } from './forms/datetime-input/datetime-input';
import { IsavStatusToggle } from './status-toggle/status-toggle';
import { IsavCanViewSarDirective } from './can-view-sar/can-view-sar.directive';
import { IsavContentEditorDialog } from './forms/content-editor-dialog/content-editor-dialog';
import { IsavPersonInput } from './forms/person-input/person-input';
import { ButtonDisplayComponent } from './forms/button-display/button-display.component';

export const EXPORTED_DECLARATIONS = [
    TaxonomiesNames,
    ContentTypeView,
    ContentTypeDetails,
    ContentTypeAside,
    IsavEmptyInput,
    IsavTextInput,
    IsavLinkInput,
    IsavDateInput,
    IsavCoordinatesInput,
    IsavTaxonomySelect,
    IsavTaxonomySelectInput,
    IsavTaxonomiesExternalLink,
    IsavDepartmentSelect,
    IsavDepartmentInput,
    IsavContentEditor,
    IsavContentEditorDisplay,
    IsavFirstParagraphPipe,
    IsavExpandableFirstParagraph,
    IsavHasEditAccess,
    IsavEditLink,
    IsavExternalLink,
    IsavErrorDisplay,
    IsavErrorClass,
    IsavLandingCard,
    IsavLandingCardContentDirective,
    IsavPageContent,
    IsavSortHeaderCell,
    IsavContentOwnership,
    IsavPersonSelector,
    IsavGeoLocationPipe,
    IsavGeoCoordinatesPipe,
    IsavImageUploader,
    IsavContactInformation,
    IsavProjectFieldwork,
    IsavProjectFieldworks,
    IsavPasswordInput,
    IsavTextareaInput,
    IsavSarAssets,
    IsavManyInput,
    IsavManyInputControl,
    IsavNotes,
    IsavNote,
    IsavAddNote,
    IsavNoteForm,
    IsavRadioInput,
    IsavDomainEventJournalList,
    IsavDomainEventJournal,
    MiniContentSearch,
    IsavGoBackDirective,
    IsavGoToView,
    IsavStartEndDateInput,
    IsavSarDisplay,
    IsavDocumentInput,
    IsavDocumentUploader,
    IsavDocumentUploaderDialog,
    IsavFileDisplay,
    IsavMarker,
    IsavSelectInput,
    IsavEventNamePipe,
    IsavDateTimePipe,
    IsavTitleFallback,
    IsavLatestContent,
    IsavListItemView,
    IsavRestrictTaxonomies,
    IsavFooterLink,
    IsavHintCard,
    IsavViewLink,
    IsavTimeline,
    IsavFilesLink,
    IsavCoordinateAreaInput,
    IsavMapCoordinate,
    IsavTaxonomyCheckboxList,
    IsavTaxonomyCheckboxListInput,
    IsavTypeTaxonomySelect,
    IsavRouteInput,
    IsavEditSarDialog,
    IsavEditSar,
    IsavDeleteContentModal,
    IsavMapLocationSearchControl,
    IsavMapLocationSearchHighlight,
    IsavMapMarkersDisplay,
    IsavMapMarkersCreation,
    IsavMapMarkersDialog,
    IsavImagePopup,
    IsavImageSimplePopup,
    IsavImageForm,
    IsavImageBatchUploadPopup,
    IsavImageDisplay,
    IsavImageCaption,
    IsavDescriptionDisplay,
    IsavDateInputDirective,
    UnpublishedWarning,
    IsavDateTimeInput,
    IsavStatusToggle,
    IsavCanViewSarDirective,
    IsavContentEditorDialog,
    IsavPersonInput,
    ButtonDisplayComponent,
];

export const INNER_DECLARATIONS = [ScreenSizeSplitPipe];

export const ENTRY_COMPONENTS = [
    IsavDocumentUploaderDialog,
    IsavEditSarDialog,
    IsavDeleteContentModal,
    IsavMapMarkersDialog,
    IsavContentEditorDialog,
];

export const EXPORTED_MODULES = [
    SimpleSearchContainerModule,
    AdvancedSearchModule,
    IsavMapModule,
    TooltipsSharedModule,
    SharedModule,
    IsavCommonAuthModule,
];

/**
 * Module containing reusable components and directives that depend on core module services
 */
@NgModule({
    declarations: [EXPORTED_DECLARATIONS, INNER_DECLARATIONS, ButtonDisplayComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CKEditor5WrapperModule,
        EXPORTED_MODULES,
        IsavMapModule,
        VisModule,
    ],
    providers: [DeleteConfirmationService],
    exports: [EXPORTED_DECLARATIONS, EXPORTED_MODULES, ButtonDisplayComponent],
})
export class IsavCommonModule {}
