<div (isavDialog)="close()" class="dialog search-popup" #dialog>
    <div class="page-content">
        <div class="has-text-right mt-4 mb-4">
            <button
                isavBtn
                color="transparent"
                class="is-medium search-popup-close-button"
                aria-label="close"
                (click)="close()"
            >
                <isav-icon icon="times-circle"></isav-icon>
            </button>
        </div>
        <form ngNoForm (submit)="forceSearch.next($event)">
            <div class="field search-field">
                <div class="control has-icons-right">
                    <input
                        [formControl]="extractedText"
                        class="input is-medium search-input"
                        type="text"
                        placeholder="Type your search"
                        aria-label="Type text to search for content on the page"
                        data-testid="searchinput"
                    />
                    <span class="icon is-medium is-right" *ngIf="!loading; else loadingIcon">
                        <isav-icon icon="search"></isav-icon>
                    </span>
                    <ng-template #loadingIcon>
                        <span class="icon is-medium is-right">
                            <isav-loader></isav-loader>
                        </span>
                    </ng-template>
                </div>
            </div>
        </form>
        <div class="mt-5 is-relative search-results">
            <div class="floating-close-button-container">
                <button
                    isavBtn
                    color="transparent"
                    class="is-medium floating-close-button"
                    aria-label="close"
                    (click)="close()"
                    #floatingCloseButton
                >
                    <isav-icon icon="times-circle"></isav-icon>
                </button>
            </div>
            <ng-container *ngFor="let content of content$ | async; trackBy: trackByFn">
                <p class="content-title">
                    <a
                        (click)="close()"
                        [routerLink]="viewLink(content)"
                        [queryParams]="{ iri: content['@id'] }"
                    >
                        {{ getName(content) | isavTitleFallback : content['@id'] }} | {{
                        content['@type'] }}
                    </a>
                </p>
                <p class="mb-3">
                    {{ content.lastModified | isavDate }} {{ content.description |
                    isavFirstParagraph }}
                </p>
            </ng-container>
        </div>
    </div>
</div>
