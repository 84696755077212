import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { flatten, uniq } from 'ramda';
import { combineLatest, MonoTypeOperatorFunction, Observable, of, pipe, Subject } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { Iri } from '../../../core/dto/iri';
import { SearchParams } from '../../../core/search/search-state';
import { SearchService } from '../../../core/search/search.service';
import { TaxonomiesService } from '../../../core/taxonomies/taxonomies.service';

export function distinctUntilStringifiedChange<T>(): MonoTypeOperatorFunction<T> {
    let prev: string | null = null;
    return pipe(
        filter((value: T) => {
            const stringifiedValue = JSON.stringify(value);
            const changed = prev !== stringifiedValue;
            prev = stringifiedValue;
            return changed;
        })
    );
}

/**
 * Traverses arrays and object looking for taxonomy iri references. Iri is just a string so we can
 * easily find those and each taxonomy iri begins with /api/taxonomy/ and we use that to get them.
 * @param result - search results to extract taxonomies from
 * @return {Iri[]} - extracted taxonomies list
 */
export function extractTaxonomyIris(result: any): Iri[] {
    function extract(value: any): Iri[] {
        if (Array.isArray(value)) return flatten(value.map(extract));
        if (typeof value === 'object' && value !== null) {
            return flatten(Object.values(value).map(extract));
        }
        if (typeof value === 'string' && value.startsWith('/api/taxonomy/')) return [value];
        return [];
    }

    return uniq(extract(result));
}

function isDepartment(t: Iri): boolean {
    return /departments/.test(t);
}

function isOrganisation(t: Iri): boolean {
    return /organisations/.test(t);
}

export function filterDepartmentsAndOrganisations(taxonomies: Iri[]): Iri[] {
    return taxonomies.filter((t) => isDepartment(t) || isOrganisation(t));
}

function filterOutDepartments(taxonomies: Iri[]): Iri[] {
    return taxonomies.filter((t) => !isDepartment(t));
}

/**
 * Check if query is not empty
 * @param {SearchParams} query
 * @return {boolean}
 */
export function hasValuesOtherThenEmpty(query: SearchParams): boolean {
    // We need to ignore pagination (as this does not impact result as we always send '0')
    // and searchIn as this is an internal property used to distinguish project and fieldwork search.
    // Change of searchIn generates new query and is never send to backend so it does not impact results.
    const ignoreKeys = ['pagination', 'searchIn'];
    return Object.keys(query)
        .filter((key) => !ignoreKeys.includes(key))
        .some((key) => !!query[key] || <any>query[key] === false);
}

@Directive({
    selector: '[isavRestrictTaxonomies]',
})
export class IsavRestrictTaxonomies implements OnChanges, OnInit, OnDestroy {
    @Input('isavRestrictTaxonomies') query: SearchParams;
    @Input() allQuery: SearchParams = {};

    /**
     * This is stream of possible values of taxonomies that are returned from search call from
     * api, it is used to limit options that one can select inside the search filters
     * @type {Observable<Set<Iri>>} - Stream of set of allowed taxonomy iris
     */
    get allowedTaxonomies$(): Observable<Set<Iri>> {
        return this._allowedTaxonomies$;
    }

    private _allowedTaxonomies$: Observable<Set<Iri>>;
    private queryChanged = new Subject<SearchParams>();
    private all$: Observable<any[]>;

    constructor(
        private searchService: SearchService,
        private taxonomiesService: TaxonomiesService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        // this must be checked before query to ensure that allQuery is updated
        if (changes.allQuery) {
            // compare changes using JSON.stringify
            const prev = JSON.stringify(changes.allQuery.previousValue);
            const curr = JSON.stringify(changes.allQuery.currentValue);

            if (prev !== curr) {
                this.all$ = this.getAll();
            }
        }

        if (changes.query || changes.allQuery) {
            this.queryChanged.next(this.query);
        }
    }

    ngOnInit(): void {
        this.all$ = this.getAll();
        this._allowedTaxonomies$ = this.queryChanged.pipe(
            filter((query) => !!query),
            distinctUntilStringifiedChange(),
            map((query) => {
                const correctedQuery = { ...query, pagination: '0' };
                delete correctedQuery['page'];
                return correctedQuery;
            }),
            switchMap((query) =>
                // when we do not apply any filters it is pointless to call the api
                // we should fallback to every value available hence empty array
                hasValuesOtherThenEmpty(query) ? this.searchService.getAll(query) : this.all$
            ),
            map(extractTaxonomyIris),
            switchMap((taxonomies) =>
                // departments and organisations have nested references to taxonomies like country
                this.taxonomiesService
                    .resolveMany(filterDepartmentsAndOrganisations(taxonomies))
                    .pipe(
                        map(extractTaxonomyIris),
                        // we need to filter out departments that organisation is referencing
                        // because we take list of departments from the search call
                        map(filterOutDepartments),
                        map((ts) => taxonomies.concat(ts))
                    )
            ),
            startWith([]),
            map((value) => new Set(value)),
            shareReplay(1)
        );

        this.queryChanged.next(this.query);
    }

    ngOnDestroy(): void {
        this.queryChanged.complete();
    }

    private getAll(): Observable<any[]> {
        return this.searchService
            .getAll({ ...this.allQuery, pagination: '0' })
            .pipe(take(1), shareReplay(1));
    }
}

export function applyTaxonomyRestriction<T>(
    restrict: IsavRestrictTaxonomies | undefined,
    getIri: (value: T) => Iri
): MonoTypeOperatorFunction<T[]> {
    return (source) =>
        combineLatest([source, restrict?.allowedTaxonomies$ ?? of(new Set([]))]).pipe(
            map(([taxonomies, restriction]: [T[], Set<Iri>]) => {
                if (restriction.size === 0) return taxonomies;
                return taxonomies.filter((taxonomy) => restriction.has(getIri(taxonomy)));
            })
        );
}
