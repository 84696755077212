<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip">
        <label class="has-text-weight-bold has-text-primary">
            {{ label }}
            <span *ngIf="isMandatory" class="has-text-danger">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body is-vertical">
        <ng-container *ngIf="data">
            <div class="field">
                <div class="control">
                    <isav-sar-display [sar]="data"></isav-sar-display>
                </div>
            </div>
            <div class="field is-grouped">
                <div class="control">
                    <button isavBtn color="primary" size="small" type="button" (click)="edit()">
                        Edit SAR asset
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
