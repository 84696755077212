import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

let UNIQUE_ID = 0;

@Component({
    selector: 'isav-expandable-first-paragraph',
    templateUrl: './expandable-first-paragraph.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavExpandableFirstParagraph {
    @Input() content?: string | null = '';
    expanded: boolean = false;
    id = 'isav-expandable--' + UNIQUE_ID++;

    constructor(private sanitizer: DomSanitizer) {}

    get safeContent(): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(this.content ?? '');
    }
}
