import { Directive, HostListener, Input } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { ImageGalleryModal } from '../image-gallery-modal/image-gallery-modal';
import { IsavImageLightbox } from './image-lightbox';
import { ImageGalleryModalData } from '../image-gallery-modal/image-gallery-modal';

@Directive({
    selector: '[isavLightbox]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'has-action',
    },
})
export class IsavImageLightboxDirective {
    @Input('isavLightbox') src: string[];
    @Input('isavLightboxIndex') current?: number;

    constructor(private dialogService: DialogService) {}

    @HostListener('click')
    onClick() {
        if (this.src.length === 1) {
            this.dialogService.spawnComponent<string, string, IsavImageLightbox>(
                IsavImageLightbox,
                this.src[0]
            );
        }
        if (this.src.length > 1 && this.current !== undefined) {
            this.dialogService.spawnComponent<string, ImageGalleryModalData, ImageGalleryModal>(
                ImageGalleryModal,
                { images: this.src, current: this.current }
            );
        }
    }
}
