import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogService } from '../../shared/dialog/dialog.service';
import { IsavDeleteContentModal } from './delete-content-modal';

@Injectable()
export class DeleteConfirmationService {
    constructor(private dialogService: DialogService) {}

    confirmDeletion(name: string = 'content', wipe: boolean = false): Observable<void> {
        return new Observable((observer) => {
            const popup = this.dialogService.spawnComponent(IsavDeleteContentModal, {
                name: name,
                wipe: wipe,
            });
            popup.closed$.subscribe((confirmation) => {
                if (confirmation) {
                    observer.next();
                }
                observer.complete();
            });
            return () => popup.close();
        });
    }
}
