import {
    ChangeDetectionStrategy,
    Component,
    Directive,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

const ROW_TEMPLATE = `<ng-container isavCellOutlet></ng-container>`;

export interface IRowDef {
    templateRef: TemplateRef<any>;
}

@Component({
    selector: 'isav-row, [isav-row]',
    template: ROW_TEMPLATE,
    // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
})
export class IsavRow {}

@Directive({
    selector: '[isavRowDef]',
})
export class IsavRowDef<T> implements IRowDef, OnDestroy {
    private _changed$ = new Subject<string[]>();
    private _columns: string[] = [];

    constructor(public templateRef: TemplateRef<{ $implicit: T }>) {}

    @Input('isavRowDefColumns')
    get columns(): string[] {
        return this._columns;
    }

    set columns(columns: string[]) {
        this._columns = Array.isArray(columns) ? columns : [];
        this._changed$.next(this._columns);
    }

    get changed$(): Observable<string[]> {
        return this._changed$.asObservable();
    }

    ngOnDestroy() {
        this._changed$.complete();
    }
}

@Component({
    selector: 'isav-header-row, [isav-header-row]',
    template: ROW_TEMPLATE,
    // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
})
export class IsavHeaderRow {}

@Directive({
    selector: '[isavHeaderRowDef]',
})
export class IsavHeaderRowDef implements IRowDef {
    constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
    selector: 'isav-footer-row, [isav-footer-row]',
    template: ROW_TEMPLATE,
    // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
})
export class IsavFooterRow {}

@Directive({
    selector: '[isavFooterRowDef]',
})
export class IsavFooterRowDef implements IRowDef {
    constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
    selector: 'isav-no-data-row, [isav-no-data-row]',
    template: `
        <td class="no-data-row">
            <ng-content></ng-content>
        </td>
    `,
    // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
})
export class IsavNoDataRow {}

@Directive({
    selector: '[isavNoDataRowDef]',
})
export class IsavNoDataRowDef {
    constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
    selector: '[isavCellOutlet]',
})
export class IsavCellOutlet implements OnDestroy {
    static mostRecentCellOutlet: IsavCellOutlet | null = null;

    constructor(public viewContainerRef: ViewContainerRef) {
        IsavCellOutlet.mostRecentCellOutlet = this;
    }

    static render(templateRef: TemplateRef<any>, context?: { $implicit: any }) {
        if (!IsavCellOutlet.mostRecentCellOutlet) return templateRef.createEmbeddedView(context);
        return IsavCellOutlet.mostRecentCellOutlet.viewContainerRef.createEmbeddedView(
            templateRef,
            context
        );
    }

    ngOnDestroy() {
        if (IsavCellOutlet.mostRecentCellOutlet === this) {
            IsavCellOutlet.mostRecentCellOutlet = null;
        }
    }
}
