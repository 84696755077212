import { Directive, OnDestroy, OnInit } from '@angular/core';
import { SentinelService } from '../../sentinel.service';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { IsavMap } from '../../map';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'isav-map-satellite-sentinel-layer',
})
export class IsavMapSatelliteSentinelLayer implements OnInit, OnDestroy {
    private readonly osm = new TileLayer({
        zIndex: 0,
        source: new OSM(),
    });
    private readonly layer = new TileLayer({
        zIndex: 1,
        opacity: 1,
    });

    constructor(
        private readonly sentinelService: SentinelService,
        private readonly isavMap: IsavMap
    ) {}

    ngOnInit() {
        this.isavMap.map.addLayer(this.osm);
        this.isavMap.map.addLayer(this.layer);
        this.sentinelService
            .getCapabilities()
            .pipe(untilDestroyed(this))
            .subscribe((capabilities) => {
                const options = optionsFromCapabilities(capabilities, {
                    layer: 'SATELLITE',
                    matrixSet: 'PopularWebMercator512',
                });
                this.layer.setSource(new WMTS(options));
            });
    }

    ngOnDestroy() {
        [this.osm, this.layer].map((l) => this.isavMap.map.removeLayer(l));
        this.osm.dispose();
        this.layer.dispose();
    }
}
