import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { DialogManager } from '../../shared/dialog/dialog.service';

interface DeleteData {
    name: string;
    wipe: boolean;
}

@Component({
    selector: 'isav-delete-content-modal',
    templateUrl: './delete-content-modal.html',
    styleUrls: ['./delete-content-modal.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavDeleteContentModal implements OnInit {
    name: string;
    wipe: boolean;

    constructor(private dialogManager: DialogManager<DeleteData, boolean>) {}

    ngOnInit() {
        if (this.dialogManager.data) {
            this.name = this.dialogManager.data.name;
            this.wipe = this.dialogManager.data.wipe;
        }
    }

    close(confirmed: boolean = false): void {
        this.dialogManager.close(confirmed);
    }
}
