import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, map, pluck } from 'rxjs/operators';
import { Iri } from '../dto/iri';
import { Hydra, HydraProperties } from '../dto/hydra';
import { ContentCreateAccessDto } from '../dto/content-create-access';

@Injectable()
export class ContentService {
    constructor(private http: HttpClient) {}

    getEditAccess(iri: Iri): Observable<boolean> {
        return this.http.post('/api/self/content/access-edit-lookup', { content: iri }).pipe(
            mapTo(true),
            catchError(() => of(false))
        );
    }

    getCreateAccessList(): Observable<ContentCreateAccessDto[]> {
        return this.http
            .get<Hydra<ContentCreateAccessDto>>('/api/self/contents/access-create-lookup')
            .pipe(map(Hydra.from), pluck('member'));
    }

    find<T>(queryParams: { [key: string]: string | string[] } = {}): Observable<T[]> {
        return this.findHydra<T>(queryParams).pipe(pluck('member'));
    }

    findHydra<T>(queryParams: { [key: string]: string | string[] } = {}): Observable<Hydra<T>> {
        const params = new HttpParams({ fromObject: queryParams });
        return this.http.get<HydraProperties<T>>('/api/contents', { params }).pipe(map(Hydra.from));
    }

    getByIri<T>(iri: Iri): Observable<T> {
        return this.http.get<T>(iri);
    }

    patch<T>(iri: Iri, data: Partial<T>): Observable<T> {
        const headers = new HttpHeaders().append('Content-Type', 'application/merge-patch+json');
        return this.http.patch<T>(iri, data, { headers });
    }

    publish<T>(iri: Iri): Observable<T> {
        return this.http
            .post<void>('/api/content/publish', { content: iri })
            .pipe(switchMap(() => this.getByIri<T>(iri)));
    }

    patchAndPublish<T>(iri: Iri, data: Partial<T>): Observable<T> {
        return this.patch<T>(iri, data).pipe(switchMap(() => this.publish<T>(iri)));
    }

    unpublish<T>(iri: Iri): Observable<T> {
        return this.http
            .post<void>('/api/content/unpublish', { content: iri })
            .pipe(switchMap(() => this.getByIri<T>(iri)));
    }

    shareOwnership(contentIri: Iri, personIri: Iri): Observable<void> {
        return this.http.post<void>('/api/content/ownership-share', {
            content: contentIri,
            person: personIri,
        });
    }

    revokeOwnership(contentIri: Iri, personIri: Iri): Observable<void> {
        return this.http.post<void>('/api/content/ownership-revoke', {
            content: contentIri,
            person: personIri,
        });
    }

    create(content: string): any {
        return this.http.post<any>(`/api/${content}`, {});
    }

    delete(iri: Iri): Observable<void> {
        return this.http.delete<void>(iri);
    }

    clone(iri: Iri): Observable<{ '@id': Iri }> {
        return this.http.post<{ '@id': Iri }>(`/api/content/clone`, { content: iri });
    }
}
