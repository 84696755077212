import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Directive,
    ElementRef,
    TemplateRef,
} from '@angular/core';
import { safeAddClass } from '../common-behaviours/safe-classlist';

/**
 * Directive used to locate image inside the card
 *
 * @example
 * <img *isavCardImage src="..." alt="...">
 */
@Directive({
    selector: '[isavCardImage]',
})
export class CardImageDirective {
    constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * Directive to locate image caption inside the card
 *
 * @example
 * <isav-caption *isavCardImageCaption>12.04.2020</isav-caption>
 */
@Directive({
    selector: '[isavCardImageCaption]',
})
export class CardImageCaptionDirective {
    constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * Directive used to locate header inside the card
 *
 * @example
 * <header isavCardHeader>...</header>
 */
@Directive({ selector: '[isavCardHeader]' })
export class CardHeaderDirective {
    constructor(_elementRef: ElementRef) {
        safeAddClass(_elementRef, 'card-header');
    }
}

/**
 * Directive used to locate footer inside the card
 *
 * @example
 * <div isavCardFooter>...</div>
 */
@Directive({
    selector: '[isavCardFooter]',
})
export class CardFooterDirective {}

/**
 * Card component
 *
 * Contains:
 * - Image with caption or header
 * - Content of the card
 * - Footer in blue box
 *
 * @example
 * <isav-card>
 *                <img *isavCardImage
 *                     src="https://bulma.io/images/placeholders/1280x960.png"
 *                     alt="Placeholder image">
 *                <isav-caption *isavCardImageCaption>12.04.2020</isav-caption>
 *                <div class="content">
 *                    <a href="#">#loremIpsum</a>
 *                    <p>
 *                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec
 *     iaculis mauris.
 *                    </p>
 *                </div>
 *                <div isavCardFooter>
 *                    <p><strong>Region</strong></p>
 *                    <p>Greenland, South-West</p>
 *                </div>
 * </isav-card>
 */
@Component({
    selector: 'isav-card',
    templateUrl: './card.html',
    host: {
        class: 'card',
    },
    // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
    changeDetection: ChangeDetectionStrategy.Default,
})
export class IsavCard {
    @ContentChild(CardImageDirective) image?: CardImageDirective;
    @ContentChild(CardImageCaptionDirective) caption?: CardImageCaptionDirective;
    @ContentChild(CardFooterDirective) footer?: CardFooterDirective;
}
