import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[isavFileDropped]',
})
export class IsavFileDropped {
    @Output('isavFileDropped') fileDropped = new EventEmitter<FileList>();
    @HostBinding('class.fileover') fileOver: boolean;

    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    @HostListener('dragleave', ['$event']) onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    @HostListener('drop', ['$event']) onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        const files = evt.dataTransfer.files;

        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
