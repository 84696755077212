import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    OnInit,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IsavValidators } from '../../../utils/forms/validators';
import { NoteDto } from '../../../core/dto/note';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

let UNIQUE_ID = 0;
@UntilDestroy()
@Component({
    selector: 'isav-note-form',
    templateUrl: './note-form.html',
    styleUrls: ['./note-form.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavNoteForm implements OnChanges, OnInit {
    id = 'isav-note-form-' + UNIQUE_ID++;
    @Input() value: NoteDto;
    @Output() valueChange = new EventEmitter<NoteDto>();

    form: UntypedFormGroup = new UntypedFormGroup({
        title: new UntypedFormControl(null, [IsavValidators.required('Title is required')]),
        description: new UntypedFormControl(null, [
            IsavValidators.required('Description is required'),
        ]),
        personal: new UntypedFormControl(true),
    });

    get labelId(): string {
        return `${this.id}-label`;
    }

    get areaId(): string {
        return `${this.id}-area`;
    }

    ngOnInit() {
        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.valueChange.emit(this.form.value);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && this.value) {
            this.form.patchValue(this.value, { emitEvent: false });
        }
    }
}
