<div class="field is-horizontal">
    <div class="field-label field-label-tooltip">
        <label [for]="id" class="has-text-weight-bold has-text-primary">
            {{ label }}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
        </label>
    </div>
    <div class="field-body">
        <div class="field">
            <div class="control">
                <input
                    type="date"
                    class="input"
                    [id]="id"
                    [value]="value.startDate"
                    (input)="onChange('startDate', $event.target.value)"
                    (focus)="onFocus()"
                    data-testid="start-date"
                    [attr.aria-labelledby]="id + '-startDate'"
                />
                <p class="help">Start date</p>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <input
                    type="date"
                    class="input"
                    [value]="value.endDate"
                    (input)="onChange('endDate', $event.target.value)"
                    (focus)="onFocus()"
                    data-testid="end-date"
                    [attr.aria-labelledby]="id + '-endDate'"
                />
                <p class="help">End date</p>
            </div>
        </div>
    </div>
</div>
