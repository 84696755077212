import {
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[isavClickedOutside]',
})
export class ClickedOutsideDirective implements OnInit, OnDestroy {
    @Output('isavClickedOutside') clickedOutside = new EventEmitter<MouseEvent | FocusEvent>();

    constructor(private _elementRef: ElementRef, @Inject(DOCUMENT) private _document: Document) {}

    ngOnInit() {
        this._document.addEventListener('click', this.checkForOutsideEvent, { capture: true });
        this._document.addEventListener('focus', this.checkForOutsideEvent, { capture: true });
    }

    ngOnDestroy() {
        this._document.removeEventListener('click', this.checkForOutsideEvent);
        this._document.removeEventListener('focus', this.checkForOutsideEvent);
    }

    checkForOutsideEvent = (event: MouseEvent | FocusEvent) => {
        if (!this._elementRef.nativeElement.contains(event.target)) {
            this.clickedOutside.emit(event);
        }
    };
}
