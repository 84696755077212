import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IsavMapControl } from './control/control';
import { IsavMapClusteredVectorLayer } from './features/clustered-vector-layer';
import { IsavMapDrawArea } from './features/draw-area/draw-area';
import { IsavMapRoute } from './features/route';
import { IsavMapVectorLayer } from './features/vector-layer';
import { IsavMapGeusGreenlandLayer } from './layers/greenland-geus-layer';
import { IsavMapSentinelIceLayers } from './layers/ice-layer/ice-layer';
import { IsavMapOsmWorldLayer } from './layers/world-layer';
import { IsavMap } from './map';
import { IsavMapMarker } from './features/marker';
import { IsavMapPlaceholder } from './map-placeholder';
import { IsavMapOverlayHandler } from './overlay/handler';
import { IsavMapOverlayContent } from './overlay/popup';
import { IsavMapReactiveMarker } from './features/reactive-marker';
import { IsavMapArea } from './features/area';
import { IsavMapNativeControl } from './control/native-control';
import { IsavMapControlsDisplay } from './control/controls-display';
import { FilterWithPosition } from './control/filter-with-position';
import { IsavMapIceLayerSettings } from './layers/ice-layer/ice-layer-settings';
import { SharedModule } from '../shared/shared.module';
import { IsavMapContextOption } from './context-menu/context-option';
import { IsavContextMenuHandler } from './context-menu/context-menu-handler/context-menu-handler';
import { IsavMapGeusTopographicLayer } from './layers/topographic-geus-layer';
import { IsavMapSatelliteSentinelLayer } from './layers/satellite-sentinel-layer/satellite-sentinel-layer';
import { TooltipsSharedModule } from '../tooltips/tooltips-shared.module';
import { GoogleMapModule } from './google-map/google-map.module';
import { SortControlsPipe } from './control/sort-controls';

export const INTERNAL_DECLARATIONS = [
    IsavMapGeusGreenlandLayer,
    IsavMapGeusTopographicLayer,
    IsavMapSentinelIceLayers,
    IsavMapOsmWorldLayer,
    IsavMapControlsDisplay,
    IsavMapOverlayHandler,
    FilterWithPosition,
    IsavMapIceLayerSettings,
    IsavMapSatelliteSentinelLayer,
    SortControlsPipe,
];

export const EXPORTED_DECLARATIONS = [
    IsavMap,
    IsavMapPlaceholder,
    IsavMapMarker,
    IsavMapReactiveMarker,
    IsavMapOverlayContent,
    IsavMapVectorLayer,
    IsavMapClusteredVectorLayer,
    IsavMapNativeControl,
    IsavMapControl,
    IsavMapDrawArea,
    IsavMapArea,
    IsavMapRoute,
    IsavMapContextOption,
    IsavContextMenuHandler,
];

/**
 * A shared module with maps components to implement OpenLayers
 */
@NgModule({
    declarations: [INTERNAL_DECLARATIONS, EXPORTED_DECLARATIONS],
    exports: EXPORTED_DECLARATIONS,
    imports: [
        CommonModule,
        PortalModule,
        ReactiveFormsModule,
        SharedModule,
        TooltipsSharedModule,
        GoogleMapModule,
    ],
})
export class IsavMapModule {}
