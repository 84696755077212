import {
    Component,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

export type PaginationElement = '...' | number;

/**
 * Puts constrain on a number, returning it if it is in between min/max bounds or otherwise if less
 * then min - min, or max if more then max
 * @param {number} min
 * @param {number} value
 * @param {number} max
 * @return {number}
 */
function constrain(min: number, value: number, max: number): number {
    return Math.max(0, Math.min(value, max));
}

@Component({
    selector: 'isav-pagination',
    templateUrl: './pagination.html',
    styleUrls: ['./pagination.sass'],
    host: {
        class: 'is-block is-relative py-2',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavPagination implements OnChanges {
    @Input() page = 1;
    @Output() pageChange = new EventEmitter<number>();
    @Output() all = new EventEmitter<void>();
    @Output() pages = new EventEmitter<void>();

    @Input() total = 0;
    @Input() perPage = 20;
    @Input() queryPagination = '1';
    @Input() showAllEnable = true;

    changePageInputValue = 1;

    constructor(private _cdRef: ChangeDetectorRef) {}

    get numberOfPages(): number {
        return Math.ceil(this.total / this.perPage);
    }

    get pagination(): PaginationElement[] {
        const elements: PaginationElement[] = [];
        if (this.page > 3) elements.push(1);
        if (this.page > 3) elements.push('...');

        if (this.page > 1) elements.push(this.page - 1);
        elements.push(this.page);
        if (this.page < this.numberOfPages - 1) elements.push(this.page + 1);
        if (this.page < this.numberOfPages - 2) elements.push('...');
        if (this.page !== this.numberOfPages) elements.push(this.numberOfPages);

        return elements;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.page) {
            this.changePageInputValue = changes.page.currentValue;
        }
    }

    handleEnter() {
        this.changePage(constrain(0, this.changePageInputValue, this.numberOfPages));
    }

    changePage(page: number): void {
        this.page = page;
        this.pageChange.emit(page);
        this._cdRef.markForCheck();
    }

    showAll() {
        this.all.emit();
    }

    showPages() {
        this.pages.emit();
    }
}
