import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Injector,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IsavBaseControl } from '../base-control';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { PersonDto } from '../../../core/dto/person';
import { forkJoin, fromEvent, Observable } from 'rxjs';
import { PeopleResourceService } from '../../../core/api/people-resource.service';

@UntilDestroy()
@Component({
    selector: 'isav-person-input',
    templateUrl: './person-input.html',
    styleUrls: ['./person-input.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IsavPersonInput), multi: true },
    ],
})
export class IsavPersonInput extends IsavBaseControl implements OnInit {
    @ViewChild('dropdown') dropdown: ElementRef;
    @ViewChild('input') input: ElementRef;

    value: string = '';
    search = new UntypedFormControl(null);
    people$: Observable<PersonDto[]>;
    loading: boolean = false;
    open: boolean = false;

    constructor(
        injector: Injector,
        private _cdRef: ChangeDetectorRef,
        private personService: PeopleResourceService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.people$ = this.search.valueChanges.pipe(
            filter((value) => value.length > 1),
            debounceTime(250),
            untilDestroyed(this),
            switchMap((value) => {
                const name$ = this.personService.getAll({
                    fullName: value,
                    page: '1',
                });
                const email$ = this.personService.getAll({
                    email: value,
                    page: '1',
                });
                return forkJoin([name$, email$]).pipe(
                    map((res) => res[0]['hydra:member'].concat(res[1]['hydra:member']))
                );
            }),
            map((people) => this.removeDuplicates(people).slice(0, 5)),
            tap(() => (this.open = true))
        );

        fromEvent(document, 'click')
            .pipe(untilDestroyed(this))
            .subscribe((event: MouseEvent) => {
                if (
                    !this.dropdown?.nativeElement?.contains(event.target as Node) &&
                    !this.input?.nativeElement?.contains(event.target as Node)
                ) {
                    this.open = false;
                    this._cdRef.markForCheck();
                }
            });
    }

    onChange(value: string) {
        this.value = value;
        this.open = false;
        this.search.reset('');
        this._onChange(value);
        this._cdRef.markForCheck();
    }

    writeValue(obj: string): void {
        this.value = obj;
        this._cdRef.markForCheck();
    }

    onFocus() {
        this._onTouched();
    }

    removeDuplicates(people: PersonDto[]) {
        return people.filter(
            (person, index, self) => index === self.findIndex((p) => p['@id'] === person['@id'])
        );
    }

    removePerson() {
        this.value = '';
        this._cdRef.markForCheck();
    }
}
