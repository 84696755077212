import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { DialogManager } from '../../../shared/dialog/dialog.service';
import { Iri } from '../../../core/dto/iri';
import { Subject, throwError, forkJoin } from 'rxjs';
import { UploadService } from '../../../core/api/upload.service';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ImageDto } from '../../../core/dto/image';
import { NotificationService } from '../../../core/services/notification.service';

enum ImagesUploaderStatus {
    Images = 'IMAGES',
    NoImages = 'NO_IMAGES',
    UploadingImages = 'UPLOADING_IMAGES',
}

class HttpFileUploadErrorResponse extends HttpErrorResponse {
    file: File;
    fileIndex: number;
}

@Component({
    selector: 'isav-image-batch-upload-popup',
    templateUrl: './image-batch-upload-popup.html',
    styleUrls: ['./image-batch-upload-popup.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavImageBatchUploadPopup implements OnInit, OnDestroy {
    cancel$ = new Subject<Iri[]>();
    loading: boolean = false;
    images: File[];
    status: ImagesUploaderStatus;
    names: string[];
    progress: number[];

    constructor(
        private notificationService: NotificationService,
        private dialogManager: DialogManager<Iri[], Iri[]>,
        private cdr: ChangeDetectorRef,
        private uploadService: UploadService
    ) {}

    ngOnInit() {
        this.status = ImagesUploaderStatus.NoImages;
        this.cancel$.subscribe((iris) => {
            this.dialogManager.close(iris);
        });
    }

    ngOnDestroy() {
        this.cancel$.complete();
    }

    close() {
        this.cancel$.next([]);
    }

    upload() {
        this.status = ImagesUploaderStatus.UploadingImages;
        const uploads = this.images.map((file, index) => {
            const upload = this.uploadService.uploadImage(file);
            return upload.pipe(
                tap((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.progress[index] = Math.round((100 * event.loaded) / event.total!);
                    } else if (event.type === HttpEventType.Response) {
                        this.progress[index] = 100;
                    }
                    this.cdr.markForCheck();
                }),
                catchError((err: HttpFileUploadErrorResponse) => {
                    err.file = file;
                    err.fileIndex = index;
                    return throwError(err);
                })
            );
        });

        forkJoin(uploads)
            .pipe(takeUntil(this.cancel$))
            .subscribe(
                (responses: Array<HttpResponse<ImageDto>>) => {
                    const iris: Iri[] = responses.map((response) => response.body!['@id']);
                    this.cancel$.next(iris);
                },
                (error: HttpFileUploadErrorResponse) => {
                    this.notificationService.add({
                        title: `Failed to upload images!`,
                        message: this.errorNotificationMessage(error),
                        type: 'danger',
                        delay: 10000,
                    });
                }
            );
    }

    onFileDropped(fileList: FileList): void {
        this.handleImages(Array.from(fileList));
    }

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        this.handleImages(Array.from(input.files!));
    }

    handleImages(images: File[]) {
        if (!images)
            throw new Error(`IsavImageBatchUploadPopup expected FileList got ${String(images)}`);
        this.images = images;
        this.status = ImagesUploaderStatus.Images;
        this.names = this.images.map((img) => img.name);
        this.progress = this.images.map(() => 0);
        this.cdr.markForCheck();
    }

    imagesTrackBy(index: number, file: File): any {
        return file;
    }

    remove(index) {
        if (this.images.length <= 1) throw new Error('Cannot remove last file from upload!');

        const indexFilter = (_, i) => i !== index;
        this.images = this.images.filter(indexFilter);
        this.names = this.names.filter(indexFilter);
        this.progress = this.progress.filter(indexFilter);
    }

    private errorNotificationMessage(error: HttpFileUploadErrorResponse): string {
        const imageName = this.names[error.fileIndex];
        switch (error.status) {
            case 413:
                return `File ${imageName} is too large.`;
            default:
                return `Unknown error occurred when uploading ${imageName}.`;
        }
    }
}
