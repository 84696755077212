import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';

@Component({
    selector: 'isav-image-carousel',
    templateUrl: './image-carousel.html',
    styleUrls: ['./image-carousel.sass'],
})
export class ImageCarousel implements AfterViewInit {
    @Input() images: string[] = [];
    @Output() currentIndexEvent = new EventEmitter<number>();
    @ViewChild('swiper') swiperEl: ElementRef;

    constructor(private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        const shadowRoot: DocumentFragment = this.swiperEl.nativeElement.shadowRoot;

        if (!this.swiperEl || !shadowRoot) return;

        const swiperParams = {
            breakpoints: {
                0: {
                    slidesPerView: 3,
                },
                769: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                },
                1216: {
                    slidesPerView: 4,
                },
            },
        };

        Object.assign(this.swiperEl.nativeElement, swiperParams);

        const swiperChildren = Array.from(this.swiperEl.nativeElement.shadowEl.children);
        const arrows = swiperChildren.filter((child: HTMLDivElement) => {
            return child.attributes['role']?.value === 'button';
        }) as HTMLDivElement[];

        // add part attribute to arrows for styling in shadow-root
        arrows.forEach((arrow) => {
            this.renderer.setAttribute(
                arrow,
                'part',
                `arrow ${arrow.classList.contains('swiper-button-next') ? 'next' : 'prev'}`
            );
        });
    }

    // emit clicked photo's index to parent
    setCurrentIndex(index: number): void {
        this.currentIndexEvent.emit(index);
    }
}
