export interface HydraMapping {
    readonly '@type': string;
    readonly variable: string;
    readonly property: string;
    readonly required: boolean;
}

export interface HydraSearch {
    readonly '@type': string;
    readonly 'hydra:template': string;
    readonly 'hydra:variableRepresentation': string;
    readonly 'hydra:mapping': HydraMapping[];
}

export interface HydraView {
    readonly '@id': string;
    readonly '@type': string;
    readonly 'hydra:first': string;
    readonly 'hydra:last': string;
    readonly 'hydra:next': string;
}

export interface HydraProperties<T> {
    readonly 'hydra:member': T[];
    readonly 'hydra:totalItems': number;
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly '@context'?: string;
    readonly 'hydra:view'?: HydraView;
    readonly 'hydra:search'?: HydraSearch;
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const HydraProperties = {
    empty<T>(): HydraProperties<T> {
        return {
            'hydra:totalItems': 0,
            'hydra:member': [],
        };
    },
};

export class Hydra<T> implements HydraProperties<T> {
    readonly 'hydra:member': T[];
    readonly 'hydra:search'?: {
        '@type': string;
        'hydra:template': string;
        'hydra:variableRepresentation': string;
        'hydra:mapping': HydraMapping[];
    };
    readonly 'hydra:totalItems': number;
    readonly 'hydra:view'?: {
        '@id': string;
        '@type': string;
        'hydra:first': string;
        'hydra:last': string;
        'hydra:next': string;
    };

    private constructor(data: HydraProperties<T>) {
        this['hydra:member'] = data['hydra:member'];
        this['hydra:search'] = data['hydra:search'];
        this['hydra:totalItems'] = data['hydra:totalItems'];
        this['hydra:view'] = data['hydra:view'];
    }

    public static from<T>(data: HydraProperties<T>): Hydra<T> {
        return new Hydra<T>(data);
    }

    public static empty<T>(): Hydra<T> {
        return new Hydra<T>(HydraProperties.empty());
    }

    get member(): T[] {
        return this['hydra:member'];
    }

    get totalItems(): number {
        return this['hydra:totalItems'];
    }

    get search(): HydraSearch | undefined {
        return this['hydra:search'];
    }

    get view(): HydraView | undefined {
        return this['hydra:view'];
    }

    toJSON(): HydraProperties<T> {
        return {
            'hydra:member': this.member,
            'hydra:totalItems': this.totalItems,
            'hydra:view': this.view,
            'hydra:search': this.search,
        };
    }
}
