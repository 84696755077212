import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { MarkerDto } from '../../../core/dto/marker';
import { AuthService } from '../../../core/auth/auth.service';
import { MarkersResourceService } from '../../../core/api/markers-resource.service';
import { switchMap, pluck } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { of, merge } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'isav-map-markers-display',
    templateUrl: './map-markers-display.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavMapMarkersDisplay implements OnInit {
    showMarkers: boolean = false;
    markers: MarkerDto[];

    constructor(
        private authService: AuthService,
        private markersService: MarkersResourceService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        const authenticated = this.authService.authenticated$().pipe(
            switchMap((logged) => {
                if (logged)
                    return this.markersService
                        .getAll({ pagination: '0' }, true)
                        .pipe(untilDestroyed(this), pluck('member'));
                return of([]);
            })
        );

        const addedMarkers = this.markersService.notify().pipe(
            switchMap(() => {
                return this.markersService
                    .getAll({ pagination: '0' }, true)
                    .pipe(untilDestroyed(this), pluck('member'));
            })
        );

        merge(authenticated, addedMarkers).subscribe((results) => {
            this.markers = results;
            this.cdr.markForCheck();
        });
    }

    markersTrackBy(index: number, item: MarkerDto) {
        return item['@id'];
    }

    change() {
        this.showMarkers = !this.showMarkers;
        this.cdr.markForCheck();
    }
}
