import {
    Component,
    ChangeDetectionStrategy,
    ElementRef,
    ChangeDetectorRef,
    ViewChild,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IsavMap } from '../map';
import { MapCenterService } from '../map-center.service';
import { GoogleMapLoaderService } from './google-map-loader.service';

@UntilDestroy()
@Component({
    selector: 'isav-google-map',
    templateUrl: './google-map.html',
    styleUrls: ['./google-map.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'GoogleMap',
})
export class GoogleMap implements AfterViewInit, OnDestroy {
    @ViewChild('mapContainer', { static: true }) mapContainer: ElementRef<HTMLDivElement>;

    private map?: google.maps.Map;

    constructor(
        private readonly googleMapLoaderService: GoogleMapLoaderService,
        private readonly isavMap: IsavMap,
        private readonly cdRef: ChangeDetectorRef,
        private readonly mapCenterService: MapCenterService
    ) {}

    ngAfterViewInit() {
        this.googleMapLoaderService
            .createMap()
            .pipe(untilDestroyed(this))
            .subscribe((map) => {
                this.map = map;
                this.mapContainer.nativeElement.appendChild(map.getDiv());

                const [lng, lat] = this.isavMap.fromMapCoordinate(
                    this.isavMap.map.getView().getCenter()
                ) as [number, number];
                map.setCenter({ lat, lng });
                map.setZoom(5); // reset zoom level
            });
    }

    ngOnDestroy() {
        const center = this.map?.getCenter();
        if (center) {
            this.mapCenterService.changeCenter(center.lng(), center.lat());
        }
    }
}
