import { Iri } from './dto/iri';

/**
 * This method uses iri of a single taxonomy/content to extract taxonomy/content type.
 * Single iri is looking like this /api/taxonomies/{type}/{uuid} for taxonomies
 * and like this /api/{type}/{uuid} for content.
 * We can just divide iri on / and take the one before last string to get type.
 * @param iri - iri of the taxonomy or content
 * @private
 */
export function typeFromIri(iri: Iri): string {
    return iri.split('/').slice(-2, -1)[0] || '';
}
