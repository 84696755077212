import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SentinelService {
    private readonly capabilities: Observable<any>;

    constructor(private readonly http: HttpClient) {
        const parser = new WMTSCapabilities();
        const params = new HttpParams().append('REQUEST', 'GetCapabilities');
        const url =
            'https://services.sentinel-hub.com/ogc/wmts/fa83b9e7-1a72-450f-9b1a-0591fe252875';
        this.capabilities = this.http.get(url, { params, responseType: 'text' }).pipe(
            map((r: string) => parser.read(r)),
            shareReplay(1)
        );
    }

    getCapabilities(): Observable<any> {
        return this.capabilities;
    }
}
