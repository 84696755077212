import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMapControl } from './control';

@Injectable()
export class IsavMapControlsService {
    private readonly controls$ = new BehaviorSubject<IMapControl[]>([]);

    register(control: IMapControl): void {
        this.controls$.next([...this.controls$.value, control]);
    }

    unregister(control: IMapControl): void {
        this.controls$.next(this.controls$.value.filter((c) => c !== control));
    }

    getControls(): Observable<IMapControl[]> {
        return this.controls$.asObservable();
    }
}
