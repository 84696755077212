import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isavMonthName',
})
export class IsavMonthNamePipe implements PipeTransform {
    transform(dateStr: string): string {
        const date = new Date(dateStr);
        if (Number.isNaN(date.getTime())) return '';
        const month = date.getMonth();
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        return months[month];
    }
}
