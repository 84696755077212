<ng-container *ngIf="fieldwork">
    <div class="page-container page-container-vertical content-fieldwork">
        <isav-caption>
            <span class="mr-5"><b>Fieldwork start: </b>{{ fieldwork.start | isavDate }}</span>
            <span><b>Fieldwork end: </b>{{ fieldwork.end | isavDate }}</span>
        </isav-caption>
        <div class="columns">
            <div class="column">
                <div class="content-type-details">
                    <div>
                        <p *ngIf="fieldwork.title" class="title is-5">{{ fieldwork.title }}</p>
                        <p>
                            <strong>Country:&nbsp;</strong>
                            <isav-taxonomies-names
                                [iris]="[fieldwork.country]"
                            ></isav-taxonomies-names>
                        </p>
                        <p>
                            <strong>Region:&nbsp;</strong>
                            <isav-taxonomies-names
                                [iris]="[fieldwork.region]"
                            ></isav-taxonomies-names>
                        </p>
                        <p *ngIf="fieldwork.contactName">
                            <strong>Fieldwork contact name:&nbsp;</strong>
                            {{ fieldwork.contactName }}
                        </p>
                        <p *ngIf="fieldwork.contactPhone">
                            <strong>Fieldwork contact phone:&nbsp;</strong>
                            {{ fieldwork.contactPhone }}
                        </p>
                        <p *ngIf="fieldwork.contactEmail">
                            <strong>Fieldwork contact email:&nbsp;</strong>
                            {{ fieldwork.contactEmail }}
                        </p>
                        <p *ngIf="fieldwork.trackerAddress">
                            <strong>GPS tracker address:&nbsp;</strong>
                            {{ fieldwork.trackerAddress }}
                        </p>
                        <p>
                            <strong>Location:&nbsp;</strong>
                            {{ fieldwork.location | isavGeoLocation }}
                        </p>
                    </div>
                    <ng-container *ngIf="fieldworkIncludeOutsideSettlements">
                        <div *isavCanViewSar="fieldwork.project">
                            <p class="title is-5 mt-5">SAR information:</p>
                            <isav-sar-display
                                *ngIf="sar$ | async as sar"
                                [sar]="sar"
                                noAssetInfo="This fieldwork does not have SAR asset"
                            ></isav-sar-display>
                        </div>
                    </ng-container>
                    <div *ngIf="fieldwork.description">
                        <p class="title is-5 mt-5">Description:</p>
                        <isav-description-display
                            [title]="'Fieldwork of ' + fieldwork?.project?.title + ' project'"
                            [content]="fieldwork.description"
                        ></isav-description-display>
                    </div>
                </div>
            </div>
            <div class="column">
                <isav-map
                    *ngIf="fieldwork.location || fieldwork.areaPoints?.length || fieldwork.routePoints?.length; else noLocation"
                    [initialZoom]="7"
                >
                    <isav-map-markers-creation></isav-map-markers-creation>
                    <isav-map-markers-display></isav-map-markers-display>
                    <isav-map-vector-layer>
                        <isav-map-area
                            [coordinate]="fieldwork.areaPoints | isavMapCoordinate"
                        ></isav-map-area>
                        <isav-map-marker
                            *ngIf="fieldwork.location"
                            [coordinate]="fieldwork.location | isavMapCoordinate"
                            display="point"
                            [center]="true"
                        ></isav-map-marker>
                        <isav-map-route
                            *ngIf="fieldwork.routePoints?.length"
                            [routePoints]="fieldwork.routePoints"
                            [center]="true"
                        ></isav-map-route>
                    </isav-map-vector-layer>
                </isav-map>
                <ng-template #noLocation>
                    <isav-map-placeholder class="has-text-grey">
                        This fieldwork does not have location
                    </isav-map-placeholder>
                </ng-template>
                <div *ngIf="fieldwork.fieldworkCoverPhoto" class="mt-5">
                    <img
                        [isavApiImg]="fieldwork.fieldworkCoverPhoto"
                        alt=""
                        aria-hidden="true"
                        class="is-fullwidth is-containing"
                    />
                    <isav-image-caption [iri]="fieldwork.fieldworkCoverPhoto"></isav-image-caption>
                </div>
            </div>
        </div>
    </div>
</ng-container>
