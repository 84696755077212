import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Iri } from '../../../core/dto/iri';
import { environment } from '../../../../environments/environment';
import { DialogManager } from '../../../shared/dialog/dialog.service';

@Component({
    selector: 'isav-image-simple-popup',
    templateUrl: './image-simple-popup.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavImageSimplePopup implements OnInit, OnDestroy {
    maxImageSize = environment.maxImageSize;
    cancel$ = new Subject<Iri | null>();
    imageIri: Iri | null = null;

    constructor(
        private dialogManager: DialogManager<Iri | null, Iri | null>,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.dialogManager.data) this.imageIri = this.dialogManager.data;

        this.cancel$.subscribe((iri) => {
            this.dialogManager.close(iri);
        });
    }

    ngOnDestroy() {
        this.cancel$.complete();
    }

    close(): void {
        this.cancel$.next(null);
    }

    save(): void {
        return this.cancel$.next(this.imageIri);
    }

    onChange(iri: Iri) {
        this.imageIri = iri;
        this.cdr.markForCheck();
    }
}
