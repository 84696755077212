import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { GoogleMap } from './google-map';

@NgModule({
    declarations: [GoogleMap],
    exports: [GoogleMap],
    imports: [CommonModule, SharedModule],
})
export class GoogleMapModule {}
