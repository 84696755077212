<ng-container [ngSwitch]="this.status">
    <div *ngSwitchCase="'IMAGE'" class="uploader-zone" tabindex="0" #uploaderZone>
        <img
            class="uploader-image"
            [isavApiImg]="this.imageIri"
            data-testid="image-uploader-image"
        />
    </div>

    <div
        *ngSwitchCase="'NO_IMAGE'"
        class="uploader-zone dropzone"
        (isavFileDropped)="onImageDropped($event)"
    >
        <div class="uploader-content">
            <div>Drag and drop file here</div>
            <div>or</div>
            <div class="file is-centered is-primary">
                <label class="file-label">
                    <input
                        class="file-input"
                        type="file"
                        name="resume"
                        (change)="onImageSelected($event)"
                    />
                    <span class="file-cta">
                        <span class="file-icon">
                            <i class="fas fa-upload"></i>
                        </span>
                        <span class="file-label"> Choose a file… </span>
                    </span>
                </label>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'UPLOADING_IMAGE'" class="uploader-zone">
        <div class="uploader-content">
            <span>Uploading image...</span>
            <progress class="progress is-info" value="{{percentDone}}" max="100"></progress>
        </div>
    </div>
</ng-container>
