import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'isav-action-title',
    templateUrl: './action-title.html',
    styleUrls: ['./action-title.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavActionTitle {
    @Input() title = '';
}
