import { Action, createReducer, on } from '@ngrx/store';
import { SearchState } from './search-state';
import {
    beginSearch,
    createSearchNamespace,
    saveResults,
    searchSort,
    updateQuery,
} from './search.actions';
import { produceState } from '../../utils/store';
import { Hydra } from '../dto/hydra';

export interface GlobalSearchState {
    [name: string]: SearchState<any>;
}

export const initialState = {};

/**
 * Removes all keys that are either null, undefined or '' (empty string) as those will be
 * problematic when used inside the search to communicate with the api
 *
 * @param obj - object which keys should be cleaned
 */
function removeUnwanted<T extends object>(obj: T): T {
    return Object.keys(obj).reduce((acc: T, key: string) => {
        if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
            acc[key] = obj[key];
        }
        return acc;
    }, {} as T);
}

const reducer = createReducer(
    initialState,
    on(
        createSearchNamespace,
        produceState((state, action) => {
            state[action.namespace] = {
                loading: false,
                query: {},
                results: Hydra.empty(),
                self: action.self,
            };
        })
    ),
    on(
        beginSearch,
        produceState((state, action) => {
            state[action.namespace].query = removeUnwanted({
                ...state[action.namespace].query,
                ...action.query,
            });
            state[action.namespace].loading = true;
        })
    ),
    on(
        updateQuery,
        produceState((state, action) => {
            state[action.namespace].query = removeUnwanted({
                ...state[action.namespace].query,
                ...action.query,
            });
        })
    ),
    on(
        saveResults,
        produceState((state, action) => {
            state[action.namespace].loading = false;
            state[action.namespace].results = Hydra.from(action.results);
        })
    ),
    on(
        searchSort,
        produceState((state, action) => {
            const prevQuery = { ...state[action.namespace].query };
            Object.keys(prevQuery)
                .filter((key) => key.startsWith('order['))
                .forEach((key) => (prevQuery[key] = undefined));
            state[action.namespace].query = removeUnwanted({
                ...prevQuery,
                ...action.query,
            });
        })
    )
);

/**
 * This is store reducer that handles all actions regarding storing search state.
 * It is based on namespaces, each feature has it's own namespace that is used to store search
 * results for this feature.
 *
 * Each module that uses search should dispatch action: createSearchNamespace and then use same
 * namespace later
 *
 * @param state
 * @param action
 */
export function searchReducer(state: GlobalSearchState, action: Action): GlobalSearchState {
    return reducer(state, action);
}
