import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    ViewContainerRef,
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { IsavTabTitleDirective } from './tab-title.directive';
import { IsavTabContentDirective } from './tab-content.directive';

@Component({
    selector: 'isav-tab',
    templateUrl: './tab.html',
    // we intentionally want default change detection here
    // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
    changeDetection: ChangeDetectionStrategy.Default,
})
export class IsavTab implements AfterViewInit {
    @Input() title: string;
    @ContentChild(IsavTabContentDirective) contentDirective?: IsavTabContentDirective;
    @ContentChild(IsavTabTitleDirective) titleDirective?: IsavTabTitleDirective;

    titlePortal: TemplatePortal | null;
    contentPortal: TemplatePortal | null;

    constructor(private _viewContainerRef: ViewContainerRef) {}

    ngAfterViewInit() {
        if (this.titleDirective) {
            this.titlePortal = new TemplatePortal(
                this.titleDirective.templateRef,
                this._viewContainerRef
            );
        }
        if (this.contentDirective) {
            this.contentPortal = new TemplatePortal(
                this.contentDirective.templateRef,
                this._viewContainerRef
            );
        }
    }
}
