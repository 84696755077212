<div class="simple-search-container">
    <div class="columns">
        <div class="column">
            <ng-content select="[isavSimpleSearchContent]"></ng-content>
        </div>
        <div class="column is-relative" *ngIf="isNotMobile$ | async">
            <isav-landing-page-carousel></isav-landing-page-carousel>
        </div>
    </div>
</div>
