import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Hydra } from '../dto/hydra';
import { PersonDto } from '../dto/person';

@Injectable()
export class PersonService {
    constructor(private http: HttpClient) {}

    activate(id: string) {
        return this.http.post('/api/person/activation', { person: id });
    }

    deactivate(id: string) {
        return this.http.post('/api/person/deactivation', { person: id });
    }

    changeEmail(id: string, email: string) {
        return this.http.post('/api/person/change-email', { person: id, email: email });
    }

    changePassword(id: string, password: string) {
        return this.http.post('/api/person/change-password', { person: id, password: password });
    }

    selfPasswordChange(data: { previousPassword: string; newPassword: string }) {
        return this.http.post(`/api/self/person/change-password`, data);
    }

    changeRoles(id: string, roles: string[]) {
        return this.http.post('/api/person/roles', { person: id, roles: roles });
    }

    changeOwner(data: { toUserEmail: string; fromUserEmail: string; contents: string[] }) {
        return this.http.post('/api/person/change-owner', data);
    }

    wipeData(id: string) {
        return this.http.post('/api/person/wipe-data', { person: id });
    }

    unsubscribeNewsletter(tokenId: string, personIri: string) {
        return this.http.post<void>('/api/person/unsubscribe-newsletter', {
            person: personIri,
            unsubscribeTokenId: tokenId,
        });
    }

    getMailReceivers(
        queryParams: { [key: string]: string | string[] } = {}
    ): Observable<Hydra<PersonDto>> {
        let params = new HttpParams({ fromObject: queryParams });
        if (!params.get('filterRoles[]')) params = params.append('filterRoles[]', '');
        return this.http.get<Hydra<PersonDto>>('/api/mail_receivers', {
            params,
        });
    }

    getProjectMailReceivers(
        queryParams: { [key: string]: string | string[] } = {}
    ): Observable<Hydra<PersonDto>> {
        const params = new HttpParams({ fromObject: queryParams });
        return this.http.get<Hydra<PersonDto>>('/api/project_mail_receivers', {
            params,
        });
    }
}
