<div data-testid="icon" *ngIf="value$ | async as value" class="is-relative" #icon>
    <div
        aria-hidden="true"
        tabindex="0"
        (focus)="create()"
        (blur)="destroy()"
        (mouseover)="create()"
        (mouseout)="destroy()"
    >
        <isav-icon [class.icon-map]="map" [class.icon-input]="!map" icon="info-circle"></isav-icon>
    </div>
    <div
        [style.visibility]="isOpened ? 'visible': 'hidden'"
        class="tooltip box"
        data-testid="tooltip"
        #tooltip
    >
        <p>{{ value }}</p>
    </div>
</div>
