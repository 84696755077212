import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IsavBaseControl } from '../base-control';

interface LinkInputValue {
    title: string;
    url: string;
}

@Component({
    selector: 'isav-link-input',
    templateUrl: './link-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IsavLinkInput), multi: true },
    ],
})
export class IsavLinkInput extends IsavBaseControl {
    value: LinkInputValue = { title: '', url: '' };

    constructor(_injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    writeValue(obj: any): void {
        if (typeof obj === 'object' && obj !== null) {
            this.value = {
                url: String(obj.url || ''),
                title: String(obj.title || ''),
            };
        } else {
            this.value = {
                url: '',
                title: '',
            };
        }

        this._cdRef.markForCheck();
    }

    update(property: keyof LinkInputValue, value: string) {
        this.value[property] = value;
        this._onChange(this.value);
        this._cdRef.markForCheck();
    }

    onTouched() {
        this._onTouched();
    }
}
