import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatalinkReadCollectionDto } from '../dto/datalink-read-collection';
import { BaseSearchService } from './base-search-service';

@Injectable()
export class DataLinksResourceService extends BaseSearchService<DatalinkReadCollectionDto> {
    constructor(http: HttpClient) {
        super('datalinks', http);
    }
}
