import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Maybe } from 'true-myth';
import { Nullable } from '../../utils/types/nullable';
import { TokenDto } from '../dto/token';
import { TokenService } from '../api/token.service';
import { selectState, selectToken, signIn, signOut } from './auth.store';
import { PersistentTokenStorage } from './persistent-token-storage';
import { TokenDecoder } from './token-decoder';

@Injectable()
export class AuthService {
    constructor(
        private tokenStorage: PersistentTokenStorage,
        private tokenService: TokenService,
        private store: Store,
        private decoder: TokenDecoder
    ) {
        const token = this.tokenStorage.getToken();
        const refreshToken = this.tokenStorage.getRefreshToken();
        if (token && refreshToken && this.validateToken(token)) {
            // we have a valid token and refresh token just login
            this.store.dispatch(signIn({ token, refresh_token: refreshToken }));
        } else if (refreshToken) {
            // we have invalid token but we have refresh token then just refresh
            // setTimeout is needed here so we do not cause error of cyclic dependency
            // with HTTP_INTERCEPTORS before we make an actual http call so interceptors can be
            // created
            setTimeout(() => {
                this.tokenService.postAuthTokenRefresh({ refresh_token: refreshToken }).subscribe(
                    (data) => this.store.dispatch(signIn(data)),
                    () => {
                        // need to clear the token when we fail to login!
                        this.tokenStorage.clear();
                    }
                );
            });
        } else {
            // we do not have valid data stored in the storage, so we should clear it
            this.tokenStorage.clear();
        }
        this.setupOnResumeTokenCheck();
    }

    private setupOnResumeTokenCheck() {
        fromEvent(document, 'resume')
            .pipe(
                switchMap(() => {
                    return this.store.select(selectState).pipe(take(1));
                })
            )
            .subscribe((state) => {
                const token = state.token;
                const refreshToken = state.refreshToken;
                if (token && refreshToken && this.validateToken(token)) {
                    this.store.dispatch(signIn({ token: token, refresh_token: refreshToken }));
                } else if (refreshToken) {
                    this.tokenService
                        .postAuthTokenRefresh({ refresh_token: refreshToken })
                        .subscribe(
                            (data) => this.store.dispatch(signIn(data)),
                            () => {
                                this.store.dispatch(signOut());
                            }
                        );
                } else {
                    this.store.dispatch(signOut());
                }
            });
    }

    token$(): Observable<Nullable<string>> {
        return this.store.select(selectToken);
    }

    authenticated$(): Observable<boolean> {
        return this.token$().pipe(map((token) => !!token));
    }

    roles$(): Observable<string[]> {
        return this.token$().pipe(
            map((token) => {
                return Maybe.of(token)
                    .chain((t) => this.decoder.maybeDecode(t))
                    .map((payload) => payload.roles)
                    .unwrapOr([]);
            })
        );
    }

    userIri$(): Observable<string> {
        return this.token$().pipe(
            map((token) => {
                return Maybe.of(token)
                    .chain((t) => this.decoder.maybeDecode(t))
                    .map((payload) => payload.personIri)
                    .unwrapOr('');
            })
        );
    }

    login(payload: { email: string; password: string }): Observable<TokenDto> {
        return this.tokenService
            .postAuthToken(payload)
            .pipe(tap((token) => this.store.dispatch(signIn(token))));
    }

    logout(): void {
        this.store.dispatch(signOut());
    }

    isTokenValid(): boolean {
        const token = this.tokenStorage.getToken();
        return token ? this.validateToken(token) : false;
    }

    isAuthenticated() {
        return !!this.tokenStorage.getToken();
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    authenticateWithTokens(token: string, refresh_token?: string) {
        const dto: TokenDto = { token, refresh_token };
        if (!this.validateToken(token))
            throw new Error(`Expired token passed to authenticateWithTokens!`);
        this.store.dispatch(signIn(dto));
    }

    private validateToken(token: string): boolean {
        return this.decoder
            .maybeDecode(token)
            .map((payload) => {
                const now = Date.now() / 1000;
                return payload.exp > now;
            })
            .unwrapOr(false);
    }
}
