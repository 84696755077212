import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import OLControl from 'ol/control/Control';
import { IsavMap } from '../map';

@Directive({
    selector: '[isavMapNativeControl]',
})
export class IsavMapNativeControl implements OnInit, OnDestroy {
    @Input() addClasses = true;
    private control?: OLControl;

    constructor(private readonly elementRef: ElementRef, private readonly isavMap: IsavMap) {}

    ngOnInit(): void {
        this.control = new OLControl({ element: this.elementRef.nativeElement });
        this.isavMap.map.addControl(this.control);

        if (this.addClasses) {
            this.elementRef.nativeElement.classList.add('ol-control', 'ol-unselectable');
        }
    }

    ngOnDestroy(): void {
        this.control?.dispose();
    }
}
