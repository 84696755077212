<thead>
    <ng-container #headerContainer></ng-container>
</thead>

<tbody>
    <ng-container *ngIf="!data?.length">
        <ng-container *ngTemplateOutlet="noDataRow?.templateRef"></ng-container>
    </ng-container>

    <ng-container #contentContainer></ng-container>
</tbody>

<tfoot>
    <ng-container #footerContainer></ng-container>
</tfoot>
