import { Pipe, PipeTransform } from '@angular/core';
import { Iri } from '../../core/dto/iri';

@Pipe({
    name: 'isavTitleFallback',
    pure: true,
})
export class IsavTitleFallback implements PipeTransform {
    transform(title: string | undefined | null, iri: Iri): string {
        return title || this.uuidFromIri(iri);
    }

    private uuidFromIri(iri: Iri): string {
        return iri.split('/').slice(-1)[0];
    }
}
