import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ImageDto } from '../dto/image';
import { ContentService } from './content.service';
import { Iri } from '../dto/iri';

@Injectable()
export class ImageService {
    private images = new Map<Iri, Observable<ImageDto>>();

    constructor(private contentService: ContentService) {}

    getImage$(iri: Iri): Observable<ImageDto> {
        if (this.images.has(iri)) return this.images.get(iri)!;
        this.images.set(iri, this.contentService.getByIri<ImageDto>(iri).pipe(shareReplay(1)));
        return this.images.get(iri)!;
    }
}
