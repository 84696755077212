import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TaxonomyTypes } from '../../../core/taxonomies/taxonomy-types';
import { IsavBaseControl } from '../base-control';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'isav-taxonomy-select-input',
    templateUrl: './taxonomy-select-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavTaxonomySelectInput),
            multi: true,
        },
    ],
})
export class IsavTaxonomySelectInput extends IsavBaseControl implements OnInit {
    TaxonomyTypes = TaxonomyTypes;

    @Input() taxonomy: TaxonomyTypes;
    @Input() placeholder = '';
    @Input() multiple = false;
    @Input() short: boolean;
    @Input() dropdownInBody: boolean;

    formControl = new UntypedFormControl(null);

    constructor(_injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this._onTouched();
            this._onChange(value);
        });
    }

    writeValue(obj: any): void {
        this.formControl.setValue(obj, { emitEvent: false });
        this._cdRef.markForCheck();
    }
}
