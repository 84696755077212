import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import Feature from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Text } from 'ol/style';
import Style from 'ol/style/Style';
import { prop } from 'ramda';
import { GeoLocationReadDto } from '../../../core/dto/geo-location-read';
import { RoutePointDto } from '../../../core/dto/route-point.dto';
import { IsavMap } from '../../../map/map';
import { formatStandardDate, parseStandardDate } from '../../../utils/date';
import { IsavBaseControl } from '../base-control';

export interface IsavRouteData {
    feature: Feature<Point>;
    date: string;
}

function isNumber(value: any): value is number {
    return typeof value === 'number';
}

function isIsavDate(value: any): boolean {
    if (typeof value !== 'string') return false;
    return !Number.isNaN(new Date(value).getTime());
}

function isLocation(location: any): location is GeoLocationReadDto {
    if (!location) return false;
    return (
        'longitude' in location &&
        isNumber(location.longitude) &&
        'latitude' in location &&
        isNumber(location.latitude)
    );
}

function validateRoutePoints(obj: any): obj is RoutePointDto[] {
    if (!Array.isArray(obj)) return false;
    return obj.filter(Boolean).every(({ location, date }) => {
        return isLocation(location) && isIsavDate(date);
    });
}

const POINT_STYLE_CACHE = {};
const POINT_STYLE = function (feature) {
    const index = +feature.get('featureIndex');
    if (!POINT_STYLE_CACHE[index]) {
        POINT_STYLE_CACHE[index] = new Style({
            text: new Text({
                scale: 0.8,
                text: (index + 1).toString(),
                fill: new Fill({
                    color: '#FFFFFF',
                }),
            }),
            image: new Circle({
                radius: 8,
                stroke: new Stroke({
                    color: '#FFFFFF',
                }),
                fill: new Fill({
                    color: '#1b7faf',
                }),
            }),
        });
    }

    return POINT_STYLE_CACHE[index];
};

const HIGHLIGHT_POINT_STYLE_CACHE = {};
const HIGHLIGHT_POINT_STYLE = function (feature) {
    const index = feature.get('featureIndex');

    if (!HIGHLIGHT_POINT_STYLE_CACHE[index]) {
        HIGHLIGHT_POINT_STYLE_CACHE[index] = new Style({
            text: new Text({
                scale: 0.8,
                text: (index + 1).toString(),
                fill: new Fill({
                    color: '#FFFFFF',
                }),
            }),
            image: new Circle({
                radius: 9,
                stroke: new Stroke({
                    color: '#FFFFFF',
                }),
                fill: new Fill({
                    color: '#FF3860',
                }),
            }),
        });
    }

    return HIGHLIGHT_POINT_STYLE_CACHE[index];
};

const LINE_STYLE = [
    new Style({
        stroke: new Stroke({
            width: 4,
            color: '#FFFFFF',
        }),
    }),
    new Style({
        stroke: new Stroke({
            width: 2,
            color: '#3399CC',
        }),
    }),
];

@Component({
    selector: 'isav-route-input',
    templateUrl: './route-input.html',
    styleUrls: ['./route-input.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IsavRouteInput), multi: true },
    ],
})
export class IsavRouteInput
    extends IsavBaseControl
    implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
    @ViewChild(IsavMap, { read: IsavMap, static: true }) isavMap: IsavMap;

    @Input() value: RoutePointDto[];
    @Output() valueChange = new EventEmitter<RoutePointDto[]>();

    _value: IsavRouteData[] = [];
    highlightFeatureIndex: number = -1;
    mode: 'draw' | 'edit' = 'draw';

    private readonly line = new VectorLayer({
        source: new VectorSource({ wrapX: false }),
        style: LINE_STYLE,
    });
    private readonly points = new VectorLayer({
        source: new VectorSource({ wrapX: false }),
        style: POINT_STYLE,
    });
    private readonly highlight = new VectorLayer({
        source: new VectorSource({ wrapX: false }),
        style: HIGHLIGHT_POINT_STYLE,
    });

    private readonly draw: Draw = new Draw({ type: GeometryType.LINE_STRING });
    private readonly modify: Modify = new Modify({
        source: this.points.getSource(),
    });

    private afterViewInit: boolean = false;

    constructor(private cdRef: ChangeDetectorRef, injector: Injector, private ngZone: NgZone) {
        super(injector);
    }

    get features() {
        return this._value.map(prop('feature'));
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.writeValue(this.value);
        }
    }

    ngAfterViewInit(): void {
        this.afterViewInit = true;
        this.modelToViewUpdate();

        this.draw.on('drawend', (e) => {
            this.ngZone.run(() => {
                const lineString = e.feature.getGeometry() as LineString;
                const coordinate = lineString.getCoordinates();

                this._value = coordinate.map((c, index) => {
                    const date = this._value[index]?.date ?? '';
                    const feature = new Feature(new Point(c));
                    feature.set('featureIndex', index);
                    return {
                        date,
                        feature,
                    };
                });

                this.setMode('edit');
                this.updateFeatures();
                this.emitValue();

                this.cdRef.markForCheck();
            });
        });

        this.isavMap.map.addEventListener('keydown', (e) => {
            const key = (<any>e)?.originalEvent?.key;
            if (key === 'Escape') {
                this.draw.abortDrawing();
            }

            if (key === 'Enter') {
                this.draw.finishDrawing();
            }

            if (key === 'Backspace') {
                this.draw.removeLastPoint();
            }
        });

        this.modify.on('modifyend', (e) => {
            this.ngZone.run(() => {
                this.updateLine();
                this.emitValue();
                this.cdRef.markForCheck();
            });
        });

        this.isavMap.map.addInteraction(this.modify);
        this.isavMap.map.addInteraction(this.draw);

        this.setMode(this._value.length ? 'edit' : 'draw');

        this.isavMap.map.addLayer(this.line);
        this.isavMap.map.addLayer(this.points);
        this.isavMap.map.addLayer(this.highlight);

        const line = this.line.getSource().getFeatures()[0] as Feature<LineString>;
        if (line) {
            this.isavMap.view.fit(line.getGeometry(), {
                padding: [30, 30, 30, 30],
            });
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        // no need to clear refs on the map, since map is our child and will be
        // disposed when we are destroyed
    }

    writeValue(obj: any): void {
        if (validateRoutePoints(obj)) {
            this.value = obj;
        } else {
            this.value = [];
        }

        if (this.afterViewInit) {
            this.modelToViewUpdate();
        }
    }

    highlightFeature(index: number): void {
        this.highlightFeatureIndex = index;
        this.highlight.getSource().clear();
        if (index >= 0 && index < this._value.length) {
            const feature = this._value[index].feature;
            this.highlight.getSource().addFeature(feature);
        }
    }

    trackByIndex(index: number): any {
        return index;
    }

    emitValue() {
        const value: RoutePointDto[] = this._value.map(({ date, feature }) => {
            const coordinate = feature.getGeometry().getCoordinates();
            const [longitude, latitude] = this.isavMap.fromMapCoordinate(coordinate);
            const outputDate = date ? formatStandardDate(parseStandardDate(date)) : '';
            return { date: outputDate, location: { longitude, latitude } };
        });
        this._onChange(value);
        this.value = value;
        this.valueChange.emit(value);
    }

    displayCoordinates(feature: Feature<Point>): any {
        const [longitude, latitude] = this.isavMap.fromMapCoordinate(
            feature.getGeometry().getCoordinates()
        );
        return `${longitude.toFixed(5)}, ${latitude.toFixed(5)}`;
    }

    setMode(mode: 'draw' | 'edit'): void {
        const enableDraw = mode === 'draw';
        const enableEdit = mode === 'edit';

        this.mode = mode;
        this.draw.setActive(enableDraw);
        this.modify.setActive(enableEdit);
    }

    clear(): void {
        this._value = [];
        this.updateFeatures();
        this.setMode('draw');
        this.cdRef.markForCheck();
    }

    expandMap() {
        this.isavMap.toggleFullScreenMode(true);
    }

    private modelToViewUpdate() {
        this._value = this.value.map(({ location: { longitude, latitude }, date }, index) => {
            const inputDate = date
                ? formatStandardDate(parseStandardDate(date.substring(0, 10)))
                : '';
            const coordinate = this.isavMap.toMapCoordinate([longitude, latitude]);
            const feature = new Feature(new Point(coordinate));
            feature.set('featureIndex', index);
            return {
                feature,
                date: inputDate,
            };
        });

        this.setMode(this._value.length ? 'edit' : 'draw');
        this.updateFeatures();
        this.cdRef.markForCheck();
    }

    private updateFeatures() {
        this.highlight.getSource().clear();
        this.points.getSource().clear();

        this.points.getSource().addFeatures(this.features);
        this.highlightFeature(this.highlightFeatureIndex);
        this.updateLine();
    }

    private updateLine() {
        this.line.getSource().clear();
        if (this.features.length > 0) {
            const lineCoords = this.features.map((f) => f.getGeometry().getCoordinates());
            this.line.getSource().addFeature(new Feature<LineString>(new LineString(lineCoords)));
        }
        this.cdRef.detectChanges();
    }
}
