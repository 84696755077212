<div class="modal is-active" (isavDialog)="close()">
    <div class="modal-background"></div>
    <div class="modal-card is-content-width">
        <div class="modal-card-head">
            <p class="modal-card-title">Edit SAR</p>
            <button class="delete" aria-label="close" (click)="close()"></button>
        </div>
        <div class="modal-card-body" [formGroup]="form">
            <p class="title is-5">Skills:</p>

            <ng-container formArrayName="skills">
                <div
                    *ngFor="let skill of skills; let index = index; let odd = odd"
                    [formGroupName]="index"
                    class="px-3 py-3"
                    [class.has-background-primary-light]="odd"
                >
                    <isav-taxonomy-select-input
                        label="SAR skill"
                        taxonomy="SarSkill"
                        formControlName="sarSkill"
                        [short]="true"
                        tooltipKey="Skill:sarSkill"
                    >
                    </isav-taxonomy-select-input>
                    <isav-text-input
                        label="Number of participants with this skill"
                        type="number"
                        formControlName="numberOfParticipants"
                        tooltipKey="Skill:numberOfParticipants"
                    >
                    </isav-text-input>
                    <isav-textarea-input
                        label="Description"
                        formControlName="description"
                        tooltipKey="Skill:description"
                    >
                    </isav-textarea-input>

                    <div class="field is-horizontal">
                        <div class="field-label"></div>
                        <div class="field-body">
                            <div class="field is-grouped is-grouped-right">
                                <div class="control">
                                    <button
                                        isavBtn
                                        color="danger"
                                        size="small"
                                        [outlined]="true"
                                        (click)="removeSkill(index)"
                                    >
                                        <isav-icon icon="trash"></isav-icon>
                                        <span>Remove</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <button
                isavBtn
                type="button"
                color="success"
                size="small"
                class="mt-2"
                (click)="addSkill()"
            >
                <isav-icon icon="plus" class="is-small"></isav-icon>
                <span>Add skill</span>
            </button>

            <hr />

            <p class="title is-5">Equipments:</p>

            <ng-container formArrayName="equipments">
                <div
                    *ngFor="let equipment of equipments; let index = index; let odd = odd"
                    [formGroupName]="index"
                    class="px-3 py-3"
                    [class.has-background-primary-light]="odd"
                >
                    <isav-taxonomy-select-input
                        label="SAR equipment"
                        taxonomy="SarEquipment"
                        formControlName="sarEquipment"
                        [short]="true"
                        tooltipKey="Equipment:sarEquipment"
                    >
                    </isav-taxonomy-select-input>
                    <isav-text-input
                        label="Number of this equipment"
                        type="number"
                        formControlName="numberOfParticipants"
                        tooltipKey="Equipment:numberOfParticipants"
                    >
                    </isav-text-input>
                    <isav-textarea-input
                        label="Description"
                        formControlName="description"
                        tooltipKey="Equipment:description"
                    >
                    </isav-textarea-input>

                    <div class="field is-horizontal">
                        <div class="field-label"></div>
                        <div class="field-body">
                            <div class="field is-grouped is-grouped-right">
                                <div class="control">
                                    <button
                                        isavBtn
                                        color="danger"
                                        size="small"
                                        [outlined]="true"
                                        (click)="removeEquipment(index)"
                                    >
                                        <isav-icon icon="trash"></isav-icon>
                                        <span>Remove</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <button
                isavBtn
                type="button"
                color="success"
                size="small"
                class="mt-2"
                (click)="addEquipment()"
            >
                <isav-icon icon="plus" class="is-small"></isav-icon>
                <span>Add equipment</span>
            </button>
        </div>
        <div class="modal-card-foot">
            <button class="button is-success" type="button" (click)="save()">Save changes</button>
            <button class="button" type="button" (click)="close()">Cancel</button>
        </div>
    </div>
    <isav-loader-overlay *ngIf="loading"></isav-loader-overlay>
</div>
