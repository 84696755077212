import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomainEventJournalDto } from '../../../core/dto/domain-event-journal';

@Component({
    selector: 'isav-domain-event-journal',
    templateUrl: './domain-event-journal.html',
    styleUrls: ['./domain-event-journal.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavDomainEventJournal {
    @Input() event: DomainEventJournalDto;
}
