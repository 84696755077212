<div class="hint" [class.blue]="blue">
    <div class="mb-5 hint-icon">
        <isav-icon [icon]="cardIcons[card.logoType || 0]"></isav-icon>
    </div>
    <strong class="title is-5">
        <a
            *ngIf="card.externalLink; else title"
            class="hint-link"
            [href]="card.externalLink.url"
            target="_blank"
        >
            {{ card.externalLink.title || card.title }}
        </a>
    </strong>
    <div class="mt-5 content" [innerHTML]="card.body"></div>
</div>

<ng-template #title>
    <span class="hint-title"> {{ card.title }} </span>
</ng-template>
