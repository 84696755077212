import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class MetadataService {
    private readonly TITLE = 'Isaaffik the arctic gateway';

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private meta: Meta
    ) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.removeDescription();
                this.removeScriptData();
            }
        });
    }

    getTitle(): string {
        return this.document.title;
    }

    setTitle(value: string) {
        this.document.title = `${value || 'Unnamed'} | ${this.TITLE}`;
    }

    setDescription(content: string) {
        this.meta.addTag({ name: 'description', content: content });
    }

    removeDescription(): void {
        this.meta.removeTag('name=description');
    }

    setScriptData(data: object): void {
        const script = this.getScriptElement();
        script.innerHTML = this.sanitizeScriptData(JSON.stringify(data));
    }

    removeScriptData() {
        this.document.getElementById('seo')?.remove();
    }

    seo<T extends object>(titleProperty: string): MonoTypeOperatorFunction<T> {
        return tap<T>((data: T) => {
            this.setTitle(data ? data[titleProperty] : '');
            this.setScriptData(data);
        });
    }

    private getScriptElement(): HTMLScriptElement {
        let scriptEl = this.document.getElementById('seo') as HTMLScriptElement;
        if (scriptEl) return scriptEl;
        scriptEl = this.document.createElement('script');
        scriptEl.id = 'seo';
        scriptEl.type = 'application/ld+json';
        this.document.head.appendChild(scriptEl);
        return scriptEl;
    }

    private sanitizeScriptData(text: string): string {
        return text.replace(/<\/?script/g, (v) => v.replace('<', '&lt;'));
    }
}
