<div class="page-container page-container-vertical">
    <div class="columns" [class.is-multiline]="isAsideWider">
        <div
            class="column"
            [class.is-half-tablet]="!singleColumn"
            [class.is-12-tablet]="isAsideWider"
            [class.is-5-widescreen]="isAsideWider"
        >
            <div class="content-type-details">
                <ng-content select="isav-caption"></ng-content>
                <ng-content select="[isavContentTypeDetails]"></ng-content>
            </div>
        </div>
        <div
            class="column"
            [class.is-half-tablet]="!singleColumn"
            [class.is-12-tablet]="isAsideWider"
            [class.is-7-widescreen]="isAsideWider"
            *ngIf="!singleColumn"
        >
            <ng-content select="[isavContentTypeAside]"></ng-content>
        </div>
    </div>
</div>
