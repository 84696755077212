<ng-container *ngIf="!isEdit">
    <div class="columns note mb-5">
        <div class="column is-three-quarters">
            <p class="title is-5">
                <span>{{ note.title }}&nbsp;</span>
                <span *ngIf="note.personal" class="personal"> (Personal note) </span>
            </p>
            <p>{{ note.description }}</p>
        </div>
        <div class="column" data-testid="create-info">
            <p class="has-text-centered">
                <strong>Created by</strong>
            </p>
            <p class="has-text-centered">
                <strong data-testid="author"> {{ note.author?.fullName }} </strong>
                {{ note.createdAt | isavDate }}
            </p>
            <p class="has-text-centered is-flex note-actions mt-5">
                <a data-testid="delete-note" (click)="deleteNote()">Delete note</a>
                <a data-testid="edit-note" (click)="editNote(true)">Edit note</a>
            </p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isEdit">
    <div class="is-relative">
        <isav-note-form [(value)]="editedNote">
            <button
                data-testid="btn-cancel"
                isavBtn
                color="danger"
                type="button"
                (click)="editNote(false)"
            >
                Cancel
            </button>
            <button data-testid="btn-edit" type="button" isavBtn color="success" (click)="edit()">
                Save
            </button>
        </isav-note-form>
        <isav-loader-overlay *ngIf="loading"></isav-loader-overlay>
    </div>
</ng-container>
