import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { IsavDropdownItem } from './dropdown-item';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { fromEvent, merge, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

let uniqueIdCounter = 0;

@UntilDestroy()
@Component({
    selector: 'isav-dropdown',
    templateUrl: './dropdown.html',
    styleUrls: ['./dropdown.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'isavDropdown',
})
export class IsavDropdown implements OnInit, AfterContentInit {
    @ViewChild('contentTemplate') template: TemplateRef<any>;
    @ContentChildren(IsavDropdownItem) dropdownItems: QueryList<IsavDropdownItem>;

    @Input() id = 'isav-dropdown-' + uniqueIdCounter++;

    /**
     * Emits when dropdown should be closed and tell if focus needs to be restored to the trigger
     */
    @Output() closed = new EventEmitter<boolean>();

    /**
     * Emits when dropdown should be opened
     */
    @Output() opened = new EventEmitter<void>();

    private _isOpened = false;
    private _lastFocusedItem: IsavDropdownItem | null = null;

    constructor(private _ngZone: NgZone, @Inject(DOCUMENT) private document: Document) {}

    ngOnInit() {
        merge(this.opened.pipe(mapTo(true)), this.closed.pipe(mapTo(false)))
            .pipe(untilDestroyed(this))
            .subscribe((isOpen) => (this._isOpened = isOpen));

        // those are run outside angular to not trigger change detection as they are touching
        // dom directly and we do not care for angular to take any actions especially on setTimeout
        this._ngZone.runOutsideAngular(() => {
            this.opened.pipe(untilDestroyed(this)).subscribe(() => {
                setTimeout(() => {
                    this.focusFirst();
                });
            });

            fromEvent(this.document, 'keydown', { passive: false })
                .pipe(untilDestroyed(this))
                .subscribe(this.handleKeydown);
        });
    }

    ngAfterContentInit() {
        const updateDropdownItems = this.dropdownItems.changes.pipe(map(() => this.dropdownItems));

        merge(of(this.dropdownItems), updateDropdownItems)
            .pipe(
                map((items) => items.map((item) => item.focused.pipe(mapTo(item)))),
                switchMap((focusedEvents) => merge(...focusedEvents))
            )
            .subscribe((focusedItem) => {
                this._lastFocusedItem = focusedItem;
            });
    }

    focusFirst() {
        this.dropdownItems.first.focus();
    }

    focusLast() {
        this.dropdownItems.last.focus();
    }

    focusRelative(relativeIndex: number): void {
        const items = this.dropdownItems.toArray();
        const currentIndex = this._lastFocusedItem ? items.indexOf(this._lastFocusedItem) : -1;
        const nextIndex = (items.length + currentIndex + relativeIndex) % items.length;
        items[nextIndex].focus();
    }

    handleKeydown = (event: KeyboardEvent) => {
        if (!this._isOpened) return;

        switch (event.key) {
            default:
                return;
            case 'ArrowUp':
                this.focusRelative(-1);
                break;
            case 'ArrowDown':
                this.focusRelative(1);
                break;
            case 'Home':
                this.focusFirst();
                break;
            case 'End':
                this.focusLast();
                break;
        }

        event.preventDefault();
    };
}
