import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ContentService } from '../api/content.service';

@Injectable()
export class ContentEditGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private contentService: ContentService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | UrlTree {
        if (!route.queryParamMap.has('iri')) {
            return this.router.createUrlTree(['/']);
        }
        const iri = route.queryParamMap.get('iri')!;
        const fallback = this.router.createUrlTree([state.url.replace('edit', 'view')], {
            queryParams: { iri },
        });
        return this.contentService.getEditAccess(iri).pipe(
            take(1),
            map((hasAccess) => hasAccess || fallback)
        );
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | UrlTree {
        return this.canActivate(childRoute, state);
    }
}
