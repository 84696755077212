import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchParams } from './search-state';
import { HydraProperties } from '../dto/hydra';

export interface SearchResource<T> {
    getAll(queryParams?: SearchParams, isSelf?: boolean): Observable<HydraProperties<T>>;
    getAllAsCsv(queryParams?: SearchParams, isSelf?: boolean): Observable<string>;
}

export const SEARCH_RESOURCE = new InjectionToken<SearchResource<any>>('SearchResource');
export const SEARCH_RESOURCE_NAMESPACE = new InjectionToken<string>('SearchResourceNamespace');
export const SEARCH_RESOURCE_USE_SELF = new InjectionToken<string>('SearchResourceUseSelf');
