<div *isavMapControl="'top left'" class="is-relative" #relativeContainer>
    <button class="settings-toggle-btn" type="button" (click)="toggleSettings()">
        <isav-icon icon="sliders-h"></isav-icon>
    </button>
    <isav-tooltip-display [map]="true" [tooltipKey]="'Map:iceSetting'"></isav-tooltip-display>
    <form *ngIf="visible" [formGroup]="settings" class="box settings-container">
        <div class="field">
            <label for="iceLayerCloudCoverage">Cloud Coverage</label>
            <div class="control">
                <div class="cloud-coverage-control">
                    <span class="cloud-coverage-value">{{ sliderDisplayValue }}</span>
                    <input
                        id="iceLayerCloudCoverage"
                        type="range"
                        formControlName="cloudCoverage"
                        min="0"
                        max="100"
                        step="1"
                        (input)="sliderDisplayValue = $event.target.value"
                        class="cloud-coverage-slider"
                    />
                </div>
            </div>
        </div>
        <div class="field">
            <label for="iceLayerEndDate">Date</label>
            <div class="control">
                <input id="iceLayerEndDate" type="date" class="input" formControlName="date" />
            </div>
            <p class="help">This controls last date from which images should be used.</p>
        </div>
    </form>
</div>
