<isav-select
    [id]="id"
    [value]="value"
    [readonly]="disabled"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [dropdownInBody]="dropdownInBody"
    (valueChange)="update($event)"
    (touched)="onTouched()"
>
    <ng-container *ngFor="let type of types$ | async; trackBy: iriTrackBy">
        <isav-option
            [value]="type['@id']"
            class="has-text-weight-bold"
            (selectedChange)="onParentSelect(type, $event)"
        >
            <span class="color-circle" [style.background-color]="color(type.marker)"></span>
            {{ type.name }}
        </isav-option>
        <isav-optgroup *ngIf="type.children?.length">
            <isav-option *ngFor="let child of type.children" [value]="child['@id']">
                <span class="color-circle" [style.background-color]="color(child.marker)"></span>
                {{ child.name }}
            </isav-option>
        </isav-optgroup>
    </ng-container>
</isav-select>
