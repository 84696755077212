import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { ContentService } from '../../../core/api/content.service';
import { NotesResourceService } from '../../../core/api/notes-resource.service';
import { NoteDto } from '../../../core/dto/note';
import { NotificationService } from '../../../core/services/notification.service';
import { DeleteConfirmationService } from '../../delete-content-modal/delete-confirmation.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'isav-note',
    templateUrl: './note.html',
    styleUrls: ['./note.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavNote implements OnChanges {
    @Input() note: NoteDto;
    loading: boolean = false;
    isEdit: boolean = false;
    editedNote: Partial<NoteDto>;

    constructor(
        private contentService: ContentService,
        private notificationService: NotificationService,
        private notesService: NotesResourceService,
        private deleteConfirmation: DeleteConfirmationService,
        private _cdRef: ChangeDetectorRef
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.note) {
            this.editedNote = {
                title: this.note.title,
                description: this.note.description,
                personal: this.note.personal,
            };
        }
    }

    deleteNote() {
        this.deleteConfirmation
            .confirmDeletion(this.note.title)
            .pipe(
                untilDestroyed(this),
                switchMap(() => {
                    return this.contentService.delete(this.note['@id']);
                })
            )
            .subscribe(() => {
                this.notificationService.add({
                    title: 'Success',
                    message: 'Successfully deleted note',
                    type: 'success',
                    delay: 5000,
                });
                this.notesService.updated(this.note.content!['@id']);
                this._cdRef.markForCheck();
            });
    }

    editNote(value: boolean) {
        this.isEdit = value;
    }

    edit() {
        this.loading = true;
        this.contentService.patch(this.note['@id'], this.editedNote).subscribe(
            () => {
                this.loading = false;
                this.notificationService.add({
                    title: 'Success',
                    message: 'Successfully edited note',
                    type: 'success',
                    delay: 5000,
                });
                this.editNote(false);
                this.notesService.updated(this.note.content!['@id']);
                this._cdRef.markForCheck();
            },
            (err) => {
                this.loading = false;
                this._cdRef.markForCheck();
            }
        );
    }
}
