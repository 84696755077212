import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Host,
    NgZone,
    OnInit,
} from '@angular/core';
import { combineLatest, concat, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IsavFieldIndexDirective } from './field-index.directive';
import { IsavFieldAccessor } from './field-accessor';

export interface FieldIndexData {
    index: number;
    name?: string;
    id?: string;
    status?: string;
}

@Component({
    selector: 'isav-field-index',
    templateUrl: './field-index.html',
    styleUrls: ['./field-index.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavFieldIndex implements OnInit, AfterViewInit {
    fields$: Observable<readonly FieldIndexData[]>;

    constructor(
        @Host() private fieldIndex: IsavFieldIndexDirective,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.fields$ = this.fieldIndex.fields$.pipe(
            switchMap((fields) => this.statusChanges(fields.sort(this.sortFieldsByLabels)))
        );
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.cdr.markForCheck();
        });
    }

    sortFieldsByLabels(a, b) {
        const first = Number(a.label.match(/(^\d+(?:\.\d+)?)/)[0]);
        const second = Number(b.label.match(/(^\d+(?:\.\d+)?)/)[0]);
        return first - second;
    }

    private statusChanges(fields: IsavFieldAccessor[]): Observable<readonly FieldIndexData[]> {
        const status$ = fields.map((field) => {
            const control = field.control;
            return concat(
                of(control?.status),
                control?.statusChanges?.pipe(map(() => control?.status)) || of()
            );
        });
        return combineLatest(status$).pipe(
            map((statuses: string[]): readonly FieldIndexData[] => {
                return fields.map((input, index) => {
                    const isMandatory = input.isMandatory;
                    return {
                        id: input.id,
                        name: input.name,
                        index,
                        status: isMandatory ? statuses[index] : 'NONE',
                    };
                });
            })
        );
    }

    tryToFocus(id: string): void {
        this.ngZone.runOutsideAngular(() => {
            document.getElementById(id)?.focus();
        });
    }
}
