import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import GeometryType from 'ol/geom/GeometryType';
import Polygon from 'ol/geom/Polygon';
import { Modify, Select } from 'ol/interaction';
import Draw from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import VectorLayer from 'ol/layer/Vector';
import OLMap from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import { IsavMap } from '../../map';
import { fromMapEvent } from '../../util/from-map-event';

export type DrawMode = 'modify' | 'draw';

@UntilDestroy()
@Component({
    selector: 'isav-map-draw-area',
    templateUrl: 'draw-area.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavMapDrawArea implements OnInit, OnChanges, OnDestroy {
    @Input() disabled: boolean = false;
    @Input() coordinate: Coordinate[] = []; // WGS84 coordinates
    @Output() coordinateChange = new EventEmitter<Coordinate[]>();

    private _active: DrawMode = 'draw';
    private _map: OLMap;
    private _vectorSource: VectorSource<Polygon>;
    private _vectorLayer: VectorLayer;
    private _draw: Draw;
    private _select: Select;
    private _modify: Modify;
    private _snap: Snap;

    constructor(private _isavMap: IsavMap, private ngZone: NgZone) {}

    ngOnInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this._map = this._isavMap.map;
            this._vectorSource = new VectorSource<Polygon>({
                wrapX: false,
            });
            this._vectorLayer = new VectorLayer({
                source: this._vectorSource,
                zIndex: 100,
            });
            this._map.addLayer(this._vectorLayer);
            this.initDraw();
            this.initModify();
            this.initSnap();

            this.drawFeatures();
            this.setActive(this.coordinate.length > 0 ? 'modify' : 'draw');
        });

        this._isavMap.viewChanged.pipe(untilDestroyed(this)).subscribe(() => this.drawFeatures());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.coordinate && !changes.coordinate.isFirstChange()) {
            if (!this.coordinate || !Array.isArray(this.coordinate)) {
                this.coordinate = [];
            }

            this.drawFeatures();
            this.setActive(this.coordinate.length > 0 ? 'modify' : 'draw');
        }

        if (changes.disabled && !changes.disabled.isFirstChange()) {
            this.setActive(this._active);
        }
    }

    ngOnDestroy(): void {
        this._map.removeInteraction(this._snap);
        this._map.removeInteraction(this._modify);
        this._map.removeInteraction(this._select);
        this._map.removeInteraction(this._draw);
        this._map.removeLayer(this._vectorLayer);
    }

    initDraw() {
        this._draw = new Draw({
            source: this._vectorSource,
            type: GeometryType.POLYGON,
        });
        this._map.addInteraction(this._draw);

        fromMapEvent(this._draw, 'drawend')
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.emitChange();
                this.setActive('modify');
            });

        fromMapEvent(this._map, 'keydown')
            .pipe(untilDestroyed(this))
            .subscribe((e) => {
                if (this.coordinate.length > 0) return;

                const key = (<any>e).originalEvent.key;
                if (key === 'Escape') this._draw.abortDrawing();
                if (key === 'Backspace') this._draw.removeLastPoint();
            });
    }

    initModify() {
        this._select = new Select();
        this._modify = new Modify({
            source: this._vectorSource,
        });

        this._map.addInteraction(this._select);
        this._map.addInteraction(this._modify);

        const selectedFeatures = this._select.getFeatures();
        fromMapEvent(this._select, 'change:active')
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                selectedFeatures.forEach((feature) => {
                    selectedFeatures.remove(feature);
                });
            });

        fromMapEvent(this._modify, 'modifyend')
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.emitChange();
            });
    }

    initSnap() {
        this._snap = new Snap({
            source: this._vectorSource,
        });
        this._map.addInteraction(this._snap);
    }

    emitChange() {
        setTimeout(() => {
            const features = this._vectorSource.getFeatures();
            if (features.length === 0) {
                this.coordinate = [];
                this.coordinateChange.emit([]);
                return;
            }

            const coordinate = features[0].getGeometry().getCoordinates()[0];
            this.coordinate = coordinate.map((c) => this._isavMap.fromMapCoordinate(c));
            this.coordinateChange.emit(this.coordinate);
        });
    }

    setActive(active: DrawMode): void {
        this._active = active;

        const isModify = active === 'modify' && !this.disabled;
        const isDraw = active === 'draw' && !this.disabled;

        this._select.setActive(isModify);
        this._modify.setActive(isModify);
        this._draw.setActive(isDraw);
    }

    drawFeatures(): void {
        if (this.coordinate.length === 0) return; // no features to draw

        this._vectorSource.clear();
        const mapCoordinates = this.coordinate.map((c) => this._isavMap.toMapCoordinate(c));
        const polygon = new Polygon([mapCoordinates]);
        const feature = new Feature(polygon);
        this._vectorSource.addFeature(feature);
    }

    clear(): void {
        this._vectorSource.getFeatures().forEach((feature) => {
            this._vectorSource.removeFeature(feature);
        });
        this.emitChange();
        this.setActive('draw');
    }
}
