import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'isav-error-display',
    templateUrl: './error-display.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavErrorDisplay {
    error$: Observable<string[]>;

    constructor(private cdRef: ChangeDetectorRef) {}

    private _control?: AbstractControl;
    get control(): AbstractControl | undefined {
        return this._control;
    }

    @Input() set control(control: AbstractControl | undefined) {
        this._control = control;
        this.setListeners();
    }

    private setListeners() {
        if (!this.control) return;

        const getErrors = () => Object.values(this.control?.errors || {});
        this.error$ = merge(of(getErrors()), this.control.statusChanges.pipe(map(getErrors)));
        this.cdRef.markForCheck();
    }
}
