import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { LinkDto } from '../../core/dto/link';

@Component({
    selector: 'isav-external-link',
    templateUrl: './external-link.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavExternalLink {
    @Input() link: LinkDto;
}
