import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '../../core/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MetadataService } from '../../core/services/metadata.service';
import { NotificationService } from '../../core/services/notification.service';

@Component({
    selector: 'isav-sign-in',
    templateUrl: './sign-in.html',
    styleUrls: ['./sign-in.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInPage implements OnInit {
    backendError = '';

    formGroup = this.fb.group({
        email: [''],
        password: [''],
    });

    constructor(
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private _cdRef: ChangeDetectorRef,
        private metaService: MetadataService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        this.metaService.setTitle('Sign In');

        const { token, refresh_token } = this.route.snapshot.queryParams;
        if (token) {
            try {
                this.authService.authenticateWithTokens(token, refresh_token);
                this.successfulLogin();
            } catch (e) {
                console.error(e);
                console.error(`Failed to login with passed token, please generate new one!`);
            }
        }
    }

    onSubmit() {
        const { email, password } = this.formGroup.value;
        this.setBackendError('');

        this.authService.login({ email: email, password }).subscribe({
            next: () => {
                this.successfulLogin();
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
                this.setBackendError(error.error?.['message'] ?? '');
            },
        });
    }

    setBackendError(backendError: string) {
        this.backendError = backendError;
        this._cdRef.markForCheck();
    }

    private successfulLogin() {
        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
        this.router.navigateByUrl(returnUrl);
        this.notificationService.add({
            title: 'Success',
            message: 'Successfully logged in',
            type: 'success',
            delay: 2000,
        });
    }
}
