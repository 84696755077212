import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Maybe } from 'true-myth';

@Component({
    selector: 'isav-icon',
    templateUrl: './icon.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'icon',
        'aria-hidden': 'true',
        '[class.is-tight]': 'tight',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavIcon {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input() prefix: 'fas' | 'far' | 'fab' = 'fas';
    @Input() icon?: string;
    @Input() tight = false;

    get iconClasses() {
        return Maybe.of(this.icon)
            .map((i) => `${this.prefix} fa-${i}`)
            .unwrapOr('');
    }
}
