import { HasElementRef } from './has-element-ref';
import { safeAddClass, safeRemoveClass } from './safe-classlist';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Constructor } from '../../utils/types/constructor';

export interface HasLoading {
    loading: boolean;
}

export type HasLoadingCtor = Constructor<HasLoading>;

export function mixinLoading<T extends Constructor<HasElementRef>>(base: T): T & HasLoadingCtor {
    return class extends base implements HasLoading {
        private _loading = false;
        get loading(): boolean {
            return this._loading;
        }
        set loading(value: boolean) {
            this._loading = coerceBooleanProperty(value);

            const addOrRemove = this._loading ? safeAddClass : safeRemoveClass;
            addOrRemove(this._elementRef, 'is-loading');
        }
    };
}
