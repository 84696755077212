import {
    Directive,
    EmbeddedViewRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[isavTeleportTo]',
})
export class IsavTeleportToDirective implements OnChanges, OnDestroy {
    @Input('isavTeleportTo') selector?: string;
    @Input('isavTeleportToContext') passedContext?: any;

    private view?: EmbeddedViewRef<any>;
    private cachedContext: any;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        @Inject(DOCUMENT) private document: Document
    ) {}

    get context(): any {
        if (typeof this.passedContext?.$implicit !== 'undefined') return this.passedContext;
        if (this.passedContext) return { $implicit: this.passedContext };
        return {};
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.selector ||
            (changes.passedContext && this.cachedContext !== this.passedContext)
        ) {
            this.spawnView();
        }
    }

    ngOnDestroy() {
        if (this.view) {
            this.view.destroy();
        }
    }

    private spawnView() {
        if (!this.selector) {
            throw new Error(
                `Invalid selector '${this.selector}' passed to isavTeleportTo. Please pass there a valid css selector.`
            );
        }

        const element = this.document.querySelector(this.selector);
        if (element === null) {
            console.warn(
                `isavTeleportTo directive failed to find an element that matches specified selector '${this.selector}'`
            );
        }

        if (this.view) {
            this.view.destroy();
        }

        this.cachedContext = this.context;
        this.view = this.viewContainerRef.createEmbeddedView(this.templateRef, this.cachedContext);

        if (element) {
            this.view.rootNodes.forEach((node) => element.appendChild(node));
        }
    }
}
