import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { PageDto } from '../dto/page';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PagesResourceService extends BaseSearchService<PageDto> {
    constructor(http: HttpClient) {
        super('pages', http);
    }

    private updatedPages$ = new Subject<void>();

    create(page: Partial<PageDto>): Observable<PageDto> {
        return this.http.post<PageDto>(`/api/pages`, page);
    }

    updated() {
        this.updatedPages$.next();
    }

    notify(): Observable<void> {
        return this.updatedPages$;
    }
}
