import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'isav-authentication-required',
    templateUrl: './authentication-required.html',
    styleUrls: ['./authentication-required.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavAuthenticationRequired implements OnInit {
    from$?: Observable<string>;

    constructor(private readonly route: ActivatedRoute) {}

    ngOnInit() {
        this.from$ = this.route.queryParams.pipe(untilDestroyed(this), pluck('from'));
    }
}
