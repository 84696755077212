import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
} from '@angular/core';
import { NotesResourceService } from '../../../core/api/notes-resource.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Iri } from '../../../core/dto/iri';
import { NoteDto } from '../../../core/dto/note';

@Component({
    selector: 'isav-add-note',
    templateUrl: './add-note.html',
    styleUrls: ['./add-note.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavAddNote {
    @Input() contentIri: Iri;
    @Input() hidden: boolean = true;
    @Output() hiddenChange = new EventEmitter<boolean>();
    loading: boolean = false;
    note: NoteDto;

    constructor(
        private notesService: NotesResourceService,
        private notificationService: NotificationService,
        private _cdRef: ChangeDetectorRef
    ) {}

    add() {
        this.loading = true;
        this.notesService.create({ ...this.note, content: this.contentIri }).subscribe(
            () => {
                this.loading = false;
                this.notificationService.add({
                    title: 'Success',
                    message: 'Successfully added note',
                    type: 'success',
                    delay: 5000,
                });
                this.notesService.updated(this.contentIri);
                this._cdRef.markForCheck();
                this.hide();
            },
            (err) => {
                this.loading = false;
                this._cdRef.markForCheck();
            }
        );
    }

    hide() {
        this.hiddenChange.emit(true);
        this.hidden = true;
        this.note = {} as NoteDto;
    }
}
