import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContentEditorDialogService {
    changed$ = new Subject<string>();

    next(value) {
        this.changed$.next(value);
    }

    notify(): Observable<string> {
        return this.changed$.asObservable();
    }
}
