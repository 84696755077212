<div class="field is-horizontal mb-2">
    <ng-container *ngIf="label">
        <div class="field-label field-label-tooltip">
            <label [for]="id" class="has-text-weight-bold has-text-primary">
                {{ label }}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
            </label>
            <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
        </div>
    </ng-container>
    <div class="field-body">
        <div class="field">
            <div class="control">
                <input
                    type="url"
                    class="input"
                    [id]="id"
                    [value]="value.url"
                    (input)="update('url', $event.target.value)"
                    (focus)="onTouched()"
                    placeholder="https://isaaffik.gl"
                    data-testid="url"
                />
            </div>
        </div>
        <div class="field">
            <div class="control">
                <input
                    type="text"
                    class="input"
                    [value]="value.title"
                    (input)="update('title', $event.target.value)"
                    (focus)="onTouched()"
                    aria-label="Link title"
                    placeholder="Link title"
                    data-testid="title"
                />
            </div>
        </div>
    </div>
</div>
