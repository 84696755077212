<div class="field is-horizontal">
    <div class="field-label field-label-tooltip">
        <button isavBtn (click)="open()" color="primary" size="small" class="mr-1">
            <isav-icon icon="arrows-alt-h"></isav-icon>
        </button>
        <label [for]="id" class="has-text-primary has-text-weight-bold mb-1">
            {{label}}<span class="has-text-danger" *ngIf="mandatory">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <isav-ckeditor-wrapper
            [id]="id"
            [simple]="simple"
            [value]="value"
            (valueChange)="onChange($event)"
            (focused)="onFocus()"
        ></isav-ckeditor-wrapper>
    </div>
</div>
