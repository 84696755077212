<ng-container *isavAuthenticated="true">
    <div class="is-flex" *isavMapControl="'top right'">
        <isav-tooltip-display [map]="true" [tooltipKey]="'Map:markers'"></isav-tooltip-display>
        <button
            type="button"
            [attr.aria-checked]="showMarkers.toString()"
            aria-label="Toggle showing markers on the map"
            (click)="change()"
            style="width: auto"
        >
            <span class="icon mr-1" aria-hidden="true">
                <i class="fas fa-map-marker-alt"></i>
            </span>
            <span class="icon" aria-hidden="true">
                <i
                    class="fas"
                    [class.fa-toggle-on]="showMarkers"
                    [class.fa-toggle-off]="!showMarkers"
                ></i>
            </span>
        </button>
    </div>
</ng-container>

<isav-map-clustered-vector-layer *ngIf="showMarkers">
    <isav-map-marker
        *ngFor="let marker of markers; trackBy: markersTrackBy"
        [coordinate]="marker.location | isavMapCoordinate"
        display="marker"
    >
        <a *isavMapOverlayContent="marker['@id']" routerLink="/my-isaaffik/my-markers">
            {{ marker.title | isavTitleFallback : marker['@id'] }}
        </a>
    </isav-map-marker>
</isav-map-clustered-vector-layer>
