import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { IsavBaseControl } from '../base-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'isav-button-display',
    templateUrl: './button-display.component.html',
    styleUrls: ['./button-display.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ButtonDisplayComponent),
            multi: true,
        },
    ],
})
export class ButtonDisplayComponent extends IsavBaseControl {
    @Input() type = 'button';
    @Input() buttonName = '';
    @Input() color = 'success';
    value: any;

    constructor(injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(injector);
    }

    writeValue(obj: any): void {
        this.value = obj;
        this._cdRef.markForCheck();
    }

    onChange(value: string) {
        this.value = value;
        this._onChange(value);
        this._cdRef.markForCheck();
    }
}
