import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PersistentTokenStorage } from './persistent-token-storage';
import { signIn, signOut } from './auth.store';
import { tap } from 'rxjs/operators';

@Injectable()
export class StoreTokenEffects {
    storeToken$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(signIn),
                tap(({ token, refresh_token }) => {
                    this.tokenStorage.setToken(token);
                    this.tokenStorage.setRefreshToken(refresh_token);
                })
            ),
        { dispatch: false }
    );

    clearTokens$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(signOut),
                tap(() => {
                    this.tokenStorage.clear();
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly tokenStorage: PersistentTokenStorage
    ) {}
}
