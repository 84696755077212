import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
    transform(value: undefined): undefined;
    transform(value: null): null;
    transform(value: string): string;
    transform(value: undefined | null | string): undefined | null | string {
        if (typeof value !== 'string') return value;
        return value.substring(0, 1).toUpperCase() + value.substring(1);
    }
}
