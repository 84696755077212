import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { RoutePointDto } from '../../core/dto/route-point.dto';
import { IsavMap } from '../map';
import { IsavMapOverlayContent } from '../overlay/popup';
import { DEFAULT_COLOR, IsavMapMarker } from './marker';
import { IMapMarker } from './marker.interface';

const POINT_STYLE_CACHE: { [color: string]: { [index: number]: Style } } = {};
function pointStyle(index: number, color: string = '#1b7faf'): Style {
    if (!POINT_STYLE_CACHE?.[color]?.[index]) {
        POINT_STYLE_CACHE[color] = POINT_STYLE_CACHE[color] ?? {};
        POINT_STYLE_CACHE[color][index] = new Style({
            text: new Text({
                scale: 0.8,
                text: (index + 1).toString(),
                fill: new Fill({
                    color: '#FFFFFF',
                }),
            }),
            image: new Circle({
                radius: 8,
                stroke: new Stroke({
                    color: '#FFFFFF',
                }),
                fill: new Fill({
                    color: color,
                }),
            }),
        });
    }

    return POINT_STYLE_CACHE[color][index];
}

const LINE_STYLE_CACHE: { [color: string]: Style | Style[] } = {};
function lineStyle(color: string = '#3399CC'): Style | Style[] {
    if (!LINE_STYLE_CACHE[color]) {
        LINE_STYLE_CACHE[color] = [
            new Style({
                stroke: new Stroke({
                    width: 4,
                    color: '#FFFFFF',
                }),
            }),
            new Style({
                stroke: new Stroke({
                    width: 2,
                    color: color,
                }),
            }),
        ];
    }
    return LINE_STYLE_CACHE[color];
}

@UntilDestroy()
@Component({
    selector: 'isav-map-route',
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: IsavMapMarker, useExisting: forwardRef(() => IsavMapRoute) }],
})
export class IsavMapRoute implements IMapMarker, OnChanges, OnInit, OnDestroy {
    @Input() routePoints: RoutePointDto[] = [];
    @Input() center: boolean = false;
    @Input() color: string = DEFAULT_COLOR;

    private readonly layer = new VectorLayer({
        source: new VectorSource({ wrapX: false }),
        zIndex: 200,
    });
    private _popup: IsavMapOverlayContent;
    private _points: Feature<Point>[] = [];

    constructor(private isavMap: IsavMap) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.routePoints || changes.color) {
            this.refreshPoints();
        }
    }

    ngOnInit(): void {
        this.isavMap.map.addLayer(this.layer);
        this.isavMap.viewChanged.pipe(untilDestroyed(this)).subscribe(() => this.refreshPoints());
    }

    ngOnDestroy(): void {
        this.isavMap.map.removeLayer(this.layer);
        this.layer.dispose();
    }

    addPopup(popup: IsavMapOverlayContent): void {
        this._popup = popup;
        this._points.forEach((p) => p.set('isavMapOverlayContent', popup));
    }

    private refreshPoints() {
        this.layer.getSource()?.clear();
        if (!this.routePoints || !this.routePoints.length) return;

        const pointCoords: Coordinate[] = this.routePoints.map(
            ({ location: { longitude, latitude } }) => {
                return this.isavMap.toMapCoordinate([longitude, latitude]);
            }
        );

        const lineFeature = new Feature(new LineString(pointCoords));
        lineFeature.setStyle(lineStyle(this.color));
        this.layer.getSource().addFeature(lineFeature);

        this._points = pointCoords.map((coords) => new Feature(new Point(coords)));
        this._points.forEach((feature, index) => {
            feature.setStyle(pointStyle(index, this.color));
            feature.set('isavMapOverlayContent', this._popup);
        });
        this.layer.getSource().addFeatures(this._points);

        if (this.center && pointCoords.length > 0) {
            this.isavMap.view.fit(lineFeature.getGeometry(), { padding: [30, 30, 30, 30] });
        }
    }
}
