import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PageDto } from '../../../core/dto/page';

export const CARD_ICONS: string[] = [
    'bed',
    'calendar-alt',
    'compass',
    'dolly-flatbed',
    'edit',
    'file-signature',
    'globe',
    'globe-americas',
    'globe-europe',
    'helicopter',
    'map-marked-alt',
    'plane',
    'ship',
    'truck',
    'warehouse',
];

@Component({
    selector: 'isav-hint-card',
    templateUrl: './hint-card.html',
    styleUrls: ['./hint-card.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavHintCard {
    @Input() card: PageDto;
    @Input() blue: boolean = false;

    cardIcons = CARD_ICONS;
}
