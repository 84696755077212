import { Observable } from 'rxjs';

/**
 * gathers all synchronous emits and emits last one on the next event loop tick
 */
export function debounceTick<T>(): (source: Observable<T>) => Observable<T> {
    return (source) => {
        return new Observable<T>((subscriber) => {
            let timeout: null | ReturnType<typeof setTimeout> = null;
            let lastValue: T;

            const sub = source.subscribe({
                next: (value) => {
                    lastValue = value;

                    if (!timeout) {
                        timeout = setTimeout(() => {
                            subscriber.next(lastValue);
                            timeout = null;
                        });
                    }
                },
                complete: () => {
                    setTimeout(() => subscriber.complete());
                },
                error: (err) => subscriber.error(err),
            });

            return () => {
                sub.unsubscribe();
                if (timeout) clearTimeout(timeout);
            };
        });
    };
}
