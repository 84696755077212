import { Pipe, PipeTransform } from '@angular/core';
import { slug } from '../../core/slug';

@Pipe({
    name: 'isavViewLink',
    pure: true,
})
export class IsavViewLink implements PipeTransform {
    transform(value: string | null | undefined, prefix: string): any {
        const name = value ? '/' + slug(value) : '';
        return `/${prefix}/view${name}`;
    }
}
