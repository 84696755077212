import { createAction, props } from '@ngrx/store';
import { HydraProperties } from '../dto/hydra';
import { SearchParams } from './search-state';

export interface SearchProps {
    namespace: string;
    query: SearchParams;
}

export interface SearchResults {
    namespace: string;
    results: HydraProperties<any>;
}

export const createSearchNamespace = createAction(
    'search::create-namespace',
    props<{ namespace: string; self: boolean }>()
);
export const beginSearch = createAction('search::beginSearch', props<SearchProps>());
export const updateQuery = createAction('search::updateQuery', props<SearchProps>());
export const saveResults = createAction('search::saveResults', props<SearchResults>());
export const searchSort = createAction('search::sort', props<SearchProps>());
