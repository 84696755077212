import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {
    Instance as PopperInstance,
    createPopper,
    Placement,
    PositioningStrategy,
} from '@popperjs/core';

@Directive({
    selector: '[isavPopover]',
})
export class IsavPopover implements OnChanges, OnDestroy {
    @Input('isavPopover') anchorElement?: HTMLElement;
    @Input('placement') placement: Placement = 'top';
    @Input('strategy') strategy: PositioningStrategy = 'absolute';

    private popper?: PopperInstance;

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.anchorElement) {
            this.popper?.destroy();
            if (this.anchorElement) {
                this.popper = createPopper(this.anchorElement, this.elementRef.nativeElement, {
                    placement: this.placement,
                    strategy: this.strategy,
                });
            }
        }
    }

    ngOnDestroy() {
        this.popper?.destroy();
    }
}
