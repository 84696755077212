import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';
import { AutoMatchDto } from '../dto/auto-match';

@Injectable()
export class AutoMatchesResourceService extends BaseSearchService<AutoMatchDto> {
    constructor(http: HttpClient) {
        super('auto_matches', http);
    }
}
