import { Pipe, PipeTransform } from '@angular/core';
import { IMapControl } from './control';

@Pipe({
    name: 'sortControls',
    pure: true,
})
export class SortControlsPipe implements PipeTransform {
    transform(value: IMapControl[]): IMapControl[] {
        return value.slice().sort((a, b) => a.getOrder() - b.getOrder());
    }
}
