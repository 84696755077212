import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';

export type INotifType = 'warning' | 'danger' | 'info' | 'success' | 'primary';

@Component({
    selector: 'isav-notification',
    templateUrl: './notification.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IsavNotification {
    @Input() title?: string;
    @Input() message?: string;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('class') classes = '';
    @Input() type: INotifType = 'primary';
    @Input() size: 'small' | 'normal' | 'medium' | 'large' = 'normal';
    @Output() remove = new EventEmitter<void>();

    @HostBinding('attr.class')
    get hostClass(): string {
        return `message is-${this.size} is-${this.type} ${this.classes}`;
    }
}
