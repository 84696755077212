import {
    createAction,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';
import { TokenDto } from '../dto/token';
import { Nullable } from '../../utils/types/nullable';
import { produceState } from '../../utils/store';

export interface AuthState {
    readonly token: Nullable<string>;
    readonly refresh_token: Nullable<string>;
}

export const featureKey = 'auth';

export const signIn = createAction('auth::sign-in', props<TokenDto>());
export const signOut = createAction('auth::sign-out');

export const selectAuthFeature = createFeatureSelector<AuthState>(featureKey);
export const selectToken = createSelector(selectAuthFeature, (state) => state.token);
export const selectState = createSelector(selectAuthFeature, (state) => {
    return { token: state.token, refreshToken: state.refresh_token };
});

export const initialState: () => AuthState = () => ({ token: null, refresh_token: null });
const reducer = createReducer(
    initialState(),
    on(
        signIn,
        produceState((state, action) => {
            state.token = action.token;
            state.refresh_token = action.refresh_token ?? null;
        })
    ),
    on(signOut, initialState)
);

export function authReducer(state, action) {
    return reducer(state, action);
}
