import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsavTable } from './table';
import {
    IsavCellOutlet,
    IsavFooterRow,
    IsavFooterRowDef,
    IsavHeaderRow,
    IsavHeaderRowDef,
    IsavNoDataRow,
    IsavNoDataRowDef,
    IsavRow,
    IsavRowDef,
} from './row';
import {
    IsavCell,
    IsavCellDef,
    IsavColumnDef,
    IsavFooterCell,
    IsavFooterCellDef,
    IsavHeaderCell,
    IsavHeaderCellDef,
} from './cell';
import { LayoutModule } from '@angular/cdk/layout';

const TABLE_EXPORTED_DECLARATIONS = [
    IsavTable,

    // row
    IsavHeaderRow,
    IsavHeaderRowDef,
    IsavRow,
    IsavRowDef,
    IsavFooterRow,
    IsavFooterRowDef,
    IsavNoDataRow,
    IsavNoDataRowDef,

    // cell
    IsavHeaderCell,
    IsavHeaderCellDef,
    IsavCell,
    IsavCellDef,
    IsavFooterCell,
    IsavFooterCellDef,

    IsavColumnDef,
    IsavCellOutlet,
];

@NgModule({
    imports: [CommonModule, LayoutModule],
    declarations: TABLE_EXPORTED_DECLARATIONS,
    exports: [TABLE_EXPORTED_DECLARATIONS],
})
export class IsavTableModule {}
