import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PageDto } from '../../core/dto/page';

@Component({
    selector: 'isav-footer-link',
    templateUrl: './footer-link.html',
    styleUrls: ['./footer-link.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavFooterLink {
    @Input() page: PageDto;
}
