<div class="modal is-active" (isavDialog)="close()">
    <div class="modal-background"></div>
    <div class="modal-card is-relative">
        <div class="modal-card-head">
            <p class="modal-card-title">
                <span>Marker creation</span>
            </p>
            <button class="delete" aria-label="close" type="button" (click)="close()"></button>
        </div>

        <div class="modal-card-body">
            <form [formGroup]="form">
                <isav-text-input
                    label="1. Title"
                    formControlName="title"
                    tooltipKey="Marker:title"
                ></isav-text-input>
                <isav-textarea-input
                    label="2. Description"
                    formControlName="description"
                    tooltipKey="Marker:description"
                ></isav-textarea-input>
                <isav-date-input
                    label="3. Start Date"
                    formControlName="startDate"
                    tooltipKey="Marker:startDate"
                ></isav-date-input>

                <isav-date-input
                    label="4. End Date"
                    formControlName="endDate"
                    tooltipKey="Marker:endDate"
                ></isav-date-input>
            </form>
        </div>

        <div class="modal-card-foot">
            <a isavBtn color="success" (click)="create()" [disabled]="!form.valid"> Create </a>
            <button data-testid="btn-close" isavBtn (click)="close()">Close</button>
        </div>
        <isav-loader-overlay *ngIf="loading"></isav-loader-overlay>
    </div>
</div>
