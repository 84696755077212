import { ChangeDetectionStrategy, Component, Directive } from '@angular/core';
import { ViewportObserver } from '../../shared/viewport/viewport';
import { negate } from '../../utils/rxjs/negate';

@Directive({
    selector: '[isavSimpleSearchSubmit]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'simple-search-submit',
    },
})
export class SimpleSearchSubmit {}

@Directive({
    selector: '[isavSimpleSearchForm]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'simple-search-form',
    },
})
export class SimpleSearchForm {}

@Directive({
    selector: '[isavSimpleSearchContent]',
})
export class SimpleSearchContent {}

@Directive({
    selector: '[isavSimpleSearchMap]',
})
export class SimpleSearchMap {}

@Component({
    selector: 'isav-simple-search-container',
    templateUrl: './simple-search-container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleSearchContainer {
    readonly isNotMobile$ = this.viewport.isMobile$().pipe(negate);

    constructor(private viewport: ViewportObserver) {}
}
