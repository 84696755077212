import { last } from 'ramda';
import { isVowel, lastTwo } from '../../utils/strings';
import { TypeDto } from '../dto/type';
import { OrganisationDto } from '../dto/organisation';
import { TopicDto } from '../dto/topic';
import { CountryDto } from '../dto/country';
import { UserProfileTypeDto } from '../dto/user-profile-type';
import { ThemeDto } from '../dto/theme';
import { DatalinkFormatDto } from '../dto/datalink-format';
import { DepartmentDto } from '../dto/department';
import { RegisteredCountryDto } from '../dto/registered-country';
import { RegionDto } from '../dto/region';
import { TransportDto } from '../dto/transport';
import { LogisticStatusDto } from '../dto/logistic-status';
import { SarSkillDto } from '../dto/sar-skill';
import { SarEquipmentDto } from '../dto/sar-equipment';
import { CourseFieldOfStudyDto } from '../dto/course-field-of-study';
import { CourseLevelDto } from '../dto/course-level';
import { ActivityDto } from '../dto/activity';
import { GuideCategoryDto } from '../dto/guide-category';

export enum TaxonomyTypes {
    Type = 'Type',
    Organisation = 'Organisation',
    Topic = 'Topic',
    Theme = 'Theme',
    Country = 'Country',
    UserProfileType = 'UserProfileType',
    RegisteredCountry = 'RegisteredCountry',
    DatalinkFormat = 'DatalinkFormat',
    Department = 'Department',
    Region = 'Region',
    Transport = 'Transport',
    LogisticStatus = 'LogisticStatus',
    SarSkill = 'SarSkill',
    SarEquipment = 'SarEquipment',
    CourseFieldOfStudy = 'CourseFieldOfStudy',
    CourseLevel = 'CourseLevel',
    Activity = 'Activity',
    GuideCategory = 'GuideCategory',
}

export interface TaxonomyTypesMap {
    Type: TypeDto;
    Organisation: OrganisationDto;
    Topic: TopicDto;
    Theme: ThemeDto;
    Country: CountryDto;
    UserProfileType: UserProfileTypeDto;
    DatalinkFormat: DatalinkFormatDto;
    Department: DepartmentDto;
    RegisteredCountry: RegisteredCountryDto;
    Region: RegionDto;
    Transport: TransportDto;
    LogisticStatus: LogisticStatusDto;
    SarSkill: SarSkillDto;
    SarEquipment: SarEquipmentDto;
    CourseFieldOfStudy: CourseFieldOfStudyDto;
    CourseLevel: CourseLevelDto;
    Activity: ActivityDto;
    GuideCategory: GuideCategoryDto;
}

export const taxonomiesPluralNamesExceptionsDictionary = {
    Organisation: 'Organisations',
    Region: 'Regions',
};

export function pluralizeTaxonomyName(name: string) {
    return pluralizeName(name)
        .split(/(?=[A-Z])/g)
        .join('_')
        .toLowerCase();
}

/**
 * Rules taken from:
 * https://www.grammarly.com/blog/plural-nouns/
 *
 * Exceptions are added to the exception dictionary above.
 *
 * @param name - taxonomy name to pluralize
 */
function pluralizeName(name: string): string {
    // 3, 10 and exceptions
    if (taxonomiesPluralNamesExceptionsDictionary[name]) {
        return taxonomiesPluralNamesExceptionsDictionary[name];
    }

    // 2, 7
    if (['ss', 'sh', 'ch'].includes(lastTwo(name)) || ['z', 's', 'x', 'o'].includes(last(name))) {
        return name + 'es';
    }

    if (/f$|fe$/.test(name)) return name.replace(/f$|fe$/, 'ves'); // 4

    // 5
    if (last(name) === 'y' && !isVowel(lastTwo(name)[0])) {
        return name.substring(0, name.length - 1) + 'ies';
    }

    if (/us$/.test(name)) return name.replace(/us$/, 'i'); // 8

    if (/is$/.test(name)) return name.replace(/is$/, 'es'); // 9

    if (/on$/.test(name)) return name.replace(/on$/, 'a'); // 10

    // 1, 6
    return name + 's';
}
