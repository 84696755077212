<div class="field is-horizontal mb-2">
    <div class="field-label field-label-tooltip">
        <label [for]="id" class="has-text-weight-bold has-text-primary">
            {{label}}<span *ngIf="mandatory" class="has-text-danger">&nbsp;*</span>
        </label>
        <isav-tooltip-display [tooltipKey]="tooltipKey"></isav-tooltip-display>
    </div>
    <div class="field-body">
        <div class="field">
            <div class="control">
                <isav-select
                    [id]="id"
                    [placeholder]="placeholder"
                    [value]="value"
                    (valueChange)="onChange($event)"
                    [multiple]="multiple"
                    (touched)="onTouched()"
                    [dropdownInBody]="dropdownInBody"
                    [short]="short"
                >
                    <isav-option *ngFor="let option of options" [value]="option.value">
                        {{ option.text }}
                    </isav-option>
                </isav-select>
            </div>
        </div>
    </div>
</div>
