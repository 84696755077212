import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Coordinate } from 'ol/coordinate';
import { IsavMap } from '../../../map/map';
import { IsavBaseControl } from '../base-control';
import { MapLocationDto } from '../../../core/dto/map-location';
import { IsavMapType, MapType } from '../../../map/map-type';

export interface CoordinatesInputValue {
    latitude: number | null;
    longitude: number | null;
    name: string;
}

@Component({
    selector: 'isav-coordinates-input',
    templateUrl: './coordinates-input.html',
    styleUrls: ['./coordinates-input.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsavCoordinatesInput),
            multi: true,
        },
    ],
})
export class IsavCoordinatesInput extends IsavBaseControl {
    value: CoordinatesInputValue = { latitude: null, longitude: null, name: '' };
    coordinate: Coordinate | null = null;
    @Input() mapType: MapType = IsavMapType.Greenland;
    @ViewChild(IsavMap, { read: IsavMap }) isavMap: IsavMap;

    constructor(_injector: Injector, private _cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    writeValue(obj: any): void {
        if (typeof obj === 'object' && obj !== null) {
            this.value.longitude = Number(obj.longitude) || null;
            this.value.latitude = Number(obj.latitude) || null;
            this.value.name = typeof obj.name === 'string' ? obj.name : '';
        }

        this._cdRef.markForCheck();
        this.updateCoordinates();
    }

    update(property: 'latitude' | 'longitude', value: number | null) {
        const numberValue = Number(value);
        this.value[property] = !value || Number.isNaN(numberValue) ? null : numberValue;

        this.updateCoordinates();
        this.emitValue();
        this._cdRef.markForCheck();
    }

    updateName(value: string) {
        this.value.name = value;
        this._onChange(this.value);
        this._cdRef.markForCheck();
    }

    onTouched() {
        this._onTouched();
    }

    changeCoordinate(coords: Coordinate | null): void {
        if (coords === null) {
            this.value.longitude = null;
            this.value.latitude = null;
        } else {
            const [longitude, latitude] = coords;
            this.value.longitude = longitude;
            this.value.latitude = latitude;
        }

        // ISAV-619 - Clear name after map click
        this.value.name = '';

        this.updateCoordinates();
        this.emitValue();
        this._cdRef.markForCheck();
    }

    setCoordinatesFromSearch(location: MapLocationDto) {
        this.value.longitude = location.longitude;
        this.value.latitude = location.latitude;
        this.value.name = location.name;
        this.updateCoordinates();
        this.emitValue();

        // center on new coordinates
        this.isavMap.view.setCenter(this.isavMap.toMapCoordinate(this.coordinate!));
    }

    expandMap() {
        this.isavMap.toggleFullScreenMode(true);
    }

    private emitValue() {
        this._onChange(this.value);
    }

    private updateCoordinates() {
        if (this.value.longitude && this.value.latitude)
            this.coordinate = [this.value.longitude, this.value.latitude];
        else this.coordinate = null;
    }
}
