import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { DialogManager } from '../dialog/dialog.service';

export interface ImageGalleryModalData {
    images: string[];
    current: number;
}

@Component({
    selector: 'isav-image-gallery-modal',
    templateUrl: './image-gallery-modal.html',
    styleUrls: ['./image-gallery-modal.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageGalleryModal implements OnInit {
    images: string[] = [];
    current: number = 0;
    imageLoaded = false;

    constructor(private dialogManager: DialogManager<ImageGalleryModalData, void>) {}

    @HostListener('keyup', ['$event.key']) onArrowDown(key: string) {
        if (key === 'ArrowRight') {
            this.next();
        }
        if (key === 'ArrowLeft') {
            this.prev();
        }
    }

    ngOnInit() {
        if (this.dialogManager.data) {
            this.images = this.dialogManager.data.images;
            this.current = this.dialogManager.data.current;
        } else {
            this.close();
        }
    }

    setLoaded(val: boolean) {
        this.imageLoaded = val;
    }

    get image() {
        if (!this.images?.length) return '';
        return this.images[this.current] + '/download';
    }

    next() {
        if (this.current < this.images.length - 1) {
            this.setLoaded(false);
            this.current++;
        }
    }

    prev() {
        if (this.current > 0) {
            this.setLoaded(false);
            this.current--;
        }
    }

    close(): void {
        this.dialogManager.close();
    }
}
