<footer class="footer">
    <div class="columns is-desktop is-variable is-6">
        <div class="column">
            <h2 class="title is-3">ISAAFFIK</h2>
            <p class="title-margin">
                Isaaffik is the Greenlandic word for gateway. Isaaffik Arctic Gateway is a user
                driven web platform supporting research and collaboration. Anyone engaged with
                Arctic research, education, infrastructure, and logistics may join Isaaffik.
            </p>
            <p>
                <strong>Email: </strong>
                <a href="mailto:info@isaaffik.org">info@isaaffik.org</a>
            </p>
        </div>
        <div class="column">
            <h2 class="footer-title">About</h2>
            <isav-footer-link *ngFor="let page of about" [page]="page"></isav-footer-link>
        </div>
        <div class="column">
            <h2 class="footer-title">Quick Guides</h2>
            <isav-footer-link *ngFor="let page of quickGuides" [page]="page"></isav-footer-link>
        </div>
        <div class="column">
            <h2 class="footer-title">Shortcuts</h2>
            <isav-footer-link *ngFor="let page of shortcuts" [page]="page"></isav-footer-link>
        </div>
    </div>
</footer>
