import { animate, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'isav-smooth-height',
    templateUrl: './smooth-height.html',
    styleUrls: ['./smooth-height.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('changeHeight', [
            transition('void <=> *', []),
            transition('* <=> *', [style({ height: '{{height}}px' }), animate('.8s ease')], {
                params: { height: 0 },
            }),
        ]),
    ],
})
export class IsavSmoothHeight implements OnChanges {
    @Input() trigger: any;
    private height: number = 0;

    @HostBinding('@changeHeight') get changeHeight() {
        return { value: this.trigger, params: { height: this.height } };
    }

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.height = this.elementRef.nativeElement.clientHeight;
    }
}
