import { Injectable } from '@angular/core';
import { LogisticDto } from '../dto/logistic';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './base-search-service';

@Injectable()
export class LogisticsResourceService extends BaseSearchService<LogisticDto> {
    constructor(http: HttpClient) {
        super('logistics', http);
    }
}
