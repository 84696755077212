import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { uniq } from 'ramda';
import { DocumentDto } from '../../../core/dto/document';
import { Iri } from '../../../core/dto/iri';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { IsavDocumentUploaderDialog } from '../document-uploader-dialog/document-uploader-dialog';

@UntilDestroy()
@Component({
    selector: 'isav-document-uploader',
    templateUrl: './document-uploader.html',
    styleUrls: ['./document-uploader.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavDocumentUploader {
    @Input() multiple: boolean = false;
    @Input() files: Iri[] = [];
    @Output() filesChange = new EventEmitter<Iri[]>();

    get canUploadFile() {
        return this.files.length === 0 || this.multiple;
    }

    constructor(private dialogService: DialogService, private cdRef: ChangeDetectorRef) {}

    handleFilesToUpload(files: File[]): void {
        this.dialogService
            .spawnComponent(IsavDocumentUploaderDialog, this.multiple ? files : [files[0]])
            .closed$.pipe(untilDestroyed(this))
            .subscribe((documents: DocumentDto[]) => {
                if (!documents.length) return;

                const iris = documents.map((d) => d['@id']);
                this.files = uniq([...this.files, ...iris]);
                this.filesChange.emit(this.files);
                this.cdRef.markForCheck();
            });
    }

    filesTrackBy(index: number, item: Iri): any {
        return item;
    }

    removeFile(iri: string): void {
        this.files = this.files.filter((f) => f !== iri);
        this.filesChange.emit(this.files);
    }
}
