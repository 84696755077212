import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../core/auth/auth.service';
import { NotificationService } from '../../core/services/notification.service';

@UntilDestroy()
@Component({
    selector: 'isav-top-navigation',
    templateUrl: './top-navigation.html',
    styleUrls: ['./top-navigation.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigation implements OnInit {
    constructor(
        public router: Router,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.cdRef.markForCheck();
            });
    }

    logout() {
        this.authService.logout();
        this.notificationService.add({
            title: 'Success',
            message: 'Successfully logged out',
            type: 'success',
            delay: 5000,
        });
    }
}
