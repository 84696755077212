import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { ContentService } from '../../../core/api/content.service';
import { EquipmentSarReadDto } from '../../../core/dto/equipment-sar-read';
import { SarSarReadDto } from '../../../core/dto/sar-sar-read';
import { SkillSarReadDto } from '../../../core/dto/skill-sar-read';
import { DialogManager } from '../../../shared/dialog/dialog.service';

@Component({
    selector: 'isav-edit-sar-dialog',
    templateUrl: 'edit-sar-dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavEditSarDialog implements OnInit {
    form = this.fb.group({
        skills: this.fb.array([]),
        equipments: this.fb.array([]),
    });

    loading = false;

    get skills(): AbstractControl[] {
        return (this.form.get('skills') as UntypedFormArray).controls;
    }

    get equipments(): AbstractControl[] {
        return (this.form.get('equipments') as UntypedFormArray).controls;
    }

    constructor(
        private dialogManager: DialogManager<SarSarReadDto, SarSarReadDto>,
        private fb: UntypedFormBuilder,
        private contentService: ContentService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (!this.dialogManager.data?.['@id']) {
            throw new Error('Missing @id in data passed to popup!');
        }

        this.dialogManager.data?.skills.forEach((value) => {
            this.addSkill(value);
        });
        this.dialogManager.data?.equipments.forEach((value) => {
            this.addEquipment(value);
        });
    }

    id(index: number, element: string): string {
        return `isav-edit-sar-dialog-${index}-${element}`;
    }

    save(): void {
        this.loading = true;

        const { skills, equipments } = this.form.value;
        const coerceNumberOfParticipants = (value) => ({
            ...value,
            numberOfParticipants: Number(value.numberOfParticipants),
        });
        const payload = {
            skills: skills.map(coerceNumberOfParticipants),
            equipments: equipments.map(coerceNumberOfParticipants),
        };

        const iri = this.dialogManager.data!['@id'];
        this.contentService
            .patch<SarSarReadDto>(iri, payload)
            .pipe(
                switchMap(() => {
                    return this.contentService.getByIri<SarSarReadDto>(iri);
                })
            )
            .subscribe(
                (data) => {
                    this.dialogManager.close(data);
                },
                () => {
                    this.loading = false;
                    this.cdRef.markForCheck();
                }
            );
    }

    close(): void {
        this.dialogManager.close();
    }

    addSkill(value?: SkillSarReadDto): void {
        const control = this.fb.group({
            numberOfParticipants: [value?.numberOfParticipants ?? 0],
            description: [value?.description ?? ''],
            sarSkill: [value?.sarSkill ?? null],
        });
        (this.form.get('skills') as UntypedFormArray).push(control);
    }

    removeSkill(index: number) {
        (this.form.get('skills') as UntypedFormArray).removeAt(index);
    }

    addEquipment(value?: EquipmentSarReadDto): void {
        const control = this.fb.group({
            numberOfParticipants: [value?.numberOfParticipants ?? 0],
            description: [value?.description ?? ''],
            sarEquipment: [value?.sarEquipment ?? null],
        });
        (this.form.get('equipments') as UntypedFormArray).push(control);
    }

    removeEquipment(index: number) {
        (this.form.get('equipments') as UntypedFormArray).removeAt(index);
    }
}
