import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchService } from '../../core/search/search.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[isav-sort-header-cell]',
    templateUrl: './sort-header-cell.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'has-action', // we need to change cursor to pointer
        tabindex: '0', // for keyboard users so they can focus the heading
    },
})
export class IsavSortHeaderCell<T = any> implements OnInit, OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('isav-sort-header-cell') sortOn?: string;

    sorting = 'none';

    private searchSub = Subscription.EMPTY;

    constructor(private searchService: SearchService<T>, private cdRef: ChangeDetectorRef) {}

    /**
     * Returns a readable sort on name
     * types.id -> types
     * startDate[after] -> start date
     */
    private get readableSortOnName(): string {
        if (!this.sortOn) return '';
        return this.sortOn?.replace(/[.\[].*$/g, '')?.replace(/(?=[A-Z])/g, ' ');
    }

    /**
     * Aria label for assistive technologies users so they can be informed about this element
     * actions
     */
    @HostBinding('attr.aria-label')
    get ariaLabel(): string {
        const sorting = this.sorting !== 'asc' ? 'ascending' : 'descending';
        return `Sort results in ${sorting} order by ${this.readableSortOnName}`;
    }

    ngOnInit() {
        this.searchSub = this.searchService
            .searchState()
            .pipe(
                map((results) => {
                    const order = results.query[`order[${this.sortOn}]`];
                    return String(order || 'none');
                })
            )
            .subscribe((sorting) => {
                this.sorting = sorting;
                this.cdRef.markForCheck();
            });
    }

    ngOnDestroy() {
        this.searchSub.unsubscribe();
    }

    @HostListener('click')
    @HostListener('keydown.enter')
    onClick() {
        if (!this.sortOn) return;

        const direction = this.sorting !== 'asc' ? 'asc' : 'desc';
        this.searchService.sort(this.sortOn, direction);
    }
}
