/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-host-metadata-property */

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
} from '@angular/core';
import { HasElementRef } from '../common-behaviours/has-element-ref';
import { CanDisableCtor, mixinDisabled } from '../common-behaviours/disabled';
import { HasColorCtor, mixinColor } from '../common-behaviours/color';
import { safeAddClass, safeRemoveClass } from '../common-behaviours/safe-classlist';
import { HasLoadingCtor, mixinLoading } from '../common-behaviours/loading';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HasSizeCtor, mixinSize } from '../common-behaviours/size';

/**
 * @ignore
 *
 * A base class for mixins
 */
class ButtonBase implements HasElementRef {
    constructor(public _elementRef: ElementRef) {}
}

/**
 * @ignore
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
const ButtonMixinBase: typeof ButtonBase &
    CanDisableCtor &
    HasColorCtor &
    HasLoadingCtor &
    HasSizeCtor = mixinSize(mixinLoading(mixinColor(mixinDisabled(ButtonBase))));

/**
 * Component that adds all necessary support for accessibility and colors for buttons inside
 * isaaffik. This also takes care of applying correct classes to buttons depending on their state.
 * Work with anchor elements to make them look and behave like buttons do.
 *
 * @example
 * <a isavBtn [disabled]="true">This is a disabled anchor that will not link</a>
 *
 * @example
 * <button isavBtn color="primary">This is a primary button</button>
 */
@Component({
    selector: 'button[isavBtn]',
    templateUrl: './button.html',
    inputs: ['disabled', 'color', 'loading', 'size'],
    host: {
        '[attr.type]': 'type',
        '[attr.disabled]': 'disabled || null',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[class.is-loading]': 'loading',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavButton extends ButtonMixinBase {
    @Input() type: string = 'button';

    private _outlined = false;

    get outlined() {
        return this._outlined;
    }

    @Input() set outlined(outlined: boolean) {
        this._outlined = coerceBooleanProperty(outlined);

        const addOrRemoveClass = this._outlined ? safeAddClass : safeRemoveClass;
        addOrRemoveClass(this._elementRef, 'is-outlined');
    }

    constructor(elementRef: ElementRef) {
        super(elementRef);
        safeAddClass(elementRef, 'button');
    }
}

@Component({
    selector: 'a[isavBtn]',
    templateUrl: './button.html',
    inputs: ['disabled', 'color', 'loading', 'outlined', 'size'],
    host: {
        // we need to manually disable the tabindex so a works like disabled button
        '[attr.tabindex]': 'disabled ? -1 : tabIndex',
        '[attr.disabled]': 'disabled || null',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[class.is-loading]': 'loading',
        '[style.pointer-events]': `disabled ? 'none' : null`,
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: IsavButton, useExisting: forwardRef(() => IsavAnchorButton) }],
})
export class IsavAnchorButton extends IsavButton {
    @Input('tabindex') tabIndex = 0;

    constructor(elementRef: ElementRef) {
        super(elementRef);
    }

    /**
     * Disabled default event for elements when button state is disabled.
     * This if for anchor tags since disabled attribute has no effect on them.
     *
     * @param event {MouseEvent} - event passed from browser
     */
    @HostListener('click', ['$event'])
    stopDisabledClick(event: MouseEvent) {
        if (this.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }
}
