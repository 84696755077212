import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    InjectFlags,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ContentService } from '../../../core/api/content.service';
import { Iri } from '../../../core/dto/iri';
import { SarSarReadDto } from '../../../core/dto/sar-sar-read';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { IsavFieldIndexDirective } from '../../../shared/field-index/field-index.directive';
import { IsavEditSarDialog } from './edit-sar-dialog';

let UNIQUE_ID = 0;
@UntilDestroy()
@Component({
    selector: 'isav-edit-sar',
    templateUrl: 'edit-sar.html',
    styleUrls: ['edit-sar.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsavEditSar implements OnInit, OnChanges, OnDestroy {
    @Input() readonly sar: Iri;
    @Input() tooltipKey: string;
    @Input() label: string;
    @Input() id = 'isav-edit-sar-' + UNIQUE_ID++;
    @Input() mandatory = false;

    data?: SarSarReadDto;
    private sarIriChanged = new Subject<Iri>();

    constructor(
        private dialogService: DialogService,
        private contentService: ContentService,
        private cdRef: ChangeDetectorRef,
        protected _injector: Injector
    ) {}

    get control(): undefined {
        return undefined;
    }

    get name(): string {
        return this.label;
    }

    get isMandatory(): boolean {
        return this.mandatory;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sar) {
            this.sarIriChanged.next(changes.sar.currentValue);
        }
    }

    ngOnInit(): void {
        this.sarIriChanged
            .pipe(
                untilDestroyed(this),
                switchMap((iri) => this.contentService.getByIri<SarSarReadDto>(iri))
            )
            .subscribe((data) => {
                this.data = data;
                this.cdRef.markForCheck();
            });
        this.sarIriChanged.next(this.sar);

        this._injector
            .get(IsavFieldIndexDirective, null, InjectFlags.Optional)
            ?.registerField(this);
    }

    ngOnDestroy() {
        this._injector
            .get(IsavFieldIndexDirective, null, InjectFlags.Optional)
            ?.unregisterField(this);
    }

    edit(): void {
        this.dialogService
            .spawnComponent(IsavEditSarDialog, this.data)
            .closed$.pipe(filter((v) => !!v))
            .subscribe((res: SarSarReadDto) => {
                this.data = res;
                this.cdRef.markForCheck();
            });
    }
}
