import { Observable, of, OperatorFunction } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export type Truthy<T> = Exclude<T, undefined | null | false | 0 | ''>;

export function isTruthy<T>(value: T): value is Truthy<T> {
    return !!value;
}

/**
 * Operator like a switchMap, but the function is only applied if the value is truthy.
 * When it is not then it returns `of(false)`.
 *
 * @param {(v: Truthy<T>) => Observable<R>} fn -
 *     function that should take value from previous observable and return a new observable to
 *     subscribe to. this  method is only called when value is truthy.
 * @return {OperatorFunction<T, false | R>}
 */
export const switchTruthyOrFalse = <T, R>(
    fn: (v: Truthy<T>) => Observable<R>
): OperatorFunction<T, R | false> => {
    return (source) => {
        return source.pipe(
            switchMap((value) => {
                if (isTruthy(value)) return fn(value);
                return of(false as const);
            })
        );
    };
};
