<div *ngIf="showTypeSelect" class="field isav-map-type-select">
    <label [for]="'type-select-' + id" class="label is-small mb-0">Type of map: </label>
    <isav-tooltip-display [tooltipKey]="'Map:type'"></isav-tooltip-display>
    <div class="control">
        <div class="select is-small">
            <select [formControl]="mapTypeControl" [id]="'type-select-' + id">
                <option value="Greenland">Greenland map</option>
                <option value="Ice">Ice map</option>
                <option value="WorldStreetMap">World street map</option>
                <option value="Satellite">World satellite map</option>
                <option value="TopographicGreenland">Topographic map (Greenland)</option>
                <option value="WorldStreetMapV4">Isaaffik v4 map</option>
                <option value="GoogleSatellite">Google world map</option>
            </select>
        </div>
    </div>
</div>

<div
    class="isav-map-container"
    [class.hidden]="mapTypeControl.value === 'GoogleSatellite'"
    [class.is-in-fullscreen-mode]="isInFullScreenMode"
>
    <div *ngIf="isInFullScreenMode" class="modal-background is-fixed"></div>
    <div
        [attr.id]="id"
        tabindex="0"
        class="isav-map"
        [class.is-in-fullscreen-mode]="isInFullScreenMode"
        #map
    ></div>
    <isav-map-overlay-handler></isav-map-overlay-handler>

    <ng-container [ngSwitch]="mapTypeControl.value">
        <isav-map-greenland-layer *ngSwitchCase="IsavMapType.Greenland"></isav-map-greenland-layer>
        <isav-map-ice-layer *ngSwitchCase="IsavMapType.Ice"></isav-map-ice-layer>
        <isav-map-world-layer *ngSwitchCase="IsavMapType.WorldStreetMap"></isav-map-world-layer>
        <isav-map-world-layer *ngSwitchCase="IsavMapType.WorldStreetMapV4"></isav-map-world-layer>
        <isav-map-topographic-geus-layer
            *ngSwitchCase="IsavMapType.TopographicGreenland"
        ></isav-map-topographic-geus-layer>
        <isav-map-satellite-sentinel-layer
            *ngSwitchCase="IsavMapType.Satellite"
        ></isav-map-satellite-sentinel-layer>
    </ng-container>

    <isav-map-controls-display></isav-map-controls-display>
    <isav-context-menu-handler></isav-context-menu-handler>

    <ng-container *ngIf="showFullScreenBtn || isInFullScreenMode">
        <button
            *isavMapControl="'top right'; order: 0"
            type="button"
            (click)="toggleFullScreenMode()"
        >
            <isav-icon [icon]="isInFullScreenMode ? 'compress-alt' : 'expand-alt'"></isav-icon>
        </button>
    </ng-container>
</div>

<isav-google-map *ngIf="mapTypeControl.value === 'GoogleSatellite'"></isav-google-map>
